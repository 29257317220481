import { Form, Input, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  dynamic_clear,
  dynamic_request,
  mutation_create_comments,
  query_get_all_child_comments,
  query_get_all_comment,
  useDynamicSelector,
} from "../../../services/redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const SingleFactCheckReplyComment = ({
  set_comment_reply_open,
  main_comment_id,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { id: fact_check_id } = useDynamicSelector(
    "get_fact_check_by_perma_link"
  );

  const { status: create_comment_status, error: create_comment_error } =
    useDynamicSelector("create_comment");

  useEffect(() => {
    if (create_comment_status === "Success") {
      form.resetFields("");
      set_comment_reply_open(false);
      get_all_comment_list();
      get_all_child_comment_list();
      dispatch(dynamic_clear("create_comment"));
    } else if (create_comment_status === "Failure") {
      toast.error("Please Login");
      dispatch(dynamic_clear("create_comment"));
    }
    dispatch(dynamic_clear("create_comment"));
  }, [create_comment_status, create_comment_error]);

  const create_comments_reply = (values) => {
    let key = [{ key: "create_comment", loading: true }];
    let query = mutation_create_comments;
    let variables = {
      json: {
        fact_check_id: fact_check_id,
        parent_comment_id: main_comment_id,
        content: values?.content,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_comment_list = () => {
    let key = [{ key: "get_all_parent_comments", loading: true }];
    let query = query_get_all_comment;
    let variables = {
      fact_check_id: fact_check_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_child_comment_list = () => {
    let key = [{ key: "get_all_child_comments", loading: true }];
    let query = query_get_all_child_comments;
    let variables = {
      parent_comment_id: main_comment_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const on_finish = (values) => {
    create_comments_reply(values);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "95%",
        }}
      >
        <Form onFinish={on_finish} form={form}>
          <div style={{ marginTop: "2vh" }}>
            <Form.Item name="content">
              <Input
                className="user-password reply-comment "
                suffix={
                  <button
                    htmlType="submit"
                    className="fact-check-button"
                    style={{
                      border: "none",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#FFFFFF",
                        marginLeft: "5px",
                      }}
                    >
                      {t("post")}
                    </Typography>
                  </button>
                }
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SingleFactCheckReplyComment;
