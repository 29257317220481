import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const HistorySubtitle = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t("subtitle")}
      name="subtitle"
      style={{ marginTop: "22px" }}
    >
      <Input />
    </Form.Item>
  );
};

export default HistorySubtitle;
