import React, { useEffect, useRef } from "react";
import CustomText from "../custom/custom_text";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";
import { useDarkMode } from "../contexts/dark_mode_provider";

const CorrectionPolicy = () => {
  const { t } = useTranslation();
  const top_ref = useRef(null);
  const { is_dark_mode } = useDarkMode();

  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);

  const first =
    "We fact-check claims that require verification. All the reports published by us hold the proof links or source of the facts provided in the report. And the complete article is checked by our team before publishing it.";
  const second =
    "If you think it needs a correction in the article or needs any improvement in the explanation of the information, please let us know in the “Report Article” section which is available below each article.";
  const third =
    "You can also send us your complaints on our Website, Twitter, Facebook, Instagram page or email us at info@youturn.in.";
  const fourth =
    "We are open to corrections, suggestions, and feedback which is essential for our improvement. Major corrections made in the article will be highlighted under the “Update” section.";

  const five =
    "To err is human. We here in YouTurn are committed to making things right if any article published has any incorrect information readers can report for correction in the respective article through the website. Here is a summary of the correction policy for employees.";

  const six =
    "1. When any correction is reported, it is to be analyzed thoroughly. If the analyst finds it to be a valid correction it is being escalated to the writer. If the correction is found invalid the reported reader should respond to the reader with the details.";

  const seven =
    "2. The respective employee shall make the corrections if applicable as quickly as possible which in turn would reflect in the published article. Any data before posted on the website should be reviewed and authorized by the editor.";

  const eight =
    "3. A separate title named “UPDATE” will be added at the end of the article which will hold the corrected information. The same shall be notified to the reported reader.";

  const nine =
    "4. Errors of fact need to be corrected and updated separately wherein typos, grammatical mistakes, misspellings, wrong word usage, and other such things just needs correction but need not update it separately. Any additional information related to the article may also be added under the “UPDATE” column from time to time despite the correction.";

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);
  return (
    <div
      ref={top_ref}
      className={`correction_policy_container  ${
        is_dark_mode ? "content-dark" : ""
      }`}
    >
      {/* <CustomBreadCrumbs
        pages={"Home/About us/Corrections policy"}
        color={"#000000"}
      /> */}
      <div>
        <CustomText
          weight={"700"}
          text={t("corrections_policy")}
          size={"4vh"}
          font_family={"lato"}
          align={"left"}
          line_height={"5vh"}
          marginBottom={"2vh"}
          class_name={"correction_policy_title"}
        />
      </div>
      <div>
        <CustomText
          text={t(first)}
          font_family={"lato"}
          align={"left"}
          line_height={"3vh"}
          weight={"500"}
        />
      </div>
      <div>
        <CustomText
          text={t(second)}
          font_family={"lato"}
          align={"left"}
          line_height={"3vh"}
          margin_top={"3vh"}
          weight={"500"}
        />
      </div>
      <div>
        <CustomText
          text={t(third)}
          font_family={"lato"}
          align={"left"}
          line_height={"3vh"}
          margin_top={"3vh"}
          weight={"500"}
        />
      </div>
      <div>
        <CustomText
          text={t(fourth)}
          font_family={"lato"}
          align={"left"}
          line_height={"3vh"}
          margin_top={"3vh"}
          weight={"500"}
        />
      </div>

      <div>
        <CustomText
          text={t(five)}
          font_family={"lato"}
          align={"left"}
          line_height={"3vh"}
          margin_top={"3vh"}
          weight={"500"}
        />
      </div>
      <div
        style={{
          width: "90%",
          justifyContent: "center",
          marginLeft: "4vh",
        }}
      >
        <div>
          <CustomText
            text={t(six)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"3vh"}
            weight={"500"}
          />
        </div>
        <div>
          <CustomText
            text={t(seven)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"3vh"}
            weight={"500"}
          />
        </div>
        <div>
          <CustomText
            text={t(eight)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"3vh"}
            weight={"500"}
          />
        </div>
        <div>
          <CustomText
            text={t(nine)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"3vh"}
            weight={"500"}
          />
        </div>
      </div>
    </div>
  );
};

export default CorrectionPolicy;
