import React from "react";
import { Form, Input } from "antd";

import { isMobile } from "react-device-detect";
import { change_tamil_font_style } from "../../../helpers/functions";
import DonationText from "../widgets/donation_text";
import { useTranslation } from "react-i18next";
import CustomText from "../custom/custom_text";
import CustomButton from "../custom/custom_button";
import DonationAmountInput from "../widgets/donation_amount_input";
import AmountCard from "../widgets/amount_card";
import PreviewAmountCard from "./preview_amount_card";
import { useDynamicSelector } from "../../../services/redux";

const DonationCardDummy = (props) => {
  const { height, padding } = props;
  const { t } = useTranslation();
  const { language_id } = useDynamicSelector("get_article");

  const tamil_top_content = "ஒரு சிறிய பங்களிப்பை செய்யுங்கள்";
  const english_top_content = "Make a small contribution...";

  const donation_note_tamil =
    "குறிப்பு: தற்போது இந்திய ரூபாய் நன்கொடைகளை மட்டுமே ஏற்கிறோம்";
  const donation_note_english = "Note: Currently we accept only INR donations";

  return (
    <div
      id="donation-card"
      className="donation-card"
      style={{
        backgroundColor: "#140f2d",
        height: height || "",
        borderRadius: "15px",
        padding: padding ? padding : "5%",
      }}
    >
      <div
        className="donation-card-small"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <div className="donation-and-text-small-card">
          <DonationText padding={"0 0 0 0"} />

          <div
            style={{
              display: "flex",
              width: isMobile ? "min-content" : "100%",
              gap: "10px",
              alignItems: isMobile ? "center" : "",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <text
                className={
                  isMobile
                    ? "donation-main-content font-size-50"
                    : "donation-main-content_char"
                }
              >
                #
              </text>
              <text className="donation-main-content">
                {language_id === "839bcc29-853f-4c4c-8700-98fd88558952"
                  ? "வதந்திகளை வேரறுப்போம்"
                  : "FightAgainst FakeNews"}
              </text>
            </div>
          </div>
        </div>
        <div className="donation-content-middle-container">
          <CustomText
            class_name="donation-content fw-bold"
            text={
              language_id === "839bcc29-853f-4c4c-8700-98fd88558952"
                ? tamil_top_content
                : english_top_content
            }
            size={"15px"}
            font_family={"Kufam"}
            color={"#FFFFFF"}
            align={"left"}
          />
        </div>
        <div className="landing-donation-width">
          <PreviewAmountCard class_name={"donation-input-amt-card"} />

          <div className="center-alignment mt-2">
            <text className="donation-content" style={{ fontSize: "15px" }}>
              {language_id === "839bcc29-853f-4c4c-8700-98fd88558952"
                ? "அல்லது"
                : "Or"}
            </text>
          </div>
          <div style={{ width: "100%" }}>
            <Form>
              <Form.Item name={"amount"}>
                <Input
                  disabled
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginTop: "13px",
                  }}
                  placeholder={t("enter_amount")}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: isMobile ? "20px" : "30px",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <CustomButton
              is_disabled={true}
              name={
                language_id === "839bcc29-853f-4c4c-8700-98fd88558952"
                  ? "வழங்குக"
                  : "Donate"
              }
              width={"60%"}
              max_width={"100px"}
              minWidth={"100px"}
              backgroundColor={"#00acee"}
            />
          </div>
          <div
            style={{
              lineHeight: "12px",
              width: isMobile ? "200px" : "300px",
            }}
          >
            <text
              className="donation_card_bottom_text"
              style={{ color: "gray", fontSize: "12px" }}
            >
              {language_id === "839bcc29-853f-4c4c-8700-98fd88558952"
                ? donation_note_tamil
                : donation_note_english}
            </text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationCardDummy;
