import { Button, Form, Modal, Pagination, Select, Switch, Table } from "antd";
import { HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../../../services/redux";
import {
  delete_user_queries,
  get_all_user_queries,
  get_read_user_queries,
  get_unread_user_queries,
} from "../../../../services/redux/slices/graphql/graphql_users_queries";
import { AiOutlineDelete } from "react-icons/ai";
import UserQueriesForm from "./user_queries_form";
import { toast } from "react-toastify";
import Search from "antd/es/input/Search";
import { countFormat } from "../../../../helpers/constants";

const ContactUsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { Option } = Select;

  const [delete_modal, set_delete_modal] = useState(false);
  const [delete_item_id, set_delete_item_id] = useState("");
  const [mutation_modal, set_mutation_modal] = useState(false);
  const [mutation_form_values, set_mutation_form_values] = useState({});
  const [is_readable, set_is_readable] = useState("read");
  const [search_string, set_search_string] = useState("");
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const {
    items: user_queries_list,
    loading: user_queries_loading,
    pagination: user_queries_pagination,
  } = useDynamicSelector("get_user_queries");

  const {
    items: user_unread_queries_list,
    loading: user_unread_queries_loading,
  } = useDynamicSelector("get_unread_user_queries");

  const {
    status: user_query_delete_status,
    loading: user_query_delete_loading,
    error: user_query_delete_error,
  } = useDynamicSelector("delete_user_query");

  useEffect(() => {
    get_all_user_read_queries();
  }, [search_string, page_limit, page_number]);

  useEffect(() => {
    if (user_query_delete_status === "Success") {
      toast.success("User query deleted successfully");
      handle_close();
      get_all_user_read_queries();
      dispatch(dynamic_clear("delete_user_query"));
    } else if (user_query_delete_error) {
      toast.error(user_query_delete_error?.message);
      dispatch(dynamic_clear("delete_user_query"));
    }
  }, [user_query_delete_status, user_query_delete_error]);

  const get_all_user_read_queries = () => {
    let key = [{ key: "get_user_queries", loading: true }];
    let query = get_all_user_queries;
    let variables = {
      json: {
        search_string: search_string,
        page_number: page_number,
        page_limit: page_limit,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_delete_conformation = () => {
    let key = [{ key: "delete_user_query", loading: true }];
    let query = delete_user_queries;
    let variables = {
      json: {
        id: delete_item_id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_delete = (record) => {
    set_delete_modal(true);
    set_delete_item_id(record?.id);
  };

  const handle_edit = (record) => {
    set_mutation_modal(true);
    set_mutation_form_values(record);
  };

  const handle_close = () => {
    set_delete_modal(false);
    set_mutation_modal(false);
  };

  const handle_change = (value) => {
    set_is_readable(value);
  };

  const on_search = (e) => {
    let search_value = e?.target?.value;
    if (search_value?.length >= 0) {
      set_search_string(search_value);
      set_page_number(1);
    }
  };

  const action_items = (record) => {
    return (
      <HStack justifyContent={"center"} alignItems={"center"}>
        <Pressable
          onPress={() => {
            handle_delete(record);
          }}
        >
          <AiOutlineDelete size={20} />
        </Pressable>
      </HStack>
    );
  };

  let columns = [
    {
      title: t("table:s_no"),
      key: "s_no",
      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },
    {
      title: t("table:name"),
      dataIndex: "first_name",
      key: "name",
      width: "250px",
      // render: (record) => {
      //   const name = `${record?.first_name} ${record?.last_name}`;
      //   return name;
      // },
    },
    {
      title: t("table:email"),
      dataIndex: "email",
      align: "center",
      key: "email",
      width: "300px",
    },
    {
      title: t("table:mobile"),
      dataIndex: "mobile",
      key: "mobile",
      width: "300px",
    },
    {
      title: t("table:message"),
      dataIndex: "message",
      key: "message",
      width: "300px",
    },
    // {
    //   title: t("table:action"),
    //   dataIndex: "",
    //   key: "action",
    //   width: "50px",
    //   align: "center",
    //   render: (record) => action_items(record),
    // },
  ];

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };

  return (
    <VStack space={"10px"}>
      <HStack justifyContent={"flex-end"} space={"10px"}>
        <Search
          enterButton={true}
          onSearch={on_search}
          allowClear={true}
          onChange={on_search}
          placeholder="Search"
          style={{ width: "250px", backgroundColor: "white" }}
          className="admin-list-search"
        />
      </HStack>
      <Table
        columns={columns}
        pagination={false}
        dataSource={user_queries_list}
        loading={user_queries_loading}
        // onRow={(record) => {
        //   return {
        //     onClick: () => handle_edit(record),
        //   };
        // }}
      />

      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={user_queries_pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>
      {/* <Modal
        open={mutation_modal}
        onCancel={handle_close}
        footer={null}
        title={"Update User queries"}
        destroyOnClose={true}
      >
        <UserQueriesForm
          on_cancel={handle_close}
          form={form}
          mutation_form_values={mutation_form_values}
          get_all_user_read_queries={get_all_user_read_queries}
          get_all_user_unread_queries={get_all_user_unread_queries}
        />
      </Modal> */}

      <Modal
        open={delete_modal}
        title={"Confirmation"}
        footer={null}
        onCancel={handle_close}
      >
        <span>Are you sure to delete?</span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button onClick={handle_close} danger>
            Close
          </Button>
          <Button
            onClick={handle_delete_conformation}
            type="primary"
            loading={user_query_delete_loading}
            className="delete-team-member"
          >
            Ok
          </Button>
        </HStack>
      </Modal>
    </VStack>
  );
};

export default ContactUsTable;
