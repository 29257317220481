import { Button, Form, Image, Input, Typography } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useEffect, useState } from "react";
import { ARROW_POST_IMAGE, POST_IMAGE } from "../../../helpers/image_constants";
import {
  dynamic_clear,
  dynamic_request,
  mutation_create_comments,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { retrieveItem } from "../../../helpers/functions";
import { ROUTES } from "../../routes/my_routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const SingleFactCheckCommentForm = ({
  get_all_comment_list,
  set_comment_reply_open,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { is_dark_mode } = useDarkMode();
  const session_id = retrieveItem("session_id");
  const history = useHistory();
  const [commentValue, setCommentValue] = useState("");
  const [postButtonColor, setPostButtonColor] = useState("gray");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const role = retrieveItem("role");

  const { id: fact_check_id } = useDynamicSelector(
    "get_fact_check_by_perma_link"
  );

  const {
    status: create_comment_status,
    error: create_comment_error,
    loading: create_comment_loading,
  } = useDynamicSelector("create_comment");

  const { items } = useDynamicSelector("get_all_reason");

  const on_finish = (values) => {
    if (session_id && (role === "User" || role === "Previleged User")) {
      if (values?.content) {
        create_comments(values);
      }
    } else {
      history.push(`${ROUTES.ADMIN_LOGIN}`);
    }
  };

  const create_comments = (values) => {
    let key = [{ key: "create_comment", loading: true }];
    let query = mutation_create_comments;
    let variables = {
      json: {
        fact_check_id: fact_check_id,
        content: values?.content,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    if (create_comment_status === "Success") {
      toast.success("Comment Created Successfully");
      form.resetFields("");
      set_comment_reply_open(false);
      dispatch(dynamic_clear("create_comment"));
      get_all_comment_list();
      setPostButtonColor("gray");
    } else if (create_comment_status === "Failure") {
      toast.error("Please Login");
      history.push(`${ROUTES.ADMIN_LOGIN}`);
      dispatch(dynamic_clear("create_comment"));
    }
  }, [create_comment_status, create_comment_error]);

  const handleCommentChange = (e) => {
    if (session_id && (role === "User" || role === "Previleged User")) {
      setCommentValue(e.target.value);
      if (e.target.value.length > 0) {
        setPostButtonColor("#00adee");
      } else {
        setPostButtonColor("gray");
      }
    } else if (!session_id && e.target.value.length === 1) {
      toast.error("Please Login");
    }
  };
  const preventZoom = (e) => {
    e.preventDefault();
  };

  const handleInputFocus = () => {
    document.body.style.touchAction = "manipulation";
  };

  const handleInputBlur = () => {
    document.body.style.touchAction = "auto";
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "130px",
        width: "100%",
      }}
      className={is_dark_mode ? "content-dark" : ""}
    >
      <Form onFinish={on_finish} form={form}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Form.Item name="content">
            <Input
              className={`user-password laptop-post-input ${
                isInputFocused ? "no-zoom-input" : ""
              }`}
              placeholder={t("write_a_comment")}
              style={{ WebkitTextSizeAdjust: "none" }}
              onChange={handleCommentChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              // inputMode="none"
              suffix={
                <button
                  htmlType="submit"
                  className="fact-check-button"
                  style={{ backgroundColor: postButtonColor }}
                >
                  <Typography
                    style={{
                      color: "#FFFFFF",
                      marginLeft: "5px",
                    }}
                  >
                    {t("post")}
                  </Typography>
                  <Image
                    style={{
                      width: "18px",
                      height: "18px",
                      marginLeft: "20px",
                    }}
                    visible={false}
                    preview={false}
                    src={ARROW_POST_IMAGE}
                  />
                </button>
              }
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default SingleFactCheckCommentForm;
