import React from "react";
import { TbMailFilled } from "react-icons/tb";
import CustomText from "../../custom/custom_text";
import { useDynamicSelector } from "../../../../services/redux";
import { useDarkMode } from "../../contexts/dark_mode_provider";

const UserEmail = () => {
  const item = useDynamicSelector("get_user");
  const { is_dark_mode } = useDarkMode();

  return (
    <div className="profile_icon_and_text">
      <TbMailFilled style={{ color: is_dark_mode ? "white" : "" }} />
      <CustomText
        text={item?.email ? item?.email : ""}
        font_family={"Lato"}
        class_name={"user-profile-personal-details"}
      />
    </div>
  );
};

export default UserEmail;
