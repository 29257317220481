import React from "react";
import CustomSocialMedia from "../custom/custom_social_media";
import {
  FACEBOOK_COLOR,
  INSTAGRAM_COLOR,
  LINKEDIN_COLOR,
  SHARE_CHAT,
  TELEGRAM_COLOR,
  THREADS_ICON,
  TWITTER_BLACK_BACKGROUND_IMAGE,
  WHATS_APP_LOGO,
  YOUTUBE_COLOR,
} from "../../../helpers/image_constants";
import { useDarkMode } from "../contexts/dark_mode_provider";

const SocialMediaList = ({ width }) => {
  const { is_dark_mode } = useDarkMode();

  const redirect_to_social_media = (socialMedia) => {
    switch (socialMedia) {
      case "Instagram":
        window.open("https://www.instagram.com/youturn.media/", "_blank");
        break;
      case "Facebook":
        window.open("https://www.facebook.com/youturn.in/", "_blank");
        break;
      case "Twitter":
        window.open("https://twitter.com/youturn_in", "_blank");
        break;
      case "YouTube":
        window.open(
          "https://www.youtube.com/channel/UCCLsLUlxvfdnRwG8_Uh40Ew",
          "_blank"
        );
        break;
      case "LinkedIn":
        window.open("https://in.linkedin.com/in/youturn32017", "_blank");
        break;
      case "Telegram":
        window.open("https://t.me/youturn_in", "_blank");
        break;
      case "Share chat":
        window.open(
          "https://sharechat.com/profile/youturn_in?referer=tagProfileSearchPage",
          "_blank"
        );
        break;
      case "Threads":
        window.open("https://www.threads.net/", "_blank");
        break;
      case "Whatsapp":
        window.open(
          "https://api.whatsapp.com/send?phone=916369748351&text=Hi%20You%20Turn!",
          "_blank"
        );
        break;

      default:
        console.log(`Redirecting to ${socialMedia}`);
    }
  };

  return (
    <div
      style={{
        width: width ? width : "30%",
      }}
      className={`social_media_list_card  ${
        is_dark_mode ? "content-dark" : ""
      }`}
    >
      <text className="social_media_list_title">Stay Connected</text>
      <div className="social_media_logo">
        <div className="social_media_image_with_name">
          <CustomSocialMedia
            media_name={"Facebook"}
            logo={FACEBOOK_COLOR}
            on_click={() => redirect_to_social_media("Facebook")}
          />
        </div>
        <div className="social_media_image_with_name">
          <CustomSocialMedia
            media_name={"X (Twitter)"}
            logo={TWITTER_BLACK_BACKGROUND_IMAGE}
            on_click={() => redirect_to_social_media("Twitter")}
          />
        </div>
        <div className="social_media_image_with_name">
          <CustomSocialMedia
            media_name={"Instagram"}
            logo={INSTAGRAM_COLOR}
            on_click={() => redirect_to_social_media("Instagram")}
          />
        </div>
        <div className="social_media_image_with_name">
          <CustomSocialMedia
            media_name={"YouTube"}
            logo={YOUTUBE_COLOR}
            on_click={() => redirect_to_social_media("YouTube")}
          />
        </div>
        <div className="social_media_image_with_name">
          <CustomSocialMedia
            media_name={"LinkedIn"}
            logo={LINKEDIN_COLOR}
            on_click={() => redirect_to_social_media("LinkedIn")}
          />
        </div>
        <div className="social_media_image_with_name">
          <CustomSocialMedia
            media_name={"Telegram"}
            logo={TELEGRAM_COLOR}
            on_click={() => redirect_to_social_media("Telegram")}
          />
        </div>

        <div className="social_media_image_with_name">
          <CustomSocialMedia
            media_name={"WhatsApp"}
            logo={WHATS_APP_LOGO}
            on_click={() => redirect_to_social_media("Whatsapp")}
          />
        </div>
        <div className="social_media_image_with_name">
          <CustomSocialMedia
            media_name={"Share chat"}
            logo={SHARE_CHAT}
            on_click={() => redirect_to_social_media("Share chat")}
          />
        </div>
        <div className="social_media_image_with_name">
          <CustomSocialMedia
            media_name={"Threads"}
            logo={THREADS_ICON}
            on_click={() => redirect_to_social_media("Threads")}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialMediaList;
