import React from "react";
import { useTranslation } from "react-i18next";
import AntdDynamicForm from "../../system/ui/antd_form/antd_form";

const UserContributionShortStory = (props) => {
  const { t } = useTranslation();
  const { color } = props;
  return (
    <AntdDynamicForm.RichText
      label={
        <span style={{ color: color ? color : "black" }}>{"Short story"}</span>
      }
      field="short_story"
      is_disabled={true}
    />
  );
};
export default UserContributionShortStory;
