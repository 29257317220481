import { gql } from "@apollo/client";

export const query_get_author_details = gql`
  query get_all_author_stories(
    $user_profile_id: String
    $staff_profile_id: String
    $language_id: String!
    $page_number: Int
    $page_limit: Int
    $type: String!
  ) {
    get_all_author_stories(
      user_profile_id: $user_profile_id
      staff_profile_id: $staff_profile_id
      language_id: $language_id
      page_number: $page_number
      page_limit: $page_limit
      type: $type
    ) {
      items {
        id
        record_type
        title
        subtitle
        content
        reading_time
        perma_link
        language_id
        views
        thumbnail_img
        created_date_time
        staff_profile {
          id
          name
          profile_pic
          about
          url
        }
        user_profile {
          id
          name
          profile_pic
          about
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
