import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  change_password_query,
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../services/redux";
import { change_tamil_font_style } from "../../helpers/functions";
import toast from "react-hot-toast";
import { ROUTES } from "../routes/my_routes";
import {
  YOUTURN_NEW_LOGO,
  YOU_TURN_LOGO_BLUE,
} from "../../helpers/image_constants";
import { navigate } from "../../helpers/navigator";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const ChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [new_password_visible, set_new_password_visible] = useState(false);
  const [confirm_password_visible, set_confirm_password_visible] =
    useState(false);

  const {
    status: change_password_status,
    error: change_password_error,
    loading: change_password_loading,
  } = useDynamicSelector("change_password");

  const onFinish = (values) => {
    change_password_api_call(values);
  };
  const handle_back = () => {
    navigate(ROUTES.ADMIN_LOGIN);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const new_toggle_password_visibility = () => {
    set_new_password_visible(!new_password_visible);
  };
  const confirm_toggle_password_visibility = () => {
    set_confirm_password_visible(!confirm_password_visible);
  };
  const change_password_api_call = (values) => {
    let key = [{ key: "change_password", loading: true }];
    let query = change_password_query;
    let variables = {
      current_password: values?.current_password,
      new_password: values?.new_password,
    };

    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    if (change_password_status === "Success") {
      form.resetFields("");
      toast.success("Password changed successfully");

      dispatch(dynamic_clear("change_password"));
    } else if (change_password_status === "Failure") {
      if (change_password_error.status_code === "500") {
        toast.error("Something went wrong");
      } else if (
        change_password_error.message ||
        change_password_error.status_code !== "500"
      ) {
        toast.error(change_password_error.message);
      }
      dispatch(dynamic_clear("change_password"));
    }
    dispatch(dynamic_clear("change_password"));
  }, [change_password_status, change_password_error]);

  return (
    <div className="body">
      <div onClick={handle_back} className="back_arrow">
        <img
          style={{
            borderRadius: "9px",
            zIndex: 1,
            objectFit: "cover",
            height: "50px",
            cursor: "pointer",
          }}
          src={YOU_TURN_LOGO_BLUE}
          alt="Profile"
        />
      </div>
      <div className="container" id="container">
        <div className="form-container sign-up">
          <Form onFinish={onFinish} layout="vertical">
            <img
              style={{
                borderRadius: "9px",
                zIndex: 1,
                objectFit: "cover",
                height: "80px",
              }}
              src={YOUTURN_NEW_LOGO}
              alt="Profile"
              className="youturn_logo_mobile_view_only"
            />
            <h2 className="sing_in_text">Create Account</h2>
            <div className="view-text">
              <h6 className="sing_in_text">Already have an account?</h6>
              <a
                href="#"
                className="sing_in_text"
                style={{ marginLeft: "5vh" }}
              >
                Sign in
              </a>
            </div>
            <Form.Item
              className="margin-bottom"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <input
                type="text"
                placeholder="Name"
                style={{ width: "300px" }}
              />
            </Form.Item>
            <Form.Item
              className="margin-bottom"
              name="mobile"
              rules={[{ required: true, message: "Mobile number is required" }]}
            >
              <input
                type="number"
                placeholder="Mobile"
                style={{ width: "300px" }}
              />
            </Form.Item>
            <Form.Item
              className="margin-bottom"
              name="email"
              rules={[{ required: true, message: "Email is required" }]}
            >
              <input
                type="email"
                placeholder="Email"
                style={{ width: "300px" }}
              />
            </Form.Item>

            <button type="submit">
              <div class="leap-frog">
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
              </div>
              "Submit"
            </button>
          </Form>
        </div>
        <div className="form-container sign-in">
          <Form onFinish={onFinish} layout="vertical" form={form}>
            <img
              style={{
                borderRadius: "9px",
                zIndex: 1,
                objectFit: "cover",
                height: "80px",
              }}
              src={YOUTURN_NEW_LOGO}
              alt="Profile"
              className="youturn_logo_mobile_view_only"
            />
            <h1 className="sing_in_text">
              {change_tamil_font_style(t("change_password"), "3vh", _)}
            </h1>
            <div className="view-text">
              <h6
                style={{ textDecoration: "underline" }}
                className="not_register_text"
              ></h6>
              <a href="" style={{ marginLeft: "3vh", color: "white" }}></a>
            </div>
            <Form.Item
              className="margin-bottom"
              name="current_password"
              rules={[
                { required: true, message: "Current Password is required" },
              ]}
            >
              <div style={{ position: "relative" }}>
                <input
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Current Password"
                  style={{ width: "300px" }}
                />
                {passwordVisible ? (
                  <EyeInvisibleOutlined
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <EyeOutlined
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
            </Form.Item>
            <Form.Item
              className="margin-bottom"
              name="new_password"
              rules={[{ required: true, message: "New Password is required" }]}
            >
              <div style={{ position: "relative" }}>
                <input
                  type={new_password_visible ? "text" : "password"}
                  placeholder="New Password"
                  style={{ width: "300px" }}
                />
                {new_password_visible ? (
                  <EyeInvisibleOutlined
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={new_toggle_password_visibility}
                  />
                ) : (
                  <EyeOutlined
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={new_toggle_password_visibility}
                  />
                )}
              </div>
            </Form.Item>

            <Form.Item
              className="margin-bottom"
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: `${t("confirm_password")} is required`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match")
                    );
                  },
                }),
              ]}
            >
              <div style={{ position: "relative" }}>
                <input
                  type={confirm_password_visible ? "text" : "password"}
                  placeholder="Confirm Password"
                  style={{ width: "300px" }}
                />
                {confirm_password_visible ? (
                  <EyeInvisibleOutlined
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={confirm_toggle_password_visibility}
                  />
                ) : (
                  <EyeOutlined
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={confirm_toggle_password_visibility}
                  />
                )}
              </div>
            </Form.Item>

            <button type="submit">
              {change_password_loading ? (
                <div class="leap-frog">
                  <div class="leap-frog__dot"></div>
                  <div class="leap-frog__dot"></div>
                  <div class="leap-frog__dot"></div>
                </div>
              ) : (
                t("change")
              )}
            </button>
          </Form>
        </div>
        <div className="toggle-container">
          <div className="toggle">
            <div className="toggle-panel toggle-left">
              <div
                style={{
                  marginTop: "70px",
                }}
              >
                <img
                  style={{
                    borderRadius: "9px",
                    zIndex: 1,
                    objectFit: "cover",
                    height: "80px",
                  }}
                  src={YOUTURN_NEW_LOGO}
                  alt="Profile"
                />
                <h1>{t("welcome_back")}</h1>
                <p>Unlock Your News Universe! Register Today.</p>
              </div>
            </div>
            <div className="toggle-panel toggle-right">
              <div
                style={{
                  marginTop: "70px",
                }}
              >
                <img
                  style={{
                    borderRadius: "9px",
                    zIndex: 1,
                    objectFit: "cover",
                    height: "80px",
                  }}
                  src={YOUTURN_NEW_LOGO}
                  alt="Profile"
                />
                <h1>{t("welcome")}</h1>
                <p>{t("don't_have_an_account?")}</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                  className="sing_up_button_div"
                >
                  <button
                    className="hidden"
                    id="register"
                    onClick={handle_back}
                  >
                    {t("login")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
