import { Table, Pagination } from "antd"; // Import Pagination component
import React, { useEffect, useState } from "react";
import {
  dynamic_request,
  get_all_donations_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { countFormat } from "../../../../helpers/constants";
import moment from "moment";
import { FaRupeeSign } from "react-icons/fa";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { HStack } from "native-base";
import { query_get_user_donation_list } from "../../../../services/redux/slices/graphql/graphql_user_profile";

const AdminUserProfileDonations = ({ type, user_id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const {
    items: get_user_donation_list,
    loading: list_loading,
    pagination: my_donation_pagination,
  } = useDynamicSelector("get_one_user_donation");

  useEffect(() => {
    if (type === "donations") {
      get_one_user_donations_list();
    }
  }, [type, page_limit, page_number]);

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };
  const get_one_user_donations_list = () => {
    let key = [{ key: "get_one_user_donation", loading: true }];
    let query = query_get_user_donation_list;
    let variables = {
      user_id: user_id,
      page_limit: page_limit,
      page_number: page_number,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  let columns = [
    {
      title: "S.No",
      key: "s_no",
      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },
    {
      title: t("table:references_id"),
      dataIndex: "receipt",
      key: "receipt",
      width: 300,
    },
    {
      title: t("table:date"),
      // dataIndex: "name",
      key: "created_date_time",
      render: (record) => {
        return moment(record?.created_date_time).format("MMM DD, YYYY");
      },
      width: 180,
    },

    {
      title: "Amount ",
      dataIndex: "amount",
      key: "amount",
      align: "right",

      render: (amount) => (
        <>
          <FaIndianRupeeSign />
          {amount}
        </>
      ),
    },
  ];
  return (
    <div>
      <div style={{ maxHeight: 400, overflowY: "auto" }}>
        <Table
          columns={columns}
          loading={list_loading}
          dataSource={get_user_donation_list}
          pagination={false}
          scroll={{ y: 170 }}
        />
      </div>
      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={my_donation_pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>
    </div>
  );
};

export default AdminUserProfileDonations;
