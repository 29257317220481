import { useEffect, useRef, useState } from "react";
import { retrieveItem } from "../../../helpers/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { dynamic_request, useDynamicSelector } from "../../../services/redux";
import { query_get_author_details } from "../../../services/redux/slices/graphql/graphql_author_details";
import UserProfileTitle from "../user_profile/user_profile_title";
import CustomText from "../custom/custom_text";
import { MdOutlinePendingActions, MdPublish } from "react-icons/md";
import CustomFactCheckCategories from "../custom/custom_fact_check_categories";
import { Button, Skeleton, Tabs, Empty } from "antd";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import moment from "moment";
import AuthorProfileTitle from "./authotr_profile_title";
import { useDarkMode } from "../contexts/dark_mode_provider";

const { TabPane } = Tabs;

const AuthorDetailsList = () => {
  const { type } = useParams();
  const [list, set_list] = useState([]);
  let current_language = retrieveItem("language");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const top_ref = useRef(null);
  const { is_dark_mode } = useDarkMode();

  const [page_limit, set_page_limit] = useState(6);
  const [list_loading, set_list_loading] = useState();
  const [list_pagination, set_list_pagination] = useState();
  const [status, set_type] = useState("published");
  const [redirect_type, set_redirect_type] = useState();

  const queryParams = new URLSearchParams(location.search);
  const staffProfileId = queryParams.get("staff_profile");
  const userProfileId = queryParams.get("user_profile");
  const record_type = queryParams.get("record_type");

  const {
    items: author_details,
    loading: author_loading,
    pagination: author_pagination,
  } = useDynamicSelector("get_all_author_stories");

  const on_click = () => {
    set_page_limit(page_limit + 6);
  };

  const get_author_detail = () => {
    let key = [{ key: "get_all_author_stories", loading: true }];
    let query = query_get_author_details;
    let variable = {
      user_profile_id: userProfileId,
      staff_profile_id: staffProfileId,
      language_id: current_language?.id,
      type: record_type,
      page_number: 1,
      page_limit: page_limit,
    };
    dispatch(dynamic_request(key, query, variable));
  };

  useEffect(() => {
    if (list && list > 0) {
      set_total_list(...list);
    }
  }, [list]);

  useEffect(() => {
    get_author_detail();
  }, [page_limit, record_type]);

  useEffect(() => {
    if (author_details) {
      set_list(author_details);
      set_list_loading(author_loading);
      set_list_pagination(author_pagination);
    }
  }, [author_details, author_loading, author_pagination]);

  useEffect(() => {
    if (record_type === "fact_check") {
      set_redirect_type("Fact Checks");
    } else if (record_type === "article") {
      set_redirect_type("Articles");
    }
  }, [record_type]);

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);
  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);

  return (
    <div
      style={{ overflow: "hidden" }}
      ref={top_ref}
      className={is_dark_mode ? "content-dark" : ""}
    >
      <AuthorProfileTitle type={redirect_type} title={""} />

      <CustomText
        class_name={"user_profile_titles"}
        text={redirect_type}
        font_family={"Lato"}
        size={"24px"}
        color={"#140f2d"}
        align={"left"}
        weight={"700"}
      />

      <div className="top_six_article_card">
        <div className="top_six_article">
          {author_details?.length > 0
            ? author_details?.map((item) => (
                <CustomFactCheckCategories
                  loading={false}
                  category_image={
                    item?.thumbnail_img === "null"
                      ? BROKEN_IMAGE
                      : item.thumbnail_img
                  }
                  title={item?.title ? item.title : ""}
                  sub_title={item?.subtitle ? item.subtitle : ""}
                  created_date={
                    item
                      ? moment(item.created_date_time).format(`MMM D,YYYY`)
                      : ""
                  }
                  perma_link={item?.perma_link ? item.perma_link : ""}
                  type={record_type ? record_type : ""}
                  name={
                    item.user_profile?.name ||
                    item.staff_profile?.name ||
                    "YouTurn Editorial"
                  }
                />
              ))
            : !list_loading && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <Empty description="No data" />
                </div>
              )}

          {list_loading &&
            Array.from({ length: 6 }, (_, index) => (
              <Skeleton.Input
                key={index}
                className="six-categories-card-second-div-for-skeleton"
                active
              />
            ))}
        </div>
      </div>

      <div className="see_all_article_button">
        <div className="user_profile_load_more_button">
          {list?.length === list_pagination?.total_count || list?.length < 6 ? (
            ""
          ) : (
            <Button
              className="user_profile_load_button_color"
              onClick={on_click}
            >
              {t("load_more")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthorDetailsList;
