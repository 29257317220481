import React from "react";
import CustomContributionSmallCard from "./custom_contribution_small_card";
import { Carousel } from "antd";

const CustomRecentlyAddedCarousel = (props) => {
  const { list } = props;

  return (
    <div
      className="search_result_recently_added_carousel"
      style={{
        width: "100%",
        height: "50vh",
      }}
    >
      <Carousel
        infinite={false}
        dots={false}
        slidesToShow={1.5}
        slidesToScroll={1}
        className="carousel-recently-added"
      >
        {list?.map((item, index) => (
          <div className="carousel_recently_added">
            <CustomContributionSmallCard
              item={item}
              type={item ? item?.record_type : ""}
              perma_link={item ? item?.perma_link : ""}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CustomRecentlyAddedCarousel;
