import {
  DatePicker,
  TimePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import Title from "../admin_article/admin_article_form/title";
import SubTitle from "../admin_article/admin_article_form/sub_title";
import Content from "../admin_article/admin_article_form/content";
import VForm from "../../system/ui/antd_form";
import {
  dynamic_clear,
  dynamic_request,
  dynamicSet,
  get_all_languages_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import {
  auto_save_fact_check_query,
  fact_check_process_query,
  mutation_create_fact_check,
  mutation_update_fact_check,
} from "../../../../services/redux/slices/graphql/graphql_fact_check";
import Category from "../admin_article/admin_article_form/categories";
import SpreadNews from "../admin_article/admin_article_form/spread_news";
import ShortStory from "../admin_article/admin_article_form/short_story";
import Source from "../admin_article/admin_article_form/source";
import { Box, HStack, VStack } from "native-base";
import PartyTag from "../admin_article/admin_article_form/party_tags";
import { retrieveItem, showToast } from "../../../../helpers/functions";
import { ROUTES } from "../../../routes/my_routes";
import { navigate, navigateBack } from "../../../../helpers/navigator";
import AntiPartyTag from "../admin_article/admin_article_form/anti_party_tag";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { check_perma_link_availability_query } from "../../../../services/redux/slices/graphql/graphql_perma_link";
import { useTranslation } from "react-i18next";
import "dayjs/locale/en";
import dayjs from "dayjs";
import moment from "moment";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const AdminFactCheckForm = (props) => {
  const {
    selected_item,
    modal_title,
    form,
    action_type,
    current_language_id,
    publish_date,
    set_publish_date,
    set_publish_time,
    publish_time,
    set_content,
    spread_news,
    short_story,
    set_short_story,
    set_source,
    source,
    set_spread_news,
    content,
  } = props;
  dayjs.locale("en");
  const fact_check_id = location?.pathname.split("/").pop();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [form_values, set_form_values] = useState(selected_item || {});
  const [save_triggered, set_save_triggered] = useState(false);
  const [language_id, set_language_id] = useState(current_language_id);
  const [form_loading, set_form_loading] = useState(false);
  const [change_language_id, set_change_language_id] =
    useState(current_language_id);
  const role = retrieveItem("role");

  console.log("publish_datesssssss", publish_date, publish_time);

  const data = useDynamicSelector("get_fact_check");
  const fact_check_loading = useDynamicSelector("get_fact_check");
  const { is_initial_save } = useDynamicSelector("is_initial_save");
  const ratings = [
    {
      id: "true",
      name: "True",
    },
    {
      id: "fake",
      name: "Fake",
    },
    {
      id: "mix",
      name: "Mix",
    },
    {
      id: "sarcasm",
      name: "Sarcasm",
    },
    {
      id: "misleading",
      name: "Misleading",
    },
    {
      id: "false",
      name: "False",
    },
  ];
  const { status: create_fact_check_status, error: create_fact_check_error } =
    useDynamicSelector("create_fact_check");
  const { status: update_fact_check_status, error: update_fact_check_error } =
    useDynamicSelector("update_fact_check");
  const { items: system_languages } = useDynamicSelector(
    "get_all_system_languages"
  );
  const { is_available, error: perma_link_error } = useDynamicSelector(
    "check_perma_link_availability"
  );

  useEffect(() => {
    let timer;
    if (fact_check_loading) {
      timer = setTimeout(() => {
        set_form_loading(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [fact_check_loading]);

  useEffect(() => {
    if (is_available === false) {
      form.setFields([
        {
          name: "perma_link",
          errors: ["Perma link already exists"],
        },
      ]);
    } else if (is_available === true) {
      form.setFields([
        {
          name: "perma_link",
          errors: [],
        },
      ]);
    }
  }, [is_available]);

  useEffect(() => {
    set_change_language_id(language_id || current_language_id);
  }, [language_id, current_language_id]);

  useEffect(() => {
    if (create_fact_check_status === "Success") {
      showToast({
        type: "success",
        message: "Fact Check created successfully",
      });
      navigate(ROUTES.ADMIN_FACT_CHECK);
      dispatch(dynamic_clear("create_fact_check"));
    } else if (create_fact_check_error?.message) {
      const formatted_message = create_fact_check_error?.message
        ? create_fact_check_error.message
            .replace(/perma_link/i, "Perma link")
            .replace("exist", "exists")
        : "An error occurred";
      showToast({ type: "error", message: formatted_message });
      dispatch(dynamic_clear("create_fact_check"));
    } else if (update_fact_check_status === "Success") {
      showToast({
        type: "success",
        message: "Fact Check updated successfully",
      });
      dispatch(dynamic_clear("save_fact_check"));
      dispatch(dynamic_clear("update_fact_check"));
    } else if (update_fact_check_error?.message) {
      const formatted_message = update_fact_check_error?.message
        ? update_fact_check_error.message
            .replace(/perma_link/i, "Perma link")
            .replace("exist", "exists")
        : "An error occurred";
      showToast({ type: "error", message: formatted_message });
      dispatch(dynamic_clear("update_fact_check"));
    }
  }, [
    create_fact_check_status,
    create_fact_check_error,
    update_fact_check_status,
    update_fact_check_error,
  ]);

  useEffect(() => {
    get_all_languages();
  }, []);

  useEffect(() => {
    if (form_values?.perma_link?.length) {
      perma_link_availability_checking(form_values?.perma_link);
    }
  }, [form_values]);

  useEffect(() => {
    if (
      form_values.title &&
      form_values.subtitle &&
      form_values.perma_link &&
      form_values.language_id &&
      form_values.content &&
      !save_triggered
    ) {
      const intervalId = setInterval(() => {
        if (!data?.id) {
          set_save_triggered(true);
        }
      }, 30000);
      return () => clearInterval(intervalId);
    }
  }, [form_values, save_triggered]);

  useEffect(() => {
    if (is_initial_save) {
      const interval_id = setInterval(() => {
        let auto_update_values = form.getFieldsValue();
        auto_save_fact_check(auto_update_values);
      }, 30000);
      return () => clearInterval(interval_id);
    }
  }, [
    is_initial_save,
    content,
    short_story,
    source,
    spread_news,
    form_values,
    form,
  ]);

  const create_fact_checks = (values) => {
    let key = [{ key: "create_fact_check", loading: true }];
    let query = mutation_create_fact_check;
    let data = {
      title: values?.title,
      subtitle: values?.subtitle,
      language_id: values?.language_id,
      category_tags: values?.category_tags,
      party_tags: values?.party_tags,
      anti_party_tags: values?.anti_party_tags,
      is_fact: values?.is_fact,
      perma_link: values?.perma_link,
      thumbnail_img: values?.thumbnail_img?.[0]?.url,
      content: content?.html || "",
      content_json: content.json || "",
      spread_news: spread_news.html || "",
      short_story: short_story?.html || "",
      source: source.html || "",
      spread_news_json: spread_news.json || "",
      short_story_json: short_story.json || "",
      source_json: source.json || "",
    };
    let variables = {
      json: data,
    };
    if (role === "Admin") {
      variables.json.published_date_time = `${publish_date}T${publish_time}`;
    }
    dispatch(dynamic_request(key, query, variables));
  };

  const edit_fact_check = (values) => {
    delete values.published_date;
    delete values.published_time;
    let key = [{ key: "update_fact_check", loading: true }];
    let query = mutation_update_fact_check;
    let variables = {
      id: selected_item?.id,
      json: {
        ...values,
        content: content?.html || "",
        content_json: content.json || "",
        spread_news: spread_news.html || "",
        short_story: short_story?.html || "",
        source: source.html || "",
        spread_news_json: spread_news.json || "",
        short_story_json: short_story.json || "",
        source_json: source.json || "",
        thumbnail_img: values?.thumbnail_img?.[0]?.url,
      },
    };
    if (role === "Admin" && data?.status === "published") {
      variables.json.published_date_time = `${publish_date}T${publish_time}`;
    }
    dispatch(dynamic_request(key, query, variables));
  };

  const auto_save_fact_check = (values) => {
    delete values.published_date;
    delete values.published_time;
    let key = [{ key: "auto_save_fact_check", loading: true }];
    let query = auto_save_fact_check_query;
    let variables = {
      id: id,
      json: {
        title: values?.title,
        subtitle: values?.subtitle,
        language_id: values?.language_id,
        category_tags: values?.category_tags,
        party_tags: values?.party_tags,
        anti_party_tags: values?.anti_party_tags,
        is_fact: values?.is_fact,
        perma_link: values?.perma_link,
        thumbnail_img: values?.thumbnail_img?.[0]?.url,
        content: content?.html || "",
        content_json: content.json || "",
        spread_news: spread_news.html || "",
        short_story: short_story?.html || "",
        source: source.html || "",
        spread_news_json: spread_news.json || "",
        short_story_json: short_story.json || "",
        source_json: source.json || "",
        thumbnail_img: values?.thumbnail_img?.[0]?.url,
      },
    };

    if (
      role === "Admin" &&
      publish_date &&
      publish_time &&
      data?.status === "published"
    ) {
      variables.json.published_date_time = `${publish_date}T${publish_time}`;
    }
    dispatch(dynamic_request(key, query, variables));
  };

  const fact_check_process = (type) => {
    let key = [{ key: "process_fact_check", loading: true }];
    let query = fact_check_process_query;
    let variables = {
      id: id,
      type: type,
    };
    if (role === "Admin") {
      variables.published_date_time = `${publish_date}T${publish_time}`;
    }
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const perma_link_availability_checking = (perma_link) => {
    let key = [{ key: "check_perma_link_availability", loading: true }];
    let query = check_perma_link_availability_query;
    let variables = {
      id: data?.id || "",
      type: "fact_check",
      language_id: change_language_id,
      perma_link: perma_link,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const preview_click = () => {
    const url = `${ROUTES.ADMIN_FACT_CHECK_PREVIEW}/${fact_check_id}/${change_language_id}`;
    const props = {
      fact_check_id: fact_check_id,
      language_id: change_language_id,
    };
    const queryString = new URLSearchParams(props).toString();
    const fullUrl = `${url}?${queryString}`;
    window.open(fullUrl, "_blank");
  };
  const handle_submit = (values) => {
    if (modal_title === "add" || action_type === "create") {
      create_fact_checks(values);
    } else if (action_type === "submit") {
      fact_check_process("submit");
      auto_save_fact_check(values);
    } else if (action_type === "preview") {
      auto_save_fact_check(values);
      preview_click();
    } else if (action_type === "approve") {
      fact_check_process("approve");
    } else if (action_type === "reject") {
      fact_check_process("reject");
    } else if (action_type === "unpublished") {
      fact_check_process("unpublished");
    } else if (action_type === "published") {
      fact_check_process("published");
    } else if (modal_title === "edit" || action_type === "update") {
      edit_fact_check(values);
    }
  };
  const on_change_language = (value) => {
    set_language_id(value || current_language_id);
    set_change_language_id(value || current_language_id);
  };

  const handle_key_press = (e) => {
    if (/[\/?!.,@#$%^&*()+_{}|~`]/.test(e.key)) {
      e.preventDefault();
    }
    if (e.key === " ") {
      e.preventDefault();
      const input = e.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const value = input.value;
      input.value = value.substring(0, start) + "-" + value.substring(end);
      input.setSelectionRange(start + 1, start + 1);
      const event = new Event("input", { bubbles: true });
      input.dispatchEvent(event);
    }
  };
  const handle_paste = (e) => {
    if (/[\/?!.,@#$%^&*()+_{}|~`]/.test(e.target.value)) {
      const input = e.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const value = input.value;
      input.value = value.substring(0, start) + "-" + value.substring(end);
      input.setSelectionRange(start + 1, start + 1);
      const event = new Event("input", { bubbles: true });
      input.dispatchEvent(event);
    }
    if (e.target.value === " ") {
      e.preventDefault();
      const input = e.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const value = input.value;
      input.value = value.substring(0, start) + "-" + value.substring(end);
      input.setSelectionRange(start + 1, start + 1);
      const event = new Event("input", { bubbles: true });
      input.dispatchEvent(event);
    }
  };

  const on_finish_failed = (errorInfo) => {
    const { errorFields } = errorInfo;
    if (errorFields.length > 0) {
      const fieldName = errorFields[0].name[0];
      form.scrollToField(fieldName, { behavior: "smooth", block: "center" });
    }
  };
  const handle_values_change = (value, values) => {
    set_form_values(values);
  };

  const on_change_date = (date, date_String) => {
    const date_format = date_String
      ? dayjs(date_String).tz("Asia/Kolkata").format("YYYY-MM-DD")
      : null;
    set_publish_date(date_format);
  };
  const on_change_time = (time, timeString) => {
    const time_format = time ? dayjs(time).format("HH:mm:ssZ") : null;
    set_publish_time(time_format);
  };

  return (
    <>
      <Spin spinning={form_loading} tip="Loading..."></Spin>
      <Form
        id="fact_check_form"
        initialValues={selected_item}
        form={form}
        layout="vertical"
        onFinish={handle_submit}
        onFinishFailed={on_finish_failed}
        onValuesChange={handle_values_change}
        className="admin_fact_check_form"
      >
        <VForm.TextBox
          label={t("title_with_length")}
          field={"title"}
          rules={[{ required: true, message: "Title is required " }]}
          max_length={150}
          placeholder={"Title"}
        />

        <SubTitle action_type={action_type} modal_title={modal_title} />
        <HStack space={"10px"}>
          <Box flex={1}>
            <Form.Item
              label="Language"
              name={"language_id"}
              rules={[
                { required: true, message: "Please select the language" },
              ]}
            >
              <Select onChange={on_change_language}>
                {system_languages?.map((option, index) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Box>

          {role === "Admin" && (
            <Box flex={2}>
              <HStack space={"10px"}>
                <Form.Item
                  name="published_date"
                  label={"Published Date and Time"}
                  rules={[
                    {
                      required: true,
                      message: "Please pick the Date and Time",
                    },
                  ]}
                >
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    onChange={on_change_date}
                    value={publish_date}
                  />
                </Form.Item>
                <Form.Item
                  name="published_time"
                  label={" "}
                  rules={[{ required: true, message: "" }]}
                >
                  <TimePicker
                    use24Hours
                    format={"HH:mm:ss"}
                    onChange={on_change_time}
                    value={publish_time}
                  />
                </Form.Item>
              </HStack>
            </Box>
          )}
        </HStack>
        {(language_id || selected_item?.language_id) && (
          <HStack space={"10px"}>
            <Box flex={1}>
              <Category
                language_id={
                  language_id ? language_id : selected_item?.language_id
                }
              />
            </Box>
            <Box flex={1}>
              <PartyTag
                language_id={
                  language_id ? language_id : selected_item?.language_id
                }
              />
            </Box>
            <Box flex={1}>
              <AntiPartyTag
                language_id={
                  language_id ? language_id : selected_item?.language_id
                }
              />
            </Box>
          </HStack>
        )}

        <Form.Item
          label="Rating"
          name={"is_fact"}
          rules={[{ required: true, message: "Please select the Rating" }]}
        >
          <Select>
            {ratings?.map((option, index) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <VForm.TextBox
          label={"Perma link"}
          field={"perma_link"}
          onKeyPress={handle_key_press}
          onPaste={handle_paste}
          rules={[
            { required: true, message: "Please enter the perma link" },
            {
              pattern: /^[^\/?!.,@#$%^&*()+_{}|~`]+$/,
              message: "Please avoid special characters",
            },
          ]}
        />
        <VForm.ImageFile label="Thumbnail Image" field={"thumbnail_img"} />
      </Form>
      <VStack>
        <Content
          content={selected_item?.content || ""}
          set_content={set_content}
        />
        <SpreadNews
          content={selected_item?.spread_news || ""}
          set_content={set_spread_news}
        />
        <ShortStory
          content={selected_item?.short_story || ""}
          set_content={set_short_story}
        />
        <Source
          content={selected_item?.source || ""}
          set_content={set_source}
        />
      </VStack>
    </>
  );
};

export default AdminFactCheckForm;
