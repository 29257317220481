import React, { useEffect, useState } from "react";
import { Box } from "native-base";
import { useDispatch } from "react-redux";

import { showNotification } from "@helpers/functions";
import { Button } from "antd";
import {
  get_api_modules_query,
  get_system_ui_module_list_query,
  introspection_query,
  sync_system_api_modules_mutation,
} from "../../../../../services/redux/slices/graphql/graphql_access_rights";
import {
  dynamic_request,
  useDynamicSelector,
} from "../../../../../services/redux";

const SyncEndpoints = () => {
  const { loading, types } = useDynamicSelector("schema");
  const { loading: sync_loading, status } =
    useDynamicSelector("sync_endpoints");
  const dispatch = useDispatch();

  useEffect(() => {
    loadEndpoints();
  }, []);

  useEffect(() => {
    if (types) {
      let keys = [{ key: "sync_endpoints", loading: true }];
      let queries = types
        .find((x) => x.name === "Query")
        .fields.map((x) => x.name);
      let mutations = types
        .find((x) => x.name === "Mutation")
        .fields.map((x) => x.name);
      let api_names = [...queries, ...mutations];
      let variables = {
        api_names,
      };
      dispatch(
        dynamic_request(keys, sync_system_api_modules_mutation, variables, "M")
      );
    }
  }, [types]);

  useEffect(() => {
    if (status) {
      if (status === "success") {
        loadEndpoints();
        showNotification({
          type: "success",
          message: `Endpoints synced successfully`,
        });
      }
    }
  }, [status]);

  const loadEndpoints = () => {
    let keys = [{ key: "endpoints", loading: true }];
    let variables = {};
    dispatch(dynamic_request(keys, get_api_modules_query, variables));
  };

  const handleSyncEndpoints = () => {
    let keys = [{ key: "schema", loading: true }];
    let variables = null;
    dispatch(dynamic_request(keys, introspection_query, variables));
  };

  return (
    <Box w="100%">
      <Button
        type="primary"
        onClick={handleSyncEndpoints}
        loading={loading || sync_loading}
      >
        Sync Endpoints
      </Button>
    </Box>
  );
};
export default SyncEndpoints;
