import React from "react";
import CustomText from "../../custom/custom_text";
import { useTranslation } from "react-i18next";

const AllStoriesTitle = () => {
  const { t } = useTranslation();
  return (
    <div>
      <CustomText
        text={t("all_stories")}
        font_family={"Kufam"}
        color={"#140F2D"}
        size={"2.4vh"}
        align={"start"}
        weight={"700"}
        margin_top={"4vh"}
        marginLeft={"4vh"}
      />
    </div>
  );
};

export default AllStoriesTitle;
