import React, { useEffect, useRef } from "react";
import CustomText from "../components/custom/custom_text";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const top_ref = useRef(null);

  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);
  function upperCaseToSentenceCase(text) {
    // Split the text into sentences
    let sentences = text.split(/(?<=[.!?])\s+/);

    // Convert each sentence to sentence case
    sentences = sentences.map((sentence) => {
      // Convert all letters to lowercase
      let lowerCaseSentence = sentence.toLowerCase();
      // Capitalize the first letter of the sentence
      return (
        lowerCaseSentence.charAt(0).toUpperCase() + lowerCaseSentence.slice(1)
      );
    });

    // Join the sentences back together
    return sentences.join(" ");
  }

  const first =
    "THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC CONTRACT FORMED UNDER THE INFORMATION TECHNOLOGY ACT, 2000 AND THE RULES MADE THEREUNDER AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THIS PRIVACY POLICY DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.";
  const second =
    "THIS PRIVACY POLICY IS A LEGALLY BINDING DOCUMENT BETWEEN YOU AND YOUTURN.IN (BOTH TERMS DEFINED BELOW). THE TERMS OF THIS PRIVACY POLICY WILL BE EFFECTIVE UPON YOUR ACCEPTANCE OF THE SAME (DIRECTLY OR INDIRECTLY IN ELECTRONIC FORM, BY CLICKING ON THE “I AGREE” TAB OR BY USE OR THE WEBSITE OR BY OTHER MEANS) AND WILL GOVERN THE RELATIONSHIP BETWEEN YOU AND YOUTURN.IN FOR YOUR USE OF THE WEBSITE (DEFINED BELOW).";
  const third =
    "THIS DOCUMENT IS PUBLISHED AND SHALL BE CONSTRUED IN ACCORDANCE WITH THE PROVISIONS OF THE INFORMATION TECHNOLOGY (REASONABLE SECURITY PRACTICES AND PROCEDURES AND SENSITIVE PERSONAL DATA OF INFORMATION) RULES, 2011 UNDER INFORMATION TECHNOLOGY ACT, 2000; THAT REQUIRE PUBLISHING OF THE PRIVACY POLICY FOR COLLECTION, USE, STORAGE AND TRANSFER OF SENSITIVE PERSONAL DATA OR INFORMATION.";
  const fourth =
    "PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY USING THE WEBSITE, YOU INDICATE THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT USE THIS WEBSITE. YOU HEREBY PROVIDE YOUR UNCONDITIONAL CONSENT OR AGREEMENTS TO YOUTURN.IN AS PROVIDED UNDER SECTION 43A AND SECTION 72A OF INFORMATION TECHNOLOGY ACT, 2000.";

  const five =
    "By providing us your Information or by making use of the facilities provided by the Website, you hereby consent to the collection, storage, processing and transfer of any or all of Your Personal Information and Non-Personal Information by YOUTURN as specified under this Privacy Policy. You further agree that such collection, use, storage and transfer of Your Information shall not cause any loss or wrongful gain to you or any other person.";

  const six =
    "People Educational Awareness Trust (also know as, “YOUTURN”) is concerned about the privacy of the data and information of users (including client/customers whether registered or non- registered) accessing, offering, services on Youturn’s websites, mobile sites or mobile applications (“Website”) on the Website.";

  const seven =
    "The terms “We” / “Us” / “Our” individually and collectively refer to entity being part of the definition of YOUTURN and the terms “You” / “Your” / “Yourself” refer to the users. This Privacy Policy is a contract between You and the respective YOUTURN entity whose website you use or access or You otherwise deal with. This Privacy Policy shall be read together with the respective Terms of Use or other terms and condition of the respective YOUTURN entity and its respective website or nature of business of the Website.";

  const eight =
    "We value the trust you place in us. That’s why we insist upon the highest standards for secure transactions and customer information privacy. Please read the following statement to learn about our information gathering and dissemination practices.";

  const nine =
    "Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.";
  const ten =
    "By visiting this Website, you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our website.";

  const leven =
    "By mere use of the Website, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms & Conditions.";

  const note_heading =
    "1.Collection of Personally Identifiable Information and other Information";

  const twelve =
    "When you use our website, we collect and store your personal information, which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our website to make your experience safer and easier. More importantly, while doing so we collect personal information from you that we consider necessary for achieving this purpose.";

  const thirteen =
    "In general, you can browse the Website without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. Where possible, we indicate which fields are required and which fields are optional. You always have the option to not provide information by choosing not to use a particular service or feature on the Website. We may automatically track certain information about you based upon your behaviour on our Website. We use this information to do internal research on our users’ demographics, interests, and behaviour to better understand, protect and serve our users. This information is compiled and analysed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on our website or not), which URL you next go to (whether this URL is on our website or not), your computer browser information, and your IP address.";

  const fourteen =
    "We use data collection devices such as “cookies” on certain pages of the Website to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. “Cookies” are small files placed on your hard drive that assist us in providing our services. We offer certain features that are only available through the use of a “cookie”. We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are “session cookies,” meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Website and you may be required to re-enter your password more frequently during a session.";

  const fifteen =
    "Additionally, you may encounter “cookies” or other similar devices on certain pages of the Website that are placed by third parties. We do not control the use of cookies by third parties.";

  const sixteen =
    "If you choose to buy on the Website, we collect information about your buying behaviour.";

  const seventeen =
    "If you transact with us, we collect some additional information, such as a billing address, a credit / debit card number and a credit / debit card expiration date and/ or other payment instrument details and tracking information from cheques or money orders.";

  const eighteen =
    "If you choose to post messages on our message boards, chat rooms or other message areas or leave feedback, we will collect that information you provide to us. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law.";

  const nineteen =
    "If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Website, we may collect such information into a file specific to you.";

  const twenty =
    "We collect personally identifiable information (email address, name, phone number, credit card";

  const twenty_one =
    "debit card / other payment instrument details, etc.) from you when you set up a free account with us. While you can browse some sections of our Website without being a registered member, certain activities (such as placing an order) do require registration. We do use your contact information to send you offers based on your previous orders and your interests.";

  const note_heading_two =
    "2.Use of Demographic / Profile Data / Your Information";

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const firstSentenceCase = upperCaseToSentenceCase(first);
  const secondSentenceCase = upperCaseToSentenceCase(second);
  const thirdSentenceCase = upperCaseToSentenceCase(third);
  const fourthSentenceCase = upperCaseToSentenceCase(fourth);

  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);

  return (
    <div
      ref={top_ref}
      style={{
        height: "auto",
        margin: "1vh 14vh",
        overflowX: "hidden",
      }}
      className="privacy_policy_main_div"
    >
      <div>
        <CustomText
          weight={"700"}
          text={t("privacy_policy_title")}
          size={"4vh"}
          font_family={"lato"}
          align={"left"}
          line_height={"5vh"}
          margin_top={"5vh"}
        />
      </div>
      <div style={{ marginTop: "3vh" }}>
        <CustomText
          text={t(firstSentenceCase)}
          font_family={"lato"}
          align={"left"}
          line_height={"3vh"}
          weight={"500"}
        />
        <div>
          <CustomText
            text={t(secondSentenceCase)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
        </div>
        <div>
          <CustomText
            text={t(thirdSentenceCase)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
        </div>
        <div>
          <CustomText
            text={t(fourthSentenceCase)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
        </div>

        <div>
          <CustomText
            text={t(five)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
        </div>
        <div>
          <CustomText
            text={t(six)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
        </div>
        <div>
          <CustomText
            text={t(seven)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
        </div>
        <div>
          <CustomText
            text={t(eight)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
        </div>
        <div>
          <CustomText
            weight={"700"}
            text={t("privary_policy_note")}
            size={"4vh"}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
          />
          <CustomText
            text={t(nine)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
          <CustomText
            text={t(ten)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            weight={"500"}
            margin_top={"5vh"}
          />
          <CustomText
            text={t(leven)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
          <CustomText
            text={t(note_heading)}
            size={"3vh"}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            marginLeft={"1vh"}
          />
          <CustomText
            text={t(twelve)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
          <CustomText
            text={t(thirteen)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
          <CustomText
            text={t(fourteen)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
          <CustomText
            text={t(fifteen)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
          <CustomText
            text={t(sixteen)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
          <CustomText
            text={t(seventeen)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
          <CustomText
            text={t(eighteen)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
          <CustomText
            text={t(nineteen)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
          <CustomText
            text={t(twenty)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
          <CustomText
            text={t(twenty_one)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
          {/* <CustomText
            text={t(twenty_one)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
