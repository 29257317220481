import React from "react";
import CustomText from "./custom_text";
import ArticleFirstCard from "../article/article_first_card";
import moment from "moment";
import CustomRecentlyAddedCarousel from "./custom_recently_added_carousel";

const CustomRecentlyAddedCard = (props) => {
  const { title, list, type } = props;

  return (
    <>
      <div style={{ padding: "3%" }}>
        <CustomText
          text={title}
          size={"30px"}
          font_family={"Kufam"}
          color={"#140F2D"}
          align={"left"}
          weight={"400"}
        />
        <div className="search_result_recently_added_first_card">
          <ArticleFirstCard
            title={list ? list[0]?.title : ""}
            image={list ? list[0]?.thumbnail_img : "2345"}
            view_count={list ? list[0]?.views : ""}
            created_date_time={moment(list?.created_date_time).format(
              "DD MMM, YYYY"
            )}
            id={list ? list[0]?.perma_link : ""}
            name={list ? list[0]?.staff_profile?.name : ""}
            type={type}
          />
        </div>
      </div>

      <CustomRecentlyAddedCarousel list={list} type={type} />
    </>
  );
};

export default CustomRecentlyAddedCard;
