import { Pagination, Table } from "antd";
import React, { useState } from "react";
import { useDynamicSelector } from "../../../services/redux";
import moment from "moment";
import { HStack } from "native-base";
import { countFormat } from "../../../helpers/constants";

const DonationList = (props) => {
  const {
    donation_list,
    set_page_number,
    set_page_limit,
    page_number,
    donation_pagination,
  } = props;

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "created_date_time",
      key: "created_date_time",
      render: (text, record) => moment(text).format("MMM DD, YYYY"),
    },
    {
      title: "Reference ID",
      dataIndex: "order_ref",
      key: "order_ref",
      render: (text) => {
        if (text === null || text === undefined) {
          return "-";
        }
        return text;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `₹ ${amount}`,
    },
  ];

  return (
    <div className="user_donation_details">
      <Table columns={columns} dataSource={donation_list} pagination={false} />

      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={donation_pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>
    </div>
  );
};

export default DonationList;
