import React from "react";
import CustomText from "../custom/custom_text";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";
import { useTranslation } from "react-i18next";
import { Row, Breadcrumb } from "antd";
import { Link, useParams, useLocation } from "react-router-dom";

const AuthorProfileTitle = (props) => {
  const { type, title } = props;
  const { t } = useTranslation();
  const { name: author_name } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const staffProfileId = queryParams.get("staff_profile");
  const userProfileId = queryParams.get("user_profile");
  const name = queryParams.get("name");

  const queryParams1 = new URLSearchParams();
  if (staffProfileId) {
    queryParams1.set("staff_profile", staffProfileId);
  }
  if (userProfileId) {
    queryParams1.set("user_profile", userProfileId);
  }

  return (
    <div className="user_profile_title_card">
      <div className="user_profile_bread_crumbs">
        {/* <CustomBreadCrumbs
          pages={
            type
              ? `${t("author_breadcrumbs")}/${type}`
              : `${t("author_breadcrumbs")}`
          }
        /> */}
        <Row className="custom-breadcrumbs">
          <Breadcrumb separator="">
            <Breadcrumb.Item key="home">
              <>
                <Link to="/" style={{ color: "white" }}>
                  Home
                </Link>
                <span
                  style={{
                    marginLeft: "5px",
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  &gt;
                </span>
              </>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="home">
              {type ? (
                <>
                  <Link
                    to={`/author/${name}?${queryParams1.toString()}`}
                    style={{ color: "white" }}
                  >
                    {name}
                  </Link>
                  <span
                    style={{
                      marginLeft: "5px",
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    &gt;
                  </span>
                </>
              ) : (
                <span
                  style={{
                    marginLeft: "1vh",
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  {author_name}
                </span>
              )}
            </Breadcrumb.Item>
            <Breadcrumb.Item key="home">
              <span
                style={{
                  marginLeft: "1vh",
                  color: "white",
                  textDecoration: "none",
                }}
              >
                {type}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </div>
      <CustomText
        text={title}
        font_family={"Lato"}
        size={"24px"}
        color={"#FFFFFF"}
        align={"left"}
        weight={"700"}
      />
    </div>
  );
};

export default AuthorProfileTitle;
