import { gql } from "@apollo/client";
export const get_get_check_by_perma_link_query = gql`
  query get_fact_check_by_perma_link(
    $perma_link: String!
    $language_id: String!
  ) {
    get_fact_check_by_perma_link: get_fact_check_by_perma_link(
      perma_link: $perma_link
      language_id: $language_id
    ) {
      id
      staff_profile_id
      user_profile_id
      staff_profile {
        id
        name
      }

      title
      subtitle
      content
      spread_news
      short_story
      source
      status
      is_fact
      reading_time
      likes
      views
      comments_count
      created_date_time
      is_reported
      thumbnail_img
      perma_link
      error {
        status_code
      }
    }
  }
`;

export const query_list_of_fact_check = gql`
  query get_all_fact_check(
    $page_number: Int
    $page_limit: Int
    $json: get_all_fact_check_input
  ) {
    get_all_fact_check_list: get_all_fact_check(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      items {
        id
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }

        title
        subtitle
        content
        spread_news
        short_story
        source
        status
        is_fact
        thumbnail_img
        reading_time
        likes
        views
        comments_count
        created_date_time
        perma_link
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;

export const query_get_all_fact_check_in_fact_check_list_category_wise = gql`
  query get_all_fact_check(
    $page_number: Int
    $page_limit: Int
    $json: get_all_fact_check_input
  ) {
    get_all_fact_check(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      items {
        id
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }

        title
        subtitle
        content
        spread_news
        short_story
        source
        status
        is_fact
        thumbnail_img
        reading_time
        likes
        views
        comments_count
        created_date_time
        perma_link
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;
export const get_trending_fact_checks_query = gql`
  query get_trending_fact_checks($language_id: String!) {
    fact_check_list_trending: get_trending_fact_checks(
      language_id: $language_id
    ) {
      items {
        id
        language_id
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }
        title
        subtitle
        content
        spread_news
        short_story
        source
        status
        reading_time
        likes
        views
        comments_count
        is_reported
        thumbnail_img
        created_date_time
        perma_link
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const get_recent_fact_check_list_query = gql`
  query get_recent_fact_checks(
    $language_id: String!
    $page_number: Int
    $page_limit: Int
  ) {
    get_recent_fact_check_list: get_recent_fact_checks(
      language_id: $language_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        language_id
        staff_profile_id
        staff_profile {
          id
          name
        }
        user_profile_id
        title
        subtitle
        content
        spread_news
        short_story
        source
        status
        is_fact
        reading_time
        likes
        views
        comments_count
        thumbnail_img
        created_date_time
        perma_link
        error {
          status_code
          message
        }
      }
    }
  }
`;
