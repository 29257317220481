import { gql } from "@apollo/client";

export const mutation_create_category = gql`
  mutation create_category_tag($json: category_tag_input) {
    create_category_tag(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_category = gql`
  mutation update_category_tag($json: category_tag_input, $id: String) {
    update_category_tag(json: $json, id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_category = gql`
  mutation delete_category_tag($id: String) {
    delete_category_tag(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_all_category = gql`
  query get_all_category_tag(
    $page_number: Int
    $page_limit: Int
    $language_id: String
    $search_string: String
  ) {
    get_all_category_tag(
      page_number: $page_number
      page_limit: $page_limit
      language_id: $language_id
      search_string: $search_string
    ) {
      items {
        id
        name
        language_id
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const query_get_all_category_tag = gql`
  query get_all_category_tag(
    $page_number: Int
    $page_limit: Int
    $language_id: String
  ) {
    get_all_category_tag(
      page_number: $page_number
      page_limit: $page_limit
      language_id: $language_id
    ) {
      items {
        id
        name
      }
    }
  }
`;
