import { HStack, VStack } from "native-base";
import React, { useEffect, useRef } from "react";
import PageTitle from "../../components/system/page_title/page_title";
import ContactUsTable from "../../components/admin/contact_us_list/contact_us_table";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ContactUsList = () => {
  const top_ref = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    debouncedHandleTitleClick();
  }, [pathname]);

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);

  return (
    <div ref={top_ref}>
      <HStack justifyContent={"flex-start"}>
        <PageTitle title={"Feedback"} />
      </HStack>
      <VStack padding={"40px"}>
        <ContactUsTable />
      </VStack>
    </div>
  );
};

export default ContactUsList;
