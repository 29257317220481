import { Button, Col, Image, Row, Skeleton } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../custom/custom_text";
import { LuEye } from "react-icons/lu";
import { format_view_count, navigate } from "../../../helpers/functions";
import { ROUTES } from "../../routes/my_routes";
import { width } from "styled-system";

const ListOfArticleTopContent = (props) => {
  const {
    image,
    view_count,
    title,
    margin_top,
    created_date_time,
    id,
    loading,
    type,
    name,
    height,
    width,
    inside_card_class,
  } = props;
  const { t } = useTranslation();

  const handle_click = () => {
    if (type === "article") {
      navigate(`${ROUTES.SINGLE_ARTICLE_PAGE}/${id}.html`);
    } else if (type === "fact-check" || type === "fact_check") {
      navigate(`${ROUTES.SINGLE_FACT_CHECK}/${id}.html`);
    }
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {loading ? (
        <Skeleton.Input
          className="user_contribution_top_image_skeleton"
          active
        />
      ) : (
        <Row onClick={handle_click} style={{ cursor: "pointer" }}>
          <Image
            className="list_of_article_top_image"
            visible={false}
            preview={false}
            src={image}
            alt="src"
            height={height ? height : ""}
            width={width ? width : ""}
            style={{ objectFit: "cover" }}
          />

          <Col
            className={
              inside_card_class
                ? inside_card_class
                : "list_of_article_inside_card"
            }
            style={{
              marginTop: margin_top || "",
            }}
            onClick={handle_click}
          >
            <div className="list_of_article_inside_card_width">
              <CustomText
                class_name={"list_of_article_inside_card_title"}
                text={title}
                font_family={"Lato"}
                size={"15px"}
                color={"#FFFFFF"}
                align={"left"}
              />
            </div>

            <div className="list_of_article_view_count_and_icon">
              <div className="list_of_article_view_count_and_icon_div">
                <CustomText
                  class_name={"list_of_article_inside_card_title"}
                  text={format_view_count(view_count)}
                  size={"12px"}
                  color={"#E84756"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                />
                <div className="view_icon">
                  <LuEye />
                </div>
              </div>
              <CustomText
                class_name={"list_of_article_inside_card_title"}
                text={`${name} | ${created_date_time}`}
                font_family={"Lato"}
                size={"12px"}
                weight={400}
                color={"#FFFFFF"}
                align={"left"}
              />
              <div className="list_of_article_page_button">
                <Button
                  className="custom-button"
                  style={{
                    backgroundColor: "#140F2D",
                    color: "#FFFFFF",
                    borderRadius: "10px",
                    width: "100px",
                    height: "25px",
                    border: "1px solid white",
                    fontSize: "11px",
                    cursor: "pointer",
                  }}
                  onClick={handle_click}
                >
                  {type === "article" ? t("article") : "Fact Check"}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ListOfArticleTopContent;
