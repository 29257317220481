import { Form, Input } from "antd";
import React from "react";

const NameInput = () => {
  return (
    <Form.Item
      name={"name"}
      style={{ borderBottom: "1px solid #000", paddingTop: "6%" }}
    >
      <Input style={{ border: "none", boxShadow: "none" }} />
    </Form.Item>
  );
};

export default NameInput;
