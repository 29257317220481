import { gql } from "@apollo/client";

export const get_all_comments_query = gql`
  query get_all_comments(
    $page_number: Int
    $page_limit: Int
    $search_string: String
  ) {
    get_all_comments(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        article_id
        fact_check_id
        content
        created_date_time
        commented_by {
          id
          mobile
          username
        }
        child_comments {
          id
          article_id
          fact_check_id
          content
          commented_by {
            id
            username
          }
        }
        replies_count
        error {
          message
        }
      }
    }
  }
`;

export const update_comments_query = gql`
  mutation update_comment($id: String!, $json: comment_input) {
    update_comment(id: $id, json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_comments_query = gql`
  mutation delete_comment($id: String!) {
    delete_comment(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_parent_comments_query = gql`
  query get_all_parent_comments(
    $article_id: String
    $fact_check_id: String
    $page_number: Int
    $page_limit: Int
  ) {
    get_all_parent_comments(
      article_id: $article_id
      fact_check_id: $fact_check_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        article_id
        fact_check_id
        content
        commented_by_id
        created_date_time
        commented_by {
          id
          username
        }
      }
    }
  }
`;

export const get_all_child_comments_query = gql`
  query get_all_child_comments(
    $parent_comment_id: String!
    $page_number: Int
    $page_limit: Int
  ) {
    get_child_comments: get_all_child_comments(
      parent_comment_id: $parent_comment_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        content
        parent_comment_id
        commented_by {
          id
          username
        }
        child_comments {
          id
          article_id
          fact_check_id
          content
        }
      }
    }
  }
`;

export const comments_processing_query = gql`
  mutation process_comment($id: String!, $type: String!) {
    process_comment(id: $id, type: $type) {
      id
      status
      error {
        message
      }
    }
  }
`;
