import React from "react";
import SignInForm from "../components/sign_in/sign_in_form";

const signIn = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <SignInForm />
    </div>
  );
};

export default signIn;
