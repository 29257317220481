import { gql } from "@apollo/client";

export const get_all_anti_party_tags_query = gql`
  query get_all_anti_party_tag(
    $language_id: String
    $page_number: Int
    $page_limit: Int
    $search_string:String
  ) {
    get_all_anti_party_tag(
      language_id: $language_id
      page_number: $page_number
      page_limit: $page_limit
      search_string:$search_string
    ) {
      items {
        id
        name
        is_deleted
        language_id
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const create_anti_party_tag_query = gql`
  mutation create_anti_party_tag($json: anti_party_tag_input) {
    create_anti_party_tag(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_anti_party_tag_query = gql`
  mutation update_anti_party_tag($id: String, $json: anti_party_tag_input) {
    update_anti_party_tag(id: $id, json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_anti_party_tag_query = gql`
  mutation delete_anti_party_tag($id: String) {
    delete_anti_party_tag(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
