import React, { memo } from "react";
import CustomText from "./custom_text";
import { Skeleton } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../routes/my_routes";
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";

const CustomFactCheckCategories = memo((props) => {
  const { t } = useTranslation();
  const {
    category_image,
    title,
    created_date,
    width,
    loading,
    perma_link,
    height,
    type,
    name,
  } = props;

  const history = useHistory();

  const on_click_to_single_article = () => {
    if (type === "article") {
      history.push(`${ROUTES.SINGLE_ARTICLE_PAGE}/${perma_link}.html`);
    } else if (type === "fact-check") {
      history.push(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    } else if (
      type === "related-fact-check" ||
      type === "fact_check" ||
      type === "Fact Check" ||
      type === "Bookmarked Fact Check" ||
      type === "My Fact Checks" ||
      type === "Fact Check Bookmarks"
    ) {
      history.push(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    } else if (
      type === "Article" ||
      type === "Bookmarked Article" ||
      type === "My Articles" ||
      type === "Article Bookmarks"
    ) {
      history.push(`${ROUTES.SINGLE_ARTICLE_PAGE}/${perma_link}.html`);
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton.Input
          className="fact_check_categories_skeleton"
          active
          style={{ height: "30vh", width: "20vw", gap: "15px" }}
        />
      ) : (
        <div
          style={{
            width: width ? width : "28%",
            height: height ? height : "35%",
            boxShadow: "5px 5px 15px 0px rgba(0, 101, 204, 0.13)",
            cursor: "pointer",
            borderRadius: "10px",
          }}
          className="fact_check_category_card"
          onClick={on_click_to_single_article}
        >
          {loading ? (
            <Skeleton.Input
              className="fact_check_categories_skeleton"
              style={{ height: "30vh", width: "20vw", gap: "15px" }}
              active
            />
          ) : (
            <div className="fact_check_category_card_image_div">
              <img
                src={category_image}
                alt="fact-check-category"
                className="fact_check_category_card_image"
                style={{
                  transform: "scale(1)",
                }}
              />
              <div className="fact_check_category_card_text">
                <div className="related-fact-check-title-div">
                  <CustomText
                    class_name={"related_fact_check_card_last_text"}
                    text={
                      title?.split(" ").length > 100
                        ? title
                            ?.split(" ")
                            ?.slice(0, isMobile ? 5 : isTablet ? 8 : 4)
                            ?.join(" ") + "..."
                        : title
                    }
                    font_family={"Lato"}
                    color={"white"}
                    weight={"600"}
                    align={"left"}
                  />
                </div>

                <CustomText
                  text={`${name} | ${created_date}`}
                  size={"13px"}
                  font_family={"Lato"}
                  color={"white"}
                  weight={"400"}
                  align={"left"}
                  class_name={"related_fact_check_name_view"}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
});

export default CustomFactCheckCategories;
