import { gql } from "@apollo/client";

export const get_awards_query=gql`
query get_all_award(
  $page_number: Int
  $page_limit: Int
  $search_string: String
) {
  get_all_awards_and_recognition: get_all_award(
    page_number: $page_number
    page_limit: $page_limit
    search_string: $search_string
  ) {
    pagination {
      page_number
      page_limit
      total_count
    }
    items {
      id
      title
      subtitle
      content
      description
      perma_link
      thumbnail_img
      created_date_time
    }
  }
}
`;

  export const get_one_award_query=gql`
  query get_award($id: String!) {
    get_award(id: $id) {
      id
      title
      subtitle
      content
      description
      perma_link
      thumbnail_img
      created_date_time
    }
  }
  `;

  export const create_award_query=gql`
  mutation create_award($json:award_input){
    create_award(json:$json){
      id
      status
      error{
        message
      }
    }
  }`;

  export const update_award_query=gql`
  mutation update_award($id: String!, $json: award_update_input) {
    update_award(id: $id, json: $json) {
      id
      status
      error {
        message
      }
    }
  }
  `;

  export const delete_award_query=gql`
  mutation delete_award($id: String!) {
    delete_award(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
  `;