import { Image } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../custom/custom_text";
import {
  format_views_count,
  split_tamil_english_words,
} from "../../../helpers/functions";
import { YOU_TUBE_LOGO } from "../../../helpers/image_constants";
import { useDarkMode } from "../contexts/dark_mode_provider";

const VideosCardYouTube = ({ video_item }) => {
  const { t } = useTranslation();
  const { is_dark_mode } = useDarkMode();

  const navigate_youtube = () => {
    window.open(
      `https://www.youtube.com/watch?v=${video_item?.video_id}`,
      "_blank"
    );
  };

  const views_count = format_views_count(video_item?.views);

  const get_days_since_published_text = (daysSincePublished) => {
    if (daysSincePublished === 0) {
      return t("Today");
    }
    const years = Math.floor(daysSincePublished / 365);
    const months = Math.floor((daysSincePublished % 365) / 30);
    const days = daysSincePublished % 30;
    const yearText = years > 0 ? `${years} ${t("year", { count: years })}` : "";
    const monthText =
      months > 0 ? `${months} ${t("month", { count: months })}` : "";
    const dayText = days > 0 ? `${days} ${t("day", { count: days })}` : "";
    const timeAgo = [yearText, monthText, dayText]
      .filter(Boolean)
      .join(" " + t("and") + " ");
    return timeAgo ? `${timeAgo} ${t("ago")}` : t("Today");
  };

  if (!video_item) {
    return null;
  }
  return (
    <div
      className="you-turn-videos-card"
      onClick={navigate_youtube}
      style={{
        backgroundColor: is_dark_mode ? "#2D2D2D" : "#FFFF",
      }}
    >
      <div className="you-turn-videos-card-image-first-div">
        <Image
          className="videos_page_card_image"
          visible={false}
          preview={false}
          src={video_item?.thumbnails}
        />
        <div className="youtube-logo-overlay">
          <Image
            className="youtube-logo"
            visible={false}
            preview={false}
            src={YOU_TUBE_LOGO}
          />
        </div>
      </div>
      <div className="you-turn-videos-card-image-second-div">
        <div className="you-turn-videos-card-title-div">
          <CustomText
            class_name={"youtube-page-views-text"}
            text={split_tamil_english_words(video_item?.title, _, 7)}
            font_family={"Lato"}
            align={"left"}
          />
        </div>
        <div className="you-turn-videos-card-views-div">
          <CustomText
            class_name={"you-tube-views-text"}
            text={`${views_count} ${t(
              "views"
            )} | ${get_days_since_published_text(
              Number(video_item?.days_since_published)
            )}`}
            font_family={"Lato"}
            size={"12px"}
            weight={400}
            color={"#232323"}
            align={"left"}
          />
        </div>
      </div>
    </div>
  );
};

export default VideosCardYouTube;
