import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import VForm from "../../system/ui/antd_form/antd_form";
import { Box, HStack, Pressable } from "native-base";
import {
  mutation_create_category,
  mutation_update_category,
} from "../../../../services/redux/slices/graphql/graphql_category";
import {
  create_team_member_query,
  dynamic_clear,
  dynamic_request,
  update_team_member_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { getUUID, showToast } from "../../../../helpers/functions";
import axios from "axios";
import { social_media_option } from "../../../../helpers/constants";
import { CiCircleMinus } from "react-icons/ci";
const TeammatesDetails = ({
  onCancel,
  action_type,
  get_all_team_mates,
  form_values,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [actionItem, setActionItem] = useState({});
  const [todo_social_media, set_todo_social_media] = useState([]);

  const {
    loading: create_team_member_loading,
    status: create_team_member_status,
    error: create_team_member_error,
  } = useDynamicSelector("create_team_member");

  const {
    loading: update_team_member_loading,
    status: update_team_member_status,
    error: update_team_member_error,
  } = useDynamicSelector("update_team_member");

  const type_options = [
    { id: "team", name: "YouTurn team" },
    { id: "medical_expert", name: "Medical team" },
  ];

  const handle_submit = (value) => {
    value.social_media_link = JSON.stringify(value.social_media_link);
    if (action_type?.includes("add")) {
      create_team_members(value);
    } else {
      update_team_members(value);
    }
  };

  const validate_url = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const url_regex =
      /^https:\/\/((([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,})|localhost)(:[0-9]{1,5})?(\/[^\s]*)?$/;
    if (url_regex.test(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(t("invalid_url_message"));
    }
  };

  const handle_add_social_media_todo = () => {
    const newItem = {
      url: "",
      type: "",
    };
    if (todo_social_media?.length > 0) {
      set_todo_social_media([...todo_social_media, newItem]);
    } else {
      set_todo_social_media([newItem]);
    }
  };

  const handle_remove_social_media_todo = (index) => {
    const updatedTodoList = [...todo_social_media];
    updatedTodoList.splice(index, 1);
    set_todo_social_media(updatedTodoList);
  };

  const create_team_members = (values) => {
    let key = [{ key: "create_team_member", loading: true }];
    let query = create_team_member_query;
    let variables = {
      json: {
        data: {
          name: values?.name,
          role: values?.role,
          image_url: values.image_url?.[0]?.url,
          description: values?.description,
          social_media_link: values?.social_media_link,
          type: "team",
          order_number: values?.order_number,
        },
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const update_team_members = (values) => {
    let key = [{ key: "update_team_member", loading: true }];
    let query = update_team_member_query;
    let variables = {
      json: {
        id: form_values?.id,
        data: {
          name: values?.name,
          role: values?.role,
          image_url: values?.image_url?.[0]?.url,
          description: values?.description,
          social_media_link: values?.social_media_link,
          type: "team",
        },
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    if (form_values?.id) {
      let social_media_link = JSON.parse(form_values?.social_media_link);
      set_todo_social_media(social_media_link);
      let initialValues = {
        ...form_values,
        social_media_link: social_media_link,
        image_url: form_values?.image_url
          ? [
              {
                // type: "document",
                url: form_values?.image_url,
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
      };
      form.setFieldsValue(initialValues);
    }
  }, [form_values]);

  useEffect(() => {
    if (create_team_member_status === "Success") {
      showToast({
        type: "success",
        message: "Team Member create successfully",
      });
      onCancel();
      get_all_team_mates();
      dispatch(dynamic_clear("create_team_member"));
    } else if (create_team_member_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamic_clear("create_team_member"));
    } else if (update_team_member_status === "Success") {
      showToast({
        type: "success",
        message: "Team Member updated successfully",
      });
      get_all_team_mates();
      onCancel();
      dispatch(dynamic_clear("update_team_member"));
    } else if (update_team_member_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamic_clear("update_team_member"));
    }
  }, [
    create_team_member_error,
    create_team_member_status,
    update_team_member_error,
    update_team_member_status,
  ]);
  return (
    <div>
      <VForm onSubmit={handle_submit} form={form} layout="vertical">
        <VForm.Number
          label="Order No"
          field="order_number"
          rules={[
            {
              required: true,
              message: "Order No is required ",
            },
          ]}
        />

        <VForm.TextBox
          label="Name"
          field="name"
          rules={[
            {
              required: true,
              message: "Name is required ",
            },
          ]}
        />
        <VForm.TextBox
          label="Role"
          field="role"
          rules={[
            {
              required: true,
              message: "Role is required ",
            },
          ]}
        />
        <HStack justifyContent={"flex-end"}>
          <Button onClick={handle_add_social_media_todo}>
            Add social media
          </Button>
        </HStack>
        {todo_social_media?.map((social_media, index) => {
          return (
            <HStack
              space={"10px"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box width={"150px"}>
                <VForm.Select
                  label="Type"
                  field={["social_media_link", index, "type"]}
                  rules={[
                    {
                      required: true,
                      message: "type is required ",
                    },
                  ]}
                  options={social_media_option}
                />
              </Box>
              <Box width={"250px"}>
                <VForm.TextBox
                  label="Link"
                  field={["social_media_link", index, "link"]}
                  rules={[
                    { required: true, message: "Link is required " },
                    { validator: validate_url, message: "Invalid URL format" },
                  ]}
                />
              </Box>

              <Box mt={"10px"}>
                <Pressable
                  onPress={() => {
                    handle_remove_social_media_todo(index);
                  }}
                >
                  <CiCircleMinus color="red" size={25} />
                </Pressable>
              </Box>
            </HStack>
          );
        })}
        <VForm.TextBox
          label="Description"
          field="description"
          rules={[
            {
              required: true,
              message: "Description is required ",
            },
          ]}
        />
        {/* <Form.Item
          label="Type"
          name="type"
          rules={[
            {
              required: true,
              message: "Type is required ",
            },
          ]}
        >
          <Select allowClear>
            {type_options?.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        <VForm.ImageFile
          label="Profile"
          field={"image_url"}
          rules={[
            {
              required: true,
              message: "Image is required ",
            },
          ]}
        />
        <HStack justifyContent={"flex-end"} space={"10px"}>
          <Button danger onClick={onCancel}>
            Close
          </Button>
          <Button
            htmlType="submit"
            loading={update_team_member_loading || create_team_member_loading}
            type="primary"
            className="team-member-submit-button"
          >
            Submit
          </Button>
        </HStack>
      </VForm>
    </div>
  );
};

export default TeammatesDetails;
