import React from "react";
import CustomText from "../../custom/custom_text";
import { PREMIUM_BADGE } from "../../../../helpers/image_constants";
import NameInput from "./name_input";
import { useDynamicSelector } from "../../../../services/redux";

const UserName = (props) => {
  const { open_form, role } = props;
  const item = useDynamicSelector("get_user");

  return (
    <div className="user_profile_name_and_role">
      {role === "Previleged User" ? (
        <img
          width={15}
          height={15}
          src={PREMIUM_BADGE}
          style={{ objectFit: "cover" }}
        />
      ) : (
        ""
      )}

      {item?.user_profile?.name && !open_form && (
        <CustomText
          text={item?.user_profile?.name}
          font_family={"Lato"}
          size={"15px"}
          color={"#140f2d"}
          align={"left"}
          weight={"700"}
        />
      )}
      {item?.staff_profile?.name && !open_form && (
        <CustomText
          text={item?.staff_profile?.name}
          font_family={"Lato"}
          size={"15px"}
          color={"#140f2d"}
          align={"left"}
          weight={"700"}
        />
      )}

      {open_form && <NameInput />}
    </div>
  );
};

export default UserName;
