import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import VForm from "../../system/ui/antd_form/antd_form";
import { HStack } from "native-base";
import { mutation_update_user } from "../../../../services/redux/slices/graphql/graphql_user";
import {
  create_staff_query,
  dynamic_clear,
  dynamic_request,
  mutation_user,
  update_staff_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getUUID } from "../../../../helpers/functions";
import { toast } from "react-toastify";
import Password from "antd/es/input/Password";

const UserDetails = ({
  onCancel,
  form,
  action_type,
  get_all_list,
  form_values,
  set_mutation_modal,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    loading: create_loading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("create_staff");

  const {
    loading: update_user_loading,
    status: update_user_status,
    error: update_user_error,
  } = useDynamicSelector("update_staff");

  const handle_submit = (value) => {
    if (action_type?.includes("add")) {
      create_staff_user(value);
    } else {
      update_one_staff(value);
    }
  };
  const create_staff_user = (value) => {
    let key = [{ key: "create_staff", loading: true }];
    let query = create_staff_query;
    let variables = {
      data: {
        name: value?.name,
        email: value?.email_id,
        mobile: value?.mobile,
        username: value?.email_id,
        password: value?.password,
        id: getUUID(),
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const update_one_staff = (value) => {
    let key = [{ key: "update_staff", loading: true }];
    let query = update_staff_query;
    let variables = {
      id: form_values?.id,
      data: {
        id: getUUID(),
        name: value?.name,
        email: value?.email_id,
        mobile: value?.mobile,
        username: value?.username,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    if (create_status === "Success") {
      toast.success("Staff created successfully");
      get_all_list();
      form.resetFields("");
      set_mutation_modal(false);
      dispatch(dynamic_clear("create_staff"));
    } else if (create_error) {
      toast.error(create_error?.message);
      dispatch(dynamic_clear("create_staff"));
    } else if (update_user_status === "success") {
      toast.success("Staff details updated Successfully");
      get_all_list();
      dispatch(dynamic_clear("update_staff"));
    } else if (update_user_error) {
      toast.error(update_user_error?.message);
      dispatch(dynamic_clear("update_staff"));
    }
  }, [create_status, update_user_status, create_error, update_user_error]);

  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  return (
    <div>
      <Form onFinish={handle_submit} form={form} layout="vertical">
        <VForm.TextBox
          label={t("form:name")}
          field="name"
          rules={[
            {
              required: true,
              message: t("error:name_is_required"),
            },
          ]}
        />

        <Form.Item
          label={t("form:mobile_number")}
          name="mobile"
          rules={[
            {
              required: true,
              message: t("error:mobile_is_required"),
            },
            {
              pattern: /^[0-9]{10}$/,
              message: t("enter_valid_mobile_number"),
            },
          ]}
        >
          <Input
            type="tel"
            inputMode="numeric"
            maxLength={10}
            onInput={handleInput}
          />
        </Form.Item>
        {/* <VForm.TextBox
          label={t("form:user_name")}
          field="username"
          rules={[
            {
              required: true,
              message: "Username is required",
            },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        /> */}

        <VForm.TextBox
          label={t("form:email")}
          field="email_id"
          rules={[
            {
              required: true,
              message: "Email Id is required",
            },
            {
              type: "email",
              message: "Enter Valid Email Address",
            },
          ]}
        />
        {action_type === "add" ? (
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: t("error:password_is_required"),
              },
            ]}
          >
            <Password className="user-password" />
          </Form.Item>
        ) : (
          ""
        )}

        {/* <VForm.CheckBox label={t("form:is_active")} field="is_active" /> */}
        <HStack justifyContent={"flex-end"} space={"10px"}>
          <Button
            htmlType="submit"
            loading={update_user_loading || create_loading}
            type="primary"
          >
            {"Submit"}
          </Button>
          <Button danger onClick={onCancel}>
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default UserDetails;
