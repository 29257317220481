import React, { useEffect, useState } from "react";
import UserContributionRecentlyAddedCard from "./user_contribution_recently_added_and_donation_card";
import { Row } from "antd";
import TopContributionTitle from "../top_contributers_title";
import DonationCard from "../../widgets/donation_card";
import { useDynamicSelector } from "../../../../services/redux";
import TopContributorsMobileView from "../top_contributors_mobile_view";
import { DEFAULT_USER } from "../../../../helpers/image_constants";

const RecentlyAddedMainRow = (props) => {
  const { page_limit } = props;
  const {
    items: get_all_contributers_items,
    loading: get_all_contributers_items_loading,
    pagination: get_all_user_contributers_pagination,
  } = useDynamicSelector("get_all_user_contributers");
  const {
    items: recently_added_for_user_contribution_items,
    loading: recently_added_for_user_contribution_loading,
    pagination: recently_user_contribution_pagination,
  } = useDynamicSelector("recently_added_for_user_contribution");
  const [filtered_item, set_filtered_item] = useState([]);

  const top_fact_check = filtered_item?.slice(1, 2);
  const recent_fact_checks = filtered_item?.slice(2, 6);

  useEffect(() => {
    if (
      recently_added_for_user_contribution_items &&
      recently_added_for_user_contribution_items.length > 0
    ) {
      set_filtered_item([...recently_added_for_user_contribution_items]);
    }
  }, [recently_added_for_user_contribution_items]);
  return (
    <Row className="user_contribution_recently_added_first_row">
      <UserContributionRecentlyAddedCard
        top_fact_check={top_fact_check}
        recent_fact_checks={recent_fact_checks}
        recently_added_for_user_contribution_loading={
          recently_added_for_user_contribution_loading
        }
      />

      <div className="top_contributors_mobile_view">
        <TopContributionTitle />

        <div className="user_contribution_recently_added_carousel">
          {/* {get_all_contributers_items &&
            get_all_contributers_items?.length > 0 &&
            get_all_contributers_items?.map((contributor, index) => ( */}
          <TopContributorsMobileView
            list={get_all_contributers_items}
            // key={index}
            // user_profile_id={contributor.user_profile_id}
            // contributor_image={
            //   contributor.profile_pic ? contributor.profile_pic : DEFAULT_USER
            // }
            // contributor_name={contributor.name}
          />
          {/* ))} */}
        </div>
      </div>

      <div className="user_contribution_donation_card_div">
        <DonationCard />
      </div>
    </Row>
  );
};

export default RecentlyAddedMainRow;
