import React from "react";
import CustomText from "../../custom/custom_text";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../../routes/my_routes";
const DonationCardTitle = (props) => {
  const { card_title } = props;
  const history = useHistory();

  const on_click_donation = () => {
    history.push(`${ROUTES.USER_PROFILE_DONATION_LIST}`);
  };
  return (
    <div className="profile_card_title_with_icon">
      <CustomText
        text={card_title}
        font_family={"Lato"}
        size={"13px"}
        color={"#140F2D"}
        align={"left"}
        weight={"700"}
      />
      <CustomText
        text={"View all"}
        font_family={"Lato"}
        size={"12px"}
        color={"#140F2D"}
        align={"left"}
        weight={"600"}
        is_link={true}
        on_click={on_click_donation}
      />
    </div>
  );
};

export default DonationCardTitle;
