import { Form, Input, Select } from "antd";
import React from "react";

const FeaturedArticle = (props) => {
  const { featured_article_list, set_type, set_article_id, is_editing } = props;

  const on_change = (value) => {
    set_type("article");
    set_article_id(value);
  };

  return (
    <Form.Item
      name="article_id"
      label="Article"
      rules={[
        {
          required: true,
          message: "Please choose the article",
        },
      ]}
    >
      {is_editing ? (
        <Select allowClear onChange={on_change}>
          {featured_article_list?.map((option, index) => (
            <Select.Option key={option?.id} value={option?.id}>
              {option?.title}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Input readOnly disabled={true} />
      )}
    </Form.Item>
  );
};

export default FeaturedArticle;
