import React, { useEffect, useState } from "react";
import {
  FORM_BACKGROUND,
  THANK_YOU_IMAGE,
} from "../../../helpers/image_constants";
import { HStack, VStack } from "native-base";
import PageTitle from "../system/page_title/page_title";
import { Button, Form, Modal, Select } from "antd";
import Title from "../admin/admin_article/admin_article_form/title";
import SubTitle from "../admin/admin_article/admin_article_form/sub_title";
import { navigate, navigateBack } from "../../../helpers/navigator";
import { useTranslation } from "react-i18next";
import VForm from "../system/ui/antd_form";
import Content from "../admin/admin_article/admin_article_form/content";
import SpreadNews from "../admin/admin_article/admin_article_form/spread_news";
import ShortStory from "../admin/admin_article/admin_article_form/short_story";
import Source from "../admin/admin_article/admin_article_form/source";
import {
  dynamic_clear,
  dynamic_request,
  get_all_languages_query,
  mutation_create_fact_check,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { showToast } from "../../../helpers/functions";
import { ROUTES } from "../../routes/my_routes";
import CreateSuccessModal from "../admin/admin_article/admin_article_form/create_success_modal";
import ConfettiAnimation from "../widgets/result";

const FactCheckContribution = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [show_modal, set_show_modal] = useState(false);
  const [create_status, set_create_status] = useState(false);

  const {
    status: create_fact_check_status,
    error: create_fact_check_error,
    loading: create_fact_check_loading,
  } = useDynamicSelector("create_fact_check");

  const { items: system_languages } = useDynamicSelector(
    "get_all_system_languages"
  );

  useEffect(() => {
    get_all_languages();
  }, []);

  useEffect(() => {
    if (create_fact_check_status === "Success") {
      set_show_modal(true);
      set_create_status(true);
      setTimeout(() => {
        set_show_modal(false);
        set_create_status(false);

        navigate(`${ROUTES.USER_PROFILE_LIST}/my-factcheck`);
      }, 10000);

      dispatch(dynamic_clear("create_fact_check"));
    } else if (create_fact_check_error?.message) {
      showToast({ type: "error", message: create_fact_check_error?.message });
      dispatch(dynamic_clear("create_fact_check"));
    }
  }, [create_fact_check_status, create_fact_check_error]);

  const handle_back = () => {
    navigateBack();
  };

  const handle_submit = (values) => {
    create_fact_checks(values);
  };

  const create_fact_checks = (values) => {
    let key = [{ key: "create_fact_check", loading: true }];
    let query = mutation_create_fact_check;
    let data = {
      ...values,
      party_tags: values?.party_tag_id,
      thumbnail_img: values?.thumbnail_img?.[0]?.url,
      is_fact: values?.is_fact,
    };
    delete data.party_tag_id;
    let variables = {
      json: data,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };
  return (
    <div
      style={{
        position: "relative",
        backgroundImage: `url(${FORM_BACKGROUND})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* <CreateSuccessModal
        set_show_modal={set_show_modal}
        create_status={create_status}
        show_modal={show_modal}
      /> */}
      <Modal
        visible={show_modal}
        closable={false}
        footer={null}
        width={800}
        onCancel={() => set_show_modal(false)}
      >
        <div style={{ textAlign: "center", height: "400px" }}>
          <h2>Thank You!</h2>
          <h6>We extend our sincere appreciation for your fact check</h6>
          <img
            style={{
              borderRadius: "9px",
              zIndex: 1,
              objectFit: "cover",
              height: "170px",
              marginTop: "4vh",
            }}
            src={THANK_YOU_IMAGE}
            alt="Profile"
          />
          <h6 style={{ marginTop: "4vh" }}>
            Together, let's make an impact! We're submitting your fact check to
            process and Keep supporting us, and let's change lives!
          </h6>
          {create_status && <ConfettiAnimation />}
        </div>
      </Modal>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0.1,
        }}
      ></div>
      <VStack style={{ padding: "50px" }}>
        <PageTitle title={"Add Fact check"} class_name={"user_article_form"} />
        <Form
          id="fact_check_form"
          //   initialValues={selected_item}
          form={form}
          layout="vertical"
          onFinish={handle_submit}
        >
          <Title color={"#ffffff"} />
          <SubTitle color={"#ffffff"} />
          <VForm.ImageFile
            label={<span style={{ color: "white" }}>Thumbnail Image</span>}
            field={"thumbnail_img"}
            rules={[{ required: true, message: "Please upload the image" }]}
          />
          <Form.Item
            label={<span style={{ color: "white" }}>Language</span>}
            name="language_id"
            rules={[{ required: true, message: "Language is required " }]}
          >
            <Select
            // onChange={on_change_language}
            // defaultValue={system_languages ? system_languages?.[0]?.name : ""}
            >
              {system_languages?.map((option, index) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <VForm.TextBox
            label={<span style={{ color: "white" }}>Perma link</span>}
            field={"perma_link"}
            rules={[{ required: true, message: "Perma link is required " }]}
          />
          <Content color={"#ffffff"} />
          <SpreadNews color={"#ffffff"} />
          <ShortStory color={"#ffffff"} />
          <Source color={"#ffffff"} />
          <HStack justifyContent={"flex-end"} space={"10px"}>
            <Button onClick={handle_back} danger>
              {t("back")}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={create_fact_check_loading}
            >
              {"Create"}
            </Button>
          </HStack>
        </Form>
      </VStack>
    </div>
  );
};

export default FactCheckContribution;
