import React, { useEffect, useState } from "react";
import { Button, Drawer, Layout, Space } from "antd";
import Sidebar from "../components/system/sidebar/sidebar";
import { Box, HStack, Text, VStack } from "native-base";
import { ROUTES } from "../routes/my_routes";
import { BiSolidContact } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";

// import HeaderBar from "@views/components/user/header_bar/header_bar";
import { useCheckLogin } from "@helpers/hooks";
import { navigate } from "@helpers/functions";
import { useTranslation } from "react-i18next";

import { retrieveItem } from "../../helpers/functions";
import AdminHeaderBar from "../components/user/header_bar/admin_header_bar";
import { RiArticleFill } from "react-icons/ri";
import { MdFactCheck, MdReport, MdWorkHistory } from "react-icons/md";
import { MdCategory } from "react-icons/md";
import { FaFilePen, FaRectangleList, FaTag } from "react-icons/fa6";
import { GiTeamIdea } from "react-icons/gi";
import { MdDashboard } from "react-icons/md";
import { TbSquareRoundedCheckFilled } from "react-icons/tb";
import { FaAward, FaCommentDots, FaRegCommentDots } from "react-icons/fa";
import { BsFillPinFill, BsFillQuestionCircleFill } from "react-icons/bs";
const { Content } = Layout;

const UserLayout = ({ children }) => {
  const { t } = useTranslation();
  const is_logged_in = useCheckLogin();
  const [open, setOpen] = useState(false);

  const role = retrieveItem("role");

  const menu_list = [
    {
      key: "Admin landing page",
      icon: <MdFactCheck />,
      label: t("Landing Page Slots"),
      url: `${ROUTES.ADMIN_LANDING_PAGE}/1`,
      accessKey: "Admin landing page",
    },
    {
      key: "Recommended stories",
      icon: <FaRectangleList />,
      label: "Recommended Stories",
      url: `${ROUTES.RECOMMENDED_STORIES}/1`,
      accessKey: "Recommended stories",
    },
    {
      key: "Featured stories",
      icon: <BsFillPinFill />,
      label: "Featured Stories",
      url: ROUTES.FEATURED_STORIES,
      accessKey: "Featured stories",
    },

    {
      key: "Admin article",
      icon: <RiArticleFill />,
      label: t("admin_article"),
      url: ROUTES.ADMIN_ARTICLE,
      accessKey: "Admin article",
    },
    {
      key: "Admin fact check",
      icon: <MdFactCheck />,
      label: t("admin_fact_check"),
      url: ROUTES.ADMIN_FACT_CHECK,
      accessKey: "Admin fact check",
    },
    {
      key: "Master data",
      icon: <MdDashboard />,
      label: t("master_data"),
      accessKey: "Master data",
      children: [
        {
          key: "user",
          icon: <AiOutlineUser />,
          label: t("users"),
          url: ROUTES.ADMIN_USER_LIST,
          accessKey: "Master data",
        },
        {
          key: "Category",
          icon: <MdCategory />,
          label: t("category"),
          url: ROUTES.ADMIN_CATEGORY,
          accessKey: "Master data",
        },
        {
          key: "Party tag",
          icon: <FaTag />,
          label: t("party_tag"),
          url: ROUTES.ADMIN_PARTY_TAG,
          accessKey: "Master data",
        },
        {
          key: "Anti Party tag",
          icon: <FaTag />,
          label: "Anti Party tag",
          url: ROUTES.ADMIN_ANTI_PARTY_TAG,
          accessKey: "Master data",
        },
        {
          key: "Team",
          icon: <GiTeamIdea />,
          label: t("team"),
          url: ROUTES.ADMIN_TEAMMATES,
          accessKey: "Master data",
        },
        {
          key: "Comments",
          icon: <FaRegCommentDots />,
          label: "Comments",
          url: ROUTES.COMMENTS,
          accessKey: "Master data",
        },
        {
          key: "Reasons",
          icon: <BsFillQuestionCircleFill />,
          label: "Reasons",
          url: ROUTES.REASONS,
          accessKey: "Master data",
        },
        {
          key: "Awards",
          icon: <FaAward />,
          label: "Awards",
          url: ROUTES.AWARDS,
          accessKey: "Master data",
        },
      ],
    },
    {
      key: "User claims",
      icon: <FaFilePen />,
      label: "User Claims",
      url: ROUTES.USER_CLAIM_LIST,
      accessKey: "User claims",
    },
    {
      key: "Reports list",
      // icon: <MdReport />,
      icon: <FaCommentDots />,
      label: "Report list",
      url: ROUTES.REPORT_LIST,
      accessKey: "Reports list",
    },
    {
      key: "Feedback",
      icon: <BiSolidContact />,
      label: "Feedback",
      url: ROUTES.CONTACT_US_LIST,
      accessKey: "Feedback",
    },
    {
      key: "Admin donation",
      icon: <MdFactCheck />,
      label: t("Donations"),
      url: ROUTES.ADMIN_DONATION,
      accessKey: "Admin donation",
    },
    {
      key: "Access rights",
      icon: <TbSquareRoundedCheckFilled />,
      label: "Access Rights",
      url: ROUTES.ACCESS_RIGHTS,
      accessKey: "Access rights",
    },
  ];

  useEffect(() => {
    if (is_logged_in === false) {
      navigate(ROUTES.LOGIN);
    }
  }, [is_logged_in]);

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Layout>
      <AdminHeaderBar setOpen={setOpen} />
      <HStack flex={1}>
        <Sidebar menu_list={menu_list} />
        <VStack flex={1}>
          <Content style={{ overflowY: "auto" }}>{children}</Content>
        </VStack>
      </HStack>
      <Drawer
        // className="ant-drawer-title ant-drawer-close"
        title="Notifications"
        placement="right"
        onClose={onClose}
        open={open}
        closable
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
          </Space>
        }
      >
        <Box
          style={{
            padding: 10,
            alignItems: "center",
          }}
        >
          {/* <NotificationLists /> */}
        </Box>
      </Drawer>
    </Layout>
  );
};
export default UserLayout;
