import CryptoJS from "crypto-js";
import { useLocation } from "react-router-dom";

export const encryptData = (data, salt) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

export const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(
    ciphertext,
    "4Y1oO0ElwMgib9bwVyglYxqhdpWBFpxw"
  );

  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};

export const queryStringSet = (key, value) => {
  const queryParams = new URLSearchParams();
  // queryParams.set(key, value);
  return queryParams.set(key, value);
};
 
export const queryStringRetrieve = (key) => {
  const location = useLocation();
 let param1;
 const queryParams = new URLSearchParams(location.search);
    param1 = queryParams.get(key);
  return param1;
};

export const queryStringRetrieve1 = (key, value) => {
  const queryParams = new URLSearchParams(value);
  return queryParams.get(key);
};