import { Form, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import JoinUsCard from "../components/widgets/join_us_card";
import DonationCardSmall from "../components/widgets/donation_card_small";
import {
  dynamic_clear,
  dynamic_request,
  mutation_add_fact_check_to_saved_items,
  mutation_fact_check_report,
  mutation_remove_fact_check_from_saved_items,
  query_fact_check_saved,
  query_get_fact_check_by_perma_link,
  useDynamicSelector,
} from "../../services/redux";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import SingleFactCheckReportCard from "../components/single_fact_check/single_fact_check_report_card";
import { debounce } from "lodash";
import SingleFactCheckTopContent from "../components/search_results/search_results_header_contents/single_fact_check_top_content";
import ReportShareCard from "../components/single_article_page/report_share_card";
import SingleFactCheckMainContent from "../components/single_fact_check/single_fact_check_main_content";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import { retrieveItem } from "../../helpers/functions";
import toast from "react-hot-toast";
import { ROUTES } from "../routes/my_routes";
import SingleFactCheckRelatedRecentFactCheck from "../components/single_fact_check/single_fact_check_related_recent_fact_check";

const SingleFactCheck = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const { perma_link } = useParams();
  const { is_dark_mode } = useDarkMode();
  const session_id = retrieveItem("session_id");
  let current_language = retrieveItem("language");
  const [model_open, set_model_open] = useState(false);
  const role = retrieveItem("role");

  const top_ref = useRef(null);
  const url = window.location.href;
  const queryParams = new URLSearchParams(location.search);

  const {
    views,
    title,
    id: fact_check_id,
    reading_time,
    thumbnail_img,
    loading: get_all_fact_check_loading,
    error: get_all_fact_check_error,
  } = useDynamicSelector("get_fact_check_by_perma_link");

  const { is_saved: check_saved } = useDynamicSelector(
    "get_fact_check_perma_link_saved"
  );

  const { status: create_report_status, error: create_report_error } =
    useDynamicSelector("create_fact_check_report");

  useEffect(() => {
    if (get_all_fact_check_error) {
      dispatch(dynamic_clear("get_fact_check_by_perma_link"));
      history.push(ROUTES.PAGE_NOT_FOUND);
    }
  }, [get_all_fact_check_error]);

  useEffect(() => {
    debounced_handle_title_click();
  }, [pathname]);

  useEffect(() => {
    if (perma_link) {
      get_fact_check_by_perma();
      get_fact_check_saved_perma();
    }
  }, [perma_link]);

  useEffect(() => {
    if (title) document.title = title;
  }, [title]);

  useEffect(() => {
    if (create_report_status === "Success") {
      toast.success("Report submitted");
      set_model_open(false);
      form.resetFields("");
      dispatch(dynamic_clear("create_fact_check_report"));
    } else if (create_report_error) {
      toast.error(create_report_error?.message);
    }
    dispatch(dynamic_clear("create_fact_check_report"));
  }, [create_report_status, create_report_error]);

  useEffect(() => {
    const og_title = document.createElement("meta");
    og_title.setAttribute("property", "og:title");
    og_title.setAttribute("content", title);
    document.head.appendChild(og_title);
    const og_image = document.createElement("meta");
    og_image.setAttribute("property", "og:image");
    og_image.setAttribute("content", thumbnail_img);
    document.head.appendChild(og_image);
    return () => {
      document.head.removeChild(og_title);
      document.head.removeChild(og_image);
    };
  }, [title, thumbnail_img, url]);

  const get_fact_check_by_perma = () => {
    let key = [{ key: "get_fact_check_by_perma_link", loading: true }];
    let query = query_get_fact_check_by_perma_link;
    let variables = {
      language_id: current_language?.id,
      perma_link: perma_link,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_fact_check_saved_perma = () => {
    let key = [{ key: "get_fact_check_perma_link_saved", loading: true }];
    let query = query_fact_check_saved;
    let variables = {
      language_id: current_language?.id,
      perma_link: perma_link,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_mutation_add_fact_check_to_saved_items = () => {
    let key = [{ key: "add_fact_check_to_saved_items", loading: true }];
    let query = mutation_add_fact_check_to_saved_items;
    let variables = {
      fact_check_id: fact_check_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_mutation_remove_fact_check_from_saved_items = () => {
    let key = [{ key: "remove_fact_check_from_saved_items", loading: true }];
    let query = mutation_remove_fact_check_from_saved_items;
    let variables = {
      fact_check_id: fact_check_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_save = () => {
    if (session_id && (role === "User" || role === "Previleged User")) {
      get_fact_check_saved_perma();
      if (check_saved) {
        get_mutation_remove_fact_check_from_saved_items();
      } else {
        get_mutation_add_fact_check_to_saved_items();
      }
    } else {
      const queryParams = new URLSearchParams();
      queryParams.set("type", "bookmark");
      queryParams.set("types", "fact-check");
      queryParams.set("id", fact_check_id);
      queryParams.set("redirecturl", window.location.pathname);
      history.push({
        pathname: "/login",
        search: `?${queryParams.toString()}`,
      });
    }
  };

  const report = (values) => {
    let key = [{ key: "create_fact_check_report", loading: true }];
    let query = mutation_fact_check_report;
    let variables = {
      json: {
        fact_check_id: fact_check_id,
        reason_id: values?.reason,
        description: values?.description,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_page_position = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debounced_handle_title_click = debounce(handle_page_position, 800);

  return (
    <>
      <div
        ref={top_ref}
        className={`single-fact-check-full-page  ${
          is_dark_mode ? "content-dark" : ""
        }`}
      >
        <SingleFactCheckTopContent
          is_saved={check_saved}
          handle_save={handle_save}
          report={report}
          form={form}
          set_model_open={set_model_open}
          model_open={model_open}
        />

        <ReportShareCard
          reading_time={reading_time}
          view_count={views}
          title={title}
          thumbnail_img={thumbnail_img}
          loading={get_all_fact_check_loading}
          report={report}
          set_model_open={set_model_open}
          model_open={model_open}
        />
        <SingleFactCheckMainContent
          get_fact_check_saved_perma={get_fact_check_saved_perma}
          handle_save={handle_save}
        />
        <div className="laptop-report-donatio-row">
          <SingleFactCheckReportCard />
          <DonationCardSmall width={"32vw"} />
        </div>

        <SingleFactCheckRelatedRecentFactCheck />

        <Row className="laptop-join-us-card-row">
          <JoinUsCard />
        </Row>
      </div>
    </>
  );
};
export default SingleFactCheck;
