import { useDynamicSelector } from "../../../../services/redux";
import { Image, Skeleton } from "antd";
import CustomBreadCrumbs from "../../custom/custom_breadcrumbs";
import { BROKEN_IMAGE } from "../../../../helpers/image_constants";
import { split_tamil_english_words_for_breadcrumbs } from "../../../../helpers/functions";
import CustomReportModel from "../../custom/custom_report_model";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import SingleFactCheckMobileTopContent from "../../single_fact_check/single_fact_check_mobile_top_content";
import SingleFactCheckReportShareSave from "../../single_fact_check/single_fact_check_report_share_save";
import SingleFactCheckAuthorDetails from "../../single_fact_check/single_fact_check_author_details";
import SingleFactCheckTopTitle from "../../single_fact_check/single_fact_check_top_title";

const SingleFactCheckTopContent = (props) => {
  const { is_saved, handle_save, form, model_open, set_model_open, report } =
    props;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    thumbnail_img,
    title,
    loading: ger_all_fact_check_loading,
  } = useDynamicSelector("get_fact_check_by_perma_link");

  return (
    <div className="top-content-fact-check">
      <div className="fact-check-row-class">
        <div className="fact-check-top-box">
          <div className="fact-check-bread-crumbs-div">
            <CustomBreadCrumbs
              pages={`Home/ Fact Check/ ${split_tamil_english_words_for_breadcrumbs(
                title,
                "_",
                2
              )}`}
            />
          </div>
          <div className="fact-check-space-between">
            {ger_all_fact_check_loading ? (
              <Skeleton.Input
                className="skeleton-fact-check-bread-crumbs-col"
                active
              />
            ) : (
              <div className="fact-check-bread-crumbs-col">
                <SingleFactCheckTopTitle />

                <SingleFactCheckReportShareSave
                  is_saved={is_saved}
                  handle_save={handle_save}
                  set_model_open={set_model_open}
                />

                {ger_all_fact_check_loading ? (
                  <Skeleton.Input
                    className="skeleton-fact-check-bread-crumbs-col"
                    active
                  />
                ) : (
                  <SingleFactCheckAuthorDetails />
                )}
              </div>
            )}

            {ger_all_fact_check_loading ? (
              <Skeleton.Input
                className="skeleton-single-fact-check-top-image-col"
                style={{
                  width: "110vh",
                  height: "80vh",
                }}
                active
              />
            ) : (
              <div className="single-fact-check-top-image-col">
                <Image
                  className="single-fact-check-top-image"
                  visible={false}
                  preview={false}
                  src={thumbnail_img ? thumbnail_img : BROKEN_IMAGE}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <SingleFactCheckMobileTopContent />

      <CustomReportModel
        model_open={model_open}
        set_model_open={set_model_open}
        // report={report}
        form={form}
      />
    </div>
  );
};

export default SingleFactCheckTopContent;
