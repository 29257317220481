import { Form } from "antd";
import { TextArea } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";

const UserAddressFormItem = () => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name="address"
      label={t("address")}
      rules={[{ required: true, message: "Please enter your  address" }]}
    >
      <TextArea placeholder={t("address")}></TextArea>
    </Form.Item>
  );
};

export default UserAddressFormItem;
