import React from "react";
import CustomText from "../custom/custom_text";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDynamicSelector } from "../../../services/redux";
import { useDarkMode } from "../contexts/dark_mode_provider";

const CustomCountDetailsCard = (props) => {
  const { title, count, staffProfileId, userProfileId, record_type, name } =
    props;
  const { is_dark_mode } = useDarkMode();

  const history = useHistory();
  const { items: author_details } = useDynamicSelector(
    "get_all_author_stories"
  );
  const { article_count, fact_check_count } = useDynamicSelector(
    "get_dashboard_count"
  );

  const on_click_to_profile = () => {
    const queryParams = new URLSearchParams();

    if (staffProfileId) {
      queryParams.set("staff_profile", staffProfileId);
    }
    if (userProfileId) {
      queryParams.set("user_profile", userProfileId);
    }
    if (record_type) {
      queryParams.set("record_type", record_type);
    }
    if (name) {
      queryParams.set("name", name);
    }
    history.push({
      pathname: `/author-profile`,
      search: `?${queryParams.toString()}`,
    });
  };

  return (
    <div
      className={"author_profile_count_card"}
      style={{ backgroundColor: is_dark_mode ? "#2D2D2D" : "#FFFF" }}
      onClick={on_click_to_profile}
    >
      <CustomText
        text={title}
        font_family={"Lato"}
        size={"18px"}
        color={"#140F2D"}
        align={"left"}
        weight={"700"}
        class_name={"count_title"}
      />

      <CustomText
        text={title === "Article" ? article_count : fact_check_count}
        font_family={"Lato"}
        size={"30px"}
        color={"#140F2D"}
        align={"left"}
        weight={"700"}
      />
    </div>
  );
};

export default CustomCountDetailsCard;
