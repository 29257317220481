import React, { useEffect, useRef, useState } from "react";
import CustomBreadCrumbs from "../components/custom/custom_breadcrumbs";
import { ARROW_ICON } from "../../helpers/image_constants";
import { Image, Skeleton, Typography } from "antd";
import { Button, Input, Row } from "antd";
import CustomNewsStoryCard from "../components/custom/custom_news_story_card";
import JoinUsCard from "../components/widgets/join_us_card";
import CustomNewsArticle from "../components/custom/custom_news_article";
import SocialMediaList from "../components/widgets/social_media_list";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { dynamic_request, useDynamicSelector } from "../../services/redux";
import CategoriesList from "../components/list_of_article/categories_list";
import { debounce } from "lodash";
import CustomArticleText from "../components/custom/custom_article_text";
import moment from "moment";
import RecentlyAdded from "../components/list_of_article_page/recently_added";
import {
  navigate,
  retrieveItem,
  split_tamil_english_words,
} from "../../helpers/functions";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  query_get_all_article_in_article_list,
  query_get_all_article_in_article_list_category_wise,
  query_get_article_by_perma_link_in_article_list,
  query_get_recent_article_in_article_list,
  query_get_three_trending_article,
} from "../../services/redux/slices/graphql/graphql_article_list";
import ListOfArticleTopContent from "../components/list_of_article_page/list_of_article_top_content";
import CustomFactCheckCategories from "../components/custom/custom_fact_check_categories";
import TopArticleInListOfArticle from "../components/list_of_article_page/top_article_in_list_of_article";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../routes/my_routes";

import CustomText from "@custom/custom_text";

const ArticlesList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { category, link } = useParams();
  const history = useHistory();
  const [article_category_id, set_article_category_id] = useState();
  const [article_category_name, set_article_category_name] = useState();
  const [filtered_item, set_filtered_item] = useState([]);
  let current_language = retrieveItem("language");
  const [page_limit, set_page_limt] = useState(6);
  const { pathname } = useLocation();
  const location = useLocation();
  const top_ref = useRef(null);

  const { items: article_list, loading: article_list_loading } =
    useDynamicSelector("get_all_article_in_article_list");

  const {
    items: category_wise_article_list,
    loading: category_wise_article_list_loading,
  } = useDynamicSelector("get_all_article");

  const {
    title,
    loading: article_by_perma_link_loading,
  } = useDynamicSelector("get_articles_by_perma_link");
  const { items: three_trending_articles } = useDynamicSelector(
    "get_three_trending_article"
  );

  const { items: recent_article_items, loading: recent_article_items_loading } =
    useDynamicSelector("get_recent_article_list");

  const perma_link = !location?.props_state?.is_category
    ? location?.props_state?.perma_link
    : "";
  const sliced_link = link.slice(0, link.indexOf(".html"));

  useEffect(() => {
    debouncedHandleTitleClick();
  }, [pathname]);
  useEffect(() => {
    if (title) document.title = title;
  }, [title]);
  useEffect(() => {
    if (article_list && article_list.length > 0) {
      set_filtered_item([...article_list]);
    }
  }, [article_list]);

  useEffect(() => {
    get_one_article_by_perma_link();
    get_three_trending_article_list();
    get_recent_article_list();
  }, [location?.props_state?.type, perma_link, sliced_link]);
  useEffect(() => {
    get_all_article_list();
  }, [page_limit, article_category_id]);

  useEffect(() => {
    get_category_wise_article_list;
  }, [article_category_id]);

  useEffect(() => {
    if (article_list?.length && article_category_name)
      history.push(
        `${ROUTES.LIST_OF_ARTICLES}/${article_category_name}/${
          article_list?.[0]?.perma_link + ".html"
        }`
      );
  }, [category_wise_article_list, article_category_name]);

  const { Search } = Input;
  const filtered_items = perma_link
    ? filtered_item?.filter((item) => item.perma_link !== perma_link)
    : filtered_item?.filter((item) => item.perma_link !== sliced_link);

  const get_one_article_by_perma_link = () => {
    let key = [{ key: "get_articles_by_perma_link", loading: true }];
    let query = query_get_article_by_perma_link_in_article_list;
    let variables = {
      perma_link: perma_link || sliced_link,
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const get_all_article_list = () => {
    let key = [{ key: "get_all_article_in_article_list", loading: true }];
    let query = query_get_all_article_in_article_list;
    let variables = {
      page_limit: page_limit,
      page_number: 1,
      json: {
        // category_tag: category_id ? category_id : "",
        category_tag: location?.props_state?.category_id
          ? location?.props_state?.category_id
          : article_category_id,
        language_id: current_language?.id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_three_trending_article_list = () => {
    let key = [{ key: "get_three_trending_article", loading: true }];
    let query = query_get_three_trending_article;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_recent_article_list = () => {
    let key = [{ key: "get_recent_article_list", loading: true }];
    let query = query_get_recent_article_in_article_list;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_category_wise_article_list = () => {
    let key = [{ key: "get_all_article", loading: true }];
    let query = query_get_all_article_in_article_list_category_wise;
    let variables = {
      page_limit: 6,
      json: {
        category_tag: article_category_id || "",
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const on_click = () => {
    set_page_limt(page_limit + 6);
  };

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);

  const handle_click = (type, id) => {
    if (type === "article") {
      navigate(`/single-article-page/${id}.html`);
    } else if (type === "fact-check") {
      navigate(`/fact-check/${id}.html`);
    }
  };

  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);

  // const [filteredContent, setFilteredContent] = useState("");
  // let htmlContent = article_list?.items?.[5]?.content;
  // const allowedTags = ["p", "h1"];
  // useEffect(() => {
  //   // Parse the HTML content
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlContent, "text/html");

  //   // Filter the content based on allowed tags
  //   const filteredElements = Array.from(doc.body.children).filter((element) => {
  //     return allowedTags.includes(element.tagName.toLowerCase());
  //   });

  //   // Create a new HTML string with the filtered content
  //   const filteredHtml = filteredElements
  //     .map((element) => element.outerHTML)
  //     .join("");

  //   // Set the filtered content to the state
  //   setFilteredContent(filteredHtml);
  // }, [htmlContent, allowedTags]);

  // const tagRegex = /<[^>]*>([^<]*)<\/[^>]*>/g;

  // // Use the match function to find all matches
  // const parser = new DOMParser();
  // const doc = parser.parseFromString(htmlContent, "text/html");
  // const matches = htmlContent?.match(tagRegex);
  // const content = article_list?.[0]?.content?.split("<span>")?.[0]?.split(".");

  return (
    <div ref={top_ref}>
      <div className="article_list_page_thumnail_image_with_text">
        <TopArticleInListOfArticle
          category_name={
            article_category_name
              ? article_category_name
              : location?.props_state?.category_name
          }
          // article_perma_link={
          //   location?.props_state?.perma_link
          //     ? location?.props_state?.perma_link
          //     : sliced_link
          // }
          article_perma_link={
            sliced_link
            // sliced_link ? sliced_link : article_list?.[0]?.perma_link
          }
          type={"article"}
        />
      </div>

      <Row className="videos_list_news_story_card">
        <div className="search_results_items">
          <div className="search_result_cards">
            <div className="article_list_news_stories_card">
              {/* {article_list_loading
                ? Array.from({ length: page_limit + 6 }, (_, index) => (
                    <Skeleton.Input
                      key={index}
                      className="fact-check-list-news-story-card-skeleton"
                      active
                    />
                  )) */}
              {filtered_items?.map((item, index) => (
                <CustomNewsStoryCard
                  article_img={item?.thumbnail_img}
                  name={item?.staff_profile?.name}
                  date={item?.created_date_time}
                  viewed_count={item?.views}
                  reading_time={item?.reading_time}
                  img_width={"27.8vw"}
                  content_width={"44vh"}
                  first_row_width={"17vw"}
                  text={split_tamil_english_words(item?.title, _, 4)}
                  subtitle={split_tamil_english_words(item?.subtitle, _, 9)}
                  type={"article"}
                  perma_link={item?.perma_link}
                />
              ))}
            </div>
            <div className="article_list_in_list_of_article">
              {article_list?.map((item, index) => (
                <CustomFactCheckCategories
                  category_image={item?.thumbnail_img}
                  created_date={item?.created_date_time}
                  viewed_count={item?.views}
                  reading_time={item?.reading_time}
                  title={split_tamil_english_words(item?.title, _, 7)}
                  sub_title={split_tamil_english_words(item?.subtitle, _, 9)}
                  type={"article"}
                  perma_link={item?.perma_link}
                />
              ))}
            </div>
            {article_list_loading &&
              Array.from({ length: page_limit + 6 }, (_, index) => (
                <Skeleton.Input
                  key={index}
                  className="fact-check-list-news-story-card-skeleton"
                  active
                />
              ))}

            <div className="load_more_button">
              <Button className="load_button_color" onClick={on_click}>
                {t("Load More")}
              </Button>
            </div>
          </div>
          <div
            className="list_of_article_trending_now_text"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "50px",
            }}
          >
            <div className="trending-now-arrow-with-text">
              <Image
                className="trending-now-arrow-image"
                visible={false}
                preview={false}
                src={ARROW_ICON}
              />

              <CustomText
                class_name={"trending_now_article_list"}
                text={"Trending Now"}
                font_family={"lato"}
                size={"22px"}
                align={"left"}
                weight={"700"}
              />
            </div>
            <div className="list_of_article_page_news_article">
              {three_trending_articles?.slice(0, 3)?.map((item, index) => (
                <CustomNewsArticle
                  view_count={item?.views}
                  reading_time={item?.reading_time}
                  date={moment(item?.created_date_time).format("MMM DD,YYYY")}
                  header_color={"#0065cc"}
                  name={item?.staff_profile?.name}
                  article={item?.title}
                  perma_link={item?.perma_link}
                  type={"article"}
                />
              ))}
            </div>
            <div
              style={{ position: "sticky", top: "10px" }}
              className="article_ist_social_media_list"
            >
              <SocialMediaList width={"100%"} />
            </div>
          </div>
        </div>
      </Row>
      <Row style={{ width: "100%" }}>
        <CategoriesList
          set_article_category_id={set_article_category_id}
          set_article_category_name={set_article_category_name}
          article_category_name={article_category_name}
          article_category_id={article_category_id}
          perma_link={
            article_category_id
              ? category_wise_article_list?.[0]?.perma_link
              : ""
          }
        />
      </Row>
      <div className="article_list_recently_added">
        <RecentlyAdded
          height={"115vh"}
          article_list={recent_article_items}
          type={"article"}
        />
      </div>
      <div>
        <JoinUsCard />
      </div>
    </div>
  );
};

export default ArticlesList;
