import React, { useEffect, useRef } from "react";
import CustomBreadCrumbs from "../components/custom/custom_breadcrumbs";
import CustomText from "../components/custom/custom_text";
import { useTranslation } from "react-i18next";
import DonationCard from "../components/widgets/donation_card";
import { Row } from "antd";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import { debounce } from "lodash";
import SupportUsDonationAndEmpowerTheTruth from "../components/support_us/support_us_donation_and_empower_the_truth";

const SupportUs = () => {
  const { t } = useTranslation();
  const top_ref = useRef(null);
  const { is_dark_mode } = useDarkMode();

  const handle_page_position = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debounced_handle_title_click = debounce(handle_page_position, 200);

  useEffect(() => {
    debounced_handle_title_click();
  }, []);
  return (
    <div
      className={`support_us_container  ${is_dark_mode ? "content-dark" : ""}`}
      ref={top_ref}
    >
      <div className="support_us_title">
        <CustomBreadCrumbs pages={"Home/ Support us"} />
        <CustomText
          text={t("support_us")}
          class_name={"support_us_text"}
          size={"25px"}
          font_family={"Kufam"}
          color={"#FFFFFF"}
          align={"left"}
        />
      </div>

      <Row className="donation_card_small_view">
        <DonationCard />
      </Row>
      <SupportUsDonationAndEmpowerTheTruth />
    </div>
  );
};

export default SupportUs;
