import { Col, Row, Skeleton, Space } from "antd";
import React, { memo } from "react";
import { LuEye } from "react-icons/lu";
import { AiFillClockCircle } from "react-icons/ai";
import CustomText from "./custom_text";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { format_view_count } from "../../../helpers/functions";
import { box_shadow } from "../../../helpers/constants";
import { useDynamicSelector } from "../../../services/redux";
import CustomLanguageText from "./custom_language_text";
import { ROUTES } from "../../routes/my_routes";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";
import { ARROW_GIF_ICON } from "../../../helpers/image_constants";

const CustomNewsStoryCardVertical = memo((props) => {
  const { t } = useTranslation();
  const {
    id,
    reading_time,
    view_count,
    date,
    name,
    src,
    title,
    subtitle,
    min_height,
    margin_top,
    perma_link,
    type,
    height,
    max_height,
  } = props;
  const history = useHistory();

  const { is_dark_mode } = useDarkMode();
  const { loading: slots_loading } = useDynamicSelector("get_all_slot");

  const toggleReadMore = () => {
    if (type != "" && type === "article") {
      history.push(`${ROUTES.SINGLE_ARTICLE_PAGE}/${perma_link}.html`);
    } else if ((type != "" && type === "fact-check") || type === "fact_check") {
      history.push(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    }
  };

  return (
    <div
      onClick={toggleReadMore}
      style={{
        minHeight: min_height ?? "36vh",
        maxHeight: max_height ?? "73vh",
        width: "20.5vw",
        boxShadow: box_shadow,
        backgroundColor: is_dark_mode ? "#2D2D2D" : "#FFFF",
        borderRadius: "1px 1px 10px 10px",
        overflow: "hidden",
        height: height ?? "76vh",
        cursor: "pointer",
        flexWrap: "nowrap",
      }}
      className="vertical_card_container"
    >
      {slots_loading ? (
        <Skeleton.Input style={{ width: "50vw", height: "30vh" }} active />
      ) : (
        <Row
          className="landing_page_vertical_card_image_row"
          style={{
            width: "auto",
            height: "auto",
            overflow: "hidden",
          }}
        >
          <Col className="landing_page_vertical_image_card">
            {src && (
              <img
                className="landing_page_vertical_image"
                style={{
                  width: "20.5vw",
                  transform: "scale(1)",
                  objectFit: "cover",
                }}
                src={src}
              />
            )}
          </Col>
        </Row>
      )}

      {slots_loading ? (
        <>
          <Skeleton.Input className="skeleton_heading_card" active />
        </>
      ) : (
        <div className="custom_language_text_vertical">
          <CustomLanguageText
            line_height={"3.2vh"}
            text={title ? title : ""}
            weight={"700"}
            font_family={"Lato_Bold"}
            align={"left"}
            id={id}
            color={is_dark_mode ? "#ffffff" : "#161715"}
          />
        </div>
      )}

      {slots_loading ? (
        <div className="subtitle_skeleton">
          <Skeleton.Input className="subtitle_vertical_card" active />
          <Skeleton.Input className="subtitle_vertical_card" active />
          <Skeleton.Input className="subtitle_vertical_card" active />
          <Skeleton.Input className="subtitle_vertical_card" active />
        </div>
      ) : (
        <div>
          <div
            className="continue_reading_text"
            style={{ color: is_dark_mode ? "#ffffff" : "gray" }}
          >
            {subtitle?.length > 0 ? (
              <>
                <div
                  className={
                    min_height === "89vh"
                      ? "subtitle_text_user_contributed_stories"
                      : "subtitle_text"
                  }
                >
                  {subtitle}
                </div>
              </>
            ) : (
              <>
                {subtitle}
                <span
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                    marginTop: "1vh",
                  }}
                />
              </>
            )}
          </div>
        </div>
      )}
      <Row
        style={{
          width: "20.5vw",
          height: "6vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "2% 5% 0 5%",
        }}
      >
        <Col>
          <div className="name_and_date_vertical_card">
            {slots_loading ? (
              <div className="skelton_name_date">
                <Skeleton.Input size="small" active block={false} />
                <Skeleton.Input size="small" active block={false} />
              </div>
            ) : (
              <>
                <div>
                  <CustomText
                    line_height={"3vh"}
                    text={capitalize(name)}
                    // size={"1.8vh"}
                    color={"#61666D"}
                    weight={"400"}
                    font_family={"Lato"}
                    align={"left"}
                    class_name={"landing-author-text"}
                  />
                </div>

                <CustomText
                  line_height={"3vh"}
                  text={date}
                  color={"#61666D"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                  class_name={"landing-author-text"}
                />
              </>
            )}
          </div>
        </Col>

        <Col
          style={{
            height: "10vh",
          }}
        >
          {slots_loading ? (
            <div className="skelton_view_time">
              <Skeleton.Input size="small" active block={false} />
              <Skeleton.Input size="small" active block={false} />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.9vh",
                }}
              >
                <div
                  style={{
                    color: "#E84756",
                    width: "2.2vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LuEye />
                </div>
                <div>
                  <CustomText
                    line_height={"3vh"}
                    text={format_view_count(view_count)}
                    color={"#E84756"}
                    weight={"400"}
                    font_family={"Lato"}
                    align={"left"}
                    class_name={"landing-author-text"}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5vh",
                }}
              >
                <div
                  style={{
                    color: "gray",
                    width: "2.2vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AiFillClockCircle />
                </div>

                <div>
                  <CustomText
                    text={`${reading_time} ${t("min")}`}
                    color={"#61666D"}
                    weight={"400"}
                    font_family={"Lato"}
                    align={"left"}
                    class_name={"landing-author-text"}
                  />
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>

      {slots_loading ? (
        <></>
      ) : (
        <div
          className="continue_reading_box"
          onClick={() => {
            toggleReadMore();
          }}
        >
          <span
            style={{
              marginTop: margin_top ? margin_top : "",
            }}
            className="continue_reading_vertical_card"
          >
            {t("Continue Reading")}

            <img width={30} height={30} src={ARROW_GIF_ICON} />
          </span>
        </div>
      )}
    </div>
  );
});

export default CustomNewsStoryCardVertical;
