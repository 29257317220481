import { Button, Card, Col, Form, Image, Row } from "antd";
import React, { useEffect, useState } from "react";
import ReportsText from "../reports_card/reports_text";
import ReportButton from "../reports_card/report_button";
import CustomText from "../custom/custom_text";
import { MICK_IMAGE } from "../../../helpers/image_constants";
import { width } from "styled-system";
import CustomReportModel from "../custom/custom_report_model";
import {
  change_tamil_font_style,
  retrieveItem,
} from "../../../helpers/functions";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import {
  dynamic_clear,
  dynamic_request,
  mutation_report,
  useDynamicSelector,
} from "../../../services/redux";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../routes/my_routes";

const ReportsCard = (props) => {
  const {
    height,
    size,
    img_height,
    img_left,
    img_top,
    column_width,
    margin_top,
    button_margin_top,
    button_margin_left,
    width,
  } = props;
  const { t } = useTranslation();
  const [model_open, set_model_open] = useState(false);
  const session_id = retrieveItem("session_id");
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const role = retrieveItem("role");

  const report_click = () => {
    if (session_id && (role === "User" || role === "Previleged User")) {
      set_model_open(true);
    } else {
      history.push(`${ROUTES.ADMIN_LOGIN}`);
    }
  };

  const { id: fact_check_id } = useDynamicSelector(
    "get_fact_check_by_perma_link"
  );
  const { id: article_id } = useDynamicSelector("get_article_by_perma_link");

  const {
    status: create_report_status,
    error: create_report_error,
    loading: create_report_loading,
  } = useDynamicSelector("create_report");

  useEffect(() => {
    if (create_report_status === "Success") {
      toast.success("Report submitted");
      set_model_open(false);
      form.resetFields("");
      dispatch(dynamic_clear("create_report"));
    } else if (create_report_error) {
      toast.error(create_report_error?.message);
    }
    dispatch(dynamic_clear("create_report"));
  }, [create_report_status, create_report_error]);

  const report = (values) => {
    let key = [{ key: "create_report", loading: true }];
    let query = mutation_report;
    let variables = {
      json: fact_check_id
        ? {
            fact_check_id: fact_check_id,
            reason_id: values?.reason,
            description: values?.description,
          }
        : {
            article_id: article_id,
            reason_id: values?.reason,
            description: values?.description,
          },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <div
      className="report-card"
      style={{
        height: height ? height : "auto",
        width: width ? width : "52vw",
        borderRadius: "20px",
        // borderColor: "black",
        border: "1px solid black",
        // marginLeft: "18px",
        display: "flex",
        flexDirection: "row",
        padding: "4% 4% 0 4%",
        gap: "30px",
      }}
    >
      <div
        style={{
          width: "70vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <CustomText
            text={change_tamil_font_style(
              t("feel_something_is_wrong_with_the_article", "3vh", _)
            )}
            size={size ? size : "20px"}
            weight={"700"}
            font_family={"Kufam"}
            align={"left"}
            line_height={"35px"}
          />
        </div>
        <div
          style={{
            // marginTop: img_top ? img_top : "9px",
            marginLeft: img_left ? img_left : "90px",
          }}
        >
          <Image
            style={{
              // width: "80px",
              height: img_height ? img_height : "80px",
            }}
            visible={false}
            preview={false}
            src={MICK_IMAGE}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CustomText
          text={t("we_are_committed_text")}
          size={"14px"}
          // color={"#61666D"}
          weight={"400"}
          font_family={"Lato"}
          align={"left"}
        />
        <div style={{ paddingBottom: "30px" }}>
          <Button
            className="custom-button"
            style={{
              backgroundColor: "#e84756",
              border: "transparent",
              color: "white",
              width: "310px",
              fontSize: "14px",
              height: "30px",
              marginTop: button_margin_top ? button_margin_top : "40px",
              borderRadius: "5px",
              marginLeft: button_margin_left ? button_margin_left : "",
            }}
            onClick={report_click}
          >
            {t("report")}
            {/* <text style={{ fontSize: "14px" }}>{t("report")}</text> */}
          </Button>
        </div>
      </div>

      <CustomReportModel
        model_open={model_open}
        set_model_open={set_model_open}
        report={report}
        form={form}
      />
    </div>
  );
};

export default ReportsCard;
