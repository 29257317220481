import { HStack, VStack } from "native-base";
import React from "react";
import PageTitle from "../../components/system/page_title/page_title";
import FeaturedStories from "../../components/admin/admin_featured_stories/featured_stories";

const AdminFeaturedStories = () => {
  return (
    <div>
      <HStack justifyContent={"flex-start"}>
        <PageTitle title={"Featured Stories"} />
      </HStack>
      <VStack padding={"40px"}>
        <FeaturedStories />
      </VStack>
    </div>
  );
};

export default AdminFeaturedStories;
