import { Button, Form, Modal, Select, Table } from "antd";
import Search from "antd/es/input/Search";
import { HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  delete_reason_query,
  get_all_reasons_query,
} from "../../../../../../services/redux/slices/graphql/graphql_admin_report";
import { useDispatch } from "react-redux";
import {
  dynamic_clear,
  dynamic_request,
  get_all_languages_query,
  useDynamicSelector,
} from "../../../../../../services/redux";
import moment from "moment";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { toast } from "react-toastify";
import ReasonsForm from "./reasons_form";
import { getDefaultLanguageId } from "../../../../../../helpers/constants";

const ReasonsList = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [delete_id, set_delete_id] = useState("");
  const [delete_modal, set_delete_modal] = useState(false);
  const [mutation_modal, set_mutation_modal] = useState(false);
  const [mutation_type, set_mutation_type] = useState("");
  const [edit_reason_id, set_edit_reason_id] = useState("");
  const [selected_items, set_selected_items] = useState({});
  const [search_text, set_search_value] = useState("");

  const [language_id, set_language_id] = useState(
    "839bcc29-853f-4c4c-8700-98fd88558952"
  );

  const { items: reasons_list, loading: reasons_list_loading } =
    useDynamicSelector("report_reasons");

  const { items: languages_list } = useDynamicSelector(
    "get_all_system_languages"
  );

  const {
    status: delete_reason_status,
    loading: delete_loading,
    error: delete_reason_error,
  } = useDynamicSelector("delete_reason");

  useEffect(() => {
    if (language_id) {
      get_all_reasons();
    }
  }, [language_id, search_text]);

  useEffect(() => {
    get_languages();
  }, []);

  useEffect(() => {
    if (delete_reason_status === "Success") {
      toast.success("Reason deleted successfully");
      get_all_reasons();
      handle_delete_modal_close();
      dispatch(dynamic_clear("delete_reason"));
    } else if (delete_reason_error) {
      toast.error(delete_reason_error?.message);
      handle_delete_modal_close();
      dispatch(dynamic_clear("delete_reason"));
    }
  }, [delete_reason_status, delete_reason_error]);

  useEffect(() => {
    if (mutation_type === "add") {
      form.resetFields();
    } else if (mutation_type === "edit") {
      form.setFieldsValue({ ...selected_items });
    }
  }, [mutation_type, form, selected_items]);

  const get_all_reasons = () => {
    let key = [{ key: "report_reasons", loading: true }];
    let query = get_all_reasons_query;
    let variables = {
      language_id: language_id,
      search_string: search_text,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_delete_conformation = () => {
    let key = [{ key: "delete_reason", loading: true }];
    let query = delete_reason_query;
    let variables = {
      id: delete_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_add = () => {
    set_mutation_type("add");
    set_mutation_modal(true);
    set_selected_items({});
  };

  const handle_delete = (record) => {
    set_delete_modal(true);
    set_delete_id(record?.id);
  };

  const handle_edit = (record) => {
    set_mutation_type("edit");
    set_mutation_modal(true);
    set_edit_reason_id(record?.id);
    set_selected_items(record);
  };

  const handle_delete_modal_close = () => {
    set_delete_modal(false);
  };

  const handle_mutation_modal_close = () => {
    set_mutation_modal(false);
  };

  const handle_change = (value) => {
    set_language_id(value);
  };

  const action_items = (record) => {
    return (
      <HStack justifyContent={"space-between"} alignItems={"center"}>
        <Pressable
          onPress={() => {
            handle_delete(record);
          }}
        >
          <AiOutlineDelete size={20} />
        </Pressable>
      </HStack>
    );
  };

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Created date",
    //   key: "date",
    //   render: (record) => {
    //     return moment(record?.created_date_time).format("DD/MM/YYYY");
    //   },
    // },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: "50px",
      render: (record) => action_items(record),
    },
  ];

  const onSearch = (e) => {
    let search_value = e?.target?.value;
    if (search_value?.length >= 0) {
      set_search_value(search_value);
    }
  };

  return (
    <VStack space={"10px"}>
      <HStack justifyContent={"flex-end"} space={"10px"}>
        <Search
          enterButton={true}
          allowClear={true}
          onChange={onSearch}
          onSearch={onSearch}
          placeholder="Search"
          style={{ width: "250px", backgroundColor: "white" }}
          className="admin-list-search"
        />
        <Select
          onChange={handle_change}
          defaultValue={languages_list ? languages_list?.[0]?.name : ""}
        >
          {languages_list?.map((option, index) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
        <Button onClick={handle_add} type="primary">
          Add Reason
        </Button>
      </HStack>
      <Table
        columns={columns}
        loading={reasons_list_loading}
        dataSource={reasons_list}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handle_edit(record),
            className: "table-cursor",
          };
        }}
        pagination={null}
      />

      <Modal
        open={mutation_modal}
        title={mutation_type?.includes("add") ? "Add Reason" : "Update Reason"}
        onCancel={handle_mutation_modal_close}
        footer={null}
        destroyOnClose={true}
      >
        <ReasonsForm
          mutation_type={mutation_type}
          handle_mutation_modal_close={handle_mutation_modal_close}
          get_all_reasons={get_all_reasons}
          edit_reason_id={edit_reason_id}
          selected_items={selected_items}
        />
      </Modal>
      <Modal
        open={delete_modal}
        title={"Confirmation"}
        footer={null}
        onCancel={handle_delete_modal_close}
      >
        <span>Are you sure to delete?</span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button onClick={handle_delete_modal_close} danger>
            Close
          </Button>
          <Button
            onClick={handle_delete_conformation}
            type="primary"
            loading={delete_loading}
          >
            Ok
          </Button>
        </HStack>
      </Modal>
    </VStack>
  );
};

export default ReasonsList;
