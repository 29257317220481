import { gql } from "@apollo/client";

export const query_get_all_fact_check = gql`
query get_all_fact_check(
  $page_number: Int
  $page_limit: Int
  $json: get_all_fact_check_input
) {
  get_fact_check_list: get_all_fact_check(
    page_number: $page_number
    page_limit: $page_limit
    json: $json
  ) {
    items {
      id
      title
    }
    error {
      message
    }
  }
}

`;

export const mutation_update_slots = gql`
  mutation create_slot($json: slot_input) {
    create_slot(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_all_slots = gql`
query get_all_slot($language_id:String!){
  admin_slot:get_all_slot(language_id:$language_id){
    items{
      ref_id
      fact_check_id
      fact_check {
         id
        user_profile_id
        staff_profile_id
        staff_profile {
          name
        }
        title
        subtitle
        content
        reading_time
        likes
        views
        created_date_time
        published_date_time
        thumbnail_img
        perma_link
      }
      article_id
      article{
       id
        user_profile_id
        staff_profile_id
        title
        subtitle
        content
        reading_time
        likes
        views
        created_date_time
        published_date_time
        thumbnail_img
        perma_link
      }
      language_id
    }
  }
}`;

export const query_get_all_articles=gql`
query get_all_article(
  $page_number: Int
  $page_limit: Int
  $json: get_all_article_input
) {
  get_all_articles: get_all_article(
    page_number: $page_number
    page_limit: $page_limit
    json: $json
  ) {
    pagination {
      page_number
      page_limit
      total_count
    }
    items {
      id
      title
    }
    error {
      status_code
      message
    }
  }
}`
