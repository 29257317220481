import React from "react";
import { change_tamil_font_style } from "../../../helpers/functions";
import { useDynamicSelector } from "../../../services/redux";
import CustomButton from "../custom/custom_button";
import { useTranslation } from "react-i18next";

const VideosLoadMoreButton = ({ on_click }) => {
  const { t } = useTranslation();

  const {
    items: you_turn_videos_list,
    pagination: yoi_turn_videos_pagination,
  } = useDynamicSelector("you_turn_videos");

  return (
    <div className="videos_page_load_more_button">
      {you_turn_videos_list?.length ===
      yoi_turn_videos_pagination?.total_count ? (
        ""
      ) : (
        <CustomButton
          class_name={"video_load_button_color"}
          name={change_tamil_font_style(t("see_more"), "2vh", _)}
          on_Click={on_click}
          max_width={"600px"}
        />
      )}
    </div>
  );
};

export default VideosLoadMoreButton;
