import React, { useEffect, useState } from "react";
import {
  CLOCK_ICON,
  EMAIL_LOGO,
  EYE_ICON,
  FACEBOOK_COLOR,
  LINKEDIN_COLOR,
  LINK_ICON,
  REPORT_ARTICLE,
  REPORT_ICON_COLOR,
  SHARE_ICON,
  SHARE_ICON_COLOR,
  TELEGRAM_COLOR,
  THREADS_ICON,
  TWITTER_X_LOGO_BLACK,
  WHATS_APP_LOGO,
} from "../../../helpers/image_constants";
import CustomText from "../custom/custom_text";
import { Image, Popover, Row } from "antd";
import CustomReportModel from "../custom/custom_report_model";
import { useTranslation } from "react-i18next";
import { SITE_URL } from "../../../helpers/constants";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDynamicSelector } from "../../../services/redux";
import LinkedInShareButton from "react-linkedin-share-link";
import { FaShareAlt } from "react-icons/fa";
import { Skeleton } from "antd";
import { Helmet } from "react-helmet";
import { getContent, retrieveItem } from "../../../helpers/functions";
import { ROUTES } from "../../routes/my_routes";
import toast from "react-hot-toast";

const ReportShareCard = (props) => {
  const {
    reading_time,
    view_count,
    thumbnail_img,
    title,
    loading,
    report,
    set_model_open,
    model_open,
  } = props;
  const [share_url, set_share_url] = useState();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const url = window.location.href;
  const history = useHistory();
  const role = retrieveItem("role");

  const session_id = retrieveItem("session_id");
  let current_language = retrieveItem("language");

  useEffect(() => {
    if (current_language?.name === "tamil") {
      const site_url = new URL(SITE_URL);
      const domain = site_url.hostname;
      const link_without_html = pathname?.replace(".html", "");
      const url = `https://share.${domain}${link_without_html}`;
      set_share_url(url);
    } else {
      const site_url = new URL(SITE_URL);
      const domain = site_url.hostname;
      const link_without_html = pathname?.replace(".html", "");
      const url = `https://share.${domain}/en${link_without_html}`;
      set_share_url(url);
    }
  }, [pathname, SITE_URL]);

  const copy_to_clipboard = () => {
    const redirect_url = window.location.href;
    navigator.clipboard?.writeText(redirect_url);
    toast.success("Link Copied");
  };

  const content = getContent(
    title,
    share_url,
    thumbnail_img,
    copy_to_clipboard
  );

  const report_click = () => {
    if (session_id && (role === "User" || role === "Previleged User")) {
      set_model_open(true);
    } else {
      history.push(`${ROUTES.ADMIN_LOGIN}`);
    }
  };

  return (
    <div className="single-fact-check-report-share-div">
      {loading ? (
        <Skeleton.Input className="report_share_card_skeleton_mobile" active />
      ) : (
        <Row
          style={{
            display: "flex",
            marginTop: "20px",
            width: "97%",
            height: "40px",
            borderTop: "1px solid #F49D36",
            borderBottom: "1px solid #F49D36",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              width: "50vw",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "20vw",
              }}
            >
              <Row style={{ gap: "5px" }}>
                <Image
                  style={{
                    width: "25px",
                    marginTop: "-4px",
                  }}
                  visible={false}
                  preview={false}
                  src={EYE_ICON}
                />

                <CustomText
                  text={view_count}
                  size={"13px"}
                  color={"#E84756"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                />
              </Row>
            </div>

            <div
              style={{
                display: "flex",
                width: "22vw",
              }}
            >
              <Row style={{ gap: "5px" }}>
                <Image
                  style={{
                    width: "25px",
                    marginTop: "-4px",
                  }}
                  visible={false}
                  preview={false}
                  src={CLOCK_ICON}
                />

                <CustomText
                  text={`${reading_time} ${t("min")}`}
                  size={"13px"}
                  color={"#61666D"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                />
              </Row>
            </div>
          </div>
          <div
            style={{
              gap: "10px",
              display: "flex",
              alignItems: "center",
              width: "22vw",
            }}
          >
            <Image
              style={{
                width: "25px",
                // marginTop: "-4px",
                marginRight: "20px",
                height: "20px",
              }}
              visible={false}
              preview={false}
              src={REPORT_ICON_COLOR}
              onClick={report_click}
            />

            <Popover
              placement="top"
              content={content}
              trigger="hover"
              className="share_pop_over"
            >
              <Image
                style={{
                  height: "20px",
                  marginBottom: "3px",
                }}
                visible={false}
                preview={false}
                src={SHARE_ICON_COLOR}
              />
            </Popover>

            <CustomReportModel
              model_open={model_open}
              set_model_open={set_model_open}
              report={report}
            />
          </div>
        </Row>
      )}
    </div>
  );
};

export default ReportShareCard;
