import { Button, Modal, Select, Table } from "antd";
import Search from "antd/es/input/Search";
import { HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import {
  delete_award_query,
  get_awards_query,
} from "../../../../services/redux/slices/graphql/graphql_awards";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { navigateWithProps } from "../../../../helpers/navigator";
import { ROUTES } from "../../../routes/my_routes";
import { toast } from "react-toastify";

const AwardsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selected_items, set_selected_items] = useState({});
  const [modal_title, set_modal_title] = useState("");
  const [delete_modal, set_delete_modal] = useState(false);
  const [delete_id, set_delete_id] = useState("");
  const [search_text, set_search_value] = useState("");

  const { items: awards_list, loading: awards_list_loading } =
    useDynamicSelector("get_all_awards_and_recognition");
  const {
    status: delete_status,
    loading: delete_loading,
    error: delete_error,
  } = useDynamicSelector("delete_award");

  useEffect(() => {
    get_awards_and_recognition();
  }, [search_text]);

  useEffect(() => {
    if (delete_status === "Success") {
      toast.success("Award deleted successfully");
      get_awards_and_recognition();
      handle_close();
      dispatch(dynamic_clear("delete_award"));
    } else if (delete_error) {
      toast.error(delete_error?.message);
      handle_close();
      dispatch(dynamic_clear("delete_award"));
    }
  }, [delete_status, delete_error]);

  const action_items = (record) => {
    return (
      <HStack justifyContent={"center"} alignItems={"center"}>
        <Pressable
          onPress={() => {
            handle_delete(record);
          }}
        >
          <AiOutlineDelete size={20} />
        </Pressable>
      </HStack>
    );
  };

  let columns = [
    {
      title: "S.No",
      key: "s_no",
      render: (record, _, index) => index + 1,
    },
    {
      title: "Date",
      key: "created_date_time",
      render: (record) => {
        return moment(record?.created_date_time).format("DD/MM/YYYY");
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Media",
      dataIndex: "subtitle",
      key: "subtitle",
    },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "action",
      width: "50px",
      render: (record) => action_items(record),
    },
  ];

  const get_awards_and_recognition = () => {
    let key = [{ key: "get_all_awards_and_recognition", loading: true }];
    let query = get_awards_query;
    let variables = {
      search_string: search_text,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_delete_conformation = () => {
    let key = [{ key: "delete_award", loading: true }];
    let query = delete_award_query;
    let variables = {
      id: delete_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_add = () => {
    set_modal_title("add");
    navigateWithProps(ROUTES.AWARDS_DETAILS, {
      modal_title: "add",
      record: {},
    });
  };

  const handle_delete = (record) => {
    set_delete_modal(true);
    set_delete_id(record?.id);
  };

  const handle_close = () => {
    set_delete_modal(false);
  };

  const handle_edit = (record) => {
    set_selected_items(record);
    set_modal_title("edit");
    navigateWithProps(`${ROUTES.AWARDS_DETAILS}/${record?.id}`, {
      modal_title: "edit",
      record: record,
    });
  };

  const onSearch = (e) => {
    let search_value = e?.target?.value;
    if (search_value?.length >= 0) {
      set_search_value(search_value);
    }
  };
  return (
    <VStack space={"10px"}>
      <HStack justifyContent={"flex-end"} space={"10px"}>
        <Search
          enterButton={true}
          allowClear={true}
          onSearch={onSearch}
          onChange={onSearch}
          placeholder="Search"
          style={{ width: "250px", backgroundColor: "white" }}
          className="admin-list-search"
        />
        <Button onClick={handle_add} type="primary">
          Add award
        </Button>
      </HStack>
      <Table
        columns={columns}
        loading={awards_list_loading}
        dataSource={awards_list}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handle_edit(record), // click row
            className: "table-cursor",
          };
        }}
        pagination={null}
      />
      <Modal
        open={delete_modal}
        title={"Confirmation"}
        footer={null}
        onCancel={handle_close}
      >
        <span>Are you sure to delete?</span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button onClick={handle_close} danger>
            Close
          </Button>
          <Button
            onClick={handle_delete_conformation}
            type="primary"
            loading={delete_loading}
          >
            Ok
          </Button>
        </HStack>
      </Modal>
    </VStack>
  );
};

export default AwardsList;
