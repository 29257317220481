import { Box, HStack, VStack } from "native-base";
import PageTitle from "../../components/system/page_title/page_title";
import LandingPageForm from "../../components/admin/landing_page/landing_page_form";

const AdminLandingPage = () => {
  return (
    <div>
      <PageTitle title={"Landing Page slots"} />
      <LandingPageForm />
    </div>
  );
};

export default AdminLandingPage;
