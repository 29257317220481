import { Button, Col, Image, Row, Skeleton } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../custom/custom_text";
import { LuEye } from "react-icons/lu";
import { navigate } from "../../../helpers/functions";
import { ROUTES } from "../../routes/my_routes";

const ArticleFirstCard = (props) => {
  const {
    image,
    view_count,
    title,
    margin_top,
    created_date_time,
    id,
    loading,
    type,
    name,
  } = props;
  const { t } = useTranslation();

  const handle_click = () => {
    if (type === "article") {
      navigate(`${ROUTES.SINGLE_ARTICLE_PAGE}/${id}.html`);
    } else if (type === "fact-check") {
      navigate(`${ROUTES.SINGLE_FACT_CHECK}/${id}.html`);
    }
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {loading ? (
        <Skeleton.Input className="article_top_image_skeleton" active />
      ) : (
        <Row>
          <Image
            onClick={handle_click}
            className="article_top_image"
            visible={false}
            preview={false}
            src={image}
            alt="src"
          />

          <Col
            className="article_page_inside_card"
            onClick={handle_click}
            style={{
              marginTop: margin_top || "",
            }}
          >
            <div className="top_article_inside_card_width">
              <CustomText
                class_name={"article_inside_card_title"}
                text={title}
                font_family={"Lato"}
                size={"15px"}
                color={"#FFFFFF"}
                align={"left"}
              />
            </div>

            <div className="article_inside_card">
              <div className="view_count_and_icon">
                <CustomText
                  class_name={"article_page_inside_card_title"}
                  text={view_count}
                  size={"12px"}
                  color={"#E84756"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                />
                <div className="view_icon">
                  <LuEye />
                </div>
              </div>
              <CustomText
                class_name={"article_page_inside_card_title"}
                text={t(`${name} | ${created_date_time}`)}
                font_family={"Lato"}
                size={"12px"}
                weight={400}
                color={"#FFFFFF"}
                align={"left"}
              />
              <div className="article_page_button">
                <Button
                  className="custom-button"
                  style={{
                    backgroundColor: "#140F2D",
                    color: "#FFFFFF",
                    borderRadius: "10px",
                    width: "100px",
                    height: "25px",
                    border: "1px solid white",
                    fontSize: "11px",
                  }}
                  onClick={handle_click}
                >
                  {t("article")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ArticleFirstCard;
