import { Button, Form, Input, Modal, Pagination, Table } from "antd";
import { HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  delete_team_member_query,
  dynamic_clear,
  dynamic_request,
  get_all_team_mates_query,
  useDynamicSelector,
} from "../../../../services/redux";
import {
  mutation_delete_category,
  query_get_all_category,
} from "../../../../services/redux/slices/graphql/graphql_category";
import { useDispatch } from "react-redux";
import TeammatesDetails from "./teammates_details";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { showToast } from "../../../../helpers/functions";
import { countFormat } from "../../../../helpers/constants";

const TeammatesList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [mutation_modal, set_mutation_modal] = useState(false);
  const [mutation_type, set_mutation_type] = useState("");
  const [delete_modal, set_delete_modal] = useState(false);
  const [mutation_form_values, set_mutation_form_values] = useState({});
  const [search_string, set_search_string] = useState(null);
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const { Search } = Input;

  const {
    items: team_mates_list,
    loading: list_loading,
    pagination: team_mates_loading,
  } = useDynamicSelector("get_all_team_member");
  const {
    loading: delete_team_member_loading,
    status: delete_team_member_status,
    error: delete_team_member_error,
  } = useDynamicSelector("delete_team_member");

  useEffect(() => {
    get_all_team_mates();
  }, [page_number, page_limit, search_string]);

  useEffect(() => {
    if (mutation_form_values?.id) {
      form.setFieldsValue(mutation_form_values);
    } else {
      form.resetFields();
    }
  }, [mutation_form_values]);

  useEffect(() => {
    if (delete_team_member_status === "Success") {
      showToast({
        type: "success",
        message: "Team member deleted successfully",
      });
      handle_close();
      get_all_team_mates();
      dispatch(dynamic_clear("delete_team_member"));
    } else if (delete_team_member_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamic_clear("delete_team_member"));
    }
  }, [delete_team_member_status, delete_team_member_error]);

  const action_items = (record) => {
    return (
      <HStack justifyContent={"center"} alignItems={"center"}>
        <Pressable
          onPress={() => {
            handle_delete(record);
          }}
        >
          <AiOutlineDelete size={20} />
        </Pressable>
      </HStack>
    );
  };

  let columns = [
    {
      title: "S.No",
      key: "s_no",
      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("table:role"),
      dataIndex: "role",
      key: "role",
    },
    {
      title: t("table:Description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "action",
      width: "50px",
      render: (record) => action_items(record),
    },
  ];

  const get_all_team_mates = () => {
    let key = [{ key: "get_all_team_member", loading: true }];
    let query = get_all_team_mates_query;
    let variables = {
      json: {
        search_string: search_string,
        page_number: page_number,
        page_limit: page_limit,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_delete_conformation = () => {
    let key = [{ key: "delete_team_member", loading: true }];
    let query = delete_team_member_query;
    let variables = {
      json: {
        id: mutation_form_values?.id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const on_search = (e) => {
    let search_value = e?.target?.value;
    if (search_value?.length >= 0) {
      set_search_string(search_value);
    }
  };

  const handle_add = () => {
    set_mutation_modal(true);
    set_mutation_type("add");
  };
  const handle_close = () => {
    set_mutation_modal(false);
    set_mutation_type(null);
    form.resetFields();
    set_mutation_form_values({});
    set_delete_modal(false);
  };

  const handle_edit = (value) => {
    set_mutation_modal(true);
    set_mutation_type("edit");
    set_mutation_form_values(value);
  };

  const handle_delete = (value) => {
    set_delete_modal(true);
    set_mutation_form_values(value);
  };

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };
  return (
    <VStack space={"10px"}>
      <HStack justifyContent={"flex-end"} space={"10px"}>
        <Search
          enterButton={true}
          onSearch={on_search}
          allowClear={true}
          onChange={on_search}
          placeholder="Search"
          style={{ width: "250px", backgroundColor: "white" }}
          className="admin-list-search"
        />
        <Button className="add-team-mates" onClick={handle_add} type="primary">
          Add team member
        </Button>
      </HStack>
      <Table
        columns={columns}
        loading={list_loading}
        dataSource={team_mates_list}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handle_edit(record),
            className: "table-cursor",
          };
        }}
        pagination={false}
      />
      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={team_mates_loading?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>

      <Modal
        open={mutation_modal}
        title={
          mutation_type?.includes("add")
            ? "Add team member"
            : "Update team member"
        }
        onCancel={handle_close}
        footer={null}
        destroyOnClose={true}
      >
        <TeammatesDetails
          onCancel={handle_close}
          form={form}
          action_type={mutation_type}
          get_all_team_mates={get_all_team_mates}
          form_values={mutation_form_values}
        />
      </Modal>
      <Modal
        open={delete_modal}
        title={"Confirmation"}
        footer={null}
        onCancel={handle_close}
      >
        <span>Are you sure to delete?</span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button onClick={handle_close} danger>
            Close
          </Button>
          <Button
            onClick={handle_delete_conformation}
            type="primary"
            loading={delete_team_member_loading}
            className="delete-team-member"
          >
            Ok
          </Button>
        </HStack>
      </Modal>
    </VStack>
  );
};

export default TeammatesList;
