import React from "react";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";
import CustomText from "../custom/custom_text";
import { Skeleton } from "antd";
import ListOfArticleTopContent from "../list_of_article_page/list_of_article_top_content";
import CustomFactCheckText from "../custom/custom_fact_check_text";
import {
  extractTextFromPTags,
  split_tamil_english_words,
} from "../../../helpers/functions";
import { useDynamicSelector } from "../../../services/redux";
import moment from "moment";

const UserContributionTopHalf = (props) => {
  const { recently_added_for_user_contribution_loading } = props;
  const {
    items: user_contribution_first_half_items,
    loading: user_contribution_first_half_loading,
  } = useDynamicSelector("get_all_user_contribution");
  return (
    <div className="bread_crumbs_and_top_content_div">
      <div className="user-contribution-bread-crumbs">
        <div className="bread_crumbs_div">
          <CustomBreadCrumbs pages={"Home/User Contribution"} />
        </div>

        <div style={{ marginTop: "10px" }}>
          <CustomText
            class_name={"user_contribution_mobile_title"}
            text={"User Contribution"}
            size={"25px"}
            font_family={"Kufam"}
            color={"#FFFFFF"}
            align={"left"}
          />
        </div>
      </div>

      <div className="user_contribution_top_half">
        {recently_added_for_user_contribution_loading ? (
          Array.from({ length: 1 }, (_, index) => (
            <Skeleton.Input
              key={index}
              active
              className="fact-check-top-image-skeleton"
            />
          ))
        ) : (
          <div className="list_top_content_div">
            <ListOfArticleTopContent
              loading={user_contribution_first_half_loading}
              title={
                user_contribution_first_half_items
                  ? user_contribution_first_half_items[0]?.title
                  : ""
              }
              image={
                user_contribution_first_half_items
                  ? user_contribution_first_half_items[0]?.thumbnail_img
                  : ""
              }
              view_count={
                user_contribution_first_half_items
                  ? user_contribution_first_half_items[0]?.views
                  : ""
              }
              created_date_time={
                user_contribution_first_half_items
                  ? moment(
                      user_contribution_first_half_items[0]
                        ?.published_date_time || created_date_time
                    )?.format("MMM DD, YYYY")
                  : ""
              }
              // created_date_time={
              //   user_contribution_first_half_items
              //     ? moment(
              //         user_contribution_first_half_items[0]?.published_date_time
              //           ? parseInt(
              //               user_contribution_first_half_items[0]
              //                 ?.published_date_time,
              //               10
              //             )
              //           : user_contribution_first_half_items[0]
              //               ?.created_date_time
              //       ).format(`MMM DD, YYYY`)
              //     : ""
              // }
              id={
                user_contribution_first_half_items
                  ? user_contribution_first_half_items[0]?.perma_link
                  : ""
              }
              name={
                user_contribution_first_half_items
                  ? user_contribution_first_half_items[0]?.user_profile?.name
                  : ""
              }
              type={
                user_contribution_first_half_items
                  ? user_contribution_first_half_items[0]?.record_type
                  : ""
              }
              height={"66vh !important"}
            />
          </div>
        )}

        <CustomFactCheckText
          fact_check_list_loading={user_contribution_first_half_loading}
          headline={
            user_contribution_first_half_items
              ? split_tamil_english_words(
                  user_contribution_first_half_items?.[0]?.title
                )
              : ""
          }
          content={
            user_contribution_first_half_items
              ? user_contribution_first_half_items[0]?.subtitle
                ? user_contribution_first_half_items[0]?.subtitle
                : extractTextFromPTags(
                    user_contribution_first_half_items?.[0]?.content
                  )
              : ""
          }
          id={
            user_contribution_first_half_items
              ? user_contribution_first_half_items[0]?.perma_link
              : ""
          }
          type={
            user_contribution_first_half_items
              ? user_contribution_first_half_items[0]?.record_type
              : ""
          }
          size={60}
          class_name={"feature-story-sub-title-content-user-contribution"}
          headline_class={"user_contribution_headline"}
        />
      </div>
    </div>
  );
};

export default UserContributionTopHalf;
