import { Form } from "antd";
import React from "react";
import { YOUTURN_NEW_LOGO } from "../../../../helpers/image_constants";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { change_tamil_font_style } from "../../../../helpers/functions";
const OtpForm = (props) => {
  const { t } = useTranslation();
  const {
    otp,
    on_finish,
    create_user_loading,
    sign_up,
    forgot_click,
    form,
    password_visible,
    toggle_password_visibility,
  } = props;
  return (
    <div className="form-container sign-in">
      {!otp && (
        <Form onFinish={on_finish} layout="vertical" form={form}>
          <img
            style={{
              borderRadius: "9px",
              zIndex: 1,
              objectFit: "cover",

              height: "80px",
            }}
            src={YOUTURN_NEW_LOGO}
            alt="Profile"
            className="youturn_logo_mobile_view_only"
          />
          <h1 className="sing_in_text">{t("login")}</h1>
          <Form.Item
            className="margin-bottom"
            name="username"
            rules={[{ required: true, message: "Email is required" }]}
          >
            <input
              type="text"
              placeholder="Username or Email"
              style={{ width: "300px" }}
            />
          </Form.Item>

          <Form.Item
            className="margin-bottom"
            name="password"
            rules={[{ required: true, message: "Password is required" }]}
          >
            <div style={{ position: "relative" }}>
              <input
                type={password_visible ? "text" : "password"}
                placeholder="Password"
                style={{ width: "300px" }}
              />
              {password_visible ? (
                <EyeInvisibleOutlined
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={toggle_password_visibility}
                />
              ) : (
                <EyeOutlined
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={toggle_password_visibility}
                />
              )}
            </div>
          </Form.Item>
          <div
            href=""
            onClick={forgot_click}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            className="forgot_password_text"
          >
            {/* {t("forgot_your_password")} */}
          </div>
          <button type="submit">
            {create_user_loading ? (
              <div class="leap-frog">
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
              </div>
            ) : (
              t("login")
            )}
          </button>

          <div className="view-text">
            <h6 className="sing_in_text">
              {change_tamil_font_style(t("don't_have_an_account?"), "14px", _)}
            </h6>
            <h6
              className="sing_in_text_underline"
              onClick={sign_up}
              style={{ marginLeft: "10px" }}
            >
              {change_tamil_font_style(t("sign_up"), "15px", _)}
            </h6>
          </div>
        </Form>
      )}
    </div>
  );
};

export default OtpForm;
