import { Form, Select } from "antd";
import React from "react";

const SelectLanguage = (props) => {
  const {
    set_language_id,
    languages_list,
    set_is_article_editing,
    set_is_fact_check_editing,
    set_is_youtube_editing,
    set_is_user_contribution_editing,
  } = props;

  const on_change_language = (value) => {
    set_language_id(value);
    set_is_article_editing(false);
    set_is_fact_check_editing(false);
    set_is_youtube_editing(false);
    set_is_user_contribution_editing(false);
  };
  return (
    <Form.Item
      name="language_id"
      label="Language"
      rules={[
        {
          required: true,
          message: "Please select the language",
        },
      ]}
    >
      <Select onChange={on_change_language}>
        {languages_list?.map((option, index) => (
          <Select.Option key={option.id} value={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectLanguage;
