import React from "react";
import { HStack, VStack } from "native-base";
import PageTitle from "../../components/system/page_title/page_title";
import TeammatesList from "../../components/admin/teammates/teammates_list";

const Teammates = () => {
  return (
    <div>
      <HStack justifyContent={"flex-start"}>
        <PageTitle title={"Team members"} />
      </HStack>
      <VStack padding={"40px"}>
        <TeammatesList />
      </VStack>
    </div>
  );
};

export default Teammates;
