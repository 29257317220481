import React from "react";

const CustomAdminSlotCard = () => {
  return (
    <div
      style={{
        minHeight: "36vh",
        maxHeight: "73vh",
        width: "20.5vw",
        backgroundColor: "#FFFF",
        borderRadius: "1px 1px 10px 10px",
        overflow: "hidden",
        height: "76vh",
        cursor: "pointer",
        flexWrap: "nowrap",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "2px dashed #000",
        position: "relative",
      }}
      className="vertical_card_container"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          pointerEvents: "none",
        }}
      >
        <line
          x1="12"
          y1="5"
          x2="12"
          y2="19"
          stroke="black"
          strokeWidth="2"
          strokeDasharray="4 2"
        />
        <line
          x1="5"
          y1="12"
          x2="19"
          y2="12"
          stroke="black"
          strokeWidth="2"
          strokeDasharray="4 2"
        />
      </svg>
    </div>
  );
};

export default CustomAdminSlotCard;
