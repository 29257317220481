import { gql } from "@apollo/client";

export const get_all_fact_check_query = gql`
  query get_all_fact_check(
    $page_number: Int
    $page_limit: Int
    $json: get_all_fact_check_input
  ) {
    get_all_fact_check(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      items {
        id
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
        staff_profile_id
        user_profile_id
        user_claims {
          id
          topic
        }

        title
        subtitle
        log_count
        content
        language_id
        spread_news
        short_story
        source
        status
        is_fact
        thumbnail_img
        reading_time
        likes
        views
        comments_count
        created_date_time
        published_date_time
        perma_link
        category_tags {
          id
          name
          language_id
        }
        party_tags {
          id
          name
          language_id
        }
        anti_party_tags {
          id
          name
          language_id
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;
export const get_all_fact_check_first_half = gql`
  query get_all_fact_check(
    $page_number: Int
    $page_limit: Int
    $json: get_all_fact_check_input
  ) {
    get_all_fact_check_first_half: get_all_fact_check(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      items {
        id
        staff_profile_id
        user_profile_id
        staff_profile {
          name
        }
        title
        subtitle
        content
        spread_news
        short_story
        source
        status
        is_fact
        thumbnail_img
        reading_time
        likes
        views
        comments_count
        created_date_time
        published_date_time
        perma_link
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;
export const get_fact_check_query = gql`
  query get_all_fact_check(
    $page_number: Int
    $page_limit: Int
    $json: get_all_fact_check_input
  ) {
    get_fact_check_query: get_all_fact_check(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      items {
        id
        staff_profile_id
        user_profile_id

        title
        subtitle
        content
        spread_news
        short_story
        source
        status
        is_fact
        thumbnail_img
        reading_time
        likes
        views
        comments_count
        created_date_time
        perma_link
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;

export const get_fact_check_query_category_list = gql`
  query get_fact_check_query_category(
    $page_number: Int
    $page_limit: Int
    $json: get_all_fact_check_input
  ) {
    get_fact_check_query_category: get_all_fact_check(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      items {
        id
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }
        title
        perma_link
        subtitle
        content
        spread_news
        short_story
        source
        status
        is_fact
        thumbnail_img
        reading_time
        likes
        views
        comments_count
        created_date_time
        published_date_time
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;

export const mutation_update_fact_check = gql`
  mutation update_fact_check($json: fact_check_input, $id: String!) {
    update_fact_check(json: $json, id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const auto_save_fact_check_query = gql`
 mutation auto_save_fact_check($json: fact_check_input, $id: String!) {
  auto_save_fact_check(json: $json, id: $id) {
    id
    status
    error {
      status_code
      message
    }
  }
}
`;

export const mutation_create_fact_check = gql`
  mutation create_fact_check($json: fact_check_input) {
    create_fact_check(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_fact_check = gql`
  mutation delete_fact_check($id: String!) {
    delete_fact_check(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const recent_fact_check_query = gql`
  query get_recent_fact_checks(
    $language_id: String!
    $page_number: Int
    $page_limit: Int
  ) {
    get_recent_fact_checks(
      language_id: $language_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        staff_profile_id
        title
        subtitle
        content
        spread_news
        short_story
        source
        status
        is_fact
        reading_time
        likes
        views
        comments_count
        thumbnail_img
        created_date_time
        published_date_time
        perma_link
        staff_profile {
          id
          name
        }
      }
      error {
        message
      }
    }
  }
`;

export const get_recent_fact_check_query = gql`
  query get_recent_fact_checks(
    $language_id: String!
    $page_number: Int
    $page_limit: Int
  ) {
    get_recent_fact_check_items: get_recent_fact_checks(
      language_id: $language_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        staff_profile_id
        title
        subtitle
        content
        spread_news
        short_story
        source
        status
        is_fact
        reading_time
        likes
        views
        comments_count
        thumbnail_img
        created_date_time
        perma_link
      }
      error {
        message
      }
    }
  }
`;

export const fact_check_process_query = gql`
  mutation process_fact_check(
    $id: String!
    $type: String!
    $published_date_time: String
  ) {
    process_fact_check(
      id: $id
      type: $type
      published_date_time: $published_date_time
    ) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_all_approval_fact_check_query = gql`
  query get_all_previleged_user_fact_check(
    $json: get_all_fact_check_input
    $page_number: Int
    $page_limit: Int
  ) {
    get_all_previleged_user_fact_check(
      json: $json
      page_number: $page_number
      page_limit: $page_limit
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        language_id
        title
        subtitle
        user_claims {
          id
          topic
        }
        perma_link
        content
        status
        reading_time
        log_count
        is_fact
        likes
        views
        comments_count
        thumbnail_img
        created_date_time
        published_date_time
        category_tags {
          id
          name
        }
        anti_party_tags {
          id
          name
        }
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
      }
    }
  }
`;

export const get_all_my_fact_check_query = gql`
  query get_all_my_fact_check(
    $page_number: Int
    $page_limit: Int
    $language_id: String
    $status: String
    $title: String
    $from_date: String
    $to_date: String
  ) {
    get_all_my_fact_check(
      page_number: $page_number
      page_limit: $page_limit
      language_id: $language_id
      status: $status
      title: $title
      from_date: $from_date
      to_date: $to_date
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        staff_profile {
          name
        }
        staff_profile_id
        user_profile_id
        user_claims {
          id
          topic
        }
        staff_profile {
          id
          name
        }
        title
        subtitle
        content
        spread_news
        short_story
        source
        status
        is_fact
        thumbnail_img
        language_id
        reading_time
        likes
        views
        comments_count
        created_date_time
        published_date_time
        perma_link
        log_count

        category_tags {
          id
          name
        }
        party_tags {
          id
          name
        }
        anti_party_tags {
          id
          name
        }
      }
      error {
        message
      }
    }
  }
`;

export const get_all_fact_check_history_query = gql`
  query get_all_fact_check_history(
    $fact_check_id: String
    $page_number: Int
    $page_limit: Int
  ) {
    get_all_fact_check_history(
      fact_check_id: $fact_check_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        title
        subtitle
        perma_link
        content
        fact_check_id
        thumbnail_img
        status
        is_fact
        spread_news
        short_story
        source
        created_date_time
        error {
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_all_editors_fact_check_query = gql`
  query get_all_editor_fact_check(
    $json: get_all_fact_check_input
    $page_number: Int
    $page_limit: Int
  ) {
    get_all_editor_fact_check(
      json: $json
      page_number: $page_number
      page_limit: $page_limit
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        language_id
        log_count
        title
        subtitle
        content
        spread_news
        short_story
        user_claims {
          id
          topic
        }
        source
        status
        reading_time
        likes
        views
        is_fact
        comments_count
        thumbnail_img
        created_date_time
        published_date_time
        perma_link
        user_profile {
          id
          name
        }
        staff_profile {
          id
          name
        }
        party_tags {
          id
          name
        }
        category_tags {
          id
          name
        }
        anti_party_tags {
          id
          name
        }
      }
    }
  }
`;

export const save_fact_check_query = gql`
  mutation save_fact_check($json: fact_check_input) {
    save_fact_check(json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_all_published_fact_check_query = gql`
  query get_all_published_fact_check(
    $page_number: Int
    $page_limit: Int
    $json: get_all_fact_check_input
  ) {
    get_all_published_fact_check(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      items {
        id
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
        staff_profile_id
        user_profile_id
        user_claims {
          id
          topic
        }
        title
        subtitle
        log_count
        content
        language_id
        spread_news
        short_story
        source
        status
        is_fact
        thumbnail_img
        reading_time
        likes
        views
        comments_count
        created_date_time
        published_date_time
        perma_link
        category_tags {
          id
          name
          language_id
        }
        party_tags {
          id
          name
          language_id
        }
        anti_party_tags {
          id
          name
          language_id
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;
