import { Row } from "antd";
import React from "react";
import CustomText from "./custom_text";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../routes/my_routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDynamicSelector } from "../../../services/redux";
import { isMobile, isTablet } from "react-device-detect";

const CustomNewsArticle = (props) => {
  const {
    article,
    name,
    date,
    key,
    perma_link,
    type,
    thumbnail_img,
    date_type,
    date_type_new,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const on_click = () => {
    if (type === "article") {
      history.push(`${ROUTES.SINGLE_ARTICLE_PAGE}/${perma_link}.html`);
    } else if (type === "fact-check" || type === "fact_check") {
      history.push(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    }
  };

  return (
    <Row
      key={key}
      style={{
        background: `url('${thumbnail_img}')`,
      }}
      className="trending_stories_main_card"
      onClick={on_click}
    >
      <div className="custom_new_article_card_inside_div">
        <div className="trending_stories_text">
          <CustomText
            class_name={"related_fact_check_card_last_text"}
            text={
              article?.split(" ").length > 4
                ? article
                    ?.split(" ")
                    ?.slice(0, isMobile ? 5 : isTablet ? 8 : 6)
                    ?.join(" ") + "..."
                : article
            }
            size={"2vh"}
            font_family={"Lato"}
            color={"white"}
            weight={"600"}
            align={"left"}
            line_height={"24px"}
          />
        </div>
        <CustomText
          text={`${name} | ${
            date_type === "user_contribution" ? date_type_new : date
          }`}
          size={"12px"}
          font_family={"Lato"}
          color={"white"}
          weight={"400"}
          align={"left"}
          class_name={"related_fact_check_name_view"}
        />
      </div>
      {/* <div className="inside_news_card"> */}
      {/* <div
          style={{
            width: news_article_row_width ? news_article_row_width : "100%",
            height: "1.6vh",
            backgroundColor: header_color,
            borderRadius: "9px 9px 0 0",
          }}
        ></div> */}
      {/* <div
          style={{
            width: news_article_content_width
              ? news_article_content_width
              : "90%",
            height: "20vh",
            padding: "1vh 2.9vh",
          }}
        >
          <CustomLanguageText
            class_name="article-text-heading-content"
            line_height={"3.5vh"}
            text={
              article?.split(" ").length > 8
                ? article?.split(" ")?.slice(0, 10)?.join(" ") + "..."
                : article
            }
            align={"left"}
            weight={"700"}
            marginTop={"-10px"}
            marginBottom={"5px"}
            font_family={"Lato_Bold"}
            size={isEnglish(article) ? font.english : font.tamil}
          />
        </div>
        <div
          style={{
            width: news_article_row_width ? news_article_row_width : "100%",
            height: "10vh",
            display: "flex",
            flexDirection: "row",
            padding: "10px",
          }}
        >
          <div
            style={{
              width: news_article_content_width
                ? news_article_content_width
                : "180px",
              height: "50px",
              marginLeft: "1vw",
              justifyContent: "space-between",
              width: "80%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                width: "70%",
              }}
            >
              <CustomText
                line_height={"4vh"}
                text={name}
                size={"2vh"}
                weight={"400"}
                align={"left"}
                font_family={"Lato"}
                color={"#61666D"}
              />

              <CustomText
                text={date}
                size={"2vh"}
                weight={"400"}
                align={"left"}
                font_family={"Lato"}
                color={"#61666D"}
              />
            </div>
          </div>

          <Col
            className="views-and-reading-time"
            style={{
              height: "10vh",
              display: "flex",
              flexDirection: "column",
              gap: "3px",
            }}
          >
            <div
              style={{
                gap: "3px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginTop: "2px",
                }}
              >
                <LuEye color="#E84756" />
              </div>
              <div style={{ marginTop: "1vh" }}>
                <CustomText
                  line_height={"3vh"}
                  text={format_view_count(view_count)}
                  size={"2vh"}
                  color={"#E84756"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                />
              </div>
            </div>
            <div
              style={{
                gap: "3px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "gray",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AiFillClockCircle />
              </div>

              <div>
                <CustomText
                  text={`${reading_time} ${t("min")}`}
                  size={"2vh"}
                  color={"#61666D"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                />
              </div>
            </div>
          </Col>
        </div> */}
      {/* </div> */}
    </Row>
  );
};

export default CustomNewsArticle;
