import React from "react";
import { IoCall } from "react-icons/io5";
import CustomText from "../../custom/custom_text";
import { useDynamicSelector } from "../../../../services/redux";
import MobileInput from "./mobile_input";
import { useDarkMode } from "../../contexts/dark_mode_provider";

const UserMobileNo = (props) => {
  const { open_form } = props;
  const { is_dark_mode } = useDarkMode();

  const item = useDynamicSelector("get_user");

  return (
    <div className="profile_icon_and_text">
      <IoCall style={{ color: is_dark_mode ? "white" : "" }} />

      {open_form != true ? (
        <CustomText
          text={
            (item?.user_profile?.mobile ?? item?.staff_profile?.mobile) || ""
          }
          font_family={"Lato"}
          class_name={"user-profile-personal-details"}
        />
      ) : (
        <MobileInput />
      )}
    </div>
  );
};

export default UserMobileNo;
