import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { change_tamil_font_style } from "../../../helpers/functions";
import { useDynamicSelector } from "../../../services/redux";

const DonationText = (props) => {
  const { t } = useTranslation();
  const { padding, font_size } = props;
  const { language_id } = useDynamicSelector("get_article");
  const tamil_top_content =
    "பல்வேறு சமூக ஊடகங்களில் பரப்பப்படும் செய்திகளின் பின்னணியில் உள்ள உண்மையை, பார்வையாளர்களுக்கு வழங்குவதே எங்கள் குறிக்கோள். எங்களை ஆதரிக்க நீங்கள் விரும்பும் தொகையை பங்களியுங்கள்... ";
  const english_top_content =
    "Do you think it is important for You Turn to verify the accuracy of the content it posts? It is our goal to present you information that is both true and unbiased in terms of political viewpoint. It would mean a lot to us if you decided to back us. Give, and help it become the people's digital media!";

  return (
    <p
      className="donation-content-small-card"
      style={{
        fontSize: font_size ? font_size : "2vh",
        padding: padding ? padding : "20px 35px 0 35px",
        // marginLeft: "3vw",
      }}
    >
      {language_id === "839bcc29-853f-4c4c-8700-98fd88558952"
        ? tamil_top_content
        : english_top_content}
    </p>
  );
};

export default DonationText;
