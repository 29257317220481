import React, { useEffect } from "react";
import { get_all_anti_party_tags_query } from "../../../../../services/redux/slices/graphql/graphql_anti_party_tags";
import {
  dynamic_request,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Select } from "antd";
import { retrieveItem } from "../../../../../helpers/functions";

const AntiPartyTag = (props) => {
  const { language_id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = retrieveItem("role");

  const { items: anti_party_list } = useDynamicSelector(
    "get_all_anti_party_tag"
  );

  useEffect(() => {
    get_anti_party_tag_list();
  }, [language_id]);

  const get_anti_party_tag_list = () => {
    let key = [{ key: "get_all_anti_party_tag", loading: true }];
    let query = get_all_anti_party_tags_query;
    let variables = {
      language_id: language_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <Form.Item name="anti_party_tags" label={"Anti party tags"}>
      <Select
        placeholder="Anti PartyTag"
        allowClear
        showSearch
        mode="multiple"
        // disabled={role === "Admin"}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {anti_party_list?.map((option, index) => (
          <Select.Option key={option.name} value={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default AntiPartyTag;
