import CryptoJS from "crypto-js";
import axios, { Axios } from "axios";
import i18n from "i18next";
import { t } from "i18next";
import { Tag, message as antdMessage } from "antd";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import { QueryRequest } from "@services/apollo/api_service";
import { historyRef } from "@views/routes/Router";
import { fileQuery } from "./constants";
import moment from "moment";
import { Text } from "native-base";
import { alignItems, fontWeight, textAlign } from "styled-system";
import {
  EMAIL_LOGO,
  FACEBOOK_COLOR,
  LINKEDIN_COLOR,
  LINK_ICON,
  TELEGRAM_COLOR,
  TWITTER_X_LOGO_BLACK,
  WHATS_APP_LOGO,
} from "./image_constants";

export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const encryptData = (data, salt) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

export const decryptData = (cipher_text, salt) => {
  const bytes = CryptoJS.AES.decrypt(cipher_text, salt);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};

export function storeItem(key, data) {
  const salt = process.env.REACT_APP_SALT || "";
  let value = JSON.stringify(data);
  let encryptedKey = window.btoa(key);
  let encryptedValue = encryptData(value, salt);
  window.localStorage.setItem(encryptedKey, encryptedValue);
  window.dispatchEvent(new Event(`${key}`));
}

export function retrieveItem(key) {
  const salt = process.env.REACT_APP_SALT || "";
  try {
    let encryptedKey = window.btoa(key);
    let value = window.localStorage.getItem(encryptedKey);
    const decryptedValue = decryptData(value, salt);
    return JSON.parse(decryptedValue);
  } catch (e) {
    return null;
  }
}

export function clearLocalStorage() {
  try {
    window.localStorage.clear();
  } catch (e) {
    return;
  }
}

export async function fileUpload(file, storageName) {
  let url = await getURL(storageName, file?.name);

  let final_url = url?.split("?")[0];
  let success = true;
  await axios
    .put(url, file, {
      headers: { "Content-Type": file?.type },
    })
    .then(async (res) => {
      if (res.status.toString() === "200") {
        // return final_url;
      } else {
        success = false;
      }
    });
  if (success) {
    return final_url;
  }

  async function getURL(type, fileName) {
    let { data } = await QueryRequest(fileQuery, { type, fileName });
    return data.getUploadUrl.url;
  }
}

export function useIsRTL() {
  const localeRTLList = ["ar", "he"];
  let locale = i18n.language || "en";
  if (locale && localeRTLList.includes(locale)) {
    return { isRTL: true, alignLeft: "right", alignRight: "left" };
  }
  return { isRTL: false, alignLeft: "left", alignRight: "right" };
}

export const navigate = (path) => {
  historyRef.current.push(path);
};

export const navigateWithProps = (path, props) => {
  historyRef.current.push({
    pathname: path,
    props_state: props,
  });
};

export const navigateBack = () => {
  historyRef.current.goBack();
};

export function showNotification({ type, message, duration = 3 }) {
  switch (type) {
    case "success":
      antdMessage.success(message, duration);
      break;
    case "warn":
      antdMessage.warning(message, duration);
      break;
    case "error":
      antdMessage.error(message, duration);
      break;
    default:
      antdMessage.info(message, duration);
  }
}

export function showToast({ type, message, duration = 5000, id = uuidv4() }) {
  if (id && toast.isActive(id)) {
    return;
  }

  switch (type) {
    case "info":
      toast.info(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    case "success":
      toast.success(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    case "warn":
      toast.warn(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    case "error":
      toast.error(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    default:
      toast(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
  }
}

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function getProfileUrl(url, member_gender) {
  if (url && member_gender) {
    return url;
  } else if (member_gender === "f" && !url) {
    return "https://cdn.standardmatrimony.com/female-avatar.png";
  } else if (member_gender === "m" && !url) {
    return "https://cdn.standardmatrimony.com/male-avatar.png";
  }
}
export function getAmountFormat(amount) {
  if (amount) {
    return amount
      .toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })
      .replace("₹", "");
  } else {
    return `0.00`;
  }
}

export const formatDate = (date) => {
  if (!date) {
    return "-";
  }
  return moment(date).format("DD/MM/YYYY");
};

export const formatDateTime = (date) => {
  if (!date) {
    return "-";
  }
  return moment(date).format("DD/MM/YYYY hh:mm a");
};

export const hasExceededDate = (checkDate) => {
  const currentDate = moment();
  const exceeded = currentDate.isAfter(moment(checkDate));
  return exceeded;
};

export const split_tamil_english_words = (text, from, to) => {
  const mixedWordsArray = text?.match(/[\u0B80-\u0BFF\w.]+|\d+(?:\.\d+)?/g);
  let dots = mixedWordsArray?.length > 10 ? "..." : "";
  let result = mixedWordsArray
    ? mixedWordsArray.slice(from ?? 0, to ?? 10).join(" ")
    : [];

  return `${result}${dots}`;
};

export const split_tamil_english_words_for_breadcrumbs = (text, from, to) => {
  const mixedWordsArray = text?.match(/[\u0B80-\u0BFF\w]+/g);
  let dots = mixedWordsArray?.length > 10 ? "..." : "...";
  let result = mixedWordsArray
    ? mixedWordsArray.slice(from ?? 0, to ?? 10).join(" ")
    : [];

  return `${result}${dots}`;
};

export const split_tamil_english_letters = (text, from, to) => {
  const mixedWordsArray = text?.match(/[\u0B80-\u0BFF\w]+/g);
  let dots = mixedWordsArray?.length > 10 ? "..." : "";
  let result = mixedWordsArray
    ? mixedWordsArray
        .slice(from ?? 0, to ?? 10)
        .map((word) => word.substring(0, 10))
        .join(" ")
    : [];

  return `${result}${dots}`;
};

export function isEnglish(text) {
  const englishLettersRegex = /^[A-Za-z\s]*$/;
  return englishLettersRegex.test(text);
}

export function getContent(title, share_url, thumbnail_img, copy_to_clipboard) {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <a
        href={`whatsapp://send?text=${encodeURIComponent(
          `${title}\n${share_url}`
        )}`}
        data-action="share/whatsapp/share"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={WHATS_APP_LOGO} alt="WhatsApp" style={{ height: "25px" }} />
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          share_url
        )}&quote=${encodeURIComponent(title)}`}
        target="_blank"
      >
        <img src={FACEBOOK_COLOR} alt="Facebook" style={{ height: "25px" }} />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          title
        )}&url=${encodeURIComponent(share_url)}&media=${encodeURIComponent(
          thumbnail_img
        )}`}
        target="_blank"
      >
        <img
          src={TWITTER_X_LOGO_BLACK}
          alt="Twitter"
          style={{ height: "20px" }}
        />
      </a>
      <a
        href={`mailto:?subject=${encodeURIComponent(
          title
        )}&body=${encodeURIComponent(share_url)}`}
        target="_blank"
      >
        <img src={EMAIL_LOGO} alt="Email" style={{ height: "25px" }} />
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          share_url
        )}`}
        target="_blank"
      >
        <img src={LINKEDIN_COLOR} alt="LinkedIn" style={{ height: "20px" }} />
      </a>
      <a
        href={`https://t.me/share/url?url=${encodeURIComponent(
          share_url
        )}&text=${encodeURIComponent(title)}`}
        target="_blank"
      >
        <img src={TELEGRAM_COLOR} alt="Telegram" style={{ height: "25px" }} />
      </a>

      <img
        src={LINK_ICON}
        alt="Copy link"
        style={{ height: "25px", cursor: "pointer" }}
        onClick={copy_to_clipboard}
      />
    </div>
  );
}

export const youtube_info_scrapper = async (url) => {
  const API_END_POINT = process.env.REACT_APP_GOOGLE_YOUTUBE_DATA_V3_ENDPOINT;
  const API_KEY = process.env.REACT_APP_YOUTUBE_DATA_API;
  const video_id = extract_video_id(url);
  const api_url = `${API_END_POINT}id=${video_id}&key=${API_KEY}`;

  try {
    const response = await axios.get(api_url);

    const videoInfo = {
      title: response?.data.items[0].snippet.title,
      thumbnail: response?.data.items[0].snippet.thumbnails.default.url,
      views: format_views_count(response?.data.items[0].statistics.viewCount),
      days: calculate_days_since(response?.data.items[0].snippet.publishedAt),
    };

    return videoInfo;
  } catch (error) {
    console.error("Error fetching video information:", error);
    return null;
  }
};

function extract_video_id(url) {
  const match_url_id = url.match(/[?&]v=([^&]+)/);
  return match_url_id ? match_url_id[1] : null;
}

export const calculate_days_since = (dateString) => {
  const givenDate = new Date(dateString);
  const timeDiff = new Date() - givenDate;
  const daysSince = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  return daysSince;
};

// export function format_view_count(viewCount) {
//   if (viewCount || viewCount === 0) {
//     const formattedNumber = viewCount.toLocaleString("en-US", {
//       useGrouping: true,
//       minimumFractionDigits: 0,
//       maximumFractionDigits: 0,
//     });

//     return formattedNumber.replace(/,/g, ", ");
//   } else {
//     return "0";
//   }
// }

export function format_view_count(viewCount) {
  if (viewCount || viewCount === 0) {
    let formattedNumber;

    if (viewCount >= 1000) {
      const countInK = Math.floor(viewCount / 1000);
      formattedNumber = countInK + "k";
    } else {
      formattedNumber = viewCount.toLocaleString("en-US", {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber.replace(/,/g, ", ");
  } else {
    return "0";
  }
}

export const format_views_count = (views_count) => {
  if (views_count >= 1000000) {
    return (views_count / 1000000).toFixed(1).replace(".0", "") + "M";
  } else if (views_count >= 1000) {
    return (views_count / 1000).toFixed(1).replace(".0", "") + "k";
  } else {
    return views_count?.toString();
  }
};

export const get_amount_Color = (amount) => {
  if (amount >= 0 && amount <= 100) {
    return "#bf1313"; // Red
  } else if (amount >= 101 && amount <= 200) {
    return "#2178bb"; // blue
  } else if (amount >= 201 && amount <= 300) {
    return "#468c44"; //green
  } else if (amount >= 301 && amount <= 400) {
    return "#468c44"; //green
  } else {
    return "#807a31"; // light green
  }
};

let store_order_id = "";
export const initiate_request_razorpay_payment = async (
  order_id,
  set_payment_response
) => {
  let contact = retrieveItem("mobile");
  var options = {
    key: process.env.REACT_APP_RAZORPAY_PAYMENY_KEY || "",
    currency: "INR",
    description: "Test Transaction",
    order_id: order_id,
    handler: function (response) {
      set_payment_response(response);
    },
    prefill: {
      contact: contact,
    },
    theme: {
      color: "#F37254",
    },
  };
  if (order_id !== store_order_id) {
    store_order_id = order_id;
    let rzp1 = new Razorpay(options);
    await rzp1.open();
    // Store order_id only if it's available
  } else {
    console.log(
      "something went wrong: order_id not provided or already processed"
    );
  }
};

export const get_status = (status) => {
  if (status === "pending") {
    return "Pending";
  } else if (status === "published") {
    return "Published";
  } else if (status === "approved_by_editor") {
    return "Approved by Editor";
  } else if (status === "rejected_by_editor") {
    return "Rejected by Editor";
  } else if (status === "draft") {
    return "Draft";
  } else if (status === "approved_by_admin") {
    return "Approved by admin";
  } else if (status === "rejected_by_admin") {
    return "Rejected by admin";
  } else if (status === "unpublished") {
    return "Unpublished";
  } else {
    return null;
  }
};

export const change_tamil_font_style = (
  text,
  tamil_font_size,
  tamil_line_height
) => {
  if (!text || !/[\u0B80-\u0BFF]/.test(text)) {
    return text;
  }

  return (
    <span
      style={{
        fontSize: tamil_font_size,
        lineHeight: tamil_line_height,
      }}
    >
      {text}
    </span>
  );
};

export const change_tamil_font_style_for_mobile = (
  text,
  tamil_font_size,
  tamil_line_height
) => {
  if (!text || !/[\u0B80-\u0BFF]/.test(text)) {
    return text;
  }

  return (
    <span
      style={{
        fontSize: tamil_font_size,
        lineHeight: tamil_line_height,
      }}
    >
      {text}
    </span>
  );
};

export const extractTextFromPTags = (paragraph) => {
  if (!paragraph) {
    return "";
  }

  const regex =
    /<[^>]+>|(?:https?:\/\/)?(?:www\.)?twitter\.com\/\S+|pic.twitter.com\/\S+|video.twimg.com\/\S+|\[[^\]]*\]/g;

  return paragraph.replace(regex, "");
};

export function get_share_content(
  title,
  share_url,
  thumbnail_img,
  copy_to_clipboard
) {
  return (
    <div className="share_container">
      <a
        href={`whatsapp://send?text=${encodeURIComponent(
          `${title}\n${share_url}`
        )}`}
        data-action="share/whatsapp/share"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={WHATS_APP_LOGO}
          alt="WhatsApp"
          className="article_share_icon"
        />
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          share_url
        )}&quote=${encodeURIComponent(title)}`}
        target="_blank"
      >
        <img
          src={FACEBOOK_COLOR}
          alt="Facebook"
          className="article_share_icon"
        />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          title
        )}&url=${encodeURIComponent(share_url)}&media=${encodeURIComponent(
          thumbnail_img
        )}`}
        target="_blank"
      >
        <img
          src={TWITTER_X_LOGO_BLACK}
          alt="Twitter"
          className="article_share_icons"
        />
      </a>
      <a
        href={`mailto:?subject=${encodeURIComponent(
          title
        )}&body=${encodeURIComponent(share_url)}`}
        target="_blank"
      >
        <img src={EMAIL_LOGO} alt="Email" className="article_share_icon" />
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          share_url
        )}`}
        target="_blank"
      >
        <img
          src={LINKEDIN_COLOR}
          alt="LinkedIn"
          className="article_share_icons"
        />
      </a>
      <a
        href={`https://t.me/share/url?url=${encodeURIComponent(
          share_url
        )}&text=${encodeURIComponent(title)}`}
        target="_blank"
      >
        <img
          src={TELEGRAM_COLOR}
          alt="Telegram"
          className="article_share_icon"
        />
      </a>

      <img
        src={LINK_ICON}
        alt="Copy link"
        className="copy_to_clipboard_icon"
        onClick={copy_to_clipboard}
      />
    </div>
  );
}

export const get_status_tag = (status) => {
  let color, text, backgroundColor, width, fontSize, borderRadius, fontWeight;
  switch (status) {
    case "published":
      color = "white";
      text = "Published";
      backgroundColor = "#6FBF00";
      width = "120px";
      fontSize = "16px";
      borderRadius = "4px";
      fontWeight = "bold";

      break;
    case "unpublished":
      color = "white";
      text = "Unpublished";
      backgroundColor = "#00BF93";
      width = "120px";
      fontSize = "16px";
      borderRadius = "4px";
      fontWeight = "bold";

      break;
    case "pending":
      color = "white";
      text = "To-Do";
      backgroundColor = "#43BF00";
      width = "120px";
      fontSize = "16px";
      borderRadius = "4px";
      fontWeight = "bold";
      break;
    case "in_progress":
      color = "white";
      text = "In progress";
      backgroundColor = "#005ABF";
      width = "120px";
      fontSize = "16px";
      borderRadius = "4px";
      fontWeight = "bold";

      break;
    case "under_review":
      color = "white";
      text = "Under Review";
      backgroundColor = "#BF5C00";
      width = "120px";
      fontSize = "16px";
      borderRadius = "4px";
      fontWeight = "bold";

      break;
    case "rejected":
      color = "white";
      text = "Rejected";
      backgroundColor = "#BF0000";
      width = "120px";
      fontSize = "16px";
      fontWeight = "bold";
      break;
    default:
      color = "black";
      text = "Unknown";
      backgroundColor = "#f5f5f5";
      width = "120px";
      fontSize = "16px";
  }

  return (
    <Tag
      style={{
        backgroundColor,
        color,
        width,
        display: "flex",
        justifyContent: "center",
        borderRadius,
        fontWeight,
      }}
    >
      {text}
    </Tag>
  );
};

export const date_format = (date) => {
  const language = retrieveItem("language");
  const monthNames = [
    "ஜனவரி",
    "பிப்ரவரி",
    "மார்ச்",
    "ஏப்ரல்",
    "மே",
    "ஜூன்",
    "ஜூலை",
    "ஆகஸ்ட்",
    "செப்டம்பர்",
    "அக்டோபர்",
    "நவம்பர்",
    "டிசம்பர்",
  ];
  const dayNames = [
    "ஞாயிறு",
    "திங்கள்",
    "செவ்வாய்",
    "புதன்",
    "வியாழன்",
    "வெள்ளி",
    "சனி",
  ];
  if (typeof date === "number") {
    return moment(date).format("MMM DD, YYYY");
  }

  // Check if the input is a string (ISO 8601 date)
  if (typeof date === "string") {
    if (date.includes("Z")) {
      const get_date = new Date(date);
      const day = get_date.getDate();
      const month = monthNames[get_date.getMonth()];
      const year = get_date.getFullYear();
      const dayName = dayNames[get_date.getDay()];
      if (language?.name === "tamil") {
        return `${day} ${month}, ${year}`;
      } else {
        return moment(date).format("MMM DD, YYYY");
      }
    } else {
      const get_date = new Date(Number(date));
      const day = get_date.getDate();
      const month = monthNames[get_date.getMonth()];
      const year = get_date.getFullYear();
      const dayName = dayNames[get_date.getDay()];
      if (language?.name === "tamil") {
        return `${day} ${month}, ${year}`;
      } else {
        return moment(new Date(Number(date))).format("MMM DD, YYYY");
      }
    }
  }
};

export const ensure_https = (image_url) => {
  if (!image_url) return null;
  return image_url.replace(/^http:/, "https:");
};

export const size_wise_split_content = (
  htmlContent,
  size,
  tamil = 5,
  english = 4
) => {
  // const { size } = useDynamicSelector("screen_width");
  const get_language = retrieveItem("language");
  const charSize = get_language?.name === "tamil" ? tamil : english;
  const maxChars = Math.floor(size / charSize);

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  // Get text content without HTML tags
  let textContent = doc.body.textContent || "";
  const urlRegex = /https?:\/\/[^\s]+/g;
  textContent = textContent.replace(urlRegex, "").trim();

  // Split the text into words
  const mixedWordsArray =
    textContent.match(/[\u0B80-\u0BFF\w.]+|\d+(?:\.\d+)?/g) || [];

  let truncatedText = "";
  let charCount = 0;

  for (let word of mixedWordsArray) {
    charCount += word.length + 1; // +1 for the space
    if (charCount > maxChars) break;
    truncatedText += word + " ";
  }

  truncatedText = truncatedText.trim();
  if (charCount > maxChars) truncatedText += "...";
  return truncatedText;
};
