import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../../helpers/functions";
import {
  dynamic_request,
  query_get_all_article,
  useDynamicSelector,
} from "../../../../services/redux";
import { Divider, Empty, Row, Skeleton } from "antd";
import CustomText from "../../custom/custom_text";
import CategoriesListSelector from "../../fact_check/categories_list_selector";
import FactCheckCategories from "../../fact_check/fact_check_categories";
import CustomFactCheckCategories from "../../custom/custom_fact_check_categories";
import CustomButton from "../../custom/custom_button";
import MediaShare from "../../widgets/media_share";
import moment from "moment";

const ArticleCategoryWiseList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let current_language = retrieveItem("language");
  const [category_id, set_category_id] = useState("");
  const [category_name, set_category_name] = useState(t("all"));
  const [page_limit, set_page_limit] = useState(6);
  const [article_list_page_limit, set_article_list_page_limit] = useState(7);
  const [all_items, set_all_items] = useState([]);
  const [default_category_name, set_default_category_name] = useState("");

  const {
    items: article_list,
    loading: article_loading,
    pagination: article_pagination,
  } = useDynamicSelector("get_all_article");

  const filtered_items = article_list?.filter((item, index) => index !== 0);

  useEffect(() => {
    if (category_id !== "More") {
      get_category_wise_article_list(category_id);
    }
  }, [category_id, page_limit]);

  useEffect(() => {
    if (
      article_list &&
      article_list?.length > 0 &&
      (!default_category_name ||
        default_category_name === "All" ||
        default_category_name === "அனைத்தும்" ||
        default_category_name === "மேலும்" ||
        default_category_name === "More")
    ) {
      set_all_items([...filtered_items]);
    } else if (article_list && article_list?.length > 0) {
      set_all_items([...article_list]);
    } else if (!article_loading && !article_list?.length) {
      set_all_items([]);
    }
  }, [article_list, default_category_name]);

  const get_category_wise_article_list = (category_id) => {
    let key = [{ key: "get_all_article", loading: true }];
    let query = query_get_all_article;
    let variables = {
      page_number: 1,
      page_limit:
        !default_category_name ||
        default_category_name === "All" ||
        default_category_name === "அனைத்தும்" ||
        default_category_name === "மேலும்" ||
        default_category_name === "More"
          ? article_list_page_limit
          : page_limit,
      json: {
        category_tag:
          category_id === "All" || category_id === "More" ? null : category_id,
        language_id: current_language?.id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_see_all_articles = () => {
    set_page_limit(page_limit + 6);
    set_article_list_page_limit(article_list_page_limit + 6);
  };

  return (
    <div>
      <Row className="article_browse_categories">
        <div className="article_browse_categories_title_card">
          <div className="article_browse_categories_text">
            <CustomText
              text={t("browse_categories_title")}
              font_family={"Kufam"}
              size={"30px"}
              weight={"700"}
              class_name={"browse_categories"}
              align={"left"}
            />
          </div>
          <div className="categories_selector">
            <CategoriesListSelector set_category_id={set_category_id} />
          </div>
          <div className="category_wise_article">
            <FactCheckCategories
              set_category_id={set_category_id}
              set_category_name={set_category_name}
              set_page_limit={set_page_limit}
              set_default_category_name={set_default_category_name}
              default_page_limit={set_article_list_page_limit}
            />
          </div>
        </div>
        <div className="top_six_article_card">
          <div className="top_six_article">
            {article_loading &&
              Array.from({ length: 6 }, (_, index) => (
                <Skeleton.Input
                  key={index}
                  className="six-categories-card-second-div-for-skeleton"
                  active
                />
              ))}
            {all_items?.length > 0 ? (
              all_items?.map((article) => (
                <CustomFactCheckCategories
                  loading={false}
                  category_image={article ? article?.thumbnail_img : ""}
                  title={article?.title ? article?.title : ""}
                  sub_title={article?.subtitle ? article?.subtitle : ""}
                  created_date={
                    article
                      ? moment(
                          article?.published_date_time
                            ? parseInt(article?.published_date_time, 10)
                            : article?.created_date_time
                        ).format(`MMM DD, YYYY`)
                      : ""
                  }
                  perma_link={article?.perma_link ? article?.perma_link : ""}
                  type={"article"}
                  name={
                    article?.user_profile?.name ||
                    article?.staff_profile?.name ||
                    "YouTurn Editorial"
                  }
                  category_name={category_name}
                  category_id={category_id}
                />
              ))
            ) : (
              <div className="no_data_for_category_wise_article">
                <Empty description="No data" />
              </div>
            )}
            {article_loading &&
              Array.from({ length: 6 }, (_, index) => (
                <Skeleton.Input
                  key={index}
                  className="six-categories-card-second-div-for-skeleton"
                  active
                />
              ))}
            <div className="see_all_article_button">
              {article_list?.length === article_pagination?.total_count ||
              all_items?.length < 6 ? (
                ""
              ) : (
                <Divider
                  className="see_all_article_divider"
                  style={{
                    borderColor: "black",
                    marginRight: "5px",
                  }}
                >
                  <CustomButton
                    on_Click={handle_see_all_articles}
                    minWidth={"250px"}
                    name={t("see_more")}
                    width={"25vh"}
                  />
                </Divider>
              )}
            </div>
          </div>
          <MediaShare />
        </div>
      </Row>
    </div>
  );
};

export default ArticleCategoryWiseList;
