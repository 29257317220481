import React, { useEffect } from "react";
import {
  dynamic_request,
  query_get_all_category,
  useDynamicSelector,
} from "../../../services/redux";
import { retrieveItem } from "../../../helpers/functions";
import { useDispatch } from "react-redux";
import Carousel from "react-multi-carousel";

const CategoriesCarousal = (props) => {
  const {
    set_article_category_id,
    set_article_category_name,
    set_category_key,
    category_key,
  } = props;
  let current_language = retrieveItem("language");
  const dispatch = useDispatch();

  const { items: category_list } = useDynamicSelector("get_all_category_tag");

  const handle_click = (name, id) => {
    set_article_category_id(id);
    set_article_category_name(name);
    set_category_key(name);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 2,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  const list = [
    {
      id: "0c002baf-a3d5-41cb-93e8-dd4650790034",
      name: "தொழில்நுட்பம்",
    },
    {
      id: "12deda7d-389f-4631-b6c5-c5ef70d5a074",
      name: "தமிழ்நாடு",
    },
    {
      id: "23a7b128-6bbb-4965-ac1d-c9df824751df",
      name: "அறிவியல்",
    },
    {
      id: "565aeb3a-1f2f-4997-ba65-fd34f29d08e6",
      name: "வரலாறு",
    },
    {
      id: "57d6994c-44cf-4f83-bbf8-9867c61eb6f6",
      name: "மதம்",
    },
    {
      id: "99117922-96fe-4867-9ef9-948a78a50fdb",
      name: "ஊடகம்",
    },
    {
      id: "9cda36c8-6497-4576-a577-6220e7636311",
      name: "சர்வதேசம்",
    },
    {
      id: "a0ca06fe-f4b7-43a2-83bd-e8bb32e53411",
      name: "சமூக ஊடகம்",
    },
  ];

  const get_category_list = () => {
    let key = [{ key: "get_all_category_tag", loading: true }];
    let query = query_get_all_category;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    get_category_list();
  }, []);
  return (
    <div
      style={{
        width: "100%",
        // height: "50vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* <div
        // style={{
        //   width: "100%",
        //   backgroundColor: "pink",
        //   display: "flex",
        //   // height: "10vh",
        //   gap: "10px",
        // }}
        > */}
      <Carousel
        responsive={responsive}
        infinite={true}
        style={{ width: "100%", backgroundColor: "red" }}
      >
        {list &&
          list.length > 0 &&
          list.map((item, index) => (
            <span
              key={index}
              style={{
                padding: "5px 28px",
                fontSize: "10px",
                border: "1px solid #0065CC",
                borderRadius: "17px",
                cursor: "pointer",
                boxShadow: "0 8px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor:
                  category_key === item.name ? "#007BFF" : "transparent",
                color: category_key === item.name ? "white" : "black",
              }}
              onClick={() => handle_click(item?.name, item?.id)}
            >
              {item.name}
            </span>
          ))}
      </Carousel>
      {/* </div> */}
    </div>
  );
};

export default CategoriesCarousal;
