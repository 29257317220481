import { Col, Image, Popover, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  REPORT_ICON_WHITE,
  SAVE_WHITE_FILLED,
  SAVE_WHITE_OUTLINED,
  SHARE_NEW,
} from "../../../../helpers/image_constants";
import { ROUTES } from "../../../routes/my_routes";
import { SITE_URL } from "../../../../helpers/constants";
import { get_share_content, retrieveItem } from "../../../../helpers/functions";
import { useDynamicSelector } from "../../../../services/redux";
import CustomText from "../../custom/custom_text";
import ArticleDetailsAndAuthorProfile from "../article_details_and_author_profile";
import { toast } from "react-toastify";
import SingleArticleTopTitle from "./single_article_top_title";

const SingleArticleTopTitleAndShareSave = (props) => {
  const { set_model_open, is_saved, handle_save } = props;
  const { pathname } = useLocation();
  const url = window?.location?.href;
  const [share_url, set_share_url] = useState();
  const history = useHistory();
  const session_id = retrieveItem("session_id");
  let current_language = retrieveItem("language");
  const role = retrieveItem("role");

  const {
    title,
    staff_profile,
    thumbnail_img,
    user_profile,
    loading: article_loading,
  } = useDynamicSelector("get_article_by_perma_link");

  const report_click = () => {
    if (session_id && (role === "User" || role === "Previleged User")) {
      set_model_open(true);
    } else {
      history.push(`${ROUTES.ADMIN_LOGIN}`);
    }
  };
  const copy_to_clipboard = () => {
    const redirect_url = window.location.href;
    navigator.clipboard?.writeText(redirect_url);
    toast.success("Link Copied");
  };
  useEffect(() => {
    if (current_language?.name === "tamil") {
      const site_url = new URL(SITE_URL);
      const domain = site_url.hostname;
      const link_without_html = pathname?.replace(".html", "");
      const url = `https://share.${domain}${link_without_html}`;
      set_share_url(url);
    } else {
      const site_url = new URL(SITE_URL);
      const domain = site_url.hostname;
      const link_without_html = pathname?.replace(".html", "");
      const url = `https://share.${domain}/en${link_without_html}`;
      set_share_url(url);
    }
  }, [pathname, SITE_URL]);

  const share_content = get_share_content(
    title,
    share_url,
    thumbnail_img,
    copy_to_clipboard
  );

  return (
    <Col className="single_article_page_first_col_antibiotic">
      {article_loading ? (
        <Skeleton.Input
          className="single_article_view_skeleton_loading"
          active
        />
      ) : (
        <>
          <SingleArticleTopTitle />
          <Row className="single_article_report_share_icons">
            <Image
              style={{
                width: "20px",
                cursor: "pointer",
              }}
              visible={false}
              preview={false}
              src={REPORT_ICON_WHITE}
              onClick={report_click}
            />
            <Popover
              placement="top"
              content={share_content}
              trigger="hover"
              className="share_pop_over"
            >
              <Image
                style={{
                  width: "20px",
                  cursor: "pointer",
                }}
                visible={false}
                preview={false}
                src={SHARE_NEW}
              />
            </Popover>

            {is_saved ? (
              <Image
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                visible={false}
                preview={false}
                src={SAVE_WHITE_FILLED}
                onClick={handle_save}
              />
            ) : (
              <Image
                style={{
                  width: "20px",
                  cursor: "pointer",
                  height: "20px",
                }}
                visible={false}
                preview={false}
                src={SAVE_WHITE_OUTLINED}
                onClick={handle_save}
              />
            )}
          </Row>
          <ArticleDetailsAndAuthorProfile
            profile={staff_profile?.profile_pic || user_profile?.profile_pic}
          />
        </>
      )}
    </Col>
  );
};

export default SingleArticleTopTitleAndShareSave;
