import React from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../services/redux";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";
import CustomText from "../custom/custom_text";
import ArticleFirstCard from "./article_first_card";
import CustomArticleText from "../custom/custom_article_text";
import {
  extractTextFromPTags,
  format_view_count,
} from "../../../helpers/functions";
import moment from "moment";
import ArticleTitleAndSubtitle from "./article/article_title_and_subtitle";

const TopFirstFeaturedArticleInMobile = () => {
  const { t } = useTranslation();

  const { items: top_article, loading: top_article_loading } =
    useDynamicSelector("get_top_first_article");

  return (
    <div>
      <div className="title_and_first_card_of_article">
        <div className="article_top_title">
          <div className="article_page_bread_crumbs">
            <CustomBreadCrumbs pages={"Home/ Articles"} />
          </div>
          <CustomText
            class_name={"article_title"}
            text={t("articles_title")}
            size={"5vh"}
            font_family={"Kufam"}
            color={"#FFFFFF"}
            align={"left"}
          />
        </div>

        <div className="top_first_article_in_mobile">
          <div className="article_mobile_background_container"></div>
          <div className="article_first_card_mobile">
            <ArticleFirstCard
              loading={top_article_loading}
              title={top_article ? top_article?.[0]?.title : ""}
              image={top_article ? top_article?.[0]?.thumbnail_img : ""}
              view_count={
                top_article ? format_view_count(top_article?.[0]?.views) : ""
              }
              created_date_time={moment(
                top_article?.[0]?.published_date_time
                  ? parseInt(top_article[0]?.published_date_time, 10)
                  : top_article?.[0]?.created_date_time
              ).format("DD MMM, YYYY")}
              id={top_article ? top_article?.[0]?.perma_link : ""}
              name={
                top_article
                  ? top_article?.[0]?.user_profile?.name ||
                    top_article?.[0]?.staff_profile?.name
                  : "YouTurn Editorial"
              }
              type={"article"}
            />
          </div>
        </div>

        <ArticleTitleAndSubtitle
          loading={top_article_loading}
          title={top_article ? top_article?.[0]?.title : ""}
          subtitle={
            top_article && top_article[0]?.subtitle
              ? top_article[0]?.subtitle
              : extractTextFromPTags(top_article?.[0]?.content)
          }
          type={"article"}
          id={top_article ? top_article[0]?.perma_link : ""}
        />
      </div>
    </div>
  );
};

export default TopFirstFeaturedArticleInMobile;
