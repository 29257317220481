import { Button, Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import { sync_videos } from "../../../../../services/redux/slices/graphql/graphql_featured_stories";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

const FeaturedVideo = (props) => {
  const {
    featured_you_turn_videos,
    set_type,
    set_you_tube_video_id,
    set_video_id,
    is_editing,
  } = props;
  const dispatch = useDispatch();

  const { status, loading, error } = useDynamicSelector(
    "sync_videos_from_channel"
  );

  useEffect(() => {
    if (status === "Success") {
      toast.success("Latest videos synced");
      dispatch(dynamic_clear("sync_videos_from_channel"));
    } else if (error) {
      toast.error(error?.message);
      dispatch(dynamic_clear("sync_videos_from_channel"));
    }
  }, [status, error]);

  const on_change = (value) => {
    set_you_tube_video_id(value);
    set_video_id(value);
    set_type("youtube_video");
  };

  const on_click = () => {
    let key = [{ key: "sync_videos_from_channel", loading: true }];
    let query = sync_videos;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };
  const last_sync_date = featured_you_turn_videos?.[0]?.updated_at
    ? moment(parseInt(featured_you_turn_videos[0].updated_at, 10)).format(
        "DD MMM, YYYY"
      )
    : "";
  return (
    <>
      <div className="sync_button_div">
        <Button
          style={{
            marginBottom: "1vh",
            backgroundColor: "#0065cd",
            color: "white",
          }}
          onClick={on_click}
          loading={loading}
        >
          Sync latest videos
        </Button>
        <div className="last_sync_date_div">
          Last Sync Date: {last_sync_date}
        </div>
      </div>
      <Form.Item
        name="youtube_video_id"
        label="YouTube"
        rules={[
          {
            required: true,
            message: "Please choose the video",
          },
        ]}
      >
        {is_editing ? (
          <Select onChange={on_change}>
            {featured_you_turn_videos?.map((option, index) => (
              <Select.Option key={option.id} value={option.id}>
                {option.title}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Input readOnly disabled={true} />
        )}
      </Form.Item>
    </>
  );
};

export default FeaturedVideo;
