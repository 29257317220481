import { gql } from "@apollo/client";

export const check_perma_link_availability_query = gql`
  query check_perma_link_availability(
    $language_id: String!
    $type: String!
    $perma_link: String!
    $id: String!
  ) {
    check_perma_link_availability(
      language_id: $language_id
      type: $type
      perma_link: $perma_link
      id: $id
    ) {
      is_available
      error {
        status_code
        message
      }
    }
  }
`;
