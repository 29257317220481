import React from "react";
import CustomText from "../custom/custom_text";
import {
  FACEBOOK_COLOR,
  TWITTER_BLACK_BACKGROUND_IMAGE,
} from "../../../helpers/image_constants";
import { useDynamicSelector } from "../../../services/redux";
import { useDarkMode } from "../contexts/dark_mode_provider";

const SocialMediaCard = (props) => {
  const { title, author_links } = props;
  const { is_dark_mode } = useDarkMode();

  return (
    <div className={`social_media_card  ${is_dark_mode ? "content-dark" : ""}`}>
      <CustomText
        text={title}
        font_family="Lato"
        size="18px"
        color="#140F2D"
        align="left"
        weight="700"
        class_name="count_title"
      />

      <div className="author_social_media_share">
        {author_links?.map((link, index) => (
          <a
            key={index}
            href={link.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "10px" }}
          >
            {link.type === "facebook" && (
              <img
                className="author_face_book_image"
                src={FACEBOOK_COLOR}
                alt="Facebook"
              />
            )}
            {link.type === "twitter" && (
              <img
                className="author_social_media_image"
                src={TWITTER_BLACK_BACKGROUND_IMAGE}
                alt="Twitter"
              />
            )}
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaCard;
