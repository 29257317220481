import React from "react";
import PreviewSingleFactCheckSecondColumn from "./preview_single_fact_check_second_column";
import PreviewSingleFactCheckFirstColumn from "./preview_single_fact_check_first_column";

const PreviewSingleFactCheckPrimarySection = () => {
  return (
    <div className="single_article_first_container">
      <div className="single_article_back_ground_container"></div>
      <div className="single_article_top_details_wrapper">
        <PreviewSingleFactCheckFirstColumn />
        <PreviewSingleFactCheckSecondColumn />
      </div>
    </div>
  );
};

export default PreviewSingleFactCheckPrimarySection;
