import { Button, Empty, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import CustomNewsStoryCard from "../../custom/custom_news_story_card";
import {
  extractTextFromPTags,
  split_tamil_english_words,
} from "../../../../helpers/functions";
import CustomFactCheckRectangleSmallCard from "../../custom/custom_fact_check_rectangle_small_card";
import moment from "moment";
import { useDynamicSelector } from "../../../../services/redux";
import SearchResultTrendingStory from "../search_results_header_contents/search_result_trending_story";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/custom_button";

const SearchResultStoryCard = ({ search_items, load_more_results }) => {
  const { t } = useTranslation();
  const {
    loading: search_results_loading,
    pagination: search_results_pagination,
  } = useDynamicSelector("get_search_results");

  return (
    <Row className="search_result_items_division" style={{ display: "flex" }}>
      <div className="search_results_items">
        <div className="search_result_cards">
          {search_results_loading &&
            Array.from({ length: 6 }, (_, index) => (
              <Skeleton.Input
                key={index}
                className="fact-check-list-news-story-card-skeleton"
                active
              />
            ))}

          {search_items?.length > 0 ? (
            search_items?.map((item, index) => (
              <>
                <div className="search_result_news_story_card">
                  <CustomNewsStoryCard
                    article_img={item?.thumbnail_img || ""}
                    name={item?.staff_profile?.name || ""}
                    date={item?.published_date_time || ""}
                    viewed_count={item?.views || 0}
                    reading_time={item?.reading_time || 0}
                    img_width={"27.8vw" || ""}
                    content_width={"53vh" || ""}
                    first_row_width={"48vh"}
                    text={item?.title}
                    subtitle={
                      item && item?.subtitle
                        ? item?.subtitle
                        : extractTextFromPTags(item?.content)
                    }
                    type={item?.record_type || ""}
                    img_height={"30.5vh" || ""}
                    perma_link={item?.perma_link || ""}
                  />
                </div>
                <div className="search_result_rectangle_small_card">
                  <CustomFactCheckRectangleSmallCard
                    src={item?.thumbnail_img}
                    title={split_tamil_english_words(item?.title, _, 3)}
                    date={moment(item?.published_date_time).format(
                      `MMM D,YYYY`
                    )}
                    perma_link={item?.perma_link || ""}
                    name={item?.staff_profile?.name || ""}
                    type={item?.record_type || ""}
                  />
                </div>
              </>
            ))
          ) : (
            <div className="search_result_no_data">
              <Empty description="No data" />
            </div>
          )}
          {search_results_loading &&
            Array.from({ length: 6 }, (_, index) => (
              <Skeleton.Input
                key={index}
                className="fact-check-list-news-story-card-skeleton"
                active
              />
            ))}

          {search_items?.length === search_results_pagination?.total_count ||
          search_items?.length < 8 ? (
            ""
          ) : (
            <div className="load_more_button">
              <CustomButton
                on_Click={load_more_results}
                minWidth={"250px"}
                name={t("load_more")}
                width={"25vh"}
                height={"30px"}
                maxWidth={"340px"}
                border_radius={"10px"}
              />
            </div>
          )}
        </div>

        <SearchResultTrendingStory />
      </div>
    </Row>
  );
};

export default SearchResultStoryCard;
