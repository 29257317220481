import React, { useEffect } from "react";
import {
  dynamic_request,
  query_get_recent_article,
  query_related_article,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../helpers/functions";
import RecentlyAddedArticles from "./single_article/recently_added_articles";
import { useTranslation } from "react-i18next";
import RelatedArticles from "./single_article/related_articles";

const RelatedArticleAndRecentlyAdded = () => {
  const { t } = useTranslation();
  let current_language = retrieveItem("language");
  const dispatch = useDispatch();
  const isMobile = window.innerWidth <= 768;
  const { category_tags } = useDynamicSelector("get_article_by_perma_link");

  useEffect(() => {
    get_recent_article_list();
  }, []);
  useEffect(() => {
    get_related_article();
  }, [category_tags]);

  const get_related_article = () => {
    let key = [{ key: "get_related_article", loading: true }];
    let query = query_related_article;
    let variables = {
      page_limit: 20,
      json: {
        category_tag: category_tags?.[0]?.id,
        language_id: current_language?.id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_recent_article_list = () => {
    let key = [{ key: "get_recent_articles", loading: true }];
    let query = query_get_recent_article;
    let variables = {
      page_limit: 15,
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <div className="single_article_related_fact_check">
      <RelatedArticles />
      <RecentlyAddedArticles />
    </div>
  );
};
export default RelatedArticleAndRecentlyAdded;
