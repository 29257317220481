import React from "react";
import PreviewSingleArticleFirstColumn from "./preview_single_article_first_column";
import PreviewSingleArticleSecondColumn from "./preview_single_article_second_column";

const SingleArticleBackGroundContainer = () => {
  return (
    <div className="single_article_first_container">
      <div className="single_article_back_ground_container"></div>
      <div className="single_article_top_details_wrapper">
        {/* {article_loading ? (
          <SingleArticleFirstColumnSkeleton />
        ) : (
          <SingleArticleFirstColumn handle_save={handle_save} />
        )}
        {article_loading ? (
          <FeaturedArticleImageComponentSkeleton />
        ) : (
          <SingleArticleSecondColumn />
        )} */}
        <PreviewSingleArticleFirstColumn />
        <PreviewSingleArticleSecondColumn />
      </div>
    </div>
  );
};

export default SingleArticleBackGroundContainer;
