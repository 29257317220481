import React from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../components/custom/custom_text";
import { Image, Row } from "antd";
import { FACT_CHECK_IMAGES, SIGNATORY } from "../../helpers/image_constants";
import CustomNewsStoryCardVertical from "../components/custom/custom_news_story_card_vertical";
import CustomNewsArticle from "../components/custom/custom_news_article";
import CustomButton from "../components/custom/custom_button";
import BellIcon from "../components/landing_page/bell_icon";

const YouTurnLandingPage = () => {
  const { t } = useTranslation();

  const on_click = () => {
    window.open(
      "https://ifcncodeofprinciples.poynter.org/profile/youturn",
      "_blank"
    );
  };

  const article = [
    {
      label1: "Debit/ Credit Cards - The Ultimate Accident Insurance Provider?",
    },
    {
      label2:
        "Video of plastic recycling process is being falsely shared as artificial wheat made from plastic",
    },
  ];
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "280px",
          backgroundColor: "#140f2d",
          display: "flex",
          justifyContent: "center",
          gap: "25px",
        }}
      >
        <div
          style={{
            width: "200px",
            marginLeft: "10px",
            marginTop: "30px",
          }}
        >
          <CustomText
            text={"YouTurn presents"}
            font_family={"Lato"}
            size={"12px"}
            weight={400}
            color={"#FFFFFF"}
            align={"left"}
          />
          <div style={{ marginTop: "10px" }}>
            <CustomText
              text={"Fact Check"}
              size={"20px"}
              font_family={"Kufam"}
              color={"#FFFFFF"}
              align={"left"}
            />
          </div>

          <div style={{ marginTop: "30px" }}>
            <CustomText
              text={t("fact_check_text")}
              font_family={"Lato"}
              size={"12px"}
              weight={400}
              color={"#FFFFFF"}
              align={"left"}
            />
          </div>
          <div style={{ marginTop: "50px" }}>
            <Image
              style={
                {
                  //   maxWidth: "172px",
                  //   height: "172px",
                  //   zIndex: 1,
                  //   //   boxShadow: "0 4px 8px rgba(0, 101, 204, 0.4)",
                  //   borderRadius: "9px",
                }
              }
              visible={false}
              preview={false}
              src={SIGNATORY}
              onClick={on_click}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <CustomNewsStoryCardVertical
            reading_time={"5 mins"}
            view_count={3456}
            date={"Nov 21, 2023"}
            name={"YouTurn Editorial"}
          />
        </div>
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <CustomNewsStoryCardVertical
            reading_time={"5 mins"}
            view_count={3456}
            date={"Nov 21, 2023"}
            name={"YouTurn Editorial"}
          />
        </div>
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <CustomNewsStoryCardVertical
            reading_time={"5 mins"}
            view_count={3456}
            date={"Nov 21, 2023"}
            name={"YouTurn Editorial"}
          />
        </div>
      </div>

      <Row
        style={{
          width: "100%",
          height: "520px",
          //   backgroundColor: "gray",
          marginTop: "350px",
          gap: "30px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <div>
          <BellIcon />
        </div> */}
        <div
          style={{
            marginTop: "100px",
            gap: "30px",
          }}
        >
          <div style={{ height: "80px" }}>
            <CustomText
              text={t("exposing_fake_news")}
              font_family={"Kufam"}
              size={"25px"}
              // weight={400}
              // color={"#FFFFFF"}
              align={"left"}
            />
          </div>

          <Image
            style={{
              maxWidth: "500px",
              height: "300px",
              //   zIndex: 1,
              //   //   boxShadow: "0 4px 8px rgba(0, 101, 204, 0.4)",
              //   borderRadius: "9px",
            }}
            visible={false}
            preview={false}
            src={FACT_CHECK_IMAGES}
          />
        </div>

        <div
          style={
            {
              // marginTop: "30px",
            }
          }
        >
          <CustomNewsStoryCardVertical
            reading_time={"5 mins"}
            view_count={3456}
            date={"Nov 21, 2023"}
            name={"YouTurn Editorial"}
          />
        </div>

        <div>
          <CustomNewsArticle
            article={article[1]?.label2}
            header_color={"#e84756"}
            name={"YouTurn Editorial"}
            date={" Nov 21, 2023"}
            view_count={1024}
            reading_time={"4 min"}
          />
          <CustomNewsArticle
            article={article[1]?.label2}
            header_color={"#e84756"}
            name={"YouTurn Editorial"}
            date={" Nov 21, 2023"}
            view_count={1024}
            reading_time={"4 min"}
          />
        </div>
      </Row>

      <Row
        style={{
          width: "100%",
          height: "100px",
          //   backgroundColor: "gray",
          //   marginTop: "350px",
          //   gap: "30px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CustomButton name={"See All stories "} width={"400px"} />
      </Row>
    </div>
  );
};

export default YouTurnLandingPage;
