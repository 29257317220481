import React from "react";
import CustomText from "../../custom/custom_text";
import { split_tamil_english_words } from "../../../../helpers/functions";
import TextInput from "./text_input";
import { useDynamicSelector } from "../../../../services/redux";

const UserAboutDetails = (props) => {
  const { open_form } = props;
  const item = useDynamicSelector("get_user");

  return (
    <div>
      <CustomText
        text={"About Me"}
        font_family={"Lato"}
        size={"14px"}
        color={"#140f2d"}
        align={"left"}
        weight={"700"}
      />
      <div style={{ height: "12vh" }}>
        {open_form != true ? (
          <CustomText
            class_name={"about_me_content"}
            text={
              (split_tamil_english_words(item?.user_profile?.about, _, 25) ??
                split_tamil_english_words(item?.staff_profile?.about, _, 25)) ||
              ""
            }
            font_family={"Lato"}
            size={"12px"}
            color={"#140f2d"}
            align={"left"}
            weight={"500"}
            line_height={"16px"}
          />
        ) : (
          <TextInput />
        )}
      </div>
    </div>
  );
};

export default UserAboutDetails;
