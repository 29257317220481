import { gql } from "@apollo/client";

export const query_related_article = gql`
  query get_all_article(
    $page_number: Int
    $page_limit: Int
    $json: get_all_article_input
  ) {
    get_related_article: get_all_article(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        language_id
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
        thumbnail_img
        is_saved
        title
        subtitle
        perma_link
        description
        content
        status
        reading_time
        likes
        views
        comments_count
        is_reported
        is_deleted
        created_date_time
        published_date_time
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const query_get_recent_article = gql`
  query get_recent_articles(
    $language_id: String!
    $page_number: Int
    $page_limit: Int
  ) {
    get_recent_articles(
      language_id: $language_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
        title
        subtitle
        perma_link
        description
        content
        status
        reading_time
        likes
        views
        comments_count
        is_reported
        is_deleted
        thumbnail_img
        created_date_time
        published_date_time
      }
    }
  }
`;

export const mutation_add_article_book_mark = gql`
  mutation add_article_to_saved_items($article_id: String) {
    add_article_to_saved_items(article_id: $article_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_article_book_mark = gql`
  mutation remove_article_from_saved_items($article_id: String) {
    remove_article_from_saved_items(article_id: $article_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const mutation_report_article = gql`
  mutation create_report($json: report_input) {
    create_report_article: create_report(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
