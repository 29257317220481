import { Form, Input } from "antd";
import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const DonationAmountInput = (props) => {
  const { t } = useTranslation();
  const { width, value, set_donation_amount, class_name } = props;
  const handle_input_change = (e) => {
    e.target.value = e.target.value.replace(/\D/, "");
    set_donation_amount(parseInt(e.target.value));
  };

  return (
    <Form.Item name={"amount"}>
      <Input
        rules={[
          {
            required: true,
            message: "Please enter the donation amount",
          },
          {
            pattern: /^\d+(\.\d{1,2})?$/,
            message: "Please enter a valid donation amount",
          },
        ]}
        style={{
          width: "100%",
          height: "40px",
          borderRadius: "10px",
          marginTop: "13px",
        }}
        placeholder={t("enter_amount")}
        onInput={handle_input_change}
        className={class_name}
      />
    </Form.Item>
  );
};

export default DonationAmountInput;
