import React, { useEffect, useState } from "react";
import RichText from "../../../system/ui/antd_form/rich_text";
import { Col, Radio, Row } from "antd";

const Source = (props) => {
  const { color, content, set_content, is_disabled } = props;
  const [editor_type, set_editor_type] = useState("lexical");
  const handle_change = (event) => {
    set_editor_type(event?.target?.value);
  };
  useEffect(() => {
    if (!content.json && content?.html && content?.html !== "") {
      set_editor_type("rich_text");
    }
  }, [content]);
  console.log("contentcontentcontent", content, editor_type);

  return (
    <>
      <Row justify={"space-between"} style={{ marginBottom: "10px" }}>
        <Col>
          <span style={{ color: color || "black" }}>{"Source"}</span>
        </Col>
        {!is_disabled ? (
          <Col>
            {!content.json && content?.html && content?.html !== "" && (
              <Radio.Group onChange={handle_change} value={editor_type}>
                <Radio value={"rich_text"}>Old</Radio>
                <Radio value={"lexical"}>New</Radio>
              </Radio.Group>
            )}
          </Col>
        ) : (
          <Col></Col>
        )}
      </Row>
      <RichText
        content={content}
        set_content={set_content}
        type={editor_type}
        is_disabled={is_disabled}
      />
    </>
  );
};
export default Source;
