import React, { useEffect, useRef, useState } from "react";
import CustomText from "../custom/custom_text";
import { Button } from "antd";
import SingleFactCheckCommentForm from "./single_fact_check_comment_form";
import {
  dynamic_request,
  query_get_all_child_comments,
  query_get_all_comment,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { useTranslation } from "react-i18next";
import SingleFactCheckAllComments from "./single_fact_check_all_comments";
import SingleFactCheckCommentTitle from "./single_fact_check_comment_title";

const SingleFactCheckCommentsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [comment_id, set_comment_id] = useState("");
  const [comment_reply_open, set_comment_reply_open] = useState(false);
  const [expandComments, setExpandComments] = useState(false);
  const [commentListHeight, setCommentListHeight] = useState("35vh");
  const first_comment_ref = useRef(null);
  const { is_dark_mode } = useDarkMode();

  const { id: fact_check_id } = useDynamicSelector(
    "get_fact_check_by_perma_link"
  );

  const { items: comment_list } = useDynamicSelector("get_all_parent_comments");

  useEffect(() => {
    get_all_comment_list();
    get_all_child_comment_list(1, 1);
  }, []);

  useEffect(() => {
    get_all_child_comment_list();
  }, [comment_id]);

  useEffect(() => {
    get_all_comment_list();
  }, [fact_check_id]);

  const get_all_comment_list = () => {
    let key = [{ key: "get_all_parent_comments", loading: true }];
    let query = query_get_all_comment;
    let variables = {
      fact_check_id: fact_check_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_child_comment_list = (page_number, page_limit) => {
    let key = [{ key: "get_all_child_comments", loading: true }];
    let query = query_get_all_child_comments;
    let variables = {
      parent_comment_id: comment_id,
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_child_comment = (child_comment) => {
    set_comment_id(child_comment?.id);
  };

  const handleToggleExpandComments = () => {
    setExpandComments(!expandComments);
    setCommentListHeight(expandComments ? "30vh" : "auto");
  };

  useEffect(() => {
    if (expandComments) {
      debouncedHandleTitleClick();
    } else if (!expandComments) {
      handlePagePosition();
    }
  }, [expandComments]);

  const handlePagePosition = () => {
    if (first_comment_ref.current) {
      first_comment_ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 800);

  return (
    <div className="single-fact-check-comment-full-div" id="comment-section">
      <div className="single-fact-check-comment-row">
        <div className="single-fact-check-comment-div">
          <div className="single-fact-check-comment-extra">
            <SingleFactCheckCommentTitle />
            <div style={{ width: "100%" }}>
              <SingleFactCheckAllComments
                handle_child_comment={handle_child_comment}
                set_comment_id={set_comment_id}
                expandComments={expandComments}
                commentListHeight={commentListHeight}
              />

              {comment_list?.length === 0 ? (
                ""
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    onClick={handleToggleExpandComments}
                    style={{
                      borderRadius: "7px",
                      border: "none",
                      backgroundColor: "#00adee",
                      color: "white",
                    }}
                  >
                    {!expandComments ? (
                      <>{t("see_more")}</>
                    ) : (
                      <a href={"#comment-section"}>{t("see_less")}</a>
                    )}
                  </Button>
                </div>
              )}
            </div>

            <div style={{ width: "100%" }}>
              <SingleFactCheckCommentForm
                set_comment_reply_open={set_comment_reply_open}
                get_all_comment_list={get_all_comment_list}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleFactCheckCommentsList;
