import { Image } from "antd";
import React from "react";
import CustomText from "./custom_text";
import { WHATSAPP_GIF_ICON } from "../../../helpers/image_constants";
import { useTranslation } from "react-i18next";
import { change_tamil_font_style } from "../../../helpers/functions";

const CustomJoinWhatsappChannel = () => {
  const { t } = useTranslation();
  const redirect_to_social_media = (socialMedia) => {
    switch (socialMedia) {
      case "Whatsapp":
        window.open(
          "https://api.whatsapp.com/send?phone=916369748351&text=Hi%20You%20Turn!",
          "_blank"
        );
        break;
      default:
    }
  };

  return (
    <div className="single-fact-check-whatsapp-div">
      <div
        className="single-fact-check-whatsapp-button-div"
        onClick={() => redirect_to_social_media("Whatsapp")}
      >
        <div
          style={{
            width: "23%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Image
            visible={false}
            preview={false}
            src={WHATSAPP_GIF_ICON}
            className="single-fact-check-whatsapp-image-size"
          />
        </div>
        <div className="single-fact-check-whatsapp-second-button-div">
          <CustomText
            class_name="single-fact-check-whatsapp-text"
            text={change_tamil_font_style(
              t("join_our_whatsapp_channel_for_quick_updates")
            )}
            font_family={"lato"}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomJoinWhatsappChannel;
