import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  navigate,
  split_tamil_english_words,
} from "../../../helpers/functions";
import { Skeleton } from "antd";
import { Pressable } from "native-base";
import { ROUTES } from "../../routes/my_routes";
import { ARROW_GIF_ICON } from "../../../helpers/image_constants";
import { useDarkMode } from "../contexts/dark_mode_provider";

const CustomFactCheckText = (props) => {
  const {
    headline,
    type,
    id,
    content,
    fact_check_list_loading,
    class_name,
    size,
    headline_class,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { is_dark_mode } = useDarkMode();
  const [expand_news, set_expand_news] = useState(false);
  const on_click = () => {
    if (type === "article") {
      history.push(`${ROUTES.SINGLE_ARTICLE_PAGE}/${id}.html`);
    } else if (type === "fact-check" || type === "fact_check") {
      history.push(`${ROUTES.SINGLE_FACT_CHECK}/${id}.html`);
    }
  };

  useEffect(() => {
    if (expand_news) {
      if (type === "articles") {
        navigate(`/single-article-page/${id}.html`);
      } else if (type === "fact-check") {
        navigate(`/single-fact-check/${id}.html`);
      }
    }
  }, [expand_news, type]);
  return (
    <div className="user_contribution_text">
      <div>
        {fact_check_list_loading ? (
          <Skeleton.Input className="user_contribution_text_skeleton" active />
        ) : (
          <text
            style={{
              color: "#f49d36",
              fontWeight: "bold",
              fontSize: "4vh",
              marginBottom: "10px",
              width: "10vw",
            }}
          >
            #{t("featured_story")}
          </text>
        )}
      </div>
      {fact_check_list_loading ? (
        <Skeleton.Input className="user_contribution_title_skeleton" active />
      ) : (
        <div
          className={headline_class ? headline_class : "content_headline"}
          onClick={on_click}
        >
          <text className="user_contribution_headline_text">{headline}</text>
        </div>
      )}

      {fact_check_list_loading ? (
        <Skeleton.Input
          className="user_contribution_subtitle_skeleton"
          active
        />
      ) : (
        <div>
          <div
            className={class_name ? class_name : "user_contribution_content"}
          >
            <div
              className={"feature-story-sub-title-content"}
              onClick={on_click}
            >
              <span style={{ color: is_dark_mode ? "#ffffff" : "" }}>
                {split_tamil_english_words(content, _, size)}
              </span>
            </div>
          </div>
        </div>
      )}
      <Pressable>
        <div>
          <text
            style={{
              color: "#0065CC",
              textDecoration: "underline",
              fontSize: "2vh",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={on_click}
          >
            {t("Continue Reading")}
            <img width={30} height={30} src={ARROW_GIF_ICON} />
          </text>
        </div>
      </Pressable>
    </div>
  );
};

export default CustomFactCheckText;
