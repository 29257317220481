import React, { useEffect } from "react";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { query_get_dash_board_count } from "../../../services/redux/slices/graphql/graphql_user_profile";
import CustomCountDetailsCard from "./custom_count_details_card";
import SocialMediaCard from "./social_media_card";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../helpers/functions";
import { dynamic_request, useDynamicSelector } from "../../../services/redux";

const AuthorArticleFactCheckSocialMedia = () => {
  const location = useLocation();
  const { name } = useParams();
  let current_language = retrieveItem("language");
  const dispatch = useDispatch();

  const { items: author_details } = useDynamicSelector(
    "get_all_author_stories"
  );

  const queryParams = new URLSearchParams(location.search);
  const staffProfileId = queryParams.get("staff_profile");
  const userProfileId = queryParams.get("user_profile");

  let author_links = [];

  if (author_details?.[0]?.staff_profile?.url) {
    try {
      const socialMediaUrls = JSON.parse(author_details[0].staff_profile.url);
      if (socialMediaUrls.facebook) {
        author_links.push({
          type: "facebook",
          link: socialMediaUrls.facebook.trim(),
        });
      }
      if (socialMediaUrls.twitter) {
        author_links.push({
          type: "twitter",
          link: socialMediaUrls.twitter.trim(),
        });
      }
      if (socialMediaUrls.instagram) {
        author_links.push({
          type: "instagram",
          link: socialMediaUrls.instagram.trim(),
        });
      }
    } catch (error) {
      console.error("Error parsing social media URLs:", error);
    }
  }

  return (
    <div
      className="count_details_card"
      style={{
        justifyContent: author_links?.length > 0 ? "space-between" : "center",
        gap: author_links?.length > 0 ? "" : "20px",
      }}
    >
      <CustomCountDetailsCard
        title={"Article"}
        userProfileId={userProfileId}
        staffProfileId={staffProfileId}
        name={name}
        record_type={"article"}
      />
      <CustomCountDetailsCard
        title={"Fact check"}
        userProfileId={userProfileId}
        staffProfileId={staffProfileId}
        name={name}
        record_type={"fact_check"}
      />

      {author_links.length > 0 && (
        <SocialMediaCard
          title={"Follow me on social media"}
          author_links={author_links}
        />
      )}
    </div>
  );
};

export default AuthorArticleFactCheckSocialMedia;
