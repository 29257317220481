import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import React from "react";

const PreviewSingleFactCheckSecondColumn = () => {
  const { thumbnail_img } = useDynamicSelector("get_fact_check");

  return (
    <div className="single_article_second_column_container">
      <img style={{ width: "100%" }} src={thumbnail_img || BROKEN_IMAGE} />
    </div>
  );
};

export default PreviewSingleFactCheckSecondColumn;
