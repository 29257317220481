import React from "react";
import CustomText from "../../custom/custom_text";
import moment from "moment";

const LastThreeDonations = (props) => {
  const { last_three_donations } = props;
  return (
    <div>
      {last_three_donations?.map((donation, index) => (
        <div className="user_profile_donation_details" key={index}>
          <div className="user_profile_donation_date">
            <CustomText
              text={
                donation?.created_date_time
                  ? moment(donation.created_date_time).format("DD-MM-YYYY")
                  : ""
              }
              font_family="Lato"
              size="11px"
              color="#140F2D"
              align="left"
              weight="600"
            />
          </div>
          <div className="donation_card_amount">
            <CustomText
              text={donation.amount ? `₹${donation.amount}` : ""}
              font_family="Lato"
              size="11px"
              color={donation?.amount}
              align="left"
              weight="600"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default LastThreeDonations;
