import { Button, Col, Form, Row, Select } from "antd";
import { VStack } from "native-base";
import React, { useEffect, useState } from "react";
import {
  dynamic_clear,
  dynamic_request,
  get_all_languages_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import {
  create_featured_stories_query,
  get_all_featured_fact_check_query,
  get_all_featured_stories_query,
  get_all_featured_user_articles_query,
  get_all_featured_user_fact_check_query,
  get_all_you_turn_videos_query,
  get_featured_article_query,
} from "../../../../services/redux/slices/graphql/graphql_featured_stories";
import FeaturedArticle from "./featured_stories_form/featured_article";
import FeaturedFactCheck from "./featured_stories_form/featured_fact_check";
import FeaturedVideo from "./featured_stories_form/featured_video";
import SelectLanguage from "./featured_stories_form/select_language";
import { toast } from "react-toastify";
import FeaturedUserContribution from "./featured_stories_form/featured_user_contrinution";
import { getDefaultLanguageId } from "../../../../helpers/constants";

const FeaturedStories = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [language_id, set_language_id] = useState(
    "839bcc29-853f-4c4c-8700-98fd88558952"
  );
  const [type, set_type] = useState("");
  const [article_id, set_article_id] = useState();
  const [fact_check_id, set_fact_check_id] = useState();
  const [you_tube_video_id, set_you_tube_video_id] = useState();
  const [video_id, set_video_id] = useState();
  const [record_type, set_record_type] = useState("");
  console.log("record_type", record_type);

  const [is_article_editing, set_is_article_editing] = useState(false);
  const [is_fact_check_editing, set_is_fact_check_editing] = useState(false);
  const [is_youtube_editing, set_is_youtube_editing] = useState(false);
  const [is_user_contribution_editing, set_is_user_contribution_editing] =
    useState(false);

  const [items, set_items] = useState([]);

  const { items: languages_list } = useDynamicSelector(
    "get_all_system_languages"
  );
  const { items: featured_article_list } =
    useDynamicSelector("featured_article");
  const { items: featured_fact_checks } = useDynamicSelector(
    "featured_fact_check"
  );
  const { items: featured_you_turn_videos } = useDynamicSelector(
    "get_you_turn_videos"
  );

  const {
    status: create_featured_story_status,
    error: create_featured_story_error,
    loading: create_featured_story_loading,
  } = useDynamicSelector("create_featured_story");

  const { items: featured_stories } = useDynamicSelector(
    "get_all_featured_story"
  );

  const { items: featured_user_articles } = useDynamicSelector(
    "user_contributed_articles"
  );
  const { items: featured_user_fact_checks } = useDynamicSelector(
    "user_contributed_fact_check"
  );

  useEffect(() => {
    get_languages();
  }, []);

  useEffect(() => {
    get_all_featured_stories();
  }, [language_id]);

  useEffect(() => {
    if (
      featured_user_articles &&
      featured_user_articles?.length &&
      record_type === "articles"
    ) {
      set_items([...featured_user_articles]);
    } else if (
      featured_user_fact_checks &&
      featured_user_fact_checks?.length &&
      record_type === "fact_check"
    ) {
      set_items([...featured_user_fact_checks]);
    } else {
      set_items([]);
    }
  }, [
    featured_user_articles,
    featured_user_fact_checks,
    record_type,
    language_id,
    is_user_contribution_editing,
  ]);

  useEffect(() => {
    if (featured_stories) {
      form.setFieldsValue({
        language_id: featured_stories?.[0]?.language_id,
        article_id: featured_stories?.[0]?.article?.title,
        fact_check_id: featured_stories?.[1]?.fact_check?.title,
        user_contribution_id: featured_stories?.[2]?.article_id
          ? featured_stories?.[2]?.article?.title
          : featured_stories?.[2]?.fact_check?.title,
        youtube_video_id:
          featured_stories?.[3]?.youtube_video?.title ||
          featured_stories?.[4]?.youtube_video?.title,
        user_contribution_type_id: featured_stories?.[2]?.article_id
          ? "articles"
          : "fact_check",
      });
      if (featured_stories?.[2]?.article_id) {
        set_record_type("articles");
      } else if (featured_stories?.[2]?.fact_check_id) {
        set_record_type("fact_check");
      }
    }
  }, [featured_stories, form, language_id]);

  useEffect(() => {
    if (create_featured_story_status === "Success") {
      if (type === "article") {
        toast.success("Article pinned successfully");
      } else if (type === "fact_check") {
        toast.success("Fact check pinned successfully");
      } else if (type === "youtube_video") {
        toast.success("Youtube video pinned successfully");
      } else if (type === "user_contribution") {
        toast.success("User contribution pinned successfully");
      }
      set_is_article_editing(false);
      set_is_fact_check_editing(false);
      set_is_youtube_editing(false);
      set_is_youtube_editing(false);
      set_is_user_contribution_editing(false);
      get_all_featured_stories();
      set_article_id(null);
      set_fact_check_id(null);
      set_you_tube_video_id(null);
      dispatch(dynamic_clear("create_featured_story"));
    } else if (create_featured_story_error) {
      toast.error(create_featured_story_error?.message);
      dispatch(dynamic_clear("create_featured_story"));
    }
  }, [create_featured_story_status, create_featured_story_error, type]);

  const get_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const get_featured_article = () => {
    let key = [{ key: "featured_article", loading: true }];
    let query = get_featured_article_query;
    let variables = {
      json: {
        language_id: language_id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_featured_fact_check = () => {
    let key = [{ key: "featured_fact_check", loading: true }];
    let query = get_all_featured_fact_check_query;
    let variables = {
      json: {
        language_id: language_id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_featured_video = () => {
    let key = [{ key: "get_you_turn_videos", loading: true }];
    let query = get_all_you_turn_videos_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const get_featured_user_articles = () => {
    let key = [{ key: "user_contributed_articles", loading: true }];
    let query = get_all_featured_user_articles_query;
    let variables = {
      json: {
        language_id: language_id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_featured_user_fact_check = () => {
    let key = [{ key: "user_contributed_fact_check", loading: true }];
    let query = get_all_featured_user_fact_check_query;
    let variables = {
      json: {
        language_id: language_id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const create_feature_story = () => {
    let key = [{ key: "create_featured_story", loading: true }];
    let query = create_featured_stories_query;
    let variables = {
      json: {
        type: type,
        language_id: language_id,
        article_id: article_id,
        fact_check_id: fact_check_id,
        youtube_video_id: you_tube_video_id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_featured_stories = () => {
    let key = [{ key: "get_all_featured_story", loading: true }];
    let query = get_all_featured_stories_query;
    let variables = {
      language_id: language_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const record_types = [
    {
      title: "Article",
      type: "articles",
    },
    {
      title: "Fact Check",
      type: "fact_check",
    },
  ];

  const on_change_type = (value) => {
    set_record_type(value);
    form.setFieldsValue({ user_contribution_id: "" });
  };

  const handle_change_article = () => {
    set_is_article_editing(true);
    set_type("article");
    get_featured_article();
    set_article_id(featured_stories?.[0]?.article_id);
  };

  const handle_change_fact_check = () => {
    set_is_fact_check_editing(true);
    set_type("fact_check");
    get_featured_fact_check();
    set_fact_check_id(featured_stories?.[1]?.fact_check_id);
  };

  const handle_change_video = () => {
    set_is_youtube_editing(true);
    set_type("youtube_video");
    get_featured_video();
    set_you_tube_video_id(
      featured_stories?.[3]?.youtube_video_id ||
        featured_stories?.[4]?.youtube_video_id
    );
  };

  const handle_change_user_contribution = () => {
    set_is_user_contribution_editing(true);
    set_type("user_contribution");
    get_featured_user_articles();
    get_featured_user_fact_check();
    set_record_type(record_type);
    set_article_id(featured_stories?.[2]?.article_id);
    set_fact_check_id(featured_stories?.[2]?.fact_check_id);
  };

  return (
    <VStack>
      <Form layout="vertical" form={form}>
        <Row gutter={16} align={"middle"}>
          <Col span={12}>
            <SelectLanguage
              set_language_id={set_language_id}
              languages_list={languages_list}
              set_is_article_editing={set_is_article_editing}
              set_is_fact_check_editing={set_is_fact_check_editing}
              set_is_youtube_editing={set_is_youtube_editing}
              set_is_user_contribution_editing={
                set_is_user_contribution_editing
              }
            />
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <FeaturedArticle
              featured_article_list={featured_article_list}
              set_type={set_type}
              set_article_id={set_article_id}
              is_editing={is_article_editing}
            />
          </Col>
          <Col span={12}>
            {is_article_editing ? (
              <Button
                onClick={create_feature_story}
                loading={type === "article" && create_featured_story_loading}
                className="featured-story-button"
              >
                Update
              </Button>
            ) : (
              <Button
                className="featured-story-button"
                onClick={handle_change_article}
              >
                Edit
              </Button>
            )}
          </Col>
          <Col span={12}>
            <FeaturedFactCheck
              featured_fact_checks={featured_fact_checks}
              set_type={set_type}
              set_fact_check_id={set_fact_check_id}
              is_editing={is_fact_check_editing}
            />
          </Col>
          <Col span={12}>
            {is_fact_check_editing ? (
              <Button
                onClick={create_feature_story}
                loading={type === "fact_check" && create_featured_story_loading}
                className="featured-story-button"
              >
                Update
              </Button>
            ) : (
              <Button
                className="featured-story-button"
                onClick={handle_change_fact_check}
              >
                Edit
              </Button>
            )}
          </Col>
          <Col span={12}>
            <FeaturedVideo
              featured_you_turn_videos={featured_you_turn_videos}
              set_you_tube_video_id={set_you_tube_video_id}
              set_type={set_type}
              set_video_id={set_video_id}
              is_editing={is_youtube_editing}
            />
          </Col>
          <Col span={12}>
            {is_youtube_editing ? (
              <Button
                onClick={create_feature_story}
                loading={
                  type === "youtube_video" && create_featured_story_loading
                }
                style={{
                  marginLeft: "10px",
                  marginTop: "45px",
                  backgroundColor: "#0065cd",
                  color: "white",
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                style={{
                  marginLeft: "10px",
                  marginTop: "45px",
                  backgroundColor: "#0065cd",
                  color: "white",
                }}
                onClick={handle_change_video}
              >
                Edit
              </Button>
            )}
          </Col>

          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Form.Item
              name="user_contribution_type_id"
              label="User contributed story"
              rules={[
                {
                  required: true,
                  message: "Please choose the type",
                },
              ]}
            >
              <Select
                style={{ width: "130px" }}
                onChange={on_change_type}
                value={record_type}
                disabled={!is_user_contribution_editing}
              >
                {record_types?.map((option, index) => (
                  <Select.Option key={option.type} value={option.type}>
                    {option.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div
              style={{
                display: "flex",
                paddingRight: "10px",
              }}
            >
              <Col span={12}>
                <FeaturedUserContribution
                  set_type={set_type}
                  set_article_id={set_article_id}
                  set_fact_check_id={set_fact_check_id}
                  record_type={record_type}
                  items={items}
                  form={form}
                  is_editing={is_user_contribution_editing}
                />
              </Col>
              <Col span={12}>
                {is_user_contribution_editing ? (
                  <Button
                    onClick={create_feature_story}
                    loading={
                      type === "user_contribution" &&
                      create_featured_story_loading
                    }
                    style={{
                      marginLeft: "15px",
                      backgroundColor: "#0065cd",
                      color: "white",
                    }}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    style={{
                      marginLeft: "15px",
                      backgroundColor: "#0065cd",
                      color: "white",
                    }}
                    onClick={handle_change_user_contribution}
                  >
                    Edit
                  </Button>
                )}
              </Col>
            </div>
          </Col>
        </Row>
      </Form>
    </VStack>
  );
};

export default FeaturedStories;
