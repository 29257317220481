import React from "react";
import CustomFactCheckRectangleSmallCard from "../custom/custom_fact_check_rectangle_small_card";
import CustomNewsStoryCardVertical from "../custom/custom_news_story_card_vertical";
import CustomText from "../custom/custom_text";
import { split_tamil_english_words } from "../../../helpers/functions";
import { ANTIBIOTICS_IMAGE } from "../../../helpers/image_constants";
import moment from "moment";
import ArticleFirstCard from "../article/article_first_card";
import DonationCard from "../widgets/donation_card";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Image } from "native-base";
import PartyWiseRecentCard from "./party_wise_recent_card";
import PartyWiseRecentMiddleCard from "./party_wise_recent_middle_card";
import { useDynamicSelector } from "../../../services/redux";

const PartyWiseStories = ({
  article,
  four_article,
  article_loading,
  title,
}) => {
  const history = useHistory();

  const on_click = () => {
    if (type === "article") {
      history.push(`/single-article-page/${perma_link}.html`);
    } else if (type === "fact-check") {
      history.push(`/single-fact-check/${perma_link}`);
    }
  };

  return (
    <div className="mt-5 mobile-align-stories ">
      <div className="recommended_stories_text">
        <CustomText
          class_name={"recommended_stories_mobile_text"}
          text={title ? title : "Recently Added"}
          font_family={"kufam"}
          size={"6vh"}
          weight={"700"}
        />
      </div>
      <div className="part-wise-stories-secondary-container mt-5">
        <CustomNewsStoryCardVertical
          min_height={"89vh"}
          title={split_tamil_english_words(article && article?.title, _, 8)}
          subtitle={split_tamil_english_words(
            article && article?.subtitle,
            _,
            22
          )}
          reading_time={article?.[5]?.reading_time}
          view_count={(article && article?.views) || 0}
          date={moment(article && article?.created_date_time).format(
            "MMM DD, YYYY"
          )}
          name={"YouTurn Editorial"}
          src={article?.thumbnail_img}
          type={article?.type}
          margin_top={"9vh"}
          perma_link={article?.perma_link}
        />
        {/* </div> */}

        <PartyWiseRecentMiddleCard list={four_article} />

        <div className="part-wise-stories-content">
          <div style={{ width: "70%" }}>
            <DonationCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartyWiseStories;
