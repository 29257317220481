import React from "react";
import { Button, Col, Popover, Row } from "antd";
import {
  EMAIL_LOGO,
  FACEBOOK_COLOR,
  NEW_LOGO_IMAGE_TRANSPARENT,
  TWITTER_X_LOGO_BLACK,
  WHATS_APP_LOGO,
} from "../../../helpers/image_constants";
import CustomText from "../custom/custom_text";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../routes/my_routes";
import {
  change_tamil_font_style,
  retrieveItem,
} from "../../../helpers/functions";

const JoinUsCard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const session_id = retrieveItem("session_id");
  const role = retrieveItem("role");

  const handle_become_member = () => {
    history.push(ROUTES.ADMIN_LOGIN);
  };

  const url = process.env.REACT_APP_WEB_URL;

  const content = (
    <div style={{ display: "flex", gap: "10px" }}>
      <a
        href={`whatsapp://send?text=${encodeURIComponent(url)}`}
        data-action="share/whatsapp/share"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={WHATS_APP_LOGO} alt="WhatsApp" style={{ height: "25px" }} />
      </a>

      <a
        href={`https://www.facebook.com/sharer/sharer.php?quote=${encodeURIComponent(
          url
        )}`}
        target="_blank"
      >
        <img src={FACEBOOK_COLOR} alt="launch" style={{ height: "25px" }} />
      </a>

      <a
        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          url
        )}`}
        target="_blank"
      >
        <img
          src={TWITTER_X_LOGO_BLACK}
          alt="launch"
          style={{ height: "20px" }}
        />
      </a>
      <a href={`mailto:?body=${encodeURIComponent(url)}`} target="_blank">
        <img src={EMAIL_LOGO} alt="launch" style={{ height: "25px" }} />
      </a>
    </div>
  );

  return (
    <div className="join_us">
      <Col className="join_us_card">
        {session_id && (role === "User" || role === "Previleged User") ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "25vh",
            }}
          >
            <CustomText
              class_name={"join_us_content"}
              text={t("thanks_content")}
              font_family={"Lato"}
              size={"17px"}
              weight={"600"}
              color={"#FFFFFF"}
            />
            <Popover
              placement="top"
              content={content}
              trigger="hover"
              className="share_pop_over"
            >
              <Button
                className="invite_button"
                type="primary"
                style={{
                  backgroundColor: "#00adee",
                  color: "white",
                  marginTop: "40px",
                }}
              >
                {t("invite_friends")}
              </Button>
            </Popover>
          </div>
        ) : (
          <div className="join_us_card_title_and_button">
            <CustomText
              text={t("join_us_today")}
              font_family={"Lato"}
              size={"15px"}
              weight={"400"}
              align={"left"}
              color={"#FFFFFF"}
            />
            <Row>
              <CustomText
                class_name={"join_us_content"}
                text={t("join_feature")}
                font_family={"Lato"}
                size={"23px"}
                weight={"600"}
                color={"#FFFFFF"}
              />
            </Row>
            <div className="become_member_button">
              <Button
                className="join_us_button"
                htmlType="submit"
                style={{ backgroundColor: "#00adee", color: "white" }}
                onClick={handle_become_member}
              >
                {change_tamil_font_style(t("become_member"), "2vh", _)}
              </Button>
            </div>
          </div>
        )}
      </Col>

      <Col
        className="join_us_card_image"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={NEW_LOGO_IMAGE_TRANSPARENT}
          className="youturn_transparent_logo"
        />
      </Col>
    </div>
  );
};

export default JoinUsCard;
