import React from "react";
import { Carousel, Image, Skeleton } from "antd";
import {
  DEFAULT_USER,
  YOU_TURN_LOGO_BLUE,
} from "../../../helpers/image_constants";
import { useDynamicSelector } from "../../../services/redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const TopContributorsCarousel = (props) => {
  const { list } = props;
  const history = useHistory();

  const get_user_profile = (contributor) => {
    const queryParams = new URLSearchParams();

    if (contributor?.user_profile_id) {
      queryParams.set("user_profile", contributor?.user_profile_id);
    }
    queryParams.set("type", "fact_check");

    history.push({
      pathname: `/author/${contributor?.name}`,
      search: `?${queryParams.toString()}`,
    });
  };
  return (
    <div className="top_contributors_mobile_view_first_div">
      <Carousel
        infinite={false}
        dots={false}
        slidesToShow={3}
        slidesToScroll={1}
        className="carousel-recently-added"
      >
        {list &&
          list?.length > 0 &&
          list?.map((contributor, index) => (
            <div
              key={index}
              className="carousel-item"
              onClick={() => get_user_profile(contributor)}
            >
              <Image
                preview={false}
                style={{
                  width: "90px",
                  height: "90px",
                  borderRadius: "50%",
                }}
                src={contributor?.profile_pic ?? DEFAULT_USER}
              />
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default TopContributorsCarousel;
