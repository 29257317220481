import { Typography } from "antd";
import React from "react";
import { font } from "../../../helpers/constants";
import { isEnglish } from "../../../helpers/functions";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { height } from "styled-system";

const CustomText = (props) => {
  const { is_open, on_click, is_link, backgroundColor, text } = props;
  const { is_dark_mode } = useDarkMode();

  const transition = {
    transition: "width 0.2s linear",
  };

  return (
    <Typography
      className={`${props.class_name} ${is_link ? "hover_link_color" : ""} ${
        is_dark_mode && "dark-mode-text"
      }`}
      style={{
        ...transition,
        cursor: is_link ? "pointer" : "",
        fontFamily: props.font_family ? props.font_family : "Kufam-italic",
        fontSize: props.size,
        fontWeight: props.weight ? props.weight : "700",
        color: props.color,
        width: props.width,
        height: props.height,
        textAlign: props.align ? props.align : "center",
        lineHeight: props.line_height ? props.line_height : "20px",
        marginTop: props.margin_top ? props.margin_top : "none",
        marginRight: props.marginRight,
        position: props.position,
        top: props.top,
        right: props.right,
        marginLeft: props.marginLeft,
        marginBottom: props.marginBottom ? props.marginBottom : "none",
        backgroundColor: props.backgroundColor,
        textDecoration: props.textDecoration,
      }}
      onClick={is_link ? () => on_click() : () => null}
    >
      {text}
    </Typography>
  );
};

export default CustomText;
