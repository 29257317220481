import { Button, Col, Row, Skeleton } from "antd";
import React from "react";
import CustomText from "./custom_text";
import { useTranslation } from "react-i18next";
import { LuEye } from "react-icons/lu";
import {
  change_tamil_font_style,
  format_view_count,
  isEnglish,
} from "../../../helpers/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../routes/my_routes";

const CustomFactCheckCardFirstHalf = (props) => {
  const {
    text,
    image,
    card_height,
    card_width,
    views,
    date,
    perma_link,
    fact_check_list_loading,
    type,
    name,
    class_name,
    class_name_title_card,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const single_fact_check = () => {
    if (type === "article") {
      history.push(`${ROUTES.SINGLE_ARTICLE_PAGE}/${perma_link}.html`);
    } else {
      history.push(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    }
  };

  const is_english = isEnglish(text);
  return (
    <div
      style={{
        position: "relative",
      }}
      className="fact_check_card_mobile_view"
    >
      {fact_check_list_loading ? (
        <Skeleton.Input className="fact-check-top-image-skeleton" active />
      ) : (
        <Row
          className="fact-check-fist-half-row      fact-check-first-half-responsive"
          onClick={single_fact_check}
        >
          <img
            className={class_name ? `${class_name}` : ""}
            style={{
              width: card_width ? `${card_width}` : "110vh",
              height: card_height ? `${card_height}` : "70vh",
              borderRadius: "10px",
              boxShadow: "5px 5px 15px 0px rgba(0, 101, 204, 0.15)",
              cursor: "pointer",
              objectFit: "cover",
            }}
            src={image}
          />

          <Col
            className={class_name_title_card ? `${class_name_title_card}` : ""}
            onClick={single_fact_check}
          >
            <div
              className="fact_check_inside_card_width"
              style={{
                height: "30px",
                width: "10vw",
                margin: "10px 0 0 10px",
              }}
            >
              <CustomText
                class_name={`fact_check_inside_card_title ${
                  is_english
                    ? "bottom_card_language_english"
                    : "bottom_card_language_tamil"
                }`}
                text={text}
                font_family={"Lato"}
                size={"2vh"}
                color={"#FFFFFF"}
                align={"left"}
                weight={500}
              />
            </div>
            <div
              className="fact_check_inside_card_width"
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              <div className="custom_fact_check_views_and_icon">
                <span style={{ marginTop: "5px", color: "red", width: "49px" }}>
                  {format_view_count(views) ?? 0}
                </span>
                <span style={{ marginTop: "3px" }}>
                  <LuEye
                    className="exposing-fakenews-eye-icon"
                    style={{ color: "red" }}
                  />
                </span>
              </div>

              <CustomText
                class_name={"fact_check_inside_card_title"}
                text={`${name} | ${date}`}
                font_family={"Lato"}
                size={"5px"}
                weight={"13vw"}
                color={"#FFFFFF"}
                align={"left"}
              />

              <div className="fact_check_button">
                <Button
                  className="custom-button"
                  style={{
                    backgroundColor: "#140F2D",
                    color: "#FFFFFF",
                    borderRadius: "10px",
                    maxWidth: "10vw",
                    border: "1px solid white",
                    fontSize: "11px",
                  }}
                  onClick={single_fact_check}
                >
                  {type === "article"
                    ? change_tamil_font_style(t("article"), "1.5vh", _)
                    : change_tamil_font_style(
                        t("fact_check_button_text"),
                        "1.5vh",
                        _
                      )}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CustomFactCheckCardFirstHalf;
