import React from "react";
import EditUserProfileDetails from "../components/Edit_profile/edit_user_profile";

const EditUserProfile = () => {
  return (
    <div>
      <EditUserProfileDetails />
    </div>
  );
};

export default EditUserProfile;
