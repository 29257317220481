import React from "react";
import { YOUTURN_NEW_LOGO } from "../../../../../helpers/image_constants";
import { change_tamil_font_style } from "../../../../../helpers/functions";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Form } from "antd";

const SignUpFormItems = (props) => {
  const {
    hide_form,
    handle_key_press,
    password_visible,
    toggle_password_visibility,
    sign_in,
    get_otp_loading,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      {hide_form && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                borderRadius: "9px",
                zIndex: 1,
                objectFit: "cover",
                height: "80px",
              }}
              src={YOUTURN_NEW_LOGO}
              alt="Profile"
              className="youturn_logo_mobile_view_only"
            />
          </div>

          <h2 className="sing_in_text">
            {change_tamil_font_style(t("create_account"), "3vh", _)}
          </h2>

          <Form.Item
            className="margin-bottom"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <input type="text" placeholder="Name" style={{ width: "300px" }} />
          </Form.Item>

          <Form.Item
            className="margin-bottom"
            name="mobile"
            rules={[
              { required: true, message: "Mobile number is required" },
              {
                pattern: /^\d{10}$/,
                message: "Please enter a 10-digit number",
              },
            ]}
          >
            <input
              // type="number"
              placeholder="Mobile"
              style={{ width: "300px" }}
              onKeyPress={handle_key_press}
            />
          </Form.Item>

          <Form.Item
            className="margin-bottom"
            name="email"
            rules={[
              { required: true, message: "Email is required" },
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <input
              type="email"
              placeholder="Email"
              style={{ width: "300px" }}
            />
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              flexDirection: "revert-layer",
              alignItems: "center",
              width: "min-content",
            }}
            className="margin-bottom"
            name="password"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject("Password is required");
                  }
                  if (value.length < 8) {
                    return Promise.reject(
                      "Password must be at least 8 characters long"
                    );
                  }
                  if (
                    !/(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])/.test(value)
                  ) {
                    return Promise.reject(
                      <div>{t("strong_password_rule")}</div>
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <div style={{ position: "relative" }}>
              <input
                type={password_visible ? "text" : "password"}
                placeholder="Password"
                style={{ width: "300px" }}
              />
              {password_visible ? (
                <EyeInvisibleOutlined
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={toggle_password_visibility}
                />
              ) : (
                <EyeOutlined
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={toggle_password_visibility}
                />
              )}
            </div>
          </Form.Item>
          <div
            style={{
              // width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button type="submit">
              {get_otp_loading ? (
                <div className="leap-frog">
                  <div className="leap-frog__dot"></div>
                  <div className="leap-frog__dot"></div>
                  <div className="leap-frog__dot"></div>
                </div>
              ) : (
                t("sign_up")
              )}
            </button>
          </div>

          <div className="view-text">
            <h6 className="sing_in_text">
              {/* {change_tamil_font_style(t("already_have_an_account"), "14px", _)} */}
            </h6>
            <h6
              className="sing_in_text_underline"
              onClick={sign_in}
              style={{ marginLeft: "10px" }}
            >
              {change_tamil_font_style(t("login"), "15px", _)}
            </h6>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpFormItems;
