import React, { useEffect, useRef, useState } from "react";
import SearchResultsHeaderContents from "../components/search_results/search_results_header_contents/search_results_header_contents";
import SearchResultsArticlesDropDown from "../components/search_results/search_results_body_contents/search_results_header_option_drop_down";
import { Row } from "antd";
import { useTranslation } from "react-i18next";
import JoinUsCard from "../components/widgets/join_us_card";
import { useParams } from "react-router";
import { get_search_results_query } from "../../services/redux/slices/graphql/graphql_search_results";
import {
  dynamic_request,
  recent_fact_check_query,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { change_tamil_font_style, retrieveItem } from "../../helpers/functions";
import { debounce } from "lodash";
import RecentlyAdded from "../components/list_of_article_page/recently_added";
import CustomRecentlyAddedCard from "../components/custom/custom_recently_added_card";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import SearchResultStoryCard from "../components/search_results/search_results_body_contents/search_result_story_card";

const SearchResults = () => {
  const { t } = useTranslation();
  const search_string = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const top_ref = useRef(null);
  const { is_dark_mode } = useDarkMode();
  const queryParams = new URLSearchParams(location.search);
  const search_text = queryParams.get("type");
  const language_id = retrieveItem("language");
  const [page_limit, set_page_limit] = useState(8);
  const [result_type, set_result_type] = useState("article");
  const [search_items, set_search_items] = useState([]);

  const { items: search_results_items } =
    useDynamicSelector("get_search_results");

  const { items: get_recent_fact_check_items } = useDynamicSelector(
    "get_recent_fact_checks"
  );

  const recent_fact_check = get_recent_fact_check_items?.slice(0, 5);
  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 800);

  useEffect(() => {
    get_recent_fact_check();
    debouncedHandleTitleClick();
  }, []);

  useEffect(() => {
    get_search_results();
  }, [search_text, page_limit]);

  useEffect(() => {
    get_search_results();
  }, [result_type]);

  useEffect(() => {
    if (search_results_items) {
      set_search_items(search_results_items);
    }
  }, [search_results_items]);

  const get_search_results = () => {
    let key = [{ key: "get_search_results", loading: true }];
    let query = get_search_results_query;
    let variables = {
      search_string: search_text,
      page_limit: page_limit,
      language_id: language_id?.id,
      type: result_type,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_recent_fact_check = () => {
    let key = [{ key: "get_recent_fact_checks", loading: true }];
    let query = recent_fact_check_query;
    let variables = {
      language_id: language_id?.id,
      page_number: 1,
      page_limit: 10,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const load_more_results = () => {
    set_page_limit(page_limit + 8);
  };

  return (
    <div ref={top_ref} className={is_dark_mode ? "content-dark" : ""}>
      <SearchResultsHeaderContents search_text={search_text} />

      <SearchResultsArticlesDropDown set_result_type={set_result_type} />

      <SearchResultStoryCard
        search_items={search_items}
        load_more_results={load_more_results}
      />

      <div className="search_result_recently_added">
        <RecentlyAdded
          height={"100vh"}
          article_list={recent_fact_check}
          type={"fact-check"}
        />
      </div>
      <div className="search_result_recently_added_mobile_carousel">
        <CustomRecentlyAddedCard
          title={change_tamil_font_style(t("recently_added"), "3.5vh", _)}
          list={get_recent_fact_check_items}
          type={"fact-check"}
        />
      </div>
      <Row className="search_result_join_us">
        <JoinUsCard />
      </Row>
    </div>
  );
};

export default SearchResults;
