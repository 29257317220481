import React from "react";
import { useDynamicSelector } from "../../../../services/redux";
import { Empty, Skeleton } from "antd";
import CustomButton from "../../custom/custom_button";
import { useTranslation } from "react-i18next";
import CustomNewsStoryCardVertical from "../../custom/custom_news_story_card_vertical";
import CustomFactCheckCardFirstHalf from "../../custom/custom_fact_check_card_first_half";
import CustomFactCheckRectangleSmallCard from "../../custom/custom_fact_check_rectangle_small_card";
import moment from "moment";
import { split_tamil_english_words } from "../../../../helpers/functions";

const FactCheckVerticalAndSmallCard = (props) => {
  const { t } = useTranslation();
  const {
    custom_fact_check_rectangle_small_card,
    custom_news_story_card_vertical,

    see_more_click,
  } = props;
  const {
    items: fact_check_category_list,
    loading: fact_check_category_list_loading,
    pagination: fact_check_category_pagination,
  } = useDynamicSelector("get_fact_check_query_category");
  const { items: first_half_items, loading: first_half_loading } =
    useDynamicSelector("get_all_fact_check_first_half");
  return (
    <div
      style={{
        display: "flex",
      }}
      className="party_tag_vertical_and_small_card_div"
    >
      {fact_check_category_list_loading
        ? Array.from({ length: 1 }, (_, index) => (
            <Skeleton.Input
              key={index}
              className="fact_check_vertical_card_skeleton"
              active
            />
          ))
        : custom_news_story_card_vertical?.map((item, index) => (
            <div
              style={{
                width: "40%",
              }}
              className="party-tag-news-card-vertical"
            >
              <CustomNewsStoryCardVertical
                reading_time={`${item?.reading_time}`}
                view_count={item ? item?.views : ""}
                // date={
                //   item
                //     ? moment(item.created_date_time).format(`MMM D, YYYY`)
                //     : ""
                // }
                date={
                  item
                    ? moment(
                        item?.published_date_time
                          ? parseInt(item?.published_date_time, 10)
                          : item?.created_date_time
                      ).format(`MMM DD, YYYY`)
                    : ""
                }
                name={item ? item?.staff_profile?.name : ""}
                title={
                  // item?.title?.length > 30
                  //   ? `${item?.title?.slice(0, 30)}...`
                  //   :
                  item?.title
                }
                subtitle={split_tamil_english_words(item?.subtitle, _, 100)}
                src={item?.thumbnail_img}
                perma_link={item ? item?.perma_link : ""}
                type={"fact-check"}
                loading={fact_check_category_list_loading}
              />
            </div>
          ))}

      <div className="fact-check-news-vertical-by-fist-half">
        {first_half_loading
          ? Array.from({ length: 1 }, (_, index) => (
              <Skeleton.Input
                key={index}
                className="fist_half_for_vertical_card_skeleton"
                active
              />
            ))
          : custom_news_story_card_vertical?.map((item, index) => (
              <CustomFactCheckCardFirstHalf
                image={item?.thumbnail_img}
                margin_top={"40vh"}
                text={
                  first_half_items
                    ? split_tamil_english_words(
                        first_half_items?.[0]?.title,
                        _,
                        10
                      )
                    : ""
                }
                views={`${item?.reading_time}`}
                date={
                  item
                    ? moment(item.created_date_time).format(`MMM D, YYYY`)
                    : ""
                }
                id={item?.id || ""}
                perma_link={item?.perma_link || ""}
                fact_check_list_loading={first_half_loading}
                title={
                  item?.title?.length > 20
                    ? `${item?.title?.slice(0, 10)}...`
                    : item?.title
                }
                name={item ? item?.staff_profile?.name : ""}
                class_name={"fact_check_top_image"}
                class_name_title_card={"custom-fact-check-card-first-half"}
              />
            ))}
      </div>

      <div className="party-wise-update-fact-check-small-card">
        {fact_check_category_list_loading ? (
          Array.from({ length: 4 }, (_, index) => (
            <Skeleton.Input
              key={index}
              className="party_wise_small_card_skeleton"
              active
            />
          ))
        ) : custom_fact_check_rectangle_small_card?.length > 0 ? (
          custom_fact_check_rectangle_small_card.map((item, index) => (
            <CustomFactCheckRectangleSmallCard
              key={index}
              src={item?.thumbnail_img}
              title={item?.title}
              // date={
              //   item ? moment(item.created_date_time).format("MMM D, YYYY") : ""
              // }
              date={
                item
                  ? moment(
                      item?.published_date_time
                        ? parseInt(item?.published_date_time, 10)
                        : item?.created_date_time
                    ).format(`MMM DD, YYYY`)
                  : ""
              }
              name={item ? item?.staff_profile?.name : ""}
              perma_link={item?.perma_link}
              type="fact-check"
              loading={fact_check_category_list_loading}
            />
          ))
        ) : (
          <div
            style={{
              width: "60vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Empty description="No data" />
          </div>
        )}
      </div>
      <div className="divider_see_all_button_small_card">
        {(fact_check_category_pagination?.total_count > 0 ||
          fact_check_category_list?.length !==
            fact_check_category_pagination?.total_count) && (
          <CustomButton
            name={t("see_more")}
            width={"250px"}
            on_Click={see_more_click}
          />
        )}
      </div>
    </div>
  );
};

export default FactCheckVerticalAndSmallCard;
