import { Skeleton } from "antd";
import React from "react";
import CustomText from "../custom/custom_text";
import CustomNewsArticle from "../custom/custom_news_article";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../services/redux";
import moment from "moment";

const UserContributionRecommendedStoriesCard = (props) => {
  const { t } = useTranslation();

  const {
    items: get_user_contribution_trending_stories_items,
    loading: get_user_contribution_trending_stories_loading,
  } = useDynamicSelector("get_user_trending_results");
  return (
    <div className="user_contribution_recommended_stories_mobile_view">
      <div className="user_contribution_stories_second_div">
        <div style={{ width: "100%" }}>
          <CustomText
            text={t("recommended_stories")}
            font_family={"Kufam"}
            color={"#140F2D"}
            size={"2.4vh"}
            align={"start"}
            weight={"700"}
          />
        </div>
        {get_user_contribution_trending_stories_loading
          ? Array.from({ length: 3 }, (_, index) => (
              <Skeleton.Input
                key={index}
                className="fact-check-list-three-trending-skeleton"
                active
              />
            ))
          : get_user_contribution_trending_stories_items
              ?.slice(0, 3)
              ?.map((item, index) => (
                <CustomNewsArticle
                  key={index}
                  view_count={item?.views}
                  reading_time={item?.reading_time}
                  date={moment(item?.created_date_time).format("MMM DD,YYYY")}
                  header_color={"#0065cc"}
                  name={item?.user_profile?.name}
                  article={item?.title}
                  news_article_row_width={"325px"}
                  news_article_content_width={""}
                  type={item?.record_type}
                  perma_link={item?.perma_link}
                  thumbnail_img={item?.thumbnail_img}
                  loading={get_user_contribution_trending_stories_loading}
                />
              ))}
      </div>
    </div>
  );
};

export default UserContributionRecommendedStoriesCard;
