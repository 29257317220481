import React, { useEffect } from "react";
import CustomText from "../custom/custom_text";
import { Skeleton } from "antd";
import CustomFactCheckCategories from "../custom/custom_fact_check_categories";
import {
  retrieveItem,
  split_tamil_english_words,
} from "../../../helpers/functions";
import moment from "moment";
import { BROKEN_IMAGE } from "../../../helpers/image_constants";
import {
  dynamic_request,
  query_get_all_related_fact_check_list,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const SingleFactCheckRelatedFactCheck = () => {
  let current_language = retrieveItem("language");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { category_tags } = useDynamicSelector("get_fact_check_by_perma_link");

  const { items: all_fact_check_list, loading: related_fact_check_loading } =
    useDynamicSelector("get_related_fact_check_list");

  const slice_four_fact_check = all_fact_check_list?.slice(1, 5);

  useEffect(() => {
    get_all_fact_check_list();
  }, []);

  const get_all_fact_check_list = () => {
    let key = [{ key: "get_related_fact_check_list", loading: true }];
    let query = query_get_all_related_fact_check_list;
    let variables = {
      page_number: 1,
      page_limit: 20,
      json: {
        category_tag: category_tags?.[0]?.id,
        language_id: current_language?.id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <div className="single-fact-check-related-fact-check-col">
      <div className="related-fact-check-title">
        <CustomText
          class_name="single-fact-check-related-fact-check-title"
          text={t("related_fact_checks")}
          font_family={"Lato"}
          align={"left"}
          weight={700}
        />
      </div>

      <div className="laptop-fact-check-categories">
        {related_fact_check_loading
          ? Array.from({ length: 4 }, (_, index) => (
              <Skeleton.Input
                key={index}
                className="six-categories-card-second-div-for-skeleton"
                active
              />
            ))
          : slice_four_fact_check?.map((fact_check) => (
              <CustomFactCheckCategories
                width={"23vw"}
                category_image={
                  fact_check?.thumbnail_img
                    ? fact_check?.thumbnail_img
                    : BROKEN_IMAGE
                }
                title={
                  fact_check?.title?.length > 40
                    ? split_tamil_english_words(fact_check?.title, _, 40)
                    : fact_check?.title
                }
                sub_title={
                  fact_check?.subtitle?.length > 40
                    ? `${fact_check?.subtitle?.slice(0, 30)}...`
                    : fact_check?.subtitle
                }
                created_date={
                  fact_check
                    ? moment(fact_check.created_date_time).format(`MMM D, YYYY`)
                    : ""
                }
                perma_link={fact_check?.perma_link}
                type={"related-fact-check"}
                name={fact_check?.staff_profile?.name}
              />
            ))}
      </div>
    </div>
  );
};

export default SingleFactCheckRelatedFactCheck;
