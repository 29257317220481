import React, { useEffect, useState } from "react";
import { Form, Upload, Button } from "antd";
import { YOU_TURN_LOGO_BLUE } from "../../../helpers/image_constants";
import CustomText from "../custom/custom_text";

import {
  mutation_update_user_profile,
  quey_get_user_profile,
} from "../../../services/redux/slices/graphql/graphql_file_upload";
import { fileQuery } from "../system/ui/antd_form/antd_form";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { PiUploadSimpleFill } from "react-icons/pi";
import { Box } from "native-base";
import { PlusOutlined } from "@ant-design/icons";
import { getUUID, retrieveItem } from "../../../helpers/functions";
import { QueryRequest } from "@services/apollo/api_service";
import axios from "axios";

import toast from "react-hot-toast";
import { TbCameraUp } from "react-icons/tb";
import { useDarkMode } from "../contexts/dark_mode_provider";
let fileUploadresponse;

const EditProfileUpload = ({ set_initial_values, setImageUrl }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const [initial_values, set_initial_values] = useState({});

  const [uuid, setUUID] = useState();
  const [fileList, setFileList] = useState([]);
  const [headers, setHeaders] = useState();
  const { is_dark_mode } = useDarkMode();
  const user_id = retrieveItem("user_id");

  const item = useDynamicSelector("get_user");

  const { status } = useDynamicSelector("get_upload_url");
  const {
    status: create_status,
    error: create_error,
    loading: update_loading,
  } = useDynamicSelector("update_user");

  useEffect(() => {
    if (status === "Success") {
      update_user_profile();
    }
  }, []);

  useEffect(() => {
    if (create_status === "Success") {
      if (user_id) {
        get_user_profile();
      }
      toast.success("Profile successfully updated.");
      form.resetFields();
    } else if (create_error) {
      toast.error("Profile upload failed");
    }
    dispatch(dynamic_clear("update_user"));
  }, [create_status, create_error, form, status]);

  useEffect(() => {
    if (item?.id) {
      const { user_profile, staff_profile, email } = item;

      const profile_pic =
        (user_profile?.profile_pic ?? staff_profile?.profile_pic) || "";

      form.setFieldsValue({
        profile_pic,
      });

      set_initial_values({
        profile_pic,
      });
    }
  }, [item]);

  const get_user_profile = (val) => {
    let key = [{ key: "get_user", loading: true }];
    let query = quey_get_user_profile;
    let variables = { id: user_id };
    dispatch(dynamic_request(key, query, variables));
  };

  const handleSubmitPhoto = (value) => {
    let key = [{ key: "update_user", loading: true }];
    let query = mutation_update_user_profile;
    let variables = {
      data: {
        profile_pic: value,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const action = fileUploadresponse?.data?.get_upload_url;

  const uploadButton = (
    <div
      onClick={() => {
        setUUID(getUUID());
      }}
    >
      <div>
        <PlusOutlined style={{ color: is_dark_mode ? "white" : "" }} />
      </div>
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const handleBeforeUpload = async ({ name, type }) => {
    const hasRepeatedExtension = /\.[^.]*\.[^.]+$/.test(name);

    const validExtensions = ["png", "jpg", "jpeg"];
    const isValidExtension = validExtensions.some((ext) => name.endsWith(ext));
    if (!hasRepeatedExtension && isValidExtension) {
      let fileupload = {
        type: "document",
        fileName: `${getUUID()}.${name?.split(".")?.[1]}`,
      };
      fileUploadresponse = await QueryRequest(fileQuery, fileupload);
      // toast.success("Document uploaded successfully");
    } else {
      toast.error("Invalid file format");
    }
  };
  const handleFileChanged = ({ file }) => {
    // if (file.status === "removed") {
    //   setFileList([]);
    // } else if (file.status === "uploading") {
    //   setFileList([file]);
    // } else if (file.status === "done") {
    //   const newFile = {
    //     ...file,
    //     url: fileUploadresponse?.data?.get_upload_url?.url,
    //   };
    //   setFileList([newFile]);
    // }
  };
  const handleUpload = async ({ onSuccess, onError, file }) => {
    axios
      .put(fileUploadresponse?.data?.get_upload_url?.url, file, {
        headers: { "Content-Type": file?.type },
      })

      .then(async (res) => {
        if (res.status.toString() === "200") {
          file.url =
            fileUploadresponse?.data?.get_upload_url?.url.split("?")[0];
          onSuccess(null, file);
          setImageUrl(file?.url);
          handleSubmitPhoto(file?.url);
        } else {
          onError(err, err, file);
        }
      })
      .catch((err) => {
        onError(err, err, file);
      });
  };

  return (
    <>
      {/* <img
        width={70}
        height={70}
        src={initial_values?.profile_pic || YOU_TURN_LOGO_BLUE}
        style={{ objectFit: "cover", borderRadius: 35 }}
      /> */}

      {/* <div className="edit-profile-update-pic-div"> */}
      <Box>
        <Upload
          maxCount={1}
          action={action}
          headers={headers}
          // fileList={fileList || []}
          // onRemove={handleDelete}
          customRequest={(e) => handleUpload(e)}
          beforeUpload={(args) => handleBeforeUpload(args)}
          onChange={(e) => handleFileChanged(e)}
          showUploadList={false}
        >
          <Button
            type="link"
            style={{
              cursor: "pointer",
              flexDirection: "row",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <TbCameraUp
              size={20}
              style={{
                color: is_dark_mode ? "white" : "black",
                cursor: "pointer",
              }}
            />
            {/* <CustomText
                // onClick={delete_click}
                class_name={"edit-profile-text"}
                text={"Update Photo"}
                font_family={"Lato"}
                align={"left"}
              /> */}
          </Button>
        </Upload>
      </Box>
      {/* </div> */}
    </>
  );
};

export default EditProfileUpload;
