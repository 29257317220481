import React, { useEffect, useRef, useState } from "react";
import UserProfileTitle from "../components/user_profile/user_profile_title";
import DonationList from "../components/user_profile/donation_list";
import { dynamic_request, useDynamicSelector } from "../../services/redux";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../helpers/functions";
import { debounce } from "lodash";
import CustomText from "../components/custom/custom_text";
import moment from "moment";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import { get_my_donation_query } from "../../services/redux/slices/graphql/graphql_user_profile";

const UserProfileDonationList = () => {
  const dispatch = useDispatch();
  const session_id = retrieveItem("session_id");
  const top_ref = useRef(null);
  const { is_dark_mode } = useDarkMode();

  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const { items: my_donation, pagination: my_donation_pagination } =
    useDynamicSelector("get_all_donations");

  const currentDate = moment();
  const last_three_months_donations = my_donation?.filter((donation) => {
    const donationDate = moment(donation?.created_date_time);
    return currentDate.diff(donationDate, "months") < 3;
  });

  useEffect(() => {
    if (session_id) {
      get_all_my_donation();
    }
  }, [session_id, page_number, page_limit]);
  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);

  const get_all_my_donation = () => {
    let key = [{ key: "get_all_donations", loading: true }];
    let query = get_my_donation_query;
    let variable = {
      page_limit,
      page_number,
    };
    dispatch(dynamic_request(key, query, variable));
  };

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);

  return (
    <div ref={top_ref} className={is_dark_mode ? "content-dark" : ""}>
      <UserProfileTitle type={"Donations"} title={""} />
      <CustomText
        class_name={"user_profile_titles"}
        text={"Donations"}
        font_family={"Lato"}
        size={"24px"}
        color={"#140f2d"}
        align={"left"}
        weight={"700"}
      />
      <div className="user_claim_donation_list">
        <DonationList
          donation_list={last_three_months_donations}
          donation_pagination={my_donation_pagination}
          set_page_number={set_page_number}
          set_page_limit={set_page_limit}
          page_number={page_number}
        />
      </div>
    </div>
  );
};

export default UserProfileDonationList;
