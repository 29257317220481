import {
  REPORT_ICON_COLOR,
  SHARE_ICON_COLOR,
} from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import React from "react";
import { AiFillClockCircle } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import { format_views_count } from "../../../../helpers/functions";
import { useTranslation } from "react-i18next";

const PreviewReportShareContainerMobileView = (props) => {
  const { views, reading_time } = props;
  const { t } = useTranslation();
  const article = useDynamicSelector("get_article");

  return (
    <div className="report_share_container_mobile_view">
      <div className="report_share_first_column_container">
        <div className="single_article_icons">
          <LuEye color="red" />
          <text className="single_article_view_count">
            {format_views_count(views)}
          </text>
        </div>
        <div className="single_article_icons">
          <AiFillClockCircle color="gray" />
          <text className="single_article_reading_time">
            {reading_time} {t("min")}
          </text>
        </div>
      </div>
      <div className="report_share_second_column_container">
        <img style={{ width: "20px" }} src={REPORT_ICON_COLOR} />
        <img style={{ width: "20px" }} src={SHARE_ICON_COLOR} />
      </div>
    </div>
  );
};

export default PreviewReportShareContainerMobileView;
