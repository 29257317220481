import React from "react";
import CustomText from "../custom/custom_text";
import { Divider, Row } from "antd";
import { ROUTES } from "../../routes/my_routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDynamicSelector } from "../../../services/redux";
import { useDarkMode } from "../contexts/dark_mode_provider";

const UserProfileBookMarks = (props) => {
  const { card_title, type } = props;
  const history = useHistory();
  const { is_dark_mode } = useDarkMode();

  const {
    article_count,
    fact_check_count,
    saved_fact_check_count,
    saved_article_count,
  } = useDynamicSelector("get_dashboard_count");

  const on_click_fact_check = (value) => {
    if (type === "post") {
      history.push(`${ROUTES.USER_PROFILE_LIST}/${value}`);
    } else if (type === "book-mark") {
      history.push(`${ROUTES.USER_BOOK_MARKS}/${"fact_check"}`);
    }
  };

  const on_click_article = (value) => {
    if (type === "post") {
      history.push(`${ROUTES.USER_PROFILE_LIST}/${value}`);
    } else if (type === "book-mark") {
      history.push(`${ROUTES.USER_BOOK_MARKS}/article`);
    }
  };

  return (
    <div
      className="user_profile_donation_card"
      style={{ backgroundColor: is_dark_mode ? "#2D2D2D" : "#FFFF" }}
    >
      <div className="profile_card_title_with_icon">
        <CustomText
          text={card_title}
          font_family={"Lato"}
          size={"13px"}
          color={"#140F2D"}
          align={"left"}
          weight={"700"}
        />
      </div>
      <Divider className="user_profile_divider" />

      <Row className="bookmark_count_division">
        <div
          className="user_article_or_fact_check_count"
          onClick={() => on_click_fact_check("my-factcheck")}
        >
          <div className="count_circle_out_lined">
            <CustomText
              text={
                type === "post"
                  ? fact_check_count
                  : type === "book-mark"
                  ? saved_fact_check_count
                  : ""
              }
              font_family={"Lato"}
              size={"30px"}
              color={"#140F2D"}
              align={"left"}
              weight={"700"}
            />
          </div>
          <div className="user_article_count">
            <CustomText
              class_name={"user_profile_fact_check_text"}
              text={"Fact checks"}
              font_family={"Lato"}
              size={"12px"}
              color={"#140F2D"}
              align={"left"}
              weight={"500"}
            />
          </div>
        </div>
        <div
          className="user_article_or_fact_check_count"
          onClick={() => on_click_article("my-article")}
        >
          <div className="count_circle_out_lined">
            <CustomText
              class_name={""}
              text={
                type === "post"
                  ? article_count
                  : type === "book-mark"
                  ? saved_article_count
                  : ""
              }
              font_family={"Lato"}
              size={"30px"}
              color={"#140F2D"}
              align={"left"}
              weight={"700"}
            />
          </div>
          <div className="user_article_count">
            <CustomText
              class_name={"user_profile_fact_check_text"}
              text={"Articles"}
              font_family={"Lato"}
              size={"12px"}
              color={"#140F2D"}
              align={"left"}
              weight={"500"}
            />
          </div>
        </div>
      </Row>
    </div>
  );
};
export default UserProfileBookMarks;
