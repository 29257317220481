import React from "react";

const CustomSocialMedia = (props) => {
  const { media_name, logo, marginLeft, on_click } = props;
  return (
    // <div className="social_media_image_with_name">
    <div className="custom_social_media">
      <img
        width={30}
        src={logo}
        alt="Logo"
        onClick={on_click}
        className="social-media-logo"
      />
      <div className="media_name">
        <text
          style={{
            color: "white",
            fontSize: "15px",
          }}
          onClick={on_click}
        >
          {media_name}
        </text>
      </div>
    </div>
    // </div>
  );
};

export default CustomSocialMedia;
