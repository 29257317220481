import React from "react";
import CustomSocialMedia from "../custom/custom_social_media";
import {
  FACEBOOK_COLOR,
  INSTAGRAM_COLOR,
  LINKEDIN_COLOR,
  SHARE_CHAT,
  TELEGRAM_COLOR,
  THREADS_ICON,
  TWITTER_BLACK_BACKGROUND_IMAGE,
  WHATS_APP_LOGO,
  YOUTUBE_COLOR,
} from "../../../helpers/image_constants";
import { useDarkMode } from "../contexts/dark_mode_provider";

const SocialMediaDummy = (props) => {
  const { width } = props;
  const { is_dark_mode } = useDarkMode();

  return (
    <div>
      <div
        style={{
          width: width ? width : "30%",
        }}
        className={`social_media_list_card  ${
          is_dark_mode ? "content-dark" : ""
        }`}
      >
        <text className="social_media_list_title">Stay Connected</text>
        <div className="social_media_logo">
          <div className="social_media_image_with_name">
            <CustomSocialMedia media_name={"Facebook"} logo={FACEBOOK_COLOR} />
          </div>
          <div className="social_media_image_with_name">
            <CustomSocialMedia
              media_name={"X (Twitter)"}
              logo={TWITTER_BLACK_BACKGROUND_IMAGE}
            />
          </div>
          <div className="social_media_image_with_name">
            <CustomSocialMedia
              media_name={"Instagram"}
              logo={INSTAGRAM_COLOR}
            />
          </div>
          <div className="social_media_image_with_name">
            <CustomSocialMedia media_name={"YouTube"} logo={YOUTUBE_COLOR} />
          </div>
          <div className="social_media_image_with_name">
            <CustomSocialMedia media_name={"LinkedIn"} logo={LINKEDIN_COLOR} />
          </div>
          <div className="social_media_image_with_name">
            <CustomSocialMedia media_name={"Telegram"} logo={TELEGRAM_COLOR} />
          </div>

          <div className="social_media_image_with_name">
            <CustomSocialMedia media_name={"WhatsApp"} logo={WHATS_APP_LOGO} />
          </div>
          <div className="social_media_image_with_name">
            <CustomSocialMedia media_name={"Share chat"} logo={SHARE_CHAT} />
          </div>
          <div className="social_media_image_with_name">
            <CustomSocialMedia media_name={"Threads"} logo={THREADS_ICON} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaDummy;
