import React from "react";
import { Carousel } from "antd";
import CustomText from "../custom/custom_text";
import { LuEye } from "react-icons/lu";
import { AiFillClockCircle } from "react-icons/ai";
import moment from "moment";
import {
  navigate,
  split_tamil_english_words,
} from "../../../helpers/functions";
import { ROUTES } from "../../routes/my_routes";
import { useTranslation } from "react-i18next";

const RecommendedStoriesCarousel = (props) => {
  const { list, type } = props;
  const { t } = useTranslation();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 2,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  const on_click = (value) => {
    if (value && value?.fact_check_id === null) {
      navigate(
        `${ROUTES.SINGLE_ARTICLE_PAGE}/${
          value && value?.fact_check_id
            ? value?.fact_check?.perma_link
            : value?.article?.perma_link
        }.html`
      );
    }

    if (value && value?.article_id === null) {
      navigate(
        `${ROUTES.SINGLE_FACT_CHECK}/${
          value && value?.fact_check_id
            ? value?.fact_check?.perma_link
            : value?.article?.perma_link
        }.html`
      );
    }
  };
  return (
    <div
      className="search_result_recently_added_carousel"
      style={{
        width: "100%",
        height: "46vh",
      }}
    >
      <Carousel
        infinite={false}
        dots={false}
        slidesToShow={1.5}
        slidesToScroll={1}
        className="carousel-recently-added"
      >
        {list &&
          list?.length !== 0 &&
          list?.map((item, index) => (
            <div className="carousel_recently_added">
              <div
                className="recently_added_carousel"
                onClick={() => on_click(item)}
              >
                <img
                  className="recently_added_thumbnail_image"
                  src={
                    item &&
                    (item?.fact_check_id
                      ? item?.fact_check?.thumbnail_img
                      : item?.article?.thumbnail_img)
                  }
                />

                <div className="search_result_name_and_view_icon">
                  <div className="recently_added_author_name_and_date">
                    <CustomText
                      line_height={"2vh"}
                      text={
                        item?.fact_check_id
                          ? item.fact_check?.staff_profile?.name
                          : item?.article?.staff_profile?.name
                      }
                      size={"2vh"}
                      weight={"400"}
                      align={"left"}
                      font_family={"Lato"}
                      color={"#61666D"}
                    />
                  </div>
                  <div className="search_result_view_and_clock_icon_div">
                    <div className="search_result_view_eye_icon">
                      <LuEye style={{ color: "#E84756" }} />
                    </div>
                    <div className="search_result_view_count_text">
                      <CustomText
                        text={
                          item &&
                          (item?.fact_check_id
                            ? item?.fact_check?.views
                            : item?.article?.views)
                        }
                        size={"12px"}
                        color={"#E84756"}
                        weight={"400"}
                        font_family={"Lato"}
                        align={"left"}
                      />
                    </div>
                  </div>
                </div>

                <div className="search_result_date_and_clock_icon">
                  <div className="recently_added_author_name_and_date">
                    <CustomText
                      line_height={"2vh"}
                      text={moment(
                        item &&
                          (item?.fact_check_id
                            ? item?.fact_check?.created_date_time
                            : item?.article?.created_date_time)
                      ).format("MMM DD, YYYY")}
                      size={"2vh"}
                      weight={"400"}
                      align={"left"}
                      font_family={"Lato"}
                      color={"#61666D"}
                    />
                  </div>
                  <div className="search_result_view_and_clock_icon_div">
                    <div className="search_result_view_eye_icon">
                      <AiFillClockCircle style={{ color: "#61666D" }} />
                    </div>

                    <div className="search_result_reading_time_text">
                      <CustomText
                        text={`${
                          item?.fact_check_id
                            ? item?.fact_check?.reading_time
                            : item?.article?.reading_time
                        } ${t("min")}`}
                        size={"12px"}
                        color={"#61666D"}
                        weight={"400"}
                        font_family={"Lato"}
                        align={"left"}
                      />
                    </div>
                  </div>
                </div>

                <div className="recently_added_title">
                  <CustomText
                    line_height={"2.8vh"}
                    text={split_tamil_english_words(
                      item &&
                        (item?.fact_check_id
                          ? item?.fact_check?.title
                          : item?.article?.title),
                      _,
                      10
                    )}
                    weight={"600"}
                    align={"left"}
                    font_family={"Lato"}
                    color={"#140F2D"}
                  />
                </div>
              </div>
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default RecommendedStoriesCarousel;
