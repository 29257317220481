import React, { useEffect, useState } from "react";
import CustomAddButton from "../custom/custom_add_button";
import AdminFactCheckList from "./admin_fact_check/admin_fact_check_list";
import { useTranslation } from "react-i18next";
import AdminDetails from "./admin_article/admin_details";
import { HStack, VStack } from "native-base";
import PageTitle from "../system/page_title/page_title";
import {
  dynamic_request,
  get_all_languages_query,
  query_get_all_category,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import {
  get_all_approval_fact_check_query,
  get_all_editors_fact_check_query,
  get_all_fact_check_query,
  get_all_my_fact_check_query,
  get_all_published_fact_check_query,
  mutation_delete_fact_check,
} from "../../../services/redux/slices/graphql/graphql_fact_check";
import CustomText from "../custom/custom_text";
import FactCheckDeleteModal from "./admin_article/admin_article_form/fact_check_delete_modal";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../routes/my_routes";
import {
  navigate,
  navigateWithProps,
  retrieveItem,
} from "../../../helpers/functions";
import { DatePicker, Input, Select } from "antd";
import {
  fact_check_status,
  getDefaultLanguageId,
} from "../../../helpers/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AdminFactCheck = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  let new_default_language_id = location?.props_state?.current_language_id_new;
  let back_button_true_state = location?.props_state?.back_button_state;
  const [new_back_button_state, set_back] = useState(back_button_true_state);

  const new_default_language_name =
    location?.props_state?.back_button_default_language;

  const [new_default_language_state, set_default_language_state] = useState(
    new_default_language_id
  );

  const new_fact_check_type = location?.props_state?.fact_check_type_location;

  const [fact_check_modal_open, set_fact_check_modal_open] = useState(false);
  const [modal_title, set_modal_title] = useState("");
  const [type, set_type] = useState("");
  const [selected_item, set_selected_item] = useState();
  const [delete_modal_visible, set_delete_modal_visible] = useState(false);
  const [fact_check_type, set_fact_check_type] = useState(
    new_fact_check_type || "my_fact_check"
  );

  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [search_text, set_search_value] = useState("");
  const [from_date, set_from_date] = useState("");
  const [to_date, set_to_date] = useState("");

  const [language_id, set_language] = useState("" || new_default_language_id);

  const [back_button_click, set_back_button_click] = useState(false);
  const [status_option, set_status_option] = useState();
  const [default_language, set_default_language] = useState();
  const history = useHistory();
  const { Search } = Input;
  const { RangePicker } = DatePicker;
  const role = retrieveItem("role");

  const { items: system_languages } = useDynamicSelector(
    "get_all_system_languages"
  );

  let updated_languages;

  if (Array.isArray(system_languages)) {
    updated_languages = [{ id: "", name: "All" }, ...system_languages];
  } else {
    console.log("No system languages available");
  }
  const find_all = updated_languages?.find(
    (language) => language.name === "All"
  );

  const status = fact_check_status[`${role}_${fact_check_type}`] || [];

  useEffect(() => {
    get_all_languages();
  }, []);

  useEffect(() => {
    if (new_default_language_id) {
      let selected_lang = updated_languages.find(
        (x) => x.id === new_default_language_id
      );
      set_default_language({
        key: selected_lang?.id,
        value: selected_lang?.id,
        children: selected_lang?.name,
      });
    }
  }, [new_default_language_id]);
  const create_fact_check = () => {
    set_modal_title("add");
    set_selected_item({});
    navigate(ROUTES.ADMIN_FACT_CHECK_DETAILS);
  };

  const handle_fact_check_modal_close = () => {
    set_fact_check_modal_open(false);
  };

  const get_all_my_fact_checks = (page_limit, page_number) => {
    let key = [{ key: "get_all_my_fact_check", loading: true }];
    let query = get_all_my_fact_check_query;
    let variables = {
      page_limit,
      page_number,
      language_id:
        default_language === "All"
          ? ""
          : back_button_true_state
          ? new_default_language_state === ""
            ? language_id
            : new_default_language_state
          : language_id,
      search_string: search_text,
      from_date: from_date,
      to_date: to_date,
      status: status_option,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_user_fact_check = (page_limit, page_number) => {
    let key = [{ key: "get_all_previleged_user_fact_check", loading: true }];
    let query = get_all_approval_fact_check_query;
    let variables = {
      page_limit,
      page_number,
      json: {
        language_id:
          default_language === "All"
            ? ""
            : back_button_true_state
            ? new_default_language_state === ""
              ? language_id
              : new_default_language_state
            : language_id,
        title: search_text,
        from_date: from_date,
        to_date: to_date,
        status: status_option,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_editors_fact_check = (page_limit, page_number) => {
    let key = [{ key: "get_all_editor_fact_check", loading: true }];
    let query = get_all_editors_fact_check_query;
    let variables = {
      page_limit,
      page_number,
      json: {
        language_id:
          default_language === "All"
            ? ""
            : new_back_button_state
            ? new_default_language_state === ""
              ? language_id
              : new_default_language_state
            : language_id,

        search_string: search_text,
        from_date: from_date,
        to_date: to_date,
        status: status_option,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_fact_checks = (page_limit, page_number) => {
    let key = [{ key: "get_all_published_fact_check", loading: true }];
    let query = get_all_published_fact_check_query;
    let variables = {
      page_limit,
      page_number,
      json: {
        language_id:
          default_language === "All"
            ? ""
            : back_button_true_state
            ? new_default_language_state === ""
              ? language_id
              : new_default_language_state
            : language_id,
        search_string: search_text,
        from_date: from_date,
        to_date: to_date,
        status: status_option,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const onSearch = (e) => {
    let search_value = e?.target?.value;
    if (search_value?.length >= 0) {
      set_search_value(search_value);
      set_page_number(1);
    }
  };

  const on_change_language = (values, type) => {
    set_default_language_state("");
    back_button_true_state = false;
    set_back(false);
    set_default_language(type);
    set_language(values);
    set_type(type);
  };

  const get_fact_check__type = (value) => {
    set_fact_check_type(value);
  };

  const set_date_range = (dateString, date) => {
    set_from_date(date[0]);
    set_to_date(date[1]);
  };

  const on_change = (value) => {
    set_status_option(value);
  };

  return (
    <div>
      <div
        style={{
          width: "95%",
          display: "flex",
          justifyContent: "flex-start",
          justifyContent: "space-between",
          marginTop: "20px",
          marginLeft: "23px",
        }}
      >
        <PageTitle title={"Fact Check"} />
      </div>
      <VStack style={{ padding: "30px" }}>
        <HStack justifyContent={"flex-end"} mb={"15px"} space={"10px"}>
          <Select
            style={{ width: "200px" }}
            onChange={get_fact_check__type}
            defaultValue={
              new_fact_check_type ? new_fact_check_type : "my_fact_check"
            }
          >
            <Option value="my_fact_check">My Fact check</Option>
            <Option value="user_fact_check">User contribution</Option>
            {role === "Admin" && (
              <>
                <Option value="editors_fact_check">Editor's Fact check</Option>
                <Option value="all_fact_check">Published Fact check</Option>
              </>
            )}
          </Select>
          {fact_check_type != "all_fact_check" && (
            <Select
              onChange={on_change}
              style={{ width: "150px" }}
              placeholder="Status"
              allowClear
            >
              {status?.map((option, index) => (
                <Select.Option key={option.id} value={option.id}>
                  {option?.name}
                </Select.Option>
              ))}
            </Select>
          )}

          <div style={{ width: "160px" }}>
            <RangePicker format="YYYY-MM-DD" onChange={set_date_range} />
          </div>
          <Search
            enterButton={true}
            onSearch={onSearch}
            allowClear={true}
            onChange={onSearch}
            placeholder="Search"
            style={{ width: "250px", backgroundColor: "white" }}
            className="admin-list-search"
          />
          <Select
            style={{ width: "100px" }}
            onChange={on_change_language}
            // defaultValue={default_language}
            defaultValue={new_default_language_name || "All"}
          >
            {updated_languages?.map((option, index) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
          {fact_check_type === "my_fact_check" && (
            <CustomAddButton
              button_name={t("add_fact_check")}
              onclick_button={create_fact_check}
            />
          )}
        </HStack>
        <AdminFactCheckList
          set_modal_title={set_modal_title}
          set_fact_check_modal_open={set_fact_check_modal_open}
          handle_close={handle_fact_check_modal_close}
          set_selected_item={set_selected_item}
          get_all_my_fact_checks={get_all_my_fact_checks}
          set_delete_modal_visible={set_delete_modal_visible}
          type={type}
          language_id={language_id}
          fact_check_type={fact_check_type}
          get_all_user_fact_check={get_all_user_fact_check}
          get_editors_fact_check={get_editors_fact_check}
          get_all_fact_checks={get_all_fact_checks}
          set_page_limit={set_page_limit}
          set_page_number={set_page_number}
          set_fact_check_type={set_fact_check_type}
          page_limit={page_limit}
          page_number={page_number}
          search_text={search_text}
          from_date={from_date}
          to_date={to_date}
          default_language={default_language}
          new_default_language_id={new_default_language_id}
          set_back_button_click={set_back_button_click}
          set_default_language={set_default_language}
          status_option={status_option}
        />
      </VStack>

      <FactCheckDeleteModal
        set_delete_modal_visible={set_delete_modal_visible}
        delete_modal_visible={delete_modal_visible}
        selected_item={selected_item}
      />
    </div>
  );
};

export default AdminFactCheck;
