import React from "react";
import CustomText from "../custom/custom_text";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";

const UserProfileTitle = (props) => {
  const { type, title } = props;

  return (
    <div className="user_profile_title_card">
      <div className="user_profile_bread_crumbs">
        <CustomBreadCrumbs
          pages={type ? `Home/ Profile/ ${type ? type : ""}` : "Home/ Profile"}
        />
      </div>
      <CustomText
        text={title}
        font_family={"Lato"}
        size={"80px !important"}
        color={"#FFFFFF"}
        align={"left"}
        weight={"700"}
      />
    </div>
  );
};

export default UserProfileTitle;
