import { Button, Col, Image, Row } from "antd";
import React from "react";
import {
  MIC_WHITE_IMAGE,
  NEW_MIC_IMAGE,
} from "../../../helpers/image_constants";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { useTranslation } from "react-i18next";

const SingleFactCheckReportButton = ({ report_click, margin_top }) => {
  const { is_dark_mode } = useDarkMode();
  const { t } = useTranslation();

  return (
    <Row
      style={{
        width: "90%",
        display: "flex",
        flexDirection: "row",
        marginTop: margin_top ? margin_top : "",
        alignItems: "end",
        height: "-webkit-fill-available",
      }}
    >
      <Col
        style={{
          display: "flex",
          flexDirection: "row",
          width: "58%",
        }}
      >
        <Image
          className="single-fact-check-mick-image"
          visible={false}
          preview={false}
          src={is_dark_mode ? MIC_WHITE_IMAGE : NEW_MIC_IMAGE}
        />
      </Col>
      <Col
        style={{
          width: "40%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "30%",
        }}
      >
        <Button
          className="single-fact-check-report-button"
          style={{
            backgroundColor: "#e84756",
            border: "transparent",
            color: "white",
            width: "20vw",
            height: "40px",
            borderRadius: "5px",
          }}
          onClick={report_click}
        >
          <text
            style={{ fontSize: "14px" }}
            className="single-fact-check-report-card-second-text"
          >
            {t("report")}
          </text>
        </Button>
      </Col>
    </Row>
  );
};

export default SingleFactCheckReportButton;
