import { gql } from "@apollo/client";

export const mutation_create_party_tag = gql`
  mutation create_party_tag($json: party_tag_input) {
    create_party_tag(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_party_tag = gql`
  mutation update_party_tag($id: String, $json: party_tag_input) {
    update_party_tag(id: $id, json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_party_tag = gql`
  mutation delete_party_tag($id: String) {
    delete_party_tag(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_all_party_tag = gql`
  query get_all_party_tag(
    $page_number: Int
    $page_limit: Int
    $language_id: String
    $search_string: String
  ) {
    get_all_party_tag(
      page_number: $page_number
      page_limit: $page_limit
      language_id: $language_id
      search_string: $search_string
    ) {
      items {
        id
        name
        is_deleted
        language_id
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const query_get_party_tag = gql`
  query get_all_party_tag($page_number: Int, $page_limit: Int) {
    get_party_tag: get_all_party_tag(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        is_deleted
        fact_checks {
          id
          staff_profile_id
          user_profile_id

          title
          subtitle
          content
          spread_news
          short_story
          source
          status
          is_fact
          reading_time
          likes
          views
          comments_count
          is_reported
          is_deleted
          is_active
          created_date_time
          party_tags {
            id
            name
            is_deleted
            fact_checks {
              id

              title
              subtitle
            }
          }
          error {
            status_code
            message
          }
        }
      }
    }
  }
`;
