import { gql } from "@apollo/client";

export const get_all_user_claims_query = gql`
  query get_user_claims($json: get_all_user_claim_input) {
    get_user_claims(json: $json) {
      items {
        id
        topic
        ref_url
        description
        status
        created_date_time
        mobile
        attachment
        name
        system_user {
          name
          mobile
        }
        progressed_staff {
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_user_claim_query = gql`
  query get_user_claim($json: get_user_claim_input) {
    get_user_claim(json: $json) {
      id
      topic
      ref_url
      description
      status
      created_date_time
      error {
        message
      }
    }
  }
`;

export const create_user_claim_query = gql`
  mutation create_user_claim($json: create_user_claim_input) {
    create_user_claim(json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_user_claim_query = gql`
  mutation update_user_claim($json: update_user_claim_input) {
    update_user_claim(json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_user_claim_query = gql`
  mutation delete_user_claim($json: delete_user_claim_input) {
    delete_user_claim(json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;
