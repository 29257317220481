import { Box, HStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";

const OtpInputFieldAndButtons = (props) => {
  const { t } = useTranslation();
  const { handle_verify, form_ref, verify_otp_loading, resend_otp, counter } =
    props;
  return (
    <>
      <form ref={form_ref} id="otp-form">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1vh",
          }}
        >
          {[...Array(4)].map((_, index) => (
            <input
              key={index}
              type="text"
              className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
              pattern="\d*"
              maxLength="1"
              required={"Otp is required"}
            />
          ))}
        </div>
        <div className="max-w-[260px] mx-auto mt-4">
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handle_verify();
            }}
          >
            {verify_otp_loading ? (
              <div class="leap-frog">
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
              </div>
            ) : (
              "Verify Account"
            )}
          </button>
        </div>
      </form>
      <Box className="reset_otp_box">
        {counter > 0 ? (
          <Box style={{ fontWeight: "bold", textAlign: "center" }}>
            <span>
              {t("Resend OTP in")} 00: {counter < 10 ? `0${counter}` : counter}
            </span>
          </Box>
        ) : (
          <Box alignItems="center">
            <HStack space={1}>
              {"Didn't receive OTP?"}

              <span
                className="resent_otp_text"
                onClick={() => {
                  resend_otp();
                }}
              >
                {"Resend OTP"}
              </span>
            </HStack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default OtpInputFieldAndButtons;
