import React from "react";
import CustomText from "../../custom/custom_text";
import moment from "moment";
import { get_status_tag } from "../../../../helpers/functions";

const LastFourClaims = (props) => {
  const { last_four_claims } = props;
  return (
    <div>
      {last_four_claims?.map((claim, index) => (
        <div className="user_profile_donation_details" key={index}>
          <div className="user_profile_claim_date_and_topic">
            <CustomText
              text={claim ? claim?.topic : ""}
              font_family="Lato"
              size="11px"
              color="#140F2D"
              align="left"
              weight="600"
            />
            <CustomText
              text={
                claim?.created_date_time
                  ? moment(claim?.created_date_time).format("DD-MM-YYYY")
                  : ""
              }
              font_family="Lato"
              size="9px"
              color="#140F2D"
              align="left"
              weight="500"
            />
          </div>
          <div className="donation_card_amount">
            <div className="claims_status">
              <CustomText
                text={get_status_tag(claim?.status)}
                font_family="Lato"
                size="11px"
                color="#ffffff"
                align="left"
                weight="600"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LastFourClaims;
