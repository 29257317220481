import React from "react";
import { useEffect, useState } from "react";
import { useDarkMode } from "../../components/contexts/dark_mode_provider";
import ReactHtmlParser from "html-react-parser";
import TweetIframe from "../../components/single_fact_check/tweet_iframe";
import { useDynamicSelector } from "../../../services/redux";
import cheerio from "cheerio";
import DonationCardDummy from "./donation_card_dummy";
import SocialMediaDummy from "./social_media_dummy";
import PreviewJoinWhatsappChannel from "./article_preview/preview_join_whatsapp_channel";

const ArticleContent = () => {
  const [parsed_html, set_parsed_html] = useState("");
  const { is_dark_mode } = useDarkMode();
  const { content } = useDynamicSelector("get_article");

  useEffect(() => {
    if (content) {
      const $ = cheerio.load(
        content
          .replace(/\[/g, "<")
          .replace(/\]/g, ">")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
      );
      const set_iframe_timeout = (iframe) => {
        const wrapper = $(iframe).parent();
        const spinner = wrapper.find(".loading-spinner");
        setTimeout(() => {
          spinner.css("display", "none");
        }, 5000);
      };

      const twitterEmbed = (url) => {
        return `
          <blockquote class="twitter-tweet">
            <a href="${url}"></a>
          </blockquote>
          <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        `;
      };

      $("a[href^='https://twitter.com/']").each((index, element) => {
        const twitterLink = $(element).attr("href");
        if (twitterLink) {
          const embeddedTweet = twitterEmbed(twitterLink);
          $(element).replaceWith(embeddedTweet);
        }
      });

      $("blockquote.instagram-media").each((index, element) => {
        const blockquote = $(element);
        const permalink = blockquote.attr("data-instgrm-permalink");
        if (permalink) {
          const instagramUrl = permalink.split("?")[0]; // Get the base Instagram URL
          const iframe = `<iframe src="${instagramUrl}embed" width="500" height="800" frameborder="0" scrolling="no" allowtransparency></iframe>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });
      $("blockquote").each((index, element) => {
        const blockquote = $(element);
        const tweetUrl = blockquote
          .find('a[href^="https://twitter.com/"]')
          .last()
          .attr("href");
        if (tweetUrl) {
          const iframe = `<br><TweetIframe tweeturl=${tweetUrl}></br>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });
      $("video").each((index, element) => {
        const videoElement = $(element);
        const videoSrc = videoElement.attr("mp4");
        const videoWidth = videoElement.attr("width");
        const videoHeight = videoElement.attr("height");
        const videoFrame = `<video width="100%" height="auto" controls>
                                  <source src="${videoSrc}" type="video/mp4"/>
                              </video>`;
        videoElement.replaceWith(videoFrame);
      });
      $("div[data-lexical-tweet-id]").each((index, element) => {
        const twitter_post = $(element);
        const tweetId = $(twitter_post).attr("data-lexical-tweet-id");
        if (tweetId) {
          const tweetUrl = `https://twitter.com/twitter/status/${tweetId}`;

          if (tweetUrl) {
            const embedHtml = `<TweetIframe tweeturl=${tweetUrl}>`;
            $(element).replaceWith(embedHtml);
          }
        }
      });
      $("div[data-lexical-instagram-id]").each((index, element) => {
        const blockquote = $(element);
        const permalink = blockquote.attr("data-lexical-instagram-id");
        if (permalink) {
          const instagramUrl = `https://www.instagram.com/p/${permalink}`;
          const iframe = `<iframe src="${instagramUrl}/embed" width="500" height="500" frameborder="0" scrolling="no" allowtransparency></iframe>`;

          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });
      $("iframe[data-lexical-video-url]").each((index, element) => {
        const video_element = $(element);
        const video_src = video_element.attr("data-lexical-video-url");
        const video_frame = `
              <div class="video-wrapper">
                <video  class="video-content" controls>
                  <source src="${video_src}" type="video/mp4"/>
                </video>
              </div>`;
        video_element.replaceWith(video_frame);
      });
      $("iframe[data-lexical-iframe-id]").each((index, element) => {
        const iframe_element = $(element);
        const iframe_src = iframe_element.attr("data-lexical-iframe-id");
        console.log("iframe_src", iframe_src, iframe_element);
        const iframe_frame = `
              <div class="iframe-loading">
          <div class="iframe-wrapper">
            <iframe src="${iframe_src}" width="100%" height="600" frameborder="0" allowfullscreen onload="this.parentElement.querySelector('.loading-spinner').style.display = 'none';"></iframe>
          </div>
        </div>`;
        iframe_element.replaceWith(iframe_frame);
        set_iframe_timeout(iframe_frame);
      });
      if (is_dark_mode) {
        $("body").addClass("content-dark   article-content");
      } else {
        $("body").addClass("article-content");
      }
      set_parsed_html($.html());
    }
  }, [content, is_dark_mode]);

  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === "TweetIframe" || name === "tweetiframe") {
        return <TweetIframe {...attribs}>{children}</TweetIframe>;
      }
      return null;
    },
  };
  return (
    <div className="single_article_details_with_donation_wrapper dark_mode">
      <div className="single_article_details_with_donation_container">
        <div className={"single_article_content_container"}>
          {parsed_html ? ReactHtmlParser(parsed_html, options) : ""}

          {/* <div className="join_whatsapp_channel_division">
              <JoinWhatsappChannel />
            </div>
            <SingleFactCheckReport /> */}
        </div>
        <div className="single_article_donation_container">
          <div className="single_article_donation">
            <SocialMediaDummy width={"100%"} />
          </div>
          <PreviewJoinWhatsappChannel />
          <div className="social_media_card_wrapper">
            <DonationCardDummy height={"89vh"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleContent;
