import { Button, Modal, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../../../custom/custom_text";
import {
  dynamic_request,
  mutation_delete_fact_check,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const FactCheckDeleteModal = (props) => {
  const { delete_modal_visible, set_delete_modal_visible, selected_item } =
    props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handle_delete_fact_check = () => {
    delete_fact_check();
    set_delete_modal_visible(false);
  };

  const delete_fact_check = (record) => {
    let key = [{ key: "delete_fact_check", loading: true }];
    let query = mutation_delete_fact_check;
    let variables = {
      id: selected_item?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };
  return (
    <Modal
      title="Confirmation"
      key={"delete_modal"}
      open={delete_modal_visible}
      destroyOnClose={true}
      onCancel={() => set_delete_modal_visible(false)}
      footer={[
        <Button
          key="cancel"
          onClick={() => set_delete_modal_visible(false)}
          danger
        >
          {t("Close")}
        </Button>,
        <Button
          key="confirm"
          type="primary"
          //   loading={delete_loading}
          onClick={() => handle_delete_fact_check()}
        >
          {t("Ok")}
        </Button>,
      ]}
    >
      {" "}
      {/* <CustomText
        text={"Are you sure you want to delete?"}
        size={"14px"}
        // font_family={"Kufam"}
        //   color={"#FFFFFF"}
        align={"left"}
      /> */}
      Are you sure you want to delete?
    </Modal>
  );
};

export default FactCheckDeleteModal;
