import { Button, Form, Select } from "antd";
import React, { useEffect } from "react";
import VForm from "../../system/ui/antd_form/antd_form";
import { HStack } from "native-base";
import {
  create_anti_party_tag_query,
  update_anti_party_tag_query,
} from "../../../../services/redux/slices/graphql/graphql_anti_party_tags";
import { useDispatch } from "react-redux";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../../../services/redux";
import { showToast } from "../../../../helpers/functions";

const AntiPartyTagDetails = ({
  onCancel,
  form,
  action_type,
  get_all_list,
  form_values,
}) => {
  const dispatch = useDispatch();

  const {
    loading: create_loading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("create_anti_party_tag");
  const {
    loading: update_loading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("update_anti_party_tag");
  const { items: languages_list } = useDynamicSelector(
    "get_all_system_languages"
  );

  useEffect(() => {
    if (create_status === "Success") {
      showToast({
        type: "success",
        message: "Anti party tag create successfully",
      });
      onCancel();
      get_all_list();
      dispatch(dynamic_clear("create_anti_party_tag"));
    } else if (create_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamic_clear("create_anti_party_tag"));
    } else if (update_status === "Success") {
      showToast({
        type: "success",
        message: "Anti party tag updated successfully",
      });
      get_all_list();
      onCancel();
      dispatch(dynamic_clear("update_anti_party_tag"));
    } else if (update_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamic_clear("update_anti_party_tag"));
    }
  }, [create_error, create_status, update_error, update_status]);

  const handle_submit = (value) => {
    if (action_type?.includes("add")) {
      create_anti_party_tag(value);
    } else {
      update_anti_party_tag(value);
    }
  };

  const create_anti_party_tag = (json) => {
    let key = [{ key: "create_anti_party_tag", loading: true }];
    let query = create_anti_party_tag_query;
    let variables = { json };
    dispatch(dynamic_request(key, query, variables));
  };

  const update_anti_party_tag = (json) => {
    let key = [{ key: "update_anti_party_tag", loading: true }];
    let query = update_anti_party_tag_query;
    let variables = { id: form_values?.id, json };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <div>
      <Form onFinish={handle_submit} form={form} layout="vertical">
        <VForm.TextBox
          label="Name"
          field="name"
          rules={[
            {
              required: true,
              message: "Name is required ",
            },
          ]}
        />
        <Form.Item
          name={"language_id"}
          label="Language"
          rules={[
            {
              required: true,
              message: "Language is required ",
            },
          ]}
        >
          <Select>
            {languages_list?.map((option, index) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <HStack justifyContent={"flex-end"} space={"10px"}>
          <Button danger onClick={onCancel}>
            Close
          </Button>
          <Button
            htmlType="submit"
            loading={update_loading || create_loading}
            type="primary"
          >
            {form_values?.id ? "Update" : "Create"}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default AntiPartyTagDetails;
