import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  initiate_request_razorpay_payment,
  navigate,
  retrieveItem,
} from "../../../../helpers/functions";
import {
  dynamic_clear,
  dynamic_request,
  payment_handler,
  useDynamicSelector,
} from "../../../../services/redux";
import CustomText from "../../custom/custom_text";
import { Badge, Modal, Row } from "antd";
import CustomButton from "../../custom/custom_button";
import ConfettiAnimation from "../../widgets/result";
import { THANK_YOU_IMAGE } from "../../../../helpers/image_constants";
import DonationInputs from "./donation_inputs";
import DonationAmountInput from "../../widgets/donation_amount_input";

const DonationCardBeforeDonation = (props) => {
  const {
    payment_status,
    manual_amount,
    set_manual_amount,
    set_payment_response,
    setPaymentStatus,
    paymentStatus,
  } = props;
  const dispatch = useDispatch();
  const session_id = retrieveItem("session_id");
  const [amount, set_amount] = useState(0);
  const [selected_amount, set_selected_amount] = useState("");
  const donation_ref = useRef();
  const [change_button, set_change_button] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { loading, order_id } = useDynamicSelector("create_donation");

  useEffect(() => {
    if (payment_status) {
      setPaymentStatus(true);
      setShowModal(true);
      setTimeout(() => {
        setPaymentStatus(false);
        setShowModal(false);
      }, 10000);
    }
  }, [payment_status]);

  useEffect(() => {
    set_amount(null);
  }, [manual_amount]);

  useEffect(() => {
    if (order_id) {
      initiate_request_razorpay_payment(order_id, set_payment_response);
    }
    dispatch(dynamic_clear("create_donation"));
  }, [order_id]);

  const get_order_id = (amount) => {
    query_get_order_id(amount);
  };
  const razor_pay_button = () => {
    if (!session_id) {
      navigate("/login");
    } else {
      amount > 0 && get_order_id(amount);
    }
  };

  const handle_button_change = () => {
    set_change_button(true);
  };

  const query_get_order_id = (amount) => {
    let key = [{ key: "create_donation", loading: true }];
    let query = payment_handler;
    let variables = {
      amount: parseInt(amount),
    };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <div className="user_profile_no_data_image">
      <CustomText
        text={"No recent transactions. Contribute Today!"}
        font_family="Lato"
        size="11px"
        color={"#140F2D"}
        align="left"
        weight="600"
        marginBottom={"10px"}
      />

      {change_button != true ? (
        <></>
      ) : (
        <>
          {manual_amount && (
            <Row className="donation-input-container">
              <DonationAmountInput
                set_donation_amount={set_amount}
                value={amount}
                ref={donation_ref}
              />
              <div className="back-btn">
                <Badge
                  count={"Back"}
                  color="black"
                  className="cursor-pointer amount-badge-font"
                  onClick={() => set_manual_amount(false)}
                />
              </div>
            </Row>
          )}
          {manual_amount === true ? (
            <></>
          ) : (
            <DonationInputs
              set_selected_amount={set_selected_amount}
              selected_amount={selected_amount}
              set_amount={set_amount}
            />
          )}
        </>
      )}

      {change_button != true ? (
        <CustomButton
          name={"Donate Now"}
          loading={loading}
          width={"60%"}
          max_width={"100px"}
          minWidth={"100px"}
          backgroundColor={"#140f2d"}
          border_radius={"15px"}
          height={"30px"}
          on_Click={() => handle_button_change()}
        />
      ) : (
        <CustomButton
          is_disabled_button={amount > 0 ? false : true}
          id="razorpay-button"
          name={"Donate"}
          loading={loading}
          width={"60%"}
          max_width={"100px"}
          minWidth={"100px"}
          backgroundColor={"#140f2d"}
          border_radius={"15px"}
          height={"30px"}
          on_Click={() => razor_pay_button()}
        />
      )}

      <Modal
        visible={showModal}
        closable={false}
        footer={null}
        width={800}
        onCancel={() => setShowModal(false)}
      >
        <div style={{ textAlign: "center", height: "400px" }}>
          <h2>Thank You!</h2>
          <h6>We extend our sincere appreciation for your donation.</h6>
          <img
            style={{
              borderRadius: "9px",
              zIndex: 1,
              objectFit: "cover",
              height: "170px",
              marginTop: "4vh",
            }}
            src={THANK_YOU_IMAGE}
            alt="Profile"
          />
          <h6 style={{ marginTop: "4vh" }}>
            Together, let's make an impact through your donations. Keep
            supporting us.
          </h6>
          {paymentStatus && <ConfettiAnimation />}{" "}
        </div>
      </Modal>
    </div>
  );
};
export default DonationCardBeforeDonation;
