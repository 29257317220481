import { Typography } from "antd";
import React from "react";
import { font } from "../../../helpers/constants";
import { isEnglish } from "../../../helpers/functions";

const CustomLanguageText = (props) => {
  const { is_open, english_size, tamil_size } = props;
  const transition = {
    transition: "width 0.2s linear",
  };

  let custom_text_size = isEnglish(props.text);
  return (
    <Typography
      key={props.id}
      className={props.class_name}
      style={{
        ...transition,
        fontFamily: props.font_family ? props.font_family : "Kufam-italic",
        fontSize: custom_text_size
          ? english_size ?? font.english
          : tamil_size ?? font.tamil,
        fontWeight: props.weight ? props.weight : "500",
        color: props.color,
        width: props.width,
        textAlign: props.align ? props.align : "center",
        lineHeight: props.line_height ? props.line_height : "25px",
        marginTop: props.margin_top ? props.margin_top : "none",
        marginRight: props.marginRight,
        position: props.position,
        top: props.top,
        right: props.right,
        marginLeft: props.marginLeft,
      }}
    >
      {props.text}
    </Typography>
  );
};

export default CustomLanguageText;
