import { Button, Pagination, Table, Typography } from "antd";
import Search from "antd/es/input/Search";
import { HStack, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamic_request,
  get_all_admin_history_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  navigate,
  navigateBack,
  navigateWithProps,
} from "../../../../helpers/navigator";
import { ROUTES } from "../../../routes/my_routes";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { countFormat } from "../../../../helpers/constants";
import { FaRegEye } from "react-icons/fa";

const ArticleHistoryList = (props) => {
  const { article_id } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const {
    items: article_history_items,
    loading: article_history_loading,
    pagination: article_history_pagination,
  } = useDynamicSelector("get_all_article_history");

  const new_article_type = location?.props_state?.article_type;
  const back_button_default_language =
    location?.props_state?.default_language?.children;
  const current_language_id_select =
    location?.props_state?.default_language?.value;

  useEffect(() => {
    if (article_id) {
      get_all_article_history();
    }
  }, [article_id, page_limit, page_number]);

  const get_all_article_history = () => {
    let key = [{ key: "get_all_article_history", loading: true }];
    let query = get_all_admin_history_query;
    let variables = {
      article_id: article_id,
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  let columns = [
    {
      title: "S.No",
      key: "s_no",
      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },

    {
      title: "Edited Date",
      key: "edited_date",
      render: (record) =>
        moment(record?.created_date_time).format("DD/MM/YYYY HH:mm:ss"),
    },

    {
      title: <div style={{ textAlign: "center" }}>{t("Article History")}</div>,
      key: "article",
      render: (record) => (
        <div style={{ textAlign: "center" }}>
          <FaRegEye />
        </div>
      ),
    },
  ];
  const go_back = () => {
    navigateWithProps(`${ROUTES.ADMIN_ARTICLE}`, {
      new_article_type,
      back_button_default_language,
      current_language_id_select,
      back_button_state: true,
    });
  };
  const handle_edit = (record) => {
    navigateWithProps(`${ROUTES.ARTICLE_HISTORY_DETAILS}/${record?.id}`, {
      selected_items: { record },
    });
  };

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };

  return (
    <VStack space={"10px"}>
      <HStack justifyContent={"flex-end"} space={"10px"}>
        <Button onClick={go_back} style={{ backgroundColor: "#d3e3fd" }}>
          Back
        </Button>
        {/* <Search
          enterButton={true}
          allowClear={true}
          placeholder="Search"
          style={{ width: "250px", backgroundColor: "white" }}
          className="admin-list-search"
        /> */}
      </HStack>

      <HStack justifyContent={"flex-start"}>
        <Typography style={{ fontWeight: "bold" }}>Title :</Typography>
      </HStack>
      <HStack justifyContent={"flex-start"}>
        <Typography>{article_history_items?.[0]?.title}</Typography>
      </HStack>

      <Table
        columns={columns}
        loading={article_history_loading}
        dataSource={article_history_items}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handle_edit(record), // click row
            className: "table-cursor",
          };
        }}
        pagination={false}
      />

      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={article_history_pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>
    </VStack>
  );
};

export default ArticleHistoryList;
