import React from "react";
import { AiFillClockCircle } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import { useDynamicSelector } from "@src/services/redux";
import { useTranslation } from "react-i18next";
import { date_format, format_views_count } from "../../../../helpers/functions";
import { DEFAULT_USER } from "../../../../helpers/image_constants";
import PreviewReportShareContainerMobileView from "./preview_report_share_container_mobile_view";

const PreviewSingleArticleWriterDetails = () => {
  const article = useDynamicSelector("get_article");
  const { t } = useTranslation();

  return (
    <div className="writer_details">
      <div className="single_article_writer_details_container">
        <img
          className="writer_profile_image"
          src={
            article?.staff_profile?.profile_pic ||
            article?.user_profile?.profile_pic ||
            DEFAULT_USER
          }
        />
        <div className="single_article_writer_details_wrapper">
          <text className="writer_name">
            {article?.staff_profile?.name ||
              article?.user_profile?.name ||
              "Youturn Editorial"}
          </text>
          <text className="published_date">
            {date_format(article?.published_date_time)}
          </text>
          <div className="single_article_view_and_reading_time">
            <div className="single_article_icons">
              <LuEye color="red" />
              <text className="single_article_view_count">
                {format_views_count(article?.views)}
              </text>
            </div>
            <div className="single_article_icons">
              <AiFillClockCircle color="gray" />
              <text className="single_article_reading_time">
                {article?.reading_time} {t("min")}
              </text>
            </div>
          </div>
        </div>
      </div>
      <div className="report_share_container">
        <PreviewReportShareContainerMobileView
          view={article?.views}
          reading_time={article?.reading_time}
        />
      </div>
    </div>
  );
};

export default PreviewSingleArticleWriterDetails;
