import React from "react";
import DonationCard from "../widgets/donation_card";
import CustomFactCheckRectangleSmallCard from "../custom/custom_fact_check_rectangle_small_card";
import ArticleFirstCard from "../article/article_first_card";
import CustomText from "../custom/custom_text";
import CustomNewsStoryCardVertical from "../custom/custom_news_story_card_vertical";
import { ANTIBIOTICS_IMAGE } from "../../../helpers/image_constants";
import { Row, Skeleton } from "antd";
import {
  change_tamil_font_style,
  split_tamil_english_words,
} from "../../../helpers/functions";
import moment from "moment";
import { useDynamicSelector } from "../../../services/redux";
import CustomRecentlyAddedCarousel from "../custom/custom_recently_added_carousel";
import { useTranslation } from "react-i18next";

const RecentlyAdded = (props) => {
  const { height, article_list, type, loading } = props;
  const { t } = useTranslation();
  const { loading: recent_fact_check_items_loading } = useDynamicSelector(
    "get_recent_fact_check_list"
  );

  return (
    <>
      <Row
        className="article_recommended_stories"
        style={{
          height: height ? height : "600px",
          padding: "5% 5% 0 5%",
        }}
      >
        <div className="recommended_stories_title_box">
          <div className="recommended_stories_text">
            <CustomText
              class_name={"recommended_stories_mobile_text"}
              text={change_tamil_font_style(
                t("recently_added_in_search_result"),
                "3.5vh",
                _
              )}
              font_family={"kufam"}
              size={"21px"}
              weight={"700"}
            />
          </div>

          <div className="article_recommended_stories">
            <div className="article_news_story_card_vertical">
              <CustomNewsStoryCardVertical
                title={split_tamil_english_words(
                  article_list ? article_list[0]?.title : "",
                  _,
                  11
                )}
                subtitle={split_tamil_english_words(
                  article_list ? article_list[0]?.subtitle : "",
                  _,
                  20
                )}
                reading_time={article_list ? article_list[0]?.reading_time : ""}
                view_count={article_list ? article_list[0]?.views : ""}
                date={
                  article_list
                    ? moment(article_list[0]?.created_date_time).format(
                        `MMM D,YYYY`
                      )
                    : ""
                }
                name={article_list ? article_list[0]?.staff_profile?.name : ""}
                src={article_list ? article_list[0]?.thumbnail_img : ""}
                perma_link={article_list ? article_list[0]?.perma_link : ""}
                type={type}
              />
            </div>

            <div className="article_card_first_half">
              <ArticleFirstCard
                title={article_list ? article_list[0]?.title : ""}
                image={ANTIBIOTICS_IMAGE}
                view_count={article_list ? article_list[0]?.views : ""}
                margin_top={"200px"}
                created_date_time={moment(
                  article_list?.created_date_time
                ).format("MMM DD, YYYY")}
                id={article_list ? article_list[0]?.id : ""}
              />
            </div>
          </div>
        </div>

        <div className="article_small_card">
          {recent_fact_check_items_loading
            ? Array.from({ length: 4 }, (_, index) => (
                <Skeleton.Input
                  key={index}
                  className=" "
                  active
                  style={{ width: "40vw", height: "30vh" }}
                />
              ))
            : article_list
                ?.slice(1, 5)
                ?.map((article) => (
                  <CustomFactCheckRectangleSmallCard
                    src={article?.thumbnail_img}
                    title={split_tamil_english_words(article?.title, _, 5)}
                    date={
                      article
                        ? moment(article.created_date_time).format(`MMM D,YYYY`)
                        : ""
                    }
                    perma_link={article?.perma_link}
                    type={type}
                    name={article ? article?.staff_profile?.name : ""}
                  />
                ))}
        </div>

        <div className="article_donation_card">
          <DonationCard height={"89vh"} />
        </div>
      </Row>

      <div style={{ width: "100%" }}>
        <CustomRecentlyAddedCarousel list={article_list} />
      </div>
    </>
  );
};

export default RecentlyAdded;
