import { gql } from "@apollo/client";

export const get_all_user_contributors_query = gql`
  query get_all_user_contributers(
    $page_number: Int
    $page_limit: Int
    $language_id: String
  ) {
    get_all_user_contributers(
      language_id: $language_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        user_profile_id
        language_id
        url
        name
        joined_date
        total_work
        total_views
        profile_pic
      }
      error {
        message
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_top_user_contributors_query = gql`
  query get_top_user_contributers($language_id: String) {
    get_top_user_contributers(language_id: $language_id) {
      items {
        user_profile_id
        language_id
        url
        name
        joined_date
        total_work
        total_views
      }
      error {
        message
      }
    }
  }
`;

export const get_user_recent_articles_query = gql`
  query get_user_recent_articles(
    $json: get_all_article_input
    $page_number: Int
    $page_limit: Int
  ) {
    get_user_recent_articles(
      json: $json
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        language_id

        title
        subtitle
        perma_link
        description
        content
        status
        status
        reading_time
        likes
        views
        comments_count
        is_reported
        thumbnail_img
        created_date_time
        category_tags {
          id
          name
        }
        user_profile {
          id
          name
          url
        }
        staff_profile {
          id
          name
          url
        }
      }
    }
  }
`;

export const get_user_trending_articles_query = gql`
  query get_user_trending_articles($language_id: String) {
    get_user_trending_articles(language_id: $language_id) {
      items {
        id
        language_id

        title
        subtitle
        perma_link
        description
        content
        status
        reading_time
        likes
        views
        comments_count
        thumbnail_img
        created_date_time
      }
    }
  }
`;

export const get_all_user_contribution_query = gql`
  query get_all_user_contribution(
    $language_id: String!
    $page_number: Int
    $page_limit: Int
  ) {
    get_all_user_contribution(
      language_id: $language_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        record_type
        staff_profile_id
        user_profile_id
        user_profile {
          id
          name
        }
        title
        subtitle
        content
        reading_time
        perma_link
        language_id
        likes
        views
        thumbnail_img
        created_date_time
        published_date_time
        staff_profile {
          id
          name
        }
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const get_all_user_contribution_for_recently_added_query = gql`
  query get_all_user_contribution(
    $language_id: String!
    $page_number: Int
    $page_limit: Int
  ) {
    recently_added_for_user_contribution: get_all_user_contribution(
      language_id: $language_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        record_type
        staff_profile_id
        user_profile_id
        user_profile {
          id
          name
        }
        title
        subtitle
        content
        reading_time
        perma_link
        language_id
        likes
        views
        thumbnail_img
        created_date_time
        published_date_time
        staff_profile {
          id
          name
        }
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_user_contribution_trending_stories_query = gql`
  query get_user_trending_results($language_id: String!) {
    get_user_trending_results(language_id: $language_id) {
      items {
        id
        record_type
        staff_profile_id
        user_profile_id
        title
        subtitle
        content
        reading_time
        perma_link
        language_id
        thumbnail_img
        likes
        views
        created_date_time
        published_date_time
     
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const get_user_contribution_list_query = gql`
  query get_user_contribution_list(
    $language_id: String
    $user_profile_id: String
    $page_number: Int
    $page_limit: Int
    $record_type: String
  ) {
    get_user_contribution_list(
      language_id: $language_id
      user_profile_id: $user_profile_id
      page_limit: $page_limit
      page_number: $page_number
      record_type: $record_type
    ) {
      items {
        id
        record_type
        staff_profile_id
        user_profile_id
        title
        subtitle
        status
        staff_profile {
          name
          mobile
        }
        user_profile {
          name
          mobile
        }
      }
      error {
        message
      }
    }
  }
`;
