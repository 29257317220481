import React from "react";
import TopContributors from "../top_contributors";
import { useDynamicSelector } from "../../../../services/redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../../routes/my_routes";

const UserContributionTopContributors = (props) => {
  const {
    all_stories_sliced_items,
    handle_click,
    page_limit,
    page_limit_user_contributors,
    filtered_contributors,
  } = props;
  const history = useHistory();
  const {
    items: get_all_contributers_items,
    pagination: get_all_user_contributers_pagination,
  } = useDynamicSelector("get_all_user_contributers");
  const {
    items: recently_added_for_user_contribution_items,
    pagination: recently_user_contribution_pagination,
  } = useDynamicSelector("recently_added_for_user_contribution");
  const handle_click_contributors = () => {
    history.push(ROUTES.TOP_CONTRIBUTORS);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",

        marginTop: "5vh",
      }}
      className="user_contribution_top_contributors_lap_view"
    >
      <TopContributors
        all_stories_sliced_items={all_stories_sliced_items}
        handle_click={handle_click}
        handle_click_contributors={handle_click_contributors}
        page_limit={page_limit}
        get_all_contributers_items={get_all_contributers_items}
        page_limit_user_contributors={page_limit_user_contributors}
        recently_user_contribution_pagination={
          recently_user_contribution_pagination
        }
        recently_added_for_user_contribution_items={
          recently_added_for_user_contribution_items
        }
        get_all_user_contributers_pagination={
          get_all_user_contributers_pagination
        }
        filtered_contributors={filtered_contributors}
      />
    </div>
  );
};

export default UserContributionTopContributors;
