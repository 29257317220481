import { Button, Form, Input, Modal, Pagination, Select, Table } from "antd";
import { HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamic_clear,
  dynamic_request,
  get_all_languages_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { mutation_delete_category } from "../../../../services/redux/slices/graphql/graphql_category";
import { useDispatch } from "react-redux";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { showToast } from "../../../../helpers/functions";
import PartyTagDetails from "./party_tag_details";
import {
  mutation_delete_party_tag,
  query_get_all_party_tag,
} from "../../../../services/redux/slices/graphql/graphql_party_tag";
import {
  countFormat,
  getDefaultLanguageId,
} from "../../../../helpers/constants";

const PartyTagList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mutation_modal, set_mutation_modal] = useState(false);
  const [mutation_type, set_mutation_type] = useState("");
  const [delete_modal, set_delete_modal] = useState(false);
  const [mutation_form_values, set_mutation_form_values] = useState({});
  const [search_text, set_search_value] = useState("");
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const [language_id, set_language_id] = useState(
    "839bcc29-853f-4c4c-8700-98fd88558952"
  );
  const [form] = Form.useForm();
  const { Search } = Input;

  const {
    items: list,
    loading: list_loading,
    pagination: party_tag_pagination,
  } = useDynamicSelector("get_all_party_tag");

  const {
    loading: delete_loading,
    status: delete_status,
    error: delete_error,
  } = useDynamicSelector("delete_party_tag");

  const { items: languages_list } = useDynamicSelector(
    "get_all_system_languages"
  );

  useEffect(() => {
    get_all_list();
  }, [language_id, search_text, page_limit, page_number]);

  useEffect(() => {
    get_all_languages();
  }, []);

  useEffect(() => {
    if (mutation_form_values?.id) {
      form.setFieldsValue(mutation_form_values);
    } else {
      form.resetFields();
    }
  }, [mutation_form_values]);

  useEffect(() => {
    if (delete_status === "Success") {
      showToast({
        type: "success",
        message: "Party Tag deleted successfully",
      });
      handle_close();
      get_all_list();
      dispatch(dynamic_clear("delete_party_tag"));
    } else if (delete_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamic_clear("delete_party_tag"));
    }
  }, [delete_status, delete_error]);

  const action_items = (record) => {
    return (
      <HStack justifyContent={"center"} alignItems={"center"}>
        {/* <Pressable onPress={() => { handle_edit(record) }}>
                    <AiOutlineEdit size={20} />
                </Pressable> */}
        <Pressable
          onPress={() => {
            handle_delete(record);
          }}
        >
          <AiOutlineDelete size={20} />
        </Pressable>
      </HStack>
    );
  };

  let columns = [
    {
      title: "S.No",
      key: "s_no",
      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "action",
      width: "50px",
      render: (record) => action_items(record),
    },
  ];

  const get_all_list = () => {
    let key = [{ key: "get_all_party_tag", loading: true }];
    let query = query_get_all_party_tag;
    let variables = {
      language_id: language_id,
      search_string: search_text,
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_delete_conformation = () => {
    let key = [{ key: "delete_party_tag", loading: true }];
    let query = mutation_delete_party_tag;
    let variables = { id: mutation_form_values?.id };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_add = () => {
    set_mutation_modal(true);
    set_mutation_type("add");
  };

  const handle_close = () => {
    set_mutation_modal(false);
    set_mutation_type(null);
    form.resetFields();
    set_mutation_form_values({});
    set_delete_modal(false);
  };

  const handle_edit = (value) => {
    set_mutation_modal(true);
    set_mutation_type("edit");
    set_mutation_form_values(value);
  };

  const handle_delete = (value) => {
    set_delete_modal(true);
    set_mutation_form_values(value);
  };

  const onSearch = (e) => {
    let search_value = e?.target?.value;
    if (search_value?.length >= 0) {
      set_search_value(search_value);
    }
  };

  const handle_change = (value) => {
    set_language_id(value);
  };

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };

  return (
    <VStack space={"10px"}>
      <HStack justifyContent={"flex-end"} space={"10px"}>
        <Search
          enterButton={true}
          onSearch={onSearch}
          allowClear={true}
          onChange={onSearch}
          placeholder="Search"
          style={{ width: "250px", backgroundColor: "white" }}
          className="admin-list-search"
        />
        <Select
          onChange={handle_change}
          defaultValue={languages_list && languages_list?.[0]?.name}
        >
          {languages_list?.map((option, index) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
        <Button onClick={handle_add} type="primary">
          Add Party tag
        </Button>
      </HStack>
      <Table
        columns={columns}
        loading={list_loading}
        dataSource={list}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handle_edit(record), // click row
            className: "table-cursor",
          };
        }}
        pagination={false}
      />

      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={party_tag_pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>

      <Modal
        open={mutation_modal}
        title={
          mutation_type?.includes("add")
            ? "Add Party wise"
            : "Update Party wise"
        }
        onCancel={handle_close}
        footer={null}
      >
        <PartyTagDetails
          onCancel={handle_close}
          form={form}
          action_type={mutation_type}
          get_all_list={get_all_list}
          form_values={mutation_form_values}
        />
      </Modal>
      <Modal
        open={delete_modal}
        title={"Confirmation"}
        footer={null}
        onCancel={handle_close}
      >
        <span>Are you sure to delete?</span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button onClick={handle_close} danger>
            Close
          </Button>
          <Button
            onClick={handle_delete_conformation}
            type="primary"
            loading={delete_loading}
          >
            Ok
          </Button>
        </HStack>
      </Modal>
    </VStack>
  );
};

export default PartyTagList;
