import React, { useEffect, useRef, useState } from "react";
import { Form, Modal } from "antd";
import DonationAmountInput from "./donation_amount_input";
import AmountCard from "./amount_card";
import DonationText from "./donation_text";
import { isMobile } from "react-device-detect";
import {
  change_tamil_font_style,
  initiate_request_razorpay_payment,
} from "../../../helpers/functions";
import {
  dynamic_clear,
  dynamic_request,
  payment_handler,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import CustomButton from "../custom/custom_button";
import CustomText from "../custom/custom_text";
import ConfettiAnimation from "./result";
import { THANK_YOU_IMAGE } from "../../../helpers/image_constants";
import { useTranslation } from "react-i18next";
import UserDetailsForm from "./user_details_main_form_and_form_items/user_details_form";

const DonationCard = (props) => {
  const { height, padding } = props;
  const { t } = useTranslation();
  const [form_donation] = Form.useForm();
  const dispatch = useDispatch();
  const [donation_amount, set_donation_amount] = useState(null);
  const { loading, order_id } = useDynamicSelector("create_donation");
  const [payment_response, set_payment_response] = useState("");
  const [form] = Form.useForm();
  const payment_status = payment_response.razorpay_signature;
  const [show_modal, set_show_modal] = useState(false);
  const [paymentStatus, set_payment_status] = useState(false);
  const [user_details_modal_open, set_user_details_modal_open] =
    useState(false);
  const [user_details_values, set_user_details_values] = useState();
  const [amount, set_amount] = useState();

  const donor_name = Form.useWatch("name", form_donation);
  const donor_mobile = Form.useWatch("mobile", form_donation);
  const donor_address = Form.useWatch("address", form_donation);

  useEffect(() => {
    if (order_id) {
      initiate_request_razorpay_payment(order_id, set_payment_response);
    }
    dispatch(dynamic_clear("create_donation"));
  }, [order_id]);
  useEffect(() => {
    if (payment_status) {
      set_payment_status(true);
      set_show_modal(true);
      setTimeout(() => {
        set_payment_status(false);
        set_show_modal(false);
      }, 10000);
    }
  }, [payment_status]);

  const handle_get_amount = (amount) => {
    form.setFieldsValue({ amount: amount });
    set_donation_amount(amount);
  };

  const query_get_order_id = (amount) => {
    let key = [{ key: "create_donation", loading: true }];
    let query = payment_handler;
    let variables = {
      amount: parseInt(amount),
      mobile: String(donor_mobile),
      address: donor_address,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_form_submit = (values) => {
    // query_get_order_id();
    if (donation_amount > 0) {
      set_user_details_modal_open(true);
      set_amount(values?.amount);
    }
  };
  const user_detail_modal_close = () => {
    set_user_details_modal_open(false);
    form_donation.resetFields();
  };
  const handle_ok = (values) => {
    form_donation.submit();

    query_get_order_id(donation_amount);
    set_user_details_modal_open(false);
    form_donation.resetFields();
  };
  return (
    <div
      id="donation-card"
      className="donation-card"
      style={{
        backgroundColor: "#140f2d",
        height: height || "",
        borderRadius: "15px",
        padding: padding ? padding : "5%",
      }}
    >
      <Modal
        visible={show_modal}
        closable={false}
        footer={null}
        width={800}
        onCancel={() => set_show_modal(false)}
      >
        <div style={{ textAlign: "center", height: "400px" }}>
          <h2>Thank You!</h2>
          <h6>We extend our sincere appreciation for your donation.</h6>
          <img
            style={{
              borderRadius: "9px",
              zIndex: 1,
              objectFit: "cover",
              height: "170px",
              marginTop: "4vh",
            }}
            src={THANK_YOU_IMAGE}
            alt="Profile"
          />
          <h6 style={{ marginTop: "4vh" }}>
            Together, let's make an impact through your donations. Keep
            supporting us.
          </h6>
          {paymentStatus && <ConfettiAnimation />}{" "}
        </div>
      </Modal>
      <Modal
        title={t("enter_details")}
        width={500}
        open={user_details_modal_open}
        onCancel={user_detail_modal_close}
        // onOk={handle_ok}
        footer={null}
      >
        <UserDetailsForm
          form={form_donation}
          set_user_details_values={set_user_details_values}
          handle_ok={handle_ok}
        />
      </Modal>
      <div
        className="donation-card-small"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <div className="donation-and-text-small-card">
          <DonationText padding={"0 0 0 0"} />

          <div
            style={{
              display: "flex",
              width: isMobile ? "min-content" : "100%",
              gap: "10px",
              alignItems: isMobile ? "center" : "",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <text
                className={
                  isMobile
                    ? "donation-main-content font-size-50"
                    : "donation-main-content_char"
                }
              >
                #
              </text>
              <text className="donation-main-content">
                {change_tamil_font_style(
                  t("fight_against_fake_news"),
                  "4vh",
                  "5px"
                )}
              </text>
            </div>
          </div>
        </div>
        <div className="donation-content-middle-container">
          <CustomText
            class_name="donation-content fw-bold"
            text={change_tamil_font_style(
              t("make_a_small_contribution..."),
              "1.9vh",
              "3vh"
            )}
            size={"15px"}
            font_family={"Kufam"}
            color={"#FFFFFF"}
            align={"left"}
          />
        </div>
        <div className="landing-donation-width">
          <AmountCard
            handle_get_amount={handle_get_amount}
            class_name={"donation-input-amt-card"}
          />

          <div className="center-alignment mt-2">
            <text className="donation-content" style={{ fontSize: "15px" }}>
              {t("or")}
            </text>
          </div>
          <div style={{ width: "100%" }}>
            <Form form={form} onFinish={handle_form_submit}>
              <DonationAmountInput
                set_donation_amount={set_donation_amount}
                class_name={"donation-card-input"}
              />
            </Form>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: isMobile ? "20px" : "30px",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <CustomButton
              is_disabled={donation_amount > 0 ? false : true}
              id="razorpay-button"
              name={t("donate")}
              loading={loading}
              on_Click={() => {
                form.submit();
              }}
              width={"60%"}
              max_width={"100px"}
              minWidth={"100px"}
              backgroundColor={"#00acee"}
            />
          </div>
          <div
            style={{
              lineHeight: "12px",
              // width: isMobile ? "200px" : "300px",
            }}
          >
            <text
              className="donation_card_bottom_text"
              style={{ color: "gray", fontSize: "12px" }}
            >
              {change_tamil_font_style(
                t("currently_we_accept_only_INR_donations"),
                "1.9vh",
                "2.5vh"
              )}
            </text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationCard;
