import { Skeleton } from "antd";
import React from "react";
import CustomFactCheckCategories from "../../custom/custom_fact_check_categories";
import { useDynamicSelector } from "../../../../services/redux";
import moment from "moment";

const RecommendedStoriesMobileViewCard = (props) => {
  const { all_stories_sliced_items, page_limit } = props;
  const { loading: recently_added_for_user_contribution_loading } =
    useDynamicSelector("recently_added_for_user_contribution");
  return (
    <div className="user_contribution_six-categories-card-first-div">
      <div className="user_contribution_six-categories-card-second-div">
        {recently_added_for_user_contribution_loading
          ? Array.from({ length: page_limit }, (_, index) => (
              <Skeleton.Input
                key={index}
                className="six-categories-card-second-div-for-skeleton"
                active
              />
            ))
          : all_stories_sliced_items?.map((items) => (
              <CustomFactCheckCategories
                key={items.id}
                category_image={items?.thumbnail_img}
                title={
                  items?.title?.length > 40
                    ? `${items?.title?.slice(0, 60)}`
                    : items?.title
                }
                sub_title={
                  items?.subtitle?.length > 40
                    ? `${items?.subtitle?.slice(0, 50)}...`
                    : items?.subtitle
                }
                created_date={
                  items
                    ? moment(items.created_date_time).format(`MMM D, YYYY`)
                    : ""
                }
                loading={recently_added_for_user_contribution_loading}
                perma_link={items?.perma_link ? items?.perma_link : ""}
                name={items ? items?.staff_profile?.name : ""}
                type={items?.record_type}
              />
            ))}
      </div>
    </div>
  );
};

export default RecommendedStoriesMobileViewCard;
