import React, { useEffect, useState } from "react";
import { useDynamicSelector } from "../../../services/redux";
import { useDarkMode } from "../contexts/dark_mode_provider";
import CustomText from "../custom/custom_text";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import cheerio from "cheerio";
import TweetIframe from "./tweet_iframe";

const SingleFactCheckDetailExplain = () => {
  const { t } = useTranslation();
  const { is_dark_mode } = useDarkMode();
  const [parsed_html, set_parsed_html] = useState("");
  const { content } = useDynamicSelector("get_fact_check_by_perma_link");

  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === "TweetIframe" || name === "tweetiframe") {
        return <TweetIframe {...attribs}>{children}</TweetIframe>;
      }
      return null;
    },
  };

  useEffect(() => {
    if (content) {
      const $ = cheerio.load(
        content
          .replace(/\[/g, "<")
          .replace(/\]/g, ">")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
      );

      const twitterEmbed = (url) => {
        return `
              <blockquote class="twitter-tweet">
                <a href="${url}"></a>
              </blockquote>
              <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            `;
      };

      $("a[href^='https://twitter.com/']").each((index, element) => {
        const twitter_link = $(element).attr("href");
        if (twitter_link) {
          const embedded_tweet = twitterEmbed(twitter_link);
          $(element).replaceWith(embedded_tweet);
        }
      });

      $("blockquote.instagram-media").each((index, element) => {
        const blockquote = $(element);
        const permalink = blockquote.attr("data-instgrm-permalink");
        if (permalink) {
          const instagramUrl = permalink.split("?")[0];
          const iframe = `<iframe src="${instagramUrl}embed" width="500" height="800" frameborder="0" scrolling="no" allowtransparency></iframe>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });

      $("blockquote").each((index, element) => {
        const blockquote = $(element);
        const tweetUrl = blockquote
          .find('a[href^="https://twitter.com/"]')
          .last()
          .attr("href");

        if (tweetUrl) {
          const iframe = `<br><TweetIframe tweeturl=${tweetUrl}></br>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });

      $("video").each((index, element) => {
        const video_element = $(element);
        const video_src = video_element.attr("mp4");
        const video_width = video_element.attr("width");
        const video_height = video_element.attr("height");
        // Wrap the video element with a video wrapper
        const video_frame = `
              <div class="video-wrapper">
                <video width="${video_width}" height="${video_height}" controls>
                  <source src="${video_src}" type="video/mp4"/>
                </video>
              </div>`;
        video_element.replaceWith(video_frame);
      });

      if (is_dark_mode) {
        $("body").addClass("content-dark fact-check-content");
      } else {
        $("body").addClass("fact-check-content");
      }

      set_parsed_html($.html());
    }
  }, [content, is_dark_mode]);

  return (
    <>
      <div className="single-fact-check-content-title">
        <CustomText
          class_name="single-fact-check-rating-text"
          text={t("detailed_explanation")}
          color={"#140F2D"}
          font_family={"Kufam"}
          align={"left"}
          weight={"700"}
        />
      </div>
      <div
        className={`single-fact-heck-content-align  ${
          is_dark_mode ? "content-dark" : ""
        }`}
      >
        {parsed_html ? ReactHtmlParser(parsed_html, options) : ""}
      </div>
    </>
  );
};

export default SingleFactCheckDetailExplain;
