import React from "react";
import {
  FACEBOOK_LOGO,
  INSTAGRAM_FILLED_IMAGE,
  TELEGRAM_FILLED_IMAGE,
  THREADS_LOGO,
  TWITTER_FILLED_IMAGE,
  YOUTUBE_LOGO,
} from "../../../helpers/image_constants";
import { useTranslation } from "react-i18next";
import { change_tamil_font_style } from "../../../helpers/functions";

const MediaShare = () => {
  const { t } = useTranslation();
  const handle_redirect = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div className="media_share">
      <div
        style={{
          width: "100vh",
          height: "100vh",
          background:
            "linear-gradient(280deg, #140F2D 10.84%, #F49D36 281.54%)",
          borderRadius: 8000,
          clipPath: "polygon(0 0, 100% 0, 100% 50%, 0 50%)",
          transform: "rotate(270deg)",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%) rotate(90deg)",
            color: "white",
            padding: "5%",
            width: "100%",
            height: "90%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "28vh",
            }}
          >
            <div
              style={{
                width: "10%",
                marginLeft: "19%",
                cursor: "pointer",
              }}
            >
              <img
                src={THREADS_LOGO}
                alt="img"
                height={"30px"}
                onClick={() => {
                  handle_redirect("https://facebook.com/youturn.in/");
                }}
              />
            </div>
            <div
              style={{
                width: "10%",
                marginLeft: "8%",
                cursor: "pointer",
                height: "7vh",
              }}
            >
              <img
                src={FACEBOOK_LOGO}
                alt="img"
                height={"30px"}
                onClick={() => {
                  handle_redirect("https://facebook.com/youturn.in/");
                }}
              />
            </div>
            <div
              style={{
                width: "10%",
                cursor: "pointer",
              }}
            >
              <img
                src={TWITTER_FILLED_IMAGE}
                alt="img"
                height={"30px"}
                onClick={() => {
                  handle_redirect("https://twitter.com/youturn_in");
                }}
              />
            </div>
          </div>
          <div>
            <div
              style={{
                width: "30%",
                lineHeight: "20px",
              }}
            >
              <text style={{ fontSize: "2.5vh" }}>
                {change_tamil_font_style(t("dont_miss_anything"), "1.5vh", _)}
              </text>
            </div>
            <div
              style={{
                width: "40%",
              }}
            >
              <text
                style={{
                  fontWeight: "bold",
                  fontSize: "3.5vh",
                }}
              >
                {change_tamil_font_style(
                  t("follow_us_on_our_social_media!"),
                  "2vh",
                  "10px"
                )}
              </text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "28vh",
            }}
          >
            <div
              style={{
                cursor: "pointer",
              }}
            >
              <img
                src={INSTAGRAM_FILLED_IMAGE}
                alt="img"
                height={"30px"}
                onClick={() => {
                  handle_redirect("https://www.instagram.com/youturn.media/");
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "19vh",
                cursor: "pointer",
              }}
            >
              <img
                src={YOUTUBE_LOGO}
                alt="img"
                height={"30px"}
                onClick={() => {
                  handle_redirect(
                    "https://www.youtube.com/channel/UCCLsLUlxvfdnRwG8_Uh40Ew"
                  );
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "40vh",
                cursor: "pointer",
              }}
            >
              <img
                src={TELEGRAM_FILLED_IMAGE}
                alt="img"
                height={"30px"}
                onClick={() => {
                  handle_redirect("https://t.me/youturn_in");
                  // window.open("https://web.telegram.org", "_blank");
                  // window.open("https://t.me/youturn_in", "_blank");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaShare;
