import { Image } from "antd";
import React, { useEffect } from "react";
import { ARROW_ICON } from "../../../../helpers/image_constants";
import CustomText from "../../custom/custom_text";
import CustomNewsArticle from "../../custom/custom_news_article";
import {
  dynamic_request,
  useDynamicSelector,
} from "../../../../services/redux";
import moment from "moment";
import SocialMediaList from "../../widgets/social_media_list";
import { format_view_count, retrieveItem } from "../../../../helpers/functions";
import { useDispatch } from "react-redux";
import { get_trending_results_query } from "../../../../services/redux/slices/graphql/graphql_search_results";

const SearchResultTrendingStory = () => {
  const dispatch = useDispatch();
  const language_id = retrieveItem("language");

  const { items: get_trending_results_items } = useDynamicSelector(
    "get_trending_results"
  );
  useEffect(() => {
    get_trending_results();
  }, []);

  const get_trending_results = () => {
    let key = [{ key: "get_trending_results", loading: true }];
    let query = get_trending_results_query;
    let variables = {
      language_id: language_id?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <div className="search_result_trending_now">
      <div className="trending-now-arrow-with-text">
        <Image
          className="trending-now-arrow-image"
          visible={false}
          preview={false}
          src={ARROW_ICON}
        />

        <CustomText
          class_name={"trending_now_article_list"}
          text={"Trending Now"}
          font_family={"lato"}
          size={"22px"}
          align={"left"}
          weight={"700"}
        />
      </div>
      <div className="search_result_news_article">
        {get_trending_results_items?.slice(0, 3)?.map((item, index) => (
          <CustomNewsArticle
            view_count={format_view_count(item?.views)}
            reading_time={item?.reading_time}
            date={moment(item?.created_date_time).format("MMM DD,YYYY")}
            header_color={"#0065cc"}
            name={item?.staff_profile?.name}
            article={item?.title}
            type={item?.record_type}
            perma_link={item?.perma_link}
            thumbnail_img={item?.thumbnail_img}
          />
        ))}
      </div>
      <div className="search_result_social_media_sticky">
        <SocialMediaList width={"22vw"} />
      </div>
    </div>
  );
};

export default SearchResultTrendingStory;
