import { Pagination, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { HStack } from "native-base";
import { dynamic_request, useDynamicSelector } from "../../services/redux";
import UserProfileTitle from "../components/user_profile/user_profile_title";
import CustomText from "../components/custom/custom_text";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { debounce } from "lodash";
import {
  get_status,
  get_status_tag,
  retrieveItem,
} from "../../helpers/functions";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import { query_get_my_all_claims } from "../../services/redux/slices/graphql/graphql_user_profile";
import { useDispatch } from "react-redux";
import { countFormat } from "../../helpers/constants";

const UserProfileClaimList = () => {
  const { items: my_claims, pagination: claim_pagination } = useDynamicSelector(
    "get_all_my_user_claims"
  );
  const { pathname } = useLocation();
  const top_ref = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const session_id = retrieveItem("session_id");
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const { is_dark_mode } = useDarkMode();

  useEffect(() => {
    debouncedHandleTitleClick();
  }, [pathname, page_limit, page_number]);

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 800);

  useEffect(() => {
    if (session_id) {
      get_all_my_claims();
    }
  }, [session_id, page_limit, page_number]);

  const get_all_my_claims = () => {
    let key = [{ key: "get_all_my_user_claims", loading: true }];
    let query = query_get_my_all_claims;
    let variables = {
      json: {
        page_limit: page_limit,
        page_number: page_number,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };

  const columns = [
    {
      title: "S.No",
      key: "s_no",
      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },

    {
      title: t("URL"),
      dataIndex: "ref_url",
      align: "center",
      key: "url",
      width: "300px",
      render: (text, record) => (
        <a
          href={record?.ref_url}
          target="_blank"
          rel="noopener noreferrer"
          className="user_claim_url"
        >
          Visit link
        </a>
      ),
    },
    {
      title: "Date",
      key: "created_date_time",
      width: 200,

      render: (text, record) => moment(text).format("MMM DD, YYYY"),
    },
    {
      title: "Status",
      key: "status",
      width: 200,
      render: (record) => {
        return get_status_tag(record?.status);
      },
    },
  ];

  return (
    <div ref={top_ref} className={is_dark_mode ? "content-dark" : ""}>
      <UserProfileTitle type={"Claims"} title={""} />
      <CustomText
        class_name={"user_profile_titles"}
        text={"Claims"}
        font_family={"Lato"}
        size={"24px"}
        color={"#140f2d"}
        align={"left"}
        weight={"700"}
      />
      <div className="user_claim_donation_list">
        <div className="user_claim_donation">
          <Table
            columns={columns}
            dataSource={my_claims}
            size="small"
            scroll={{ x: true }}
            pagination={false}
          />
          <HStack justifyContent={"flex-end"} mt={"20px"}>
            <Pagination
              total={claim_pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetch_data}
              showTotal={(total, range) => `Total ${countFormat(total)} items`}
            />
          </HStack>
        </div>
      </div>
    </div>
  );
};

export default UserProfileClaimList;
