import Typography from "antd/es/typography/Typography";
import React from "react";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";
import CustomText from "../custom/custom_text";
import EditUserProfileTopContents from "./edit_user_profile_top_contents";
import EditProfileUpdateForm from "./edit_profile_update_form";

const EditUserProfileDetails = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <EditUserProfileTopContents />

      <EditProfileUpdateForm />
    </div>
  );
};

export default EditUserProfileDetails;
