import React, { useEffect, useState } from "react";
import { retrieveItem } from "../../../helpers/functions";
import { DEFAULT_USER, PREMIUM_BADGE } from "../../../helpers/image_constants";
import CustomText from "../custom/custom_text";
import { Button, Form, Input, Modal } from "antd";
import { dynamic_request, useDynamicSelector } from "../../../services/redux";
import {
  mutation_delete_my_profile_pic,
  mutation_update_user_profile,
} from "../../../services/redux/slices/graphql/graphql_file_upload";
import { useDispatch } from "react-redux";
import { query_get_user } from "../../../services/redux/slices/graphql/graphql_user_profile";
import EditProfileUpload from "../Edit_profile/edit_profile_update_form";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useDarkMode } from "../contexts/dark_mode_provider";
import UserDetailsTitle from "./user_details_card/user_details_title";
import UserEmail from "./user_details_card/user_email";
import UserMobileNo from "./user_details_card/user_mobile_no";
import SaveAndEditButton from "./user_details_card/save_and_edit_button";
import UserAboutDetails from "./user_details_card/user_about_details";
import NameInput from "./user_details_card/name_input";
import UserName from "./user_details_card/user_name";

const UserDetailsCard = () => {
  const role = retrieveItem("role");
  const session_id = retrieveItem("session_id");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [initial_values, set_initial_values] = useState({});
  const [open_form, set_open_form] = useState(false);
  const [button_type, set_button_type] = useState();
  const [is_modal_visible, set_modal_visible] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const item = useDynamicSelector("get_user");
  const { is_dark_mode } = useDarkMode();
  const user_id = retrieveItem("user_id");
  const {
    status: delete_profile_status,
    error: delete_profile_error,
    loading: delete_profile_loading,
  } = useDynamicSelector("delete_my_profile_pic");

  useEffect(() => {
    if (delete_profile_status) {
      get_user_profile();
    }
  }, [delete_profile_status]);

  const delete_click = () => {
    setImageUrl(null);
    setPopoverVisible(false);
    set_modal_visible(true);
  };

  const handle_modal_ok = () => {
    set_modal_visible(false);
    delete_user_profile_pic();
  };

  const handle_modal_cancel = () => {
    set_modal_visible(false);
  };

  const delete_user_profile_pic = (val) => {
    let key = [{ key: "delete_my_profile_pic", loading: true }];
    let query = mutation_delete_my_profile_pic;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    if (user_id) {
      get_user_profile();
    }
  }, [user_id]);

  useEffect(() => {
    if (item?.id) {
      const { user_profile, staff_profile, email } = item;
      const name = (user_profile?.name ?? staff_profile?.name) || "";
      const mobile = (user_profile?.mobile ?? staff_profile?.mobile) || "";
      const about = (user_profile?.about ?? staff_profile?.about) || "";
      const url = (user_profile?.url ?? staff_profile?.url) || "";
      const profile_pic =
        (user_profile?.profile_pic ?? staff_profile?.profile_pic) || "";

      form.setFieldsValue({
        name,
        email,
        mobile,
        about,
        url,
        profile_pic,
      });

      set_initial_values({
        name,
        email,
        mobile,
        about,
        url,
        profile_pic,
      });
    }
  }, [item]);

  useEffect(() => {
    if (open_form == true) {
      set_button_type("Save");
    }
  }, [open_form]);

  const get_user_profile = () => {
    let key = [{ key: "get_user", loading: true }];
    let query = query_get_user;
    let variables = { id: user_id };
    dispatch(dynamic_request(key, query, variables));
  };

  const update_user_profile = (val) => {
    let key = [{ key: "update_user", loading: true }];
    let query = mutation_update_user_profile;
    let variables = {
      data: {
        name: val?.name,
        url: val?.url,
        about: val?.about,
        mobile: val?.mobile,
        profile_pic: val?.img?.[0]?.url,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const handle_edit = () => {
    set_open_form(true);
  };
  const handle_save_changes = (val, values) => {
    set_open_form(false);
    update_user_profile(val);
  };

  return (
    <div
      className="user_profile_details_card_division"
      style={{ backgroundColor: is_dark_mode ? "#2D2D2D" : "#FFFF" }}
    >
      <UserDetailsTitle />
      <Form
        name="basic"
        form={form}
        initialValues={initial_values}
        style={{ width: "100%" }}
        onFinish={
          open_form != true
            ? handle_edit
            : "" || open_form == true
            ? handle_save_changes
            : ""
        }
      >
        <div className="user_profile_image">
          <img
            width={60}
            height={60}
            src={initial_values?.profile_pic || DEFAULT_USER}
            style={{
              objectFit: "cover",
              borderRadius: 35,
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <EditProfileUpload
              initial_values={initial_values}
              set_initial_values={set_initial_values}
              setImageUrl={setImageUrl}
            />
            {initial_values?.profile_pic && (
              <RiDeleteBin5Fill
                style={{
                  color: is_dark_mode ? "white" : "black",
                  cursor: "pointer",
                }}
                onClick={delete_click}
              />
            )}
          </div>
          <UserName open_form={open_form} role={role} />

          <CustomText
            class_name={"premium_member_text"}
            text={role === "Previleged User" ? "Premium Member" : "Member"}
            font_family={"Lato"}
            size={"12px"}
            color={"#8b6851"}
            align={"left"}
            weight={"500"}
          />
        </div>

        <div className="user_details_card_text">
          <UserAboutDetails open_form={open_form} />
          <div className="edit_name_and_mob">
            <UserMobileNo open_form={open_form} />
            <UserEmail />
          </div>
        </div>
        <SaveAndEditButton open_form={open_form} button_type={button_type} />
      </Form>

      <Modal
        title={"Delete Profile"}
        visible={is_modal_visible}
        onOk={handle_modal_ok}
        onCancel={handle_modal_cancel}
        footer={[
          <Button key="cancel" onClick={handle_modal_cancel}>
            Cancel
          </Button>,
          <Button key="ok" type="primary" onClick={handle_modal_ok}>
            OK
          </Button>,
        ]}
        width={300}
        bodyStyle={{ padding: "12px 24px" }}
        style={{ textAlign: "center" }}
      >
        <p style={{ margin: 0 }}>
          Are you sure you want to delete the profile picture?
        </p>
      </Modal>
    </div>
  );
};

export default UserDetailsCard;
