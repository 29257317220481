import {
  Button,
  DatePicker,
  TimePicker,
  Form,
  InputNumber,
  Select,
  Upload,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import VForm from "../../system/ui/antd_form/antd_form";
import { useTranslation } from "react-i18next";
import { Box, HStack, VStack } from "native-base";
import PageTitle from "../../system/page_title/page_title";
import { useLocation } from "react-router-dom";
import {
  navigate,
  navigateWithProps,
  retrieveItem,
  showToast,
} from "../../../../helpers/functions";
import { ROUTES } from "../../../routes/my_routes";
import { UploadOutlined } from "@ant-design/icons";

import {
  article_process_query,
  dynamicSet,
  dynamic_clear,
  dynamic_request,
  get_all_languages_query,
  mutation_create_article,
  mutation_update_article,
  mutation_update_article_in_auto_save,
  query_get_all_category,
  save_article_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Content from "./admin_article_form/content";
import { useParams } from "react-router-dom";
import { query_get_article_preview } from "../../../../services/redux/slices/graphql/article_preview_graphql";
import PartyTag from "./admin_article_form/party_tags";
import moment from "moment";
import { check_perma_link_availability_query } from "../../../../services/redux/slices/graphql/graphql_perma_link";
import Category from "./admin_article_form/categories";
import dayjs from "dayjs";
import { QueryRequest } from "../../../../services/apollo/api_service";
import { fileQuery } from "../../../../helpers/constants";
import axios from "axios";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const AdminArticleDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();

  const data = useDynamicSelector("get_article");
  const article_loading = useDynamicSelector("get_article");

  let current_language_id = data?.language_id;

  const new_article_type = location?.props_state?.article_type;
  const back_button_default_language =
    location?.props_state?.default_language?.children;
  const current_language_id_select =
    location?.props_state?.default_language?.value;
  const role = retrieveItem("role");
  const current_time = dayjs(new Date()).format("HH:mm:ssZ");

  const [language_id, set_language_id] = useState(current_language_id);
  const [action_type, set_action_type] = useState("");
  const [process_type, set_process_type] = useState("");
  const [all_form_value, set_all_form_value] = useState({});
  const [publish_date, set_publish_date] = useState(null);
  const [publish_time, set_publish_time] = useState(null);
  const [content, set_content] = useState({ html: null, json: null });
  const [selected_item, set_selected_item] = useState({});
  const [form_loading, set_form_loading] = useState(false);

  console.log("set_publish_date", publish_date, publish_time);

  const article_title = Form.useWatch("title", form);
  const article_sub_title = Form.useWatch("subtitle", form);
  const article_language_id = Form.useWatch("language_id", form);
  const article_perma_link = Form.useWatch("perma_link", form);

  const {
    status: create_article_status,
    error: create_article_error,
    loading: create_loading,
  } = useDynamicSelector("create_article");

  const {
    status: update_article_status,
    error: update_article_error,
    loading: update_loading,
  } = useDynamicSelector("update_article");
  const { is_initial_save } = useDynamicSelector("is_initial_save");
  const {
    status: process_status,
    error: process_error,
    loading: process_loading,
  } = useDynamicSelector("process_article");

  const { items: system_languages } = useDynamicSelector(
    "get_all_system_languages"
  );
  const { items: categories } = useDynamicSelector("get_all_category_tag");
  const {
    id: save_article_id,
    status: save_article_status,
    loading: save_article_loading,
    error: save_article_error,
  } = useDynamicSelector("save_article");

  const { is_available, error: perma_link_error } = useDynamicSelector(
    "check_perma_link_availability"
  );

  useEffect(() => {
    let timer;
    if (article_loading) {
      timer = setTimeout(() => {
        set_form_loading(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [article_loading]);

  useEffect(() => {
    set_language_id(language_id || current_language_id);
  }, [language_id, current_language_id]);

  useEffect(() => {
    if (article_perma_link) {
      perma_link_availability_checking(article_perma_link);
    }
  }, [article_perma_link]);

  useEffect(() => {
    if (is_available === false) {
      form.setFields([
        {
          name: "perma_link",
          errors: ["Perma link already exists"],
        },
      ]);
    } else if (is_available === true) {
      form.setFields([
        {
          name: "perma_link",
          errors: [],
        },
      ]);
    }
  }, [is_available]);

  useEffect(() => {
    if (is_initial_save) {
      const interval_id = setInterval(() => {
        let auto_update_values = form.getFieldsValue();
        console.log("auto_update_values", auto_update_values, is_initial_save);
        edit_article_in_auto_save(auto_update_values);
      }, 30000);

      return () => clearInterval(interval_id);
    }
  }, [
    is_initial_save,
    form,
    content,
    selected_item,
    publish_time,
    publish_date,
  ]);

  useEffect(() => {
    get_all_categories();
    get_all_languages();
  }, [current_language_id]);

  useEffect(() => {
    if (id) {
      get_one_article();
      dispatch(dynamicSet("is_initial_save", { is_initial_save: true }));
    } else {
      dispatch(dynamic_clear("is_initial_save"));
    }
  }, [id]);

  useEffect(() => {
    if (id && data?.id) {
      let formatted_date = null;
      let formatted_time = null;
      if (data?.published_date_time) {
        const moment_object = dayjs(data?.published_date_time).tz(
          "Asia/Kolkata"
        );
        formatted_date = moment_object
          .subtract(5, "hour")
          .subtract(30, "minute")
          .format("YYYY-MM-DD");
        formatted_time = moment_object;
      } else {
        (formatted_date = dayjs(new Date())),
          (formatted_time = dayjs(current_time, "HH:mm:ssZ"));
      }
      console.log(
        "moment_object",
        moment(new Date(formatted_date)).format("YYYY-MM-DD"),
        moment(new Date(formatted_time)).format("HH:mm:ssZ")
      );

      form.setFieldsValue({
        ...data,
        category_tags: data?.category_tags?.map((x) => {
          return x.id;
        }),
        party_tags: data?.party_tags?.map((x) => {
          return x.id;
        }),
        published_date: formatted_date
          ? dayjs(formatted_date, "YYYY-MM-DD")
          : null,
        published_time: formatted_time
          ? dayjs(formatted_time, "HH:mm:ss")
          : null,
        content: data?.content,
        thumbnail_img: data?.thumbnail_img
          ? [
              {
                url: data?.thumbnail_img,
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
      });
      set_publish_date(
        formatted_date
          ? moment(new Date(formatted_date)).format("YYYY-MM-DD")
          : null
      );
      set_publish_time(
        formatted_time
          ? moment(new Date(formatted_time)).format("HH:mm:ssZ")
          : null
      );
      set_selected_item({
        ...data,
        category_tags: data?.category_tags?.map((x) => {
          return x.id;
        }),
        party_tags: data?.party_tags?.map((x) => {
          return x.id;
        }),

        published_date: formatted_date
          ? dayjs(formatted_date, "YYYY-MM-DD")
          : null,
        published_time: formatted_time
          ? dayjs(formatted_time, "HH:mm:ss")
          : null,

        content: {
          html: data?.content || "",
          json: data?.content_json || "",
        },
        thumbnail_img: data?.thumbnail_img
          ? [
              {
                url: data?.thumbnail_img,
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
      });

      if (data?.content || data?.content_json) {
        set_content({
          html: data?.content || null,
          json: data?.content_json || null,
        });
      }
    }
  }, [id, data]);

  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        published_date: dayjs(new Date()),
        published_time: dayjs(current_time, "HH:mm:ssZ"),
      });
      set_publish_date(dayjs(new Date()).format("YYYY-MM-DD"));
      set_publish_time(current_time);
    }
  }, [id]);

  useEffect(() => {
    if (create_article_status === "Success") {
      showToast({ type: "success", message: "Article created successfully" });
      dispatch(dynamic_clear("create_article"));
      navigate(`${ROUTES.ADMIN_ARTICLE}/${id}`);
    } else if (create_article_error?.message) {
      const formattedMessage = create_article_error?.message
        ? create_article_error.message
            .replace(/perma_link/i, "Perma link")
            .replace("exist", "exists")
        : "An error occurred";
      showToast({ type: "error", message: formattedMessage });
      dispatch(dynamic_clear("create_article"));
    } else if (update_article_status === "Success") {
      showToast({ type: "success", message: "Article updated successfully" });
      dispatch(dynamic_clear("update_article"));
    } else if (update_article_error?.message) {
      const formattedMessage = update_article_error?.message
        ? update_article_error.message
            .replace(/perma_link/i, "Perma link")
            .replace("exist", "exists")
        : "An error occurred";
      showToast({ type: "error", message: formattedMessage });
      dispatch(dynamic_clear("update_article"));
    }
  }, [
    create_article_status,
    create_article_error,
    update_article_status,
    update_article_error,
  ]);

  useEffect(() => {
    if (process_status === "Success") {
      if (process_type === "approve") {
        toast.success("Article Approved Successfully");
      } else if (process_type === "reject") {
        toast.success("Article Rejected Successfully");
      } else if (process_type === "submit") {
        toast.success("Article Submitted Successfully");
      } else if (process_type === "published") {
        toast.success("Article Published Successfully");
      } else if (process_type === "unpublished") {
        toast.success("Article Unpublished Successfully");
      }
      navigateWithProps(`${ROUTES.ADMIN_ARTICLE}`, {
        new_article_type,
        back_button_default_language,
        current_language_id_select,
        back_button_state: true,
      });
      dispatch(dynamic_clear("process_article"));
    } else if (process_status === "Failure") {
      toast.error("Couldn't approve the article");
      navigate(ROUTES.ADMIN_ARTICLE);
      dispatch(dynamic_clear("process_article"));
    } else if (process_error) {
      toast.error(process_error?.message);
      dispatch(dynamic_clear("process_article"));
    }
  }, [process_status, process_error, process_type, role]);

  useEffect(() => {
    if (save_article_status === "Success") {
      const id = save_article_id;
      dispatch(dynamicSet("is_initial_save", { is_initial_save: true }));
      dispatch(dynamic_clear("save_article"));
      toast.success("Article Saved Successfully");
      navigate(`${ROUTES.ADMIN_ARTICLE_DETAILS}/${id}`);
    } else if (save_article_error) {
      dispatch(dynamic_clear("save_article"));
    }
  }, [save_article_status, save_article_error]);

  const create_articles = (json) => {
    let key = [{ key: "create_article", loading: true }];
    let query = mutation_create_article;
    let all_data = {
      title: json?.title,
      subtitle: json?.subtitle,
      language_id: json?.language_id,
      perma_link: json?.perma_link,
      category_tags: json?.category_tags,
      party_tags: json?.party_tags,
      thumbnail_img: json?.thumbnail_img?.[0]?.url,
      content: content.html,
      content_json: content.json,
    };
    let variables = {
      json: all_data,
    };
    if (role === "Admin") {
      all_data.published_date_time = `${publish_date}T${publish_time}`;
    }
    dispatch(dynamic_request(key, query, variables));
  };

  const edit_article_in_auto_save = (json) => {
    let key = [{ key: "update_article_in_auto_save", loading: true }];
    let query = mutation_update_article_in_auto_save;
    let all_data = {
      title: json?.title,
      subtitle: json?.subtitle,
      language_id: json?.language_id,
      perma_link: json?.perma_link,
      category_tags: json?.category_tags,
      party_tags: json?.party_tags,
      thumbnail_img: json?.thumbnail_img?.[0]?.url,
      content: content.html,
      content_json: content.json,
    };
    let variables = {
      id: id,
      json: all_data,
    };
    if (
      role === "Admin" &&
      publish_date &&
      publish_time &&
      data?.status === "published"
    ) {
      variables.json.published_date_time = `${publish_date}T${publish_time}`;
    }
    dispatch(dynamic_request(key, query, variables));
  };

  const edit_article = (json) => {
    delete json.published_date;
    delete json.published_time;
    let key = [{ key: "update_article", loading: true }];
    let query = mutation_update_article;
    let items = {
      ...json,
      thumbnail_img: json?.thumbnail_img?.[0]?.url,
      content: content.html,
      content_json: content.json,
    };

    let variables = {
      id: data?.id,
      json: items,
    };
    if (role === "Admin" && data?.status === "published") {
      items.published_date_time = `${publish_date}T${publish_time}`;
    }
    dispatch(dynamic_request(key, query, variables));
  };

  const process_article = (type) => {
    let key = [{ key: "process_article", loading: true }];
    let query = article_process_query;
    let variables = {
      id: id,
      type: type,
    };
    if (role === "Admin") {
      variables.published_date_time = `${publish_date}T${publish_time}`;
    }
    dispatch(dynamic_request(key, query, variables));
  };

  const perma_link_availability_checking = (perma_link) => {
    let key = [{ key: "check_perma_link_availability", loading: true }];
    let query = check_perma_link_availability_query;
    let variables = {
      id: data?.id || "",
      type: "article",
      language_id: language_id,
      perma_link: perma_link,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_categories = () => {
    let key = [{ key: "get_all_category_tag", loading: true }];
    let query = query_get_all_category;
    let variables = {
      language_id: language_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const save_articles = () => {
    const json = form.getFieldsValue();
    let key = [{ key: "save_article", loading: true }];
    let query = save_article_query;
    let data = {
      title: json?.title,
      subtitle: json?.subtitle,
      language_id: json?.language_id,
      perma_link: json?.perma_link,
      category_tags: json?.category_tags,
      party_tags: json?.party_tags,
      thumbnail_img: json?.thumbnail_img?.[0]?.url,
      content: content.html,
      content_json: content.json,
    };
    delete data.party_tag_id;
    let variables = {
      json: data,
    };
    // if (role === "Admin") {
    //   data.published_date_time = `${publish_date}T${publish_time}`;
    // }
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_submit = (values) => {
    const recordId = data?.id;
    const isCreateAction = action_type === "create";
    const isUpdateAction = action_type === "update";
    const isPreviewAction = action_type === "preview";
    const is_submit = action_type === "submit";

    if (!recordId || isCreateAction) {
      create_articles(values);
    } else if (isUpdateAction) {
      edit_article(values);
    } else if (isPreviewAction) {
      edit_article_in_auto_save(values);
      preview_article();
    } else if (is_submit) {
      process_article("submit");
      edit_article_in_auto_save(values);
    }
  };

  const preview_article = () => {
    const url = `${ROUTES.ARTICLE_PREVIEW}/${id}`;
    const props = {
      article_id: id,
    };
    const queryString = new URLSearchParams(props).toString();
    const fullUrl = `${url}?${queryString}`;
    window.open(fullUrl, "_blank");
  };

  const handle_back = () => {
    navigateWithProps(`${ROUTES.ADMIN_ARTICLE}`, {
      new_article_type,
      back_button_default_language,
      current_language_id_select,
      back_button_state: true,
    });

    dispatch(dynamic_clear("get_article"));
  };

  const on_click = (type) => {
    set_process_type(type);
    process_article(type);
  };

  const on_change_language = (value) => {
    set_language_id(value);
  };

  const on_finish_failed = (errorInfo) => {
    const { errorFields } = errorInfo;
    if (errorFields.length > 0) {
      const fieldName = errorFields[0].name[0];
      form.scrollToField(fieldName, { behavior: "smooth", block: "center" });
    }
  };

  const handle_form_values_change = (values, all_values) => {
    set_all_form_value(all_values);
  };

  const get_one_article = () => {
    let key = [{ key: "get_article", loading: true }];
    let query = query_get_article_preview;
    let variables = {
      id: id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const on_change_date = (date, date_string) => {
    const date_format = date_string
      ? moment(date_string).format("YYYY-MM-DD")
      : null;
    set_publish_date(date_format);
  };

  const on_change_time = (time, timeString) => {
    const time_format = time ? dayjs(time).format("HH:mm:ssZ") : null;
    set_publish_time(time_format);
  };

  const handle_key_press = (e) => {
    if (/[\/?!.,]/.test(e.key)) {
      e.preventDefault();
    }
    if (e.key === " ") {
      e.preventDefault();
      const input = e.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const value = input.value;
      input.value = value.substring(0, start) + "-" + value.substring(end);
      input.setSelectionRange(start + 1, start + 1);
      const event = new Event("input", { bubbles: true });
      input.dispatchEvent(event);
    }
  };

  const handle_paste = (e) => {
    if (/[\/?!.,@#$%^&*()+_{}|~`]/.test(e.target.value)) {
      const input = e.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const value = input.value;
      input.value = value.substring(0, start) + "-" + value.substring(end);
      input.setSelectionRange(start + 1, start + 1);
      const event = new Event("input", { bubbles: true });
      input.dispatchEvent(event);
    }
    if (e.target.value === " ") {
      e.preventDefault();
      const input = e.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const value = input.value;
      input.value = value.substring(0, start) + "-" + value.substring(end);
      input.setSelectionRange(start + 1, start + 1);
      const event = new Event("input", { bubbles: true });
      input.dispatchEvent(event);
    }
  };

  useEffect(() => {
    dispatch(dynamic_clear("get_article"));
  }, []);

  useEffect(() => {
    if (article_title && article_language_id && !id) {
      const timer = setTimeout(() => {
        if (!id) save_articles();
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, [article_title, article_language_id]);

  const on_click_save_article = () => {
    if (article_title && article_language_id) {
      save_articles();
    } else {
      toast.error("Title and Language should be required");
    }
  };

  return (
    <div>
      <PageTitle title={!data.id ? "Add Article" : "Update Article"} />
      <VStack padding={"30px"}>
        <Spin spinning={form_loading} tip="Loading..."></Spin>
        <Form
          layout="vertical"
          form={form}
          onFinish={handle_submit}
          onFinishFailed={on_finish_failed}
          onValuesChange={handle_form_values_change}
          initialValues={selected_item}
        >
          <div
            style={{
              marginBottom: "150px",
            }}
          >
            <VForm.TextBox
              label={t("title_with_length")}
              field={"title"}
              rules={[{ required: true, message: "Title is required " }]}
              max_length={150}
            />
            <VForm.TextBox
              label={t("sub_title")}
              field={"subtitle"}
              rules={[{ required: true, message: "Subtitle is required " }]}
            />
            <HStack space={"10px"}>
              <Box flex={1}>
                <Form.Item
                  name="language_id"
                  label={"Language"}
                  rules={[{ required: true, message: "Language is required " }]}
                >
                  <Select onChange={on_change_language}>
                    {system_languages?.map((option, index) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Box>

              {role === "Admin" && (
                <Box flex={2}>
                  <HStack space={"10px"}>
                    <Form.Item
                      name="published_date"
                      label={"Published Date and Time"}
                      rules={[
                        {
                          required: true,
                          message: "Please pick the Date and Time",
                        },
                      ]}
                    >
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        onChange={on_change_date}
                      />
                    </Form.Item>
                    <Form.Item
                      name="published_time"
                      label={" "}
                      rules={[{ required: true, message: "" }]}
                    >
                      <TimePicker
                        use24Hours
                        format={"HH:mm:ss"}
                        onChange={on_change_time}
                      />
                    </Form.Item>
                  </HStack>
                </Box>
              )}
            </HStack>

            <HStack space={"10px"}>
              <Box flex={1}>
                <VForm.TextBox
                  label="Perma link"
                  field="perma_link"
                  onKeyPress={handle_key_press}
                  onPaste={handle_paste}
                  rules={[
                    { required: true, message: "Perma link is required" },
                    {
                      pattern: /^[^\/?!.,@#$%^&*()+_{}|~`]+$/,
                      message: "Please avoid special characters",
                    },
                  ]}
                />
              </Box>
              <Box flex={1}>
                <Category language_id={language_id} />
              </Box>
              <Box flex={1}>
                <PartyTag language_id={language_id} />
              </Box>
            </HStack>
            <VForm.ImageFile
              label={"Thumbnail Image"}
              field={"thumbnail_img"}
            />
            <Content
              content={selected_item?.content || ""}
              set_content={set_content}
            />
          </div>
          <div
            style={{
              height: "100px",
              position: "fixed",
              display: "flex",
              bottom: "0px",
              right: "30px",
              backgroundColor: "#f0f2f5",
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <Button onClick={handle_back} danger>
              {t("back")}
            </Button>

            {data?.id && (
              <Button
                onClick={() => {
                  set_action_type("preview");
                  form.submit();
                }}
                style={{
                  backgroundColor: "#F39C12",
                  color: "white",
                }}
              >
                {t("Preview")}
              </Button>
            )}

            {!data?.id && (
              <Button
                onClick={on_click_save_article}
                style={{
                  backgroundColor: "#cc972c",
                  color: "white",
                }}
                loading={save_article_loading}
              >
                {t("Save")}
              </Button>
            )}
            {data?.id &&
              data?.status != "published" &&
              data?.status != "approved_by_editor" &&
              data?.status != "draft" &&
              role != "Admin" &&
              data?.user_profile?.id && (
                <div>
                  <Button
                    danger
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      marginRight: "10px",
                    }}
                    onClick={() => on_click("reject")}
                  >
                    Reject
                  </Button>
                  <Button
                    style={{ backgroundColor: "green", color: "white" }}
                    onClick={() => on_click("approve")}
                  >
                    Approve
                  </Button>
                </div>
              )}

            {data.id &&
              id &&
              !(role === "Editor" && data?.status === "published") && (
                <Button
                  onClick={() => {
                    set_action_type("update");
                    form.submit();
                  }}
                  type="primary"
                  loading={update_loading}
                >
                  {"Update"}
                </Button>
              )}
            {!data.id && (
              <Button
                onClick={() => {
                  set_action_type("create");
                  form.submit();
                }}
                style={{ backgroundColor: "#1e90ff", color: "white" }}
                loading={create_loading}
              >
                {"Submit"}
              </Button>
            )}
            {!data?.id ||
              (data?.status === "draft" && (
                <Button
                  htmlType="submit"
                  onClick={() => {
                    set_action_type("submit");
                    form.submit();
                  }}
                  loading={process_loading}
                  style={{ backgroundColor: "#008000", color: "white" }}
                >
                  {"Submit"}
                </Button>
              ))}
            {data?.id &&
              (data?.status === "pending" ||
                data?.status === "unpublished" ||
                data?.status === "approved_by_editor") &&
              role === "Admin" && (
                <Button
                  onClick={() => on_click("published")}
                  loading={process_loading}
                  style={{ backgroundColor: "#008000", color: "white" }}
                >
                  {"Publish"}
                </Button>
              )}
            {data?.id && data?.status === "published" && role === "Admin" && (
              <Button
                onClick={() => on_click("unpublished")}
                loading={process_loading}
                style={{ backgroundColor: "#ff1a1a", color: "white" }}
              >
                {"Unpublish"}
              </Button>
            )}
          </div>
        </Form>
      </VStack>
    </div>
  );
};

export default AdminArticleDetails;
