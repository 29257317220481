import { Col, Row } from "antd";
import React from "react";
import CustomFactCheckRectangleSmallCard from "../../custom/custom_fact_check_rectangle_small_card";
import CustomText from "../../custom/custom_text";
import { useDynamicSelector } from "../../../../services/redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

const RecentlyAddedArticles = () => {
  const { t } = useTranslation();
  const { items: recent_article_list, loading: recent_article_loading } =
    useDynamicSelector("get_recent_articles");
  const slice_fact_recent_article = recent_article_list?.slice(6, 10);

  return (
    <Col className="single_article_recommended_stories">
      <CustomText
        class_name={"recently_added_font_size"}
        text={t("recently_added")}
        size={"26px"}
        weight={"700"}
        font_family={"Lato"}
        align={"left"}
      />

      <Row className="single_article_small_fact_check_card">
        {slice_fact_recent_article?.map((recent_added) => (
          <CustomFactCheckRectangleSmallCard
            src={recent_added?.thumbnail_img}
            title={recent_added?.title}
            date={
              recent_added
                ? moment(
                    recent_added?.published_date_time
                      ? parseInt(recent_added?.published_date_time, 10)
                      : recent_added?.created_date_time
                  ).format(`MMM DD, YYYY`)
                : ""
            }
            perma_link={recent_added?.perma_link}
            name={
              recent_added?.staff_profile?.name ||
              recent_added?.user_profile?.name ||
              "YouTurn Editorial"
            }
            type="article"
          />
        ))}
      </Row>
    </Col>
  );
};

export default RecentlyAddedArticles;
