import React from "react";
import { YOUTURN_NEW_LOGO } from "../../../../helpers/image_constants";
import { change_tamil_font_style } from "../../../../helpers/functions";
import { useTranslation } from "react-i18next";

const SignInFormMobileView = (props) => {
  const { t } = useTranslation();
  const { sign_in, sign_up, create_user_loading } = props;
  return (
    <div className="toggle-container">
      <div className="toggle">
        <div className="toggle-panel toggle-right">
          <div className="login_button_and_logo_image">
            <img
              style={{
                borderRadius: "9px",
                zIndex: 1,
                objectFit: "cover",

                height: "80px",
              }}
              src={YOUTURN_NEW_LOGO}
              alt="Profile"
            />
            <h1>{change_tamil_font_style(t("welcome_Back"), "4vh", _)}</h1>
            {/* <p>{t("already_have_an_account")}</p> */}
          </div>
        </div>
        <div className="toggle-panel toggle-left">
          <div className="sign_up_and_log_div">
            <img
              style={{
                borderRadius: "9px",
                zIndex: 1,
                objectFit: "cover",

                height: "80px",
              }}
              src={YOUTURN_NEW_LOGO}
              alt="Profile"
            />
            <h1>{t("welcome")}</h1>
            <p>{t("don't_have_an_account?")}</p>
            <div className="sing_up_button_div">
              <button className="hidden" id="register" onClick={sign_up}>
                {t("sign_up")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInFormMobileView;
