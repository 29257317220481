import React, { useEffect, useState } from "react";
import CustomText from "../../custom/custom_text";
import CustomBreadCrumbs from "../../custom/custom_breadcrumbs";
import { Row } from "antd";
import SearchResultsSearchBox from "./search_results_search_box";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SearchResultsHeaderContents = (props) => {
  const { search_text } = props;
  const history = useHistory();
  const [search_value, set_search_value] = useState("");

  useEffect(() => {
    if (search_value) {
      const queryParams = new URLSearchParams();
      queryParams.set("type", search_value);
      history.push({
        pathname: "/search-results",
        search: `?${queryParams.toString()}`,
      });
    }
  }, [search_value]);

  return (
    <div className="search_result_header_content">
      <div className="search_bread_crumbs_with_topic">
        <div className="search_result_bread_crumbs">
          <CustomBreadCrumbs pages={"Home/Search"} />
        </div>

        <Row className="search_result_topic">
          <CustomText
            class_name={"search_result_text"}
            text={`Results for: ${search_text}`}
            size={"30px"}
            font_family={"Kufam"}
            color={"#FFFFFF"}
            align={"left"}
            weight={"400"}
          />
        </Row>
      </div>
      <div
        style={{
          width: "100%",
        }}
      >
        <Row className="search_input_and_button">
          <SearchResultsSearchBox
            set_search_value={set_search_value}
            search_text={search_text}
            search_value={search_value}
          />
        </Row>
      </div>
      <div></div>
    </div>
  );
};

export default SearchResultsHeaderContents;
