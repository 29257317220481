import { Typography } from "antd";
import React, { useEffect } from "react";
import AdminFactCheckPreviewTopContent from "../../components/admin_fact_check_preview/admin_fact_check_preview_top_content";
import AdminFactCheckPreviewContent from "../../components/admin_fact_check_preview/admin_fact_check_preveiw_content";
import { overflow } from "styled-system";
import {
  dynamic_request,
  query_fact_check_preview,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PreviewSingleFactCheckPrimarySection from "../../components/admin_fact_check_preview/admin_fact_check_preview/preview_single_fact_check_primary_section";
import PreviewSingleFactCheckDetails from "../../components/admin_fact_check_preview/admin_fact_check_preview/preview_single_fact_check_details";

const AdminFactCheckPreview = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(window.location.search);
  const fact_check_id = queryParams.get("fact_check_id");
  const language_id = queryParams.get("language_id");

  const preview_fact_check = () => {
    let key = [{ key: "get_fact_check", loading: true }];
    let query = query_fact_check_preview;
    let variables = {
      id: fact_check_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    preview_fact_check();
  }, []);

  return (
    <div style={{ overflow: "auto", backgroundColor: "white" }}>
      {/* <AdminFactCheckPreviewTopContent /> */}
      <PreviewSingleFactCheckPrimarySection />
      <PreviewSingleFactCheckDetails />

      {/* <AdminFactCheckPreviewContent language_id={language_id} /> */}
    </div>
  );
};

export default AdminFactCheckPreview;
