import React, { useEffect, useState } from "react";
import CustomText from "../custom/custom_text";
import {
  EMAIL_LOGO,
  EYE_ICON,
  FACEBOOK_COLOR,
  INSTAGRAM_COLOR,
  LINKEDIN_COLOR,
  LINK_ICON,
  REPORT_ARTICLE,
  SAVE_FILLED_ICON,
  SAVE_ICON,
  SHARE_CHAT,
  SHARE_ICON,
  SHARE_ICON_COLOR,
  TELEGRAM_COLOR,
  THREADS_ICON,
  TWITTER_X_LOGO_BLACK,
  WHATS_APP_LOGO,
} from "../../../helpers/image_constants";
import { Image, Popover, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../services/redux";
import { useLocation } from "react-router-dom";
import { SITE_URL } from "../../../helpers/constants";
import LinkedInShareButton from "react-linkedin-share-link";
import CustomReportModel from "../custom/custom_report_model";
import { AiFillSave, AiOutlineSave } from "react-icons/ai";
import {
  format_view_count,
  getContent,
  retrieveItem,
} from "../../../helpers/functions";
import toast from "react-hot-toast";
import { FaLink, FaShareAlt, FaUnlink } from "react-icons/fa";

const ShareReportSave = (props) => {
  const { views, is_saved, handle_save, title, thumbnail_img } = props;
  const session_id = retrieveItem("session_id");
  const url = window.location.href;
  let current_language = retrieveItem("language");
  const role = retrieveItem("role");

  const { pathname } = useLocation();
  const [share_url, set_share_url] = useState();

  const [model_open, set_model_open] = useState(false);

  const { t } = useTranslation();

  const report_click = () => {
    if (session_id && (role === "User" || role === "Previleged User")) {
      set_model_open(true);
    } else {
      toast.error("Please Login");
    }
  };

  const copy_to_clipboard = () => {
    const redirect_url = window.location.href;
    navigator.clipboard?.writeText(redirect_url);
    toast.success("Link Copied");
  };

  useEffect(() => {
    if (current_language?.name === "tamil") {
      const site_url = new URL(SITE_URL);
      const domain = site_url.hostname;
      const link_without_html = pathname?.replace(".html", "");
      const url = `https://share.${domain}${link_without_html}`;
      set_share_url(url);
    } else {
      const site_url = new URL(SITE_URL);
      const domain = site_url.hostname;
      const link_without_html = pathname?.replace(".html", "");
      const url = `https://share.${domain}/en${link_without_html}`;
      set_share_url(url);
    }
  }, [pathname, SITE_URL]);

  const content = getContent(
    title,
    share_url,
    thumbnail_img,
    copy_to_clipboard
  );

  const on_share_click = () => {};
  return (
    <Row className="single_article_view_report_share">
      <div
        style={{
          height: "40px",
          width: "620px",
          width: "100%",
          borderTop: "1px solid #F49D36",
          borderBottom: "1px solid #F49D36",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Row className="report_share_icon">
            <Image
              style={{
                width: "20px",
                height: "20px",
                marginTop: "-4px",
              }}
              visible={false}
              preview={false}
              src={EYE_ICON}
            />

            <CustomText
              text={format_view_count(views)}
              size={"13px"}
              color={"#E84756"}
              weight={"400"}
              font_family={"Lato"}
              align={"left"}
            />
          </Row>
        </div>
        <div
          style={{ display: "flex", gap: "40px" }}
          className="report_icon_div"
        >
          <CustomReportModel
            model_open={model_open}
            set_model_open={set_model_open}
          />

          <Popover
            placement="top"
            content={content}
            trigger="hover"
            className="share_pop_over"
          >
            <Row className="report_share_icon">
              <Image
                style={{
                  width: "17px",
                  height: "17px",
                  cursor: "pointer",
                }}
                visible={false}
                preview={false}
                src={SHARE_ICON_COLOR}
              />

              <CustomText
                text={t("share")}
                size={"13px"}
                color={"#666376"}
                weight={"400"}
                font_family={"Lato"}
                align={"left"}
                is_link={true}
                on_click={on_share_click}
              />
            </Row>
          </Popover>
          <Row className="save_icon">
            {is_saved ? (
              <Image
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                visible={false}
                preview={false}
                src={SAVE_FILLED_ICON}
                onClick={handle_save}
              />
            ) : (
              <Image
                style={{
                  width: "20px",
                  cursor: "pointer",
                  height: "20px",
                }}
                visible={false}
                preview={false}
                src={SAVE_ICON}
                onClick={handle_save}
              />
            )}

            {/* {is_saved ? <AiFillSave /> : <AiOutlineSave />} */}
            <CustomText
              on_click={handle_save}
              text={t("save")}
              size={"13px"}
              color={"#666376"}
              weight={"400"}
              font_family={"Lato"}
              align={"left"}
              is_link={true}
            />
          </Row>
        </div>
      </div>
    </Row>
  );
};

export default ShareReportSave;
