import React from "react";
import CustomText from "../../custom/custom_text";
import { Col } from "antd";
import CustomFactCheckCategories from "../../custom/custom_fact_check_categories";
import { split_tamil_english_words } from "../../../../helpers/functions";
import moment from "moment";
import { useDynamicSelector } from "../../../../services/redux";
import { useTranslation } from "react-i18next";

const RelatedArticles = () => {
  const { t } = useTranslation();
  const { items: related_article_list, loading: related_article_loading } =
    useDynamicSelector("get_related_article");
  const slice_four_related_article = related_article_list?.slice(0, 4);
  return (
    <Col className="single_article_fact_check_categories">
      <CustomText
        class_name={"single_article_page_related_fact_check"}
        text={t("related_articles")}
        size={"26px"}
        weight={"700"}
        font_family={"Lato"}
        align={"left"}
      />

      <div className="related_fact_check_card_wrap">
        {slice_four_related_article?.map((article) => (
          <CustomFactCheckCategories
            width={"44%"}
            height={"43%"}
            category_image={article ? article?.thumbnail_img : ""}
            title={
              article?.title?.length > 40
                ? split_tamil_english_words(article?.title)
                : article?.title
            }
            sub_title={
              article?.subtitle?.length > 40
                ? split_tamil_english_words(article?.subtitle, _, 10)
                : article?.subtitle
            }
            created_date={
              article
                ? moment(
                    article?.published_date_time
                      ? parseInt(article?.published_date_time, 10)
                      : article?.created_date_time
                  ).format(`MMM DD, YYYY`)
                : ""
            }
            name={
              article?.staff_profile?.name ||
              article?.user_profile?.name ||
              "Youturn Editorial"
            }
            perma_link={article?.perma_link ? article?.perma_link : ""}
            type={"article"}
          />
        ))}
      </div>
    </Col>
  );
};

export default RelatedArticles;
