import { HStack, VStack } from "native-base";
import React from "react";
import PageTitle from "../../components/system/page_title/page_title";
import FactCheckHistory from "../../components/admin/admin_fact_check_history/fact_check_history";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AdminFactCheckHistory = () => {
  const location = useLocation();
  const fact_check_id = location?.props_state?.record?.id;
  return (
    <div>
      <HStack justifyContent={"flex-start"}>
        <PageTitle title={"Fact Check History"} />
      </HStack>
      <VStack padding={"40px"}>
        <FactCheckHistory fact_check_id={fact_check_id} />
      </VStack>
    </div>
  );
};

export default AdminFactCheckHistory;
