import React from "react";
import { useTranslation } from "react-i18next";
import DonationCardHorizontal from "../widgets/donation_card_horizontal";
import CustomText from "../custom/custom_text";
import CustomUserContributionFactCheck from "../custom/custom_user_contribution_fact_check";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { change_tamil_font_style } from "../../../helpers/functions";

const SupportUsDonationAndEmpowerTheTruth = () => {
  const { t } = useTranslation();
  const { is_dark_mode } = useDarkMode();

  return (
    <div className="support_us_first_container">
      <div>
        <div style={{ width: "90%" }} className="fight_against_fake_news_card">
          <DonationCardHorizontal
            class_name={"donation_card_horizontal_for_about_us"}
            donation_horizontal_main_div_class={
              "support_us_horizontal_first_div"
            }
            donation_text_class={"donation_text_for_about_us"}
            contribution_class={"contribution_main_div"}
            donate_button_class={"donate_button_div"}
            fake_news_title_class={"fake_news_title_for_about_us"}
          />
        </div>

        <div className="terms_and_conditions">
          <div className="terms_and_condition_points">
            <CustomText
              text={t("terms_and_conditions")}
              font_family={"Lato"}
              align={"left"}
              size={"3vh"}
              color={is_dark_mode ? "black" : "#140f2d"}
              class_name={"terms_and_conditions_text"}
            />
            <CustomText
              text={change_tamil_font_style(
                t("condition_first_point"),
                "2vh",
                _
              )}
              font_family={"Lato"}
              align={"left"}
              size={"2.5vh"}
              color={"#140f2d"}
              // weight={500}
              class_name={"terms_points"}
            />
            <CustomText
              text={change_tamil_font_style(
                t("condition_second_point"),
                "2vh",
                _
              )}
              font_family={"Lato"}
              align={"left"}
              size={"2.5vh"}
              color={"#140f2d"}
              class_name={"terms_points"}
            />
          </div>
        </div>
      </div>
      <div className="contribution_card_in_support_us">
        <CustomUserContributionFactCheck />
      </div>
    </div>
  );
};

export default SupportUsDonationAndEmpowerTheTruth;
