import { Button, Form, Select } from "antd";
import React, { useEffect } from "react";
import VForm from "../../system/ui/antd_form/antd_form";
import { HStack } from "native-base";
import { useTranslation } from "react-i18next";
import { update_user_claim_query } from "../../../../services/redux/slices/graphql/graphql_user_claims";
import {
  dynamic_clear,
  dynamic_request,
  fact_check_process_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { getUUID } from "../../../../helpers/functions";
import { toast } from "react-toastify";
import moment from "moment";

const UserClaimForm = (props) => {
  const {
    on_cancel,
    form,
    mutation_form_values,
    get_all_user_claims,
    claim_status,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    status: update_user_claim_status,
    loading: update_user_claim_loading,
    error: update_user_claim_error,
  } = useDynamicSelector("process_fact_check");

  useEffect(() => {
    if (update_user_claim_status === "Success") {
      toast.success("User Claim Updated Successfully");
      on_cancel();
      get_all_user_claims();
      dispatch(dynamic_clear("process_fact_check"));
    } else if (update_user_claim_error) {
      toast.error(update_user_claim_error?.message);
      on_cancel();
      dispatch(dynamic_clear("process_fact_check"));
    }
  }, [update_user_claim_status, update_user_claim_error]);

  const update_user_claim = (values) => {
    let status = String(values?.status);
    let key = [{ key: "process_fact_check", loading: true }];
    let query = fact_check_process_query;
    let variables = {
      id: mutation_form_values?.id,
      type: status,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  // const update_user_claim = (values) => {
  //   let status = String(values?.status);
  //   let key = [{ key: "update_user_claim", loading: true }];
  //   let query = update_user_claim_query;
  //   let variables = {
  //     json: {
  //       id: mutation_form_values?.id,
  //       data: {
  //         topic: values?.topic,
  //         ref_url: values?.url,
  //         description: values?.description,
  //         status: status,
  //       },
  //     },
  //   };
  //   dispatch(dynamic_request(key, query, variables));
  // };

  const handle_submit = (values) => {
    update_user_claim(values);
  };

  const status_options = [
    { id: "pending", name: "To-Do" },
    { id: "in_progress", name: "InProgress" },
    { id: "under_review", name: "Under Review" },
    { id: "published", name: "Published" },
    { id: "rejected", name: "Rejected" },
  ];

  return (
    <div>
      <Form onFinish={handle_submit} form={form} layout="vertical">
        <VForm.TextArea
          label="Description"
          field="description"
          disabled={true}
        />
        <VForm.TextBox label="URL" field="url" disabled={true} />
        <VForm.TextBox label="Date" field="created_date_time" disabled={true} />
        <Form.Item label="Status" name="status">
          <Select allowClear value={claim_status}>
            {status_options?.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <HStack justifyContent={"flex-end"} space={"10px"}>
          <Button
            htmlType="submit"
            loading={update_user_claim_loading}
            type="primary"
          >
            Submit
          </Button>
          <Button danger onClick={on_cancel}>
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default UserClaimForm;
