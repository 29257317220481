import React, { useEffect } from "react";
import { dynamic_clear, useDynamicSelector } from "../../../services/redux";
import ShareReportSave from "../single_article_page/share_report_save";
import SingleFactCheckCommentsList from "./single_fact_check_comments_list";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import CustomJoinWhatsappChannel from "../custom/custom_join_whatsapp_channel";
import SingleFactCheckSpreadNews from "./single_fact_check_spread_news";
import SingleFactCheckClaim from "./single_fact_check_claim";
import SingleFactCheckDetailExplain from "./single_fact_check_detail_explain";

const SingleFactCheckMainContent = (props) => {
  const { get_fact_check_saved_perma, handle_save } = props;
  const dispatch = useDispatch();

  const { views, thumbnail_img, title, content } = useDynamicSelector(
    "get_fact_check_by_perma_link"
  );

  const { is_saved: check_saved } = useDynamicSelector(
    "get_fact_check_perma_link_saved"
  );

  const { status: saved_status } = useDynamicSelector(
    "add_fact_check_to_saved_items"
  );

  const { status: removed_status } = useDynamicSelector(
    "remove_fact_check_from_saved_items"
  );

  useEffect(() => {
    if (saved_status === "Success") {
      get_fact_check_saved_perma();
      toast.success("Bookmark saved");
      dispatch(dynamic_clear("add_fact_check_to_saved_items"));
    }
  }, [saved_status]);

  useEffect(() => {
    if (removed_status === "Success") {
      get_fact_check_saved_perma();
      toast.success("Bookmark removed");
      dispatch(dynamic_clear("remove_fact_check_from_saved_items"));
    }
  }, [removed_status]);

  return (
    <div className="single-fact-check-content-div">
      <div className="single_fact_check_explanation">
        <SingleFactCheckClaim />

        <div className="single-fact-check-claim-column-second">
          <SingleFactCheckSpreadNews />
          <div className="hide-desk">
            <CustomJoinWhatsappChannel />
          </div>

          <SingleFactCheckDetailExplain />

          <div className="single-fact-check-view_report_share">
            <div className="single-fact-view-report-share">
              <ShareReportSave
                views={views}
                is_saved={check_saved}
                handle_save={handle_save}
                thumbnail_img={thumbnail_img}
                title={title}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SingleFactCheckCommentsList />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingleFactCheckMainContent;
