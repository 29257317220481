import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamic_request,
  query_get_all_previleged_user_article,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { countFormat } from "../../../../helpers/constants";
import moment from "moment";
import { get_user_contribution_list_query } from "../../../../services/redux/slices/graphql/graphql_user_contribution";
import { get_status } from "../../../../helpers/functions";
import { HStack } from "native-base";

const AdminUserContributions = ({ user_id, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const {
    items: get_user_contribution_list,
    loading: contributions_list_loading,
    pagination: my_contribution_pagination,
  } = useDynamicSelector("get_user_contribution_list");

  const get_one_contribution_list = () => {
    let key = [{ key: "get_user_contribution_list", loading: true }];
    let query = get_user_contribution_list_query;
    let variable = {
      user_profile_id: user_id,
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamic_request(key, query, variable));
  };

  useEffect(() => {
    if (type === "contribution") {
      get_one_contribution_list();
    }
  }, [user_id, type, page_number, page_limit]);

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };

  let columns = [
    {
      title: "S.No",
      key: "s_no",
      width: 65,
      render: (record, _, index) => index + 1,
    },
    {
      title: t("table:title"),
      dataIndex: "title",
      key: "title",
      width: 305,
    },

    {
      title: "Status",
      key: "status",
      render: (record) => {
        return get_status(record?.status);
      },
    },
    {
      title: "Type",
      // dataIndex: "record_type",
      key: "record_type",
      render: (record) => {
        return record?.record_type === "fact_check" ? "Fact Check" : "Article";
      },
    },

    {
      title: "Date",
      dataIndex: "created_date_time",
      key: "created_date_time",
      render: (text, record) => moment(text).format("MMM DD, YYYY"),
    },
  ];
  return (
    <div>
      <div style={{ maxHeight: 300, overflowY: "auto" }}>
        <Table
          columns={columns}
          loading={contributions_list_loading}
          dataSource={get_user_contribution_list}
          pagination={false}
          scroll={{ y: 170 }}
        />
      </div>
      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={my_contribution_pagination?.total_count}
          showSizeChanger={true}
          current={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>
    </div>
  );
};

export default AdminUserContributions;
