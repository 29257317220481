import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PageTitle from "../../system/page_title/page_title";
import { HStack, VStack } from "native-base";
import { Button, Form, Select } from "antd";
import { navigateBack } from "../../../../helpers/navigator";
import { useTranslation } from "react-i18next";
import Title from "../admin_article/admin_article_form/title";
import SubTitle from "../admin_article/admin_article_form/sub_title";
import Content from "../admin_article/admin_article_form/content";
import SpreadNews from "../admin_article/admin_article_form/spread_news";
import ShortStory from "../admin_article/admin_article_form/short_story";
import Source from "../admin_article/admin_article_form/source";
import VForm from "../../system/ui/antd_form";
import { useForm } from "react-hook-form";
import HistoryTitle from "./history_title";
import HistorySubtitle from "./history_subtitle";
import FactCheckContent from "./fact_check_content";
import FactCheckShortStory from "./fact_check_short_story";
import FactCheckSpreadNews from "./fact_check_spread_news";
import FactCheckSource from "./fact_check_source";

const FactCheckHistoryDetails = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const location = useLocation();
  const [content, set_content] = useState({ html: null, json: null });
  const [spread_news, set_spread_news] = useState({ html: null, json: null });
  const [short_story, set_short_story] = useState({ html: null, json: null });
  const [source, set_source] = useState({ html: null, json: null });
  const handle_modal_close = () => {
    navigateBack();
  };
  useEffect(() => {
    if (location) {
      const items = location?.props_state?.selected_items?.record;

      form.setFieldsValue({
        title: items?.title ? items?.title : "",
        subtitle: items?.subtitle ? items?.subtitle : "",
        is_fact: items?.is_fact ? items?.is_fact : "",
        perma_link: items?.perma_link ? items?.perma_link : "",
        is_fact: items?.is_fact ? items?.is_fact : "",
        content: items?.content
          ? set_content({ html: items?.content })
          : set_content({ html: null, json: null }),
        spread_news: items?.spread_news
          ? set_spread_news({ html: items?.spread_news })
          : "",
        short_story: items?.short_story
          ? set_short_story({ html: items?.short_story })
          : "",
        source: items?.source ? set_source({ html: items?.source }) : "",
        thumbnail_img: items?.thumbnail_img
          ? [
              {
                url: items?.thumbnail_img,
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
      });
    }
  }, [location, form]);
  return (
    <>
      <PageTitle title={"Fact Check History Details"} />
      <VStack padding={"30px"}>
        <HStack justifyContent={"flex-end"} space={"10px"}>
          <Button onClick={handle_modal_close} danger>
            {t("back")}
          </Button>
        </HStack>
        <Form id="fact_check_form" form={form} layout="vertical">
          <HistoryTitle />
          <HistorySubtitle />
          <Form.Item label="Rating" name={"is_fact"}>
            <Select disabled></Select>
          </Form.Item>
          <VForm.TextBox label={"Perma link"} field={"perma_link"} disabled />
          <VForm.ImageFile
            label="Thumbnail Image"
            field={"thumbnail_img"}
            is_disabled={true}
          />
          <Content
            content={content}
            set_content={set_content}
            is_disabled={true}
          />
          <SpreadNews
            content={spread_news}
            set_content={set_spread_news}
            is_disabled={true}
          />
          <ShortStory
            content={short_story}
            set_content={set_short_story}
            is_disabled={true}
          />
          <Source
            content={source}
            set_content={set_source}
            is_disabled={true}
          />
        </Form>
      </VStack>
    </>
  );
};

export default FactCheckHistoryDetails;
