import { Form, Input, Row } from "antd";
import { Box, Pressable, Text } from "native-base";
import React, { useState } from "react";

const AmountCard = (props) => {
  const { handle_get_amount, class_name } = props;
  const [selectedAmount, setSelectedAmount] = useState("");

  const handleAmountSelection = (e) => {
    setSelectedAmount(e.id);
    handle_get_amount(Number(e.id));
  };

  const handle_focus = (e) => {
    e.target.select();
  };
  let amount_values = [
    {
      id: "199",
      name: "₹199",
    },
    {
      id: "499",
      name: "₹499",
    },
    {
      id: "999",
      name: "₹999",
    },
    {
      id: "1999",
      name: "₹1, 999",
    },
  ];

  const AmountFormatContainer = () => {
    return amount_values.map((amt) => {
      return (
        <Pressable onPress={() => handleAmountSelection(amt)}>
          <Row
            className={class_name}
            style={{
              backgroundColor:
                selectedAmount === amt.id ? "#00adee" : "gray.200",
              color: selectedAmount === amt.id ? "white" : "white",
            }}
            width={{
              base: "125px",
              lg: "80px",
              xl: "100px",
              "2xl": "100px",
              "4xl": "100px",
            }}
            padding={"10px"}
            alignItems={"center"}
            borderRadius={"10px"}
          >
            <Text
              fontFamily={"lato"}
              fontSize={{
                base: "20px",
                lg: "19px",
                xl: "20px",
                "2xl": "20px",
                "4xl": "20px",
              }}
              fontWeight={"400"}
              color={selectedAmount === amt.id ? "white" : "black"}
            >
              {amt.name}
            </Text>
          </Row>
        </Pressable>
      );
    });
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        gap: "10px",
      }}
    >
      <AmountFormatContainer />
    </div>
  );
};

export default AmountCard;
