import React, { useState } from "react";
import CustomText from "../custom/custom_text";
import moment from "moment";
import { Avatar, Col, Image } from "antd";
import {
  DEFAULT_USER,
  REPLY_IMAGE,
  USER_COMMENT_PROFILE,
  USER_LOGO,
  YOU_TURN_LOGO_BLUE,
} from "../../../helpers/image_constants";
import SingleFactCheckReplyComment from "./single_fact_check_reply_comment";
import { useTranslation } from "react-i18next";

const SingleFactCheckCommentProfile = (props) => {
  const { t } = useTranslation();
  const {
    cmd_index,
    comment,
    set_comment_id,
    parent_reply_comment_id,
    set_main_comment_id,
    main_comment_id,
  } = props;
  const [comment_reply_open, set_comment_reply_open] = useState(false);
  const on_click = (values) => {
    set_comment_id(values);
  };

  const comment_reply_click = (id) => {
    set_main_comment_id(id);
    if (comment_reply_open) {
      set_comment_reply_open(false);
    } else set_comment_reply_open(true);
  };
  return (
    <div>
      <div
        style={{ height: "10vh", display: "flex", flexDirection: "row" }}
        key={cmd_index}
        id={`comment-${comment.id}`}
        onClick={() => on_click(comment.id)}
      >
        <div
          style={{
            width: "45%",
            height: "10vh",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Col className="single-fact-check-comment-profile-image">
            <div className="laptop-text-comment">
              {/* <Avatar
                src={comment?.commented_by?.user_profile?.profile_pic}
                alt="User Avatar"
                class_name="single-fact-check-mobile-view-profile-image"
                visible={false}
                preview={false}
              /> */}

              <img
                className="profile-image-size"
                src={
                  comment?.commented_by?.user_profile?.profile_pic ??
                  comment?.commented_by?.staff_profile?.profile_pic ??
                  DEFAULT_USER
                }
              />
            </div>
          </Col>

          <Col
            style={{
              height: "10vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <CustomText
              class_name={"single_fact_check_comment_name"}
              key={comment.id}
              text={comment?.commented_by?.user_profile?.name}
              align={"left"}
              font_family={"Lato"}
            />
            <CustomText
              class_name={"single_fact_check_comment_name_date"}
              text={moment(comment?.created_date_time).format(`MMM D, YYYY`)}
              align={"left"}
              font_family={"Lato"}
            />
          </Col>
        </div>
      </div>
      <div className="single-fact-check-comment-content-row">
        <div style={{ width: "5%" }}></div>
        <div className="single-fact-check-comment-content">
          <CustomText
            class_name="single-fact-check-comment-mobile"
            text={comment?.content}
            font_family={"Lato"}
            align={"left"}
          />
        </div>

        <div
          className="single-fact-check-comment-reply-div"
          onClick={() => {
            comment_reply_click(comment?.id);
          }}
          key={cmd_index}
          id={`comment-${comment.id}`}
        >
          <div className="single-fact-check-comment-reply_icon">
            <Image
              className="single-fact-check-reply-image-text"
              visible={false}
              preview={false}
              src={REPLY_IMAGE}
            />
          </div>
          <div className="single-fact-check-comment-reply">
            <CustomText
              class_name="single-fact-check-reply-comment-text"
              text={t("reply")}
              align={"left"}
              font_family={"Lato"}
              color={"#00adee"}
            />
          </div>
        </div>
      </div>
      {main_comment_id === comment?.id && comment_reply_open ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <SingleFactCheckReplyComment
            main_comment_id={main_comment_id}
            set_comment_reply_open={set_comment_reply_open}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SingleFactCheckCommentProfile;
